import { ReactComponent as Spinner } from '@tradera/blueprint/theme/icons2/loading.svg'

interface LoadingModalProps {
  message: string
  closeModal: () => void
}

const LoadingModal: React.FunctionComponent<LoadingModalProps> = ({
  message,
  closeModal,
}) => {
  return (
    <div>
      <div className="p-3">
        <div className="d-flex justify-content-center align-items-center p-3">
          <div className="text-center">
            <Spinner className="icon icon-lg iconSpin" />
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingModal
