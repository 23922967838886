import React, { useEffect, useState } from 'react'
import { ReactComponent as Plus } from '../../Icons/plus.svg'
import { ReactComponent as Close } from '../../Icons/close-icon.svg'
import { ItemType, type IItem } from '../../Items/IItem'
import { useDispatch } from 'react-redux'
import { updateItem } from '../../Items/Items.action'
import useKeyDown from '../../Hooks/useKeyDown'
import TimeInput from '../../Components/TimeInput'

interface EndTimeRendererProps {
  data: IItem
}

const EndTimeRenderer: React.FunctionComponent<EndTimeRendererProps> = ({
  data,
}) => {
  const dispatch = useDispatch()
  const [isEditMode, setEditMode] = useState(false)
  const formatTime = (value: number) => {
    return value < 10 ? '0' + value?.toString() : value?.toString()
  }

  const getEndTimeString = (data: IItem) => {
    return (
      formatTime(data.chosenEndTimeHour) +
      ':' +
      formatTime(data.chosenEndTimeMinute)
    )
  }

  const [endTimeString, setEndTimeString] = useState<string>(
    getEndTimeString(data)
  )
  useEffect(() => {
    if (endTimeString !== getEndTimeString(data)) {
      setEndTimeString(getEndTimeString(data))
    }
  }, [data])

  const callbackEnter = () => {
    if (isEditMode) {
      saveTime()
    }
  }
  useKeyDown('Enter', callbackEnter)

  const editClick = () => {
    if (data.itemType === ItemType.Auction) {
      setEditMode(true)
      if (endTimeString === '00:00') {
        setEndTimeString('')
      }
    }
  }

  const saveTime = () => {
    if (endTimeString.length === 5) {
      const [hoursStr, minutesStr] = endTimeString.split(':')
      const itemToUpdate = {
        ...data,
        hasChosenEndTime: true,
        chosenEndTimeHour: Number(hoursStr),
        chosenEndTimeMinute: Number(minutesStr),
      }
      dispatch(updateItem(itemToUpdate, false))
      setEditMode(false)
    } else {
      setEditMode(false)
    }
  }

  const resetTime = () => {
    setEditMode(false)
    setEndTimeString('')
    const itemToUpdate = {
      ...data,
      hasChosenEndTime: false,
      chosenEndTimeHour: 0,
      chosenEndTimeMinute: 0,
    }
    dispatch(updateItem(itemToUpdate, false))
  }

  const onBlurHandler = (event) => {
    saveTime()
  }

  const onTimeChangeHandler = (val) => {
    if (val.length === 5) {
      setEndTimeString(val)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {!isEditMode &&
        (endTimeString === '' || endTimeString === '00:00') &&
        data.itemType === ItemType.Auction && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              paddingLeft: 20,
            }}
            onClick={editClick}
          >
            <Plus
              className="visible-on-hover"
              style={{ width: 16, height: 16, margin: 10 }}
            />
          </div>
        )}
      {!isEditMode && endTimeString !== '' && endTimeString !== '00:00' && (
        <div
          style={{
            width: '100%',
            paddingLeft: 20,
          }}
          onClick={editClick}
        >
          {data.itemType !== ItemType.Auction ? (
            <span className="text-function">{endTimeString}</span>
          ) : (
            <span>{endTimeString}</span>
          )}
        </div>
      )}
      {isEditMode && (
        <div>
          <TimeInput
            name="timeinput"
            initTime={endTimeString}
            onTimeChange={onTimeChangeHandler}
            onBlurHandler={onBlurHandler}
          />
        </div>
      )}
      {isEditMode && (
        <Close style={{ marginLeft: 10 }} onMouseDown={resetTime} />
      )}
    </div>
  )
}

export default EndTimeRenderer
