import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import {
  duplicateItemsSucceeded,
  moveItemsSucceeded,
  createItemSucceeded,
} from '../Items/Items.action'
import type { IAction } from '../Store/interfaces'
import type { IList } from './IList'
import {
  getListsSucceeded,
  createListSucceeded,
  createImportedListSucceeded,
  getListByIdSucceeded,
  duplicateListSucceeded,
  deleteListSucceeded,
  createListFromTemplateSucceeded,
  updateList,
  updateListByIdSucceeded,
  importListFromPlZipSucceeded,
  updateListNameSucceeded,
  publishListSucceeded,
  resetCancelPublishListSucceeded,
  moveUpItemInListSucceeded,
  moveDownItemInListSucceeded,
  moveTopItemInListSucceeded,
  moveBottomItemInListSucceeded,
  updateManyItemsAtOnceSucceeded,
  deleteItemsFromListSucceeded,
  reorderListSucceeded,
} from './List.actions'

import listReducer from './List.reducer'

export interface IAdvertisementListsState {
  [key: string]: IList
}

export const initialState: IAdvertisementListsState = {}

const normalizeLists = (lists: IList[]) =>
  lists.reduce((_lists, _list) => {
    _lists[_list.id] = _list
    _lists[_list.id].items = _lists[_list.id].items.map((item) => item.id)
    return _lists
  }, {})

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

export const removeProperties = (obj: any, properties: string[]) => {
  const newState = { ...obj }
  properties.map((property) => delete newState[property])
  return newState
}

export const removeItems = (obj: any, properties: string[], listId) => {
  const newState = { ...obj }
  const filteredItems = newState[listId].items.filter(
    ({ id }) => !properties.includes(id!)
  )
  return {
    ...newState,
    [listId]: {
      ...newState[listId],
      items: filteredItems,
    },
  }
}

const byId: Reducer<IAdvertisementListsState, IAction<any>> = handleActions(
  {
    [getListsSucceeded.toString()]: (
      _state: IAdvertisementListsState,
      action: IAction<any>
    ) => normalizeLists(action.payload),
    [createListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [createImportedListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [createListFromTemplateSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [duplicateListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [createItemSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.listId]: listReducer(
        state[action.payload.listId],
        action
      ),
    }),
    [duplicateItemsSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [moveItemsSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<any>
    ) => {
      const { fromList, toList } = action.payload
      return {
        ...state,
        [fromList.id]: listReducer(state[fromList.id], {
          ...action,
          payload: fromList,
        }),
        [toList.id]: listReducer(state[toList.id], {
          ...action,
          payload: toList,
        }),
      }
    },
    [reorderListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [deleteListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<any>
    ) => removeProperties(state, action.payload),
    [deleteItemsFromListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [getListByIdSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [moveUpItemInListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [moveDownItemInListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [moveTopItemInListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [moveBottomItemInListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [updateManyItemsAtOnceSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [publishListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [resetCancelPublishListSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [updateList.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [updateListByIdSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [importListFromPlZipSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => ({
      ...state,
      [action.payload.id]: listReducer(state[action.payload.id], action),
    }),
    [updateListNameSucceeded.toString()]: (
      state: IAdvertisementListsState,
      action: IAction<IList>
    ) => {
      return {
        ...state,
        [action.payload.id]: listReducer(state[action.payload.id], action),
      }
    },
  },
  initialState
)

const allIds = handleActions(
  {
    [getListsSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<any>
    ) => [...action.payload.map((l: IList) => l.id)],
    [createListSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state, action.payload.id],
    [createImportedListSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => {
      const listId = action.payload.id
      if (!state.includes(listId)) {
        return [...state, action.payload.id]
      }
      return [...state]
    },
    [duplicateListSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state, action.payload.id],
    [deleteListSucceeded.toString()]: (state: string[], action: IAction<any>) =>
      state.filter((s) => !action.payload.includes(s)),
    [createListFromTemplateSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state, action.payload.id],
    [importListFromPlZipSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => {
      const listId = action.payload.id
      if (!state.includes(listId)) {
        return [...state, action.payload.id]
      }
      return [...state]
    },
  },
  []
)

const advertisementListsReducer = combineReducers({
  byId,
  allIds,
})

export default advertisementListsReducer
