import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../Components/Button'
import type { IFileUploadImage } from '../../Items/IItem'
import type { IUser } from '../../User/IUser'
import type { ISellItem } from '../../SellView/ISellView'
import SellItemForm from '../../SellView/SellItemForm'
import useItem from '../../SellView/SellItem.hook'
import { updateSellItem } from '../../SellView/SellView.actions'
import styles from './EditShopItem.module.scss'
import { ReactComponent as Spinner } from '../../Icons/loading.svg'
import { useTranslation } from 'react-i18next'

interface EditShopItemProps {
  closeModal: () => void
  itemFromApi: ISellItem
  user: IUser
  saving: boolean
  addImages: (images: FormData, id: string) => Promise<any>
}

const EditShopItem: React.FunctionComponent<EditShopItemProps> = ({
  closeModal,
  itemFromApi,
  user,
  saving,
  addImages,
}) => {
  const { t } = useTranslation()
  const [imageList, setImageList] = useState<IFileUploadImage[]>([])
  const { item, handleInputChange, handleEditorChange, updateItemState } =
    useItem(itemFromApi)

  const dispatch = useDispatch()

  const updateImages = (files: File[], id: string) => {
    let formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file)
    })
    addImages(formData, id)
  }

  const update = (item: ISellItem) => {
    let formData = new FormData()
    if (imageList) {
      imageList.forEach((image) => {
        if (image.file) {
          formData.append(`files`, image.file)
        }
      })
    }
    for (let key in item) {
      const objectsToStringify = [
        'category',
        'attributes',
        'shippingalternative',
      ]
      if (objectsToStringify.includes(key.toLowerCase())) {
        formData.append(key, JSON.stringify(item[key]))
      } else {
        if (item[key] === null) continue
        formData.append(key, item[key])
      }
    }
    dispatch(updateSellItem(formData))
  }

  return (
    <div className={styles.modalContainer}>
      {saving && (
        <div className={styles.loadingOverlay}>
          <Spinner className={styles.iconSpin} />
        </div>
      )}
      <div>
        <SellItemForm
          item={item}
          user={user}
          imageList={imageList}
          handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e, dispatch)
          }
          updateStateByItem={(e, v) => updateItemState(e, v, dispatch)}
          handleEditorChange={(editorState) =>
            handleEditorChange(editorState, dispatch)
          }
          updateImages={(files, id) => updateImages(files, id)}
          handleImageListChange={setImageList}
        />
        <div className="modal-footer modal-footer-sticky border-top">
          <div className="row">
            <div className="col-6">
              <Button
                clickHandler={() => closeModal()}
                variant="outlined"
                size="normal"
                text={t('modal_editShopItem_button_cancel')}
                disable={saving}
              />
            </div>
            <div className="col-6">
              <Button
                testId="create-itemchanges"
                clickHandler={() => {
                  update(item)
                }}
                variant="primary"
                size="normal"
                text={t('modal_editShopItem_button_confirm')}
                disable={saving}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditShopItem
