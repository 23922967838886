import React from 'react'
import styles from './EditCategoryForm.module.scss'
import Category from '../ItemForm/Category'
import type { IItem } from '../../Items/IItem'

interface EditCategoryFormProps {
  item: IItem
  updateStateByItem: (
    property: string,
    value: string | number | boolean | File[] | string[] | any
  ) => void
}
const EditCategoryForm: React.FunctionComponent<EditCategoryFormProps> = ({
  item,
  updateStateByItem,
}) => {
  return (
    <div className={styles.itemForm}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Category item={item} updateStateByItem={updateStateByItem} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCategoryForm
