import React, { useEffect, useState } from 'react'
import ListMenu from './ListMenu'
import type { IList } from '../List/IList'
import ProListerGrid from '../ProListerGrid'
import {
  allListsColumns,
  allTemplateColumns,
} from '../ProListerGrid/GridMetaData'
import type { ITab } from '../Content/IContent'
import { TabTypes } from '../Content/IContent'
import type { ITemplate } from '../Template/ITemplate'
import type {
  ValueSetterParams,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { useDispatch } from 'react-redux'
import { updateListName, updateList } from '../List/List.actions'
import { updateTemplateName } from '../Template/Template.actions'
import { ReactComponent as Spinner } from '../Icons/loading.svg'
import styles from './MyLists.module.scss'
import type { IUser } from '../User/IUser'
import type { ItemListMenuItemProps } from '../Components/ListMenuItem/ListMenuItem'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'

interface MyListProps {
  loadingLists: boolean
  user: IUser
  openContent: (content: ITab, name: string) => void
  getContent: (type: TabTypes) => void
  selectedRawData: (type: TabTypes) => void
  cellDoubleClick: (type: TabTypes, content: IList | ITemplate) => void
  updateContent: (type: TabTypes, content: IList | ITemplate) => void
  importListFromPlZip: (plZipFile: any) => void
  setImportFileSize: (importFileSize: number) => void
  updateColumnOrder: (columns: string[]) => any
  setImportData: (importData: any) => void
  openImportMappingModal: () => void
  onGridApi: (id: string, api: any) => void
  getMenuItems: (type: TabTypes, t: TFunction) => ItemListMenuItemProps[]
  getContextMenu: (
    type: TabTypes,
    t: TFunction
  ) => {
    name: string
    link?: string
    icon: React.ReactNode
    action?: (selectedItem: IList | ITemplate) => void
  }[]
}

const MyList: React.FunctionComponent<MyListProps> = ({
  loadingLists,
  user,
  getContent,
  selectedRawData,
  getMenuItems,
  getContextMenu,
  cellDoubleClick,
  updateContent,
  setImportFileSize,
  importListFromPlZip,
  updateColumnOrder,
  setImportData,
  openImportMappingModal,
  onGridApi,
}) => {
  const { t } = useTranslation()
  const [selectedContent, setSelectedContent] = useState<IList | ITemplate>()
  const [selectedContentType, setSelectedContentType] = useState<TabTypes>(
    TabTypes.List
  )
  const rawData = selectedRawData(selectedContentType)
  const menu = getMenuItems(selectedContentType, t)
  const contextMenu = getContextMenu(selectedContentType, t)
  const [gridApi, setGridApi]: any = useState()
  const dispatch = useDispatch()

  const onGridApiReady = (id: string, api: any) => {
    setGridApi(api)
    onGridApi(id, api)
  }

  const onCellContextMenu = (content: IList | ITemplate) => {
    setSelectedContent(content)
  }

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    if (event.api !== null) {
      var selectedRows = event.api !== null ? event.api.getSelectedRows() : ''

      /*  uncheck all cecked lists */
      event.api.forEachNode((node) => {
        var list = node.data as IList
        const itemToUpdate = {
          ...list,
          isChecked: false,
        }
        dispatch(updateList(itemToUpdate))
      })

      if (selectedContentType === TabTypes.List) {
        var selection = selectedRows as Array<IList>
        selection.map((item) => {
          const itemToUpdate = {
            ...item,
            isChecked: true,
          }
          dispatch(updateList(itemToUpdate))
        })
      }
    }
  }

  useEffect(() => {
    getContent(selectedContentType)
  }, [getContent, selectedContentType])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <ListMenu
        menuItems={menu}
        setImportFileSize={setImportFileSize}
        importListFromPlZip={importListFromPlZip}
        setImportData={setImportData}
        openImportMappingModal={openImportMappingModal}
      />

      <div
        className="card"
        style={{ flex: 1, flexDirection: 'column', height: '100%' }}
      >
        {!loadingLists ? (
          <ProListerGrid
            user={user}
            paddingBottom={96}
            onCellContextMenu={(content) =>
              onCellContextMenu(content as IList | ITemplate)
            }
            updateContent={(content) => {
              updateContent(selectedContentType, content)
            }}
            selectedContentType={selectedContentType}
            onCellDoubleClicked={(content) =>
              cellDoubleClick(selectedContentType, content as any)
            }
            rawData={rawData as any}
            selectedContent={selectedContent!}
            columns={
              selectedContentType === TabTypes.List
                ? allListsColumns
                : allTemplateColumns
            }
            menuItems={contextMenu as any}
            onCellEditingStopped={(e: ValueSetterParams) => {
              const updateAction =
                selectedContentType === TabTypes.List
                  ? updateListName
                  : updateTemplateName
              dispatch(updateAction((e.data as any).id, e.newValue))
            }}
            onSelectionChanged={(event) =>
              onSelectionChanged(event as SelectionChangedEvent)
            }
            updateColumnOrder={updateColumnOrder}
            onGridApi={(api) => onGridApiReady('mylists', api)}
          />
        ) : (
          <div className={styles.loadingContainer}>
            <Spinner className={styles.iconSpin} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MyList
