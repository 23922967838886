import React from 'react'
import type { IPriceSummary } from '../../Items/IItem'

interface PriceSummaryProps {
  highlight: boolean
  items: IPriceSummary[]
}

const PriceSummary: React.FunctionComponent<PriceSummaryProps> = ({
  items,
  highlight,
}) => {
  return (
    <div className="mr-2">
      {items.map((item, key) => (
        <p
          key={key}
          className="text-small text-left"
          style={{
            background: item.isPrice && highlight ? 'yellow' : 'transparent',
          }}
        >
          {item.name}: {item.amount} {item.indicator ? item.indicator : ' kr'}
        </p>
      ))}
    </div>
  )
}

export default PriceSummary
