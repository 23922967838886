import React from 'react'
import { format, addDays } from 'date-fns'
import svLocale from 'date-fns/locale/sv'

import Select from '../../../Components/Select'
import { ItemType, type IItem } from '../../IItem'
import Label from '../../../Components/Label'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ItemLengthProp {
  updateStateByItem: (property: string, value: any) => void
  item: IItem | ISellItem
}

const ItemLength: React.FunctionComponent<ItemLengthProp> = ({
  updateStateByItem,
  item,
}) => {
  const { t } = useTranslation()
  let dates: {
    date: Date
    dateNumber: number
  }[] = []
  let hours: string[] = []
  let minutes: string[] = []

  for (let i = 3; i <= 14; i++) {
    const date = addDays(new Date(), i)
    dates = [...dates, { date, dateNumber: i }]
  }
  for (let i = 0; i < 24; i++) {
    hours = [...hours, i < 10 ? `0${i}` : `${i}`]
  }
  for (let i = 0; i < 60; i++) {
    minutes = [...minutes, i < 10 ? `0${i}` : `${i}`]
  }

  const changeEndTimeHours = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const hour = parseInt(e.currentTarget.value)
    updateStateByItem('chosenEndTimeHour', hour as any)
  }
  const changeItemLength = (e) => {
    // Denna triggas när man ändrar i dropdown för att välja annonslängd
    const number = parseInt(e.target.value)
    updateStateByItem('advertisementLengthInDays', number)
  }

  return (
    <>
      {item.itemType === ItemType.Auction && (
        <div className="row d-flex align-items-center">
          <div className="col-md-8">
            <Select
              onChange={(e) => changeItemLength(e)}
              label={t('itemsForm_itemLength_label')}
              defaultValue={
                item.advertisementLengthInDays
                  ? item.advertisementLengthInDays.toString()
                  : '7'
              }
            >
              {dates.map((item, key) => (
                <option
                  key={`${item.dateNumber}${key}`}
                  value={item.dateNumber}
                >
                  {`${item.dateNumber} ${t(
                    'itemsForm_itemLength_days'
                  )} ${format(item.date, 'iii dd MMM', {
                    locale: svLocale,
                  })}`}
                  )
                </option>
              ))}
            </Select>
          </div>
          <div className="col-md-4">
            {item.hasChosenEndTime && (
              <div className="mb-1">
                <Label label={t('itemsForm_endTime_label')} />
                <div className="d-flex gap-x-2">
                  <Select
                    onChange={(e) => changeEndTimeHours(e)}
                    defaultValue={
                      item.chosenEndTimeHour !== 0 &&
                      item.chosenEndTimeHour < 10
                        ? '0' + item.chosenEndTimeHour.toString()
                        : item.chosenEndTimeHour !== 0 &&
                          item.chosenEndTimeHour >= 10
                        ? item.chosenEndTimeHour.toString()
                        : '00'
                    }
                  >
                    {hours.map((item, key) => (
                      <option key={`${item}${key}`} value={item}>
                        {`${item}`}
                      </option>
                    ))}
                  </Select>
                  <Select
                    defaultValue={item.chosenEndTimeMinute.toString()}
                    disable={true}
                  >
                    {minutes.map((item, key) => (
                      <option key={`${item}${key}`} value={item}>
                        {`${item}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ItemLength
