import { connect } from 'react-redux'
import { closeModal, openEditManyItemsAtOnceModal } from '../../UI/UI.actions'
import { updateManyItemsAtOnce } from '../../List/List.actions'
import EditManyItemsAtOnce from './EditManyItemsAtOnce'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
      user: state.user,
      modalType: state.ui.modalType,
      list: state.lists.byId[state.ui.id],
      items: state.lists.byId[state.ui.id].items.map(
        (item) => state.items.byId[item]
      ),
    }
  },
  {
    closeModal,
    updateManyItemsAtOnce,
    openEditManyItemsAtOnceModal,
  }
)(EditManyItemsAtOnce)

export default connectedComponent
