import { createActions } from 'redux-actions'

export interface ILoginFlowWithRefreshTokenResult {
  accessToken: string
  accessTokenExpiresIn: number
  memberId: number
  memberAlias: string
  memberFirstName: string
  memberLastName: string
  refreshToken: string
  classicApiToken: string
  classicApiTokenExpiryTime: string
}

export const {
  returnedFromLoginPage,
  completeLoginFlowWithRefreshToken,
  refreshLoginCredentials,
  logout,
  goToLoginPage,
} = createActions({
  returnedFromLoginPage: (params) => params,
  completeLoginFlowWithRefreshToken: (
    result: ILoginFlowWithRefreshTokenResult
  ) => {
    localStorage.setItem('refreshToken', result.refreshToken)
    return result
  },
  refreshLoginCredentials: (refreshToken) => ({
    refreshToken,
  }),
  logout: () => {
    localStorage.removeItem('refreshToken')
    return {}
  },
  goToLoginPage: () => {
    ;(window as any).location = `/login`
    return {}
  },
})
