import { useState } from 'react'
import type { IFileUploadImage } from '../Items/IItem'

const useImageList = () => {
  const [imageList, setImageList] = useState<IFileUploadImage[]>([])

  const clearImages = () => {
    setImageList([])
  }

  const setImageListDirectly = (newImageList: IFileUploadImage[]) => {
    setImageList(newImageList)
  }

  return { imageList, clearImages, setImageListDirectly }
}

export default useImageList
