import { connect } from 'react-redux'
import {
  closeModal,
  openPublicationResultModal,
  openBankIdModal,
  bankIdVerificationEnd,
  setUploadInterval,
  setUploadDateTime,
  setUploadItems,
  setUploadSellItems,
} from '../../UI/UI.actions'
import { publishList } from '../../List/List.actions'

import PublishList from './PublishList'
import { republishItems } from '../../SellView/SellView.actions'

const connectedComponent = connect(
  (state: any) => {
    let id = state.ui.id ? state.ui.id : state.ui.bankIdListId

    return {
      bankIdRequired: state.ui.bankIdRequired,
      bankIdSuccess: state.ui.bankIdSuccess,
      modalType: state.ui.modalType,
      list: state.lists.byId[id],
      items:
        state.ui.selectedItems.length > 0
          ? state.ui.selectedItems
          : state.ui.uploadSellItems.length > 0
          ? state.ui.uploadSellItems
          : state.lists.byId[id].items.map((item) => state.items.byId[item]),
      selectedItems:
        state.ui.uploadItems.length > 0
          ? state.ui.uploadItems.map((item) => state.items.byId[item])
          : state.ui.uploadSellItems.length > 0
          ? state.ui.uploadSellItems
          : state.ui.selectedItems,
      uploadInterval: state.ui.uploadInterval,
      uploadDateTime: state.ui.uploadDateTime,
    }
  },
  {
    closeModal,
    publishList,
    republishItems,
    openPublicationResultModal,
    openBankIdModal,
    bankIdVerificationEnd,
    setUploadInterval,
    setUploadDateTime,
    setUploadItems,
    setUploadSellItems,
  }
)(PublishList)

export default connectedComponent
