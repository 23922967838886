import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'

import CancelSellItems from './CancelSellItems'
import { cancelItems } from '../../SellView/SellView.actions'
import { SellListType } from '../../SellView/ISellView'

const connectedComponent = connect(
  (state: any) => {
    const selectedItemIds = state.sellView.byId[
      SellListType.Active
    ]?.items?.filter((item) => state.sellItems.byId[item]?.isChecked)
    const selectedItems = selectedItemIds?.map((id) => state.sellItems.byId[id])
    return {
      selectedItems,
    }
  },
  {
    closeModal,
    cancelItems,
  }
)(CancelSellItems)

export default connectedComponent
