import React from 'react'

interface LabelProps {
  label: string
  description?: string
}

const Label: React.FunctionComponent<LabelProps> = ({ label, description }) => (
  <div className="d-flex align-items-center mb-1 mt-2">
    <span className="label-small">{label}</span>
    {description && (
      <span className="text-small text-muted ml-1">{description}</span>
    )}
  </div>
)

export default Label
