import { connect } from 'react-redux'
import { closeModal, openCreateItemFromImageModal, openImageErrorMessageModal } from '../../UI/UI.actions'
import ImageDropZoneCreateItem from './ImageDropZoneCreateItem'
import { createItemsFromImage } from '../../Items/Items.action'
import { TabTypes } from '../../Content/IContent'

const connectedComponent = connect(
  (state: any) => {
    let openListId: string = ''
    for (const tabId in state.content.byId) {
      const tab = state.content.byId[tabId]
      if (tab.selected && tab.type === TabTypes.List) {
        openListId = tabId
      }
    }

    return {
      loading: state.ui.loading,
      isMinimize: state.ui.isMinimize,
      importMinimizeLoading: state.ui.importMinimizeLoading,
      id: openListId,
    }
  },
  {
    closeModal,
    openCreateItemFromImageModal,
    createItemsFromImage,
    openImageErrorMessageModal
  }
)(ImageDropZoneCreateItem)

export default connectedComponent
