import { connect } from 'react-redux'
import { closeModal, minimize } from '../../UI/UI.actions'
import ImportingListModal from './ImportingListModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
    }
  },
  {
    closeModal,
    minimize,
  }
)(ImportingListModal)

export default connectedComponent
