import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import styles from './Select.module.scss'

interface SelectProps {
  name?: string
  labelAlign?: 'top' | 'left'
  label?: string
  defaultValue?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  disable?: boolean
  styleGrid?: boolean
}

const Select: React.FunctionComponent<SelectProps> = ({
  name,
  label,
  labelAlign,
  children,
  defaultValue,
  value,
  onChange,
  disable,
  styleGrid,
}) => {
  const [id] = useState(_uniqueId('input-'))
  return (
    <div className={styleGrid ? styles.gridSelect : styles.select}>
      <div
        className={`form-group ${
          labelAlign && labelAlign === 'left' ? 'row' : ''
        }`}
      >
        {label && (
          <div className="d-flex justify-content-between align-items-center my-1">
            <div
              className={`${
                labelAlign && labelAlign === 'left'
                  ? 'col-5 col-form-label'
                  : ''
              }`}
            >
              <span className={`${'label-small'}`}>{label}</span>
            </div>
          </div>
        )}{' '}
        <select
          defaultValue={defaultValue}
          value={value}
          id={id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onChange && onChange(e)
          }
          className={`form-control single-select ${
            labelAlign && labelAlign === 'left' ? 'col-6' : ''
          }`}
          disabled={disable}
        >
          {children}
        </select>
      </div>
    </div>
  )
}

export default Select
