import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { IItemChanges } from '../Items/IItem'
import { saveItemChangesWip } from './ItemChanges.action'

export interface IItemsState {
  [key: string]: IItemChanges
}

export const initialState: IItemsState = {}

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

const byId: Reducer<IItemsState, IAction<any>> = handleActions(
  {
    [saveItemChangesWip.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        [action.payload.listId]: action.payload,
      }
    },
  },
  initialState
)

const allIds = handleActions(
  {
    [saveItemChangesWip.toString()]: (
      state: string[] = [],
      action: IAction<any>
    ) => {
      if (action.payload.id && !state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return [...state]
    },
  },
  []
)

const ItemChangesWipReducer = combineReducers({
  byId,
  allIds,
})

export default ItemChangesWipReducer
