import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import type { IItem } from '../../Items/IItem'
import type { IList } from '../../List/IList'
import type { ITemplate } from '../../Template/ITemplate'
import style from './ContextMenu.module.scss'
import type { ISellItem } from '../../SellView/ISellView'

interface ContextMenuProps {
  showPopper: boolean
  refForPopup: HTMLDivElement | null
  selectedContent: IList | IItem | ITemplate | ISellItem
  menuItems: {
    link?: string
    name: string
    action?: (selectedContent: IList | IItem | ITemplate | ISellItem) => void
    icon?: React.ReactNode
    hidden?: boolean
    disabled?: boolean
  }[]
  toggleContextMenu: (value: boolean) => void
  editNameClicked: () => void
}

const ContextMenu: React.FunctionComponent<ContextMenuProps> = ({
  showPopper,
  refForPopup,
  menuItems,
  toggleContextMenu,
  selectedContent,
  editNameClicked,
}) => {
  const [arrowRef, setArrow] = useState<HTMLDivElement | null>(null)
  const popperRef = useRef(null)
  const { styles, attributes } = usePopper(refForPopup, popperRef.current, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowRef } },
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  })
  return (
    <div>
      {showPopper ? (
        <div
          className={style.popover}
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <div
            ref={setArrow}
            className={style.arrow}
            style={styles.arrow}
            id="arrow"
          />
          <div className={style.popoverContent}>
            <div className="list-group">
              {menuItems.map((item, key) => (
                <>
                  {!item.hidden && (
                    <button
                      key={key}
                      onClick={() => {
                        if (item.name === 'Ändra namn') {
                          editNameClicked()
                          toggleContextMenu(false)
                        } else if (item.action) {
                          item.action(selectedContent)
                          toggleContextMenu(false)
                        }
                      }}
                      disabled={item.disabled}
                      className="list-group-item list-group-item-action gap-x-2"
                    >
                      {item.icon && item.icon} {item.name}
                    </button>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ContextMenu
