import type { Dispatch } from '@reduxjs/toolkit'
import { createActions } from 'redux-actions'
import { get } from '../Infrastructure/Network'
import type { IBankIdVerification } from './IBankIdVerification'
import {
  closeModal,
  openPublishModal,
  openBankIdVerifyModal,
  bankIdVerificationEnd,
  setBankIdOrderRef,
  openAlertContainer,
  setBankIdQrData,
  setAutoStartToken,
  setDesktopAutoStartToken,
} from '../UI/UI.actions'
import type { IBankIdOnFileVerification } from './IBankIdOnFileVerification'
import type { RootState } from '../Store/rootReducer'

export const {
  beginBankIdVerificationRequested,
  beginBankIdVerificationSucceeded,
  beginBankIdVerificationRejected,
} = createActions({
  beginBankIdVerificationRequested: () => ({}),
  beginBankIdVerificationSucceeded: (bankIdVerification: IBankIdVerification) =>
    bankIdVerification,
  beginBankIdVerificationRejected: () => ({}),
})

export const onBeginBankIdVerificationSucceeded =
  (bankIdVerification: IBankIdVerification) =>
  (dispatch: Dispatch<any>): void => {
    cancelled = false
    dispatch(setBankIdOrderRef(bankIdVerification))
    dispatch(setBankIdQrData(bankIdVerification.qrData))
    dispatch(setAutoStartToken(bankIdVerification.autoStartToken))

    dispatch(closeModal())
    if (bankIdVerification.bankIdOrderRef === null) {
      dispatch(
        bankIdVerificationEnd({
          bankIdSuccess: false,
          bankIdRequired: true,
          autoStartToken: null,
          desktopAutoStartToken: null,
        })
      )
      dispatch(openPublishModal(null, []))
    } else {
      dispatch(openBankIdVerifyModal(bankIdVerification))
      let token = bankIdVerification.bankIdOrderRef
        ? bankIdVerification.bankIdOrderRef
        : ''
      dispatch(getBankIdVerificationStatus(token))
    }
  }

export const beginBankIdVerification = () =>
  get({
    url: `/bankId`,
    initiated: beginBankIdVerificationRequested,
    fulfilled: (bankIdVerification: IBankIdVerification) =>
      onBeginBankIdVerificationSucceeded(bankIdVerification),
    rejected: beginBankIdVerificationRejected,
  })

// On file

export const {
  beginBankIdOnFileVerificationRequested,
  beginBankIdOnFileVerificationSucceeded,
  beginBankIdOnFileVerificationRejected,
} = createActions({
  beginBankIdOnFileVerificationRequested: () => ({}),
  beginBankIdOnFileVerificationSucceeded: (
    bankIdVerification: IBankIdOnFileVerification
  ) => bankIdVerification,
  beginBankIdOnFileVerificationRejected: () => ({}),
})

export const {
  cancelOngoingBankIdVerificationRequested,
  cancelOngoingBankIdVerificationRejected,
} = createActions({
  cancelOngoingBankIdVerificationRequested: () => ({}),
  cancelOngoingBankIdVerificationRejected: () => ({}),
})

export const onBeginBankIdOnFileVerificationSucceeded =
  (bankIdVerification: IBankIdOnFileVerification) =>
  (dispatch: Dispatch<any>) => {
    cancelled = false

    dispatch(setBankIdOrderRef(bankIdVerification))
    dispatch(setDesktopAutoStartToken(bankIdVerification.autoStartToken))

    if (bankIdVerification.bankIdOrderRef === null) {
      dispatch(
        bankIdVerificationEnd({
          bankIdSuccess: false,
          bankIdRequired: true,
          autoStartToken: null,
          desktopAutoStartToken: null,
        })
      )
      dispatch(openPublishModal(null, []))
    }
  }

export const beginBankIdOnFileVerification =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { bankIdOrderRef } = getState().ui

    dispatch(setBankIdOrderRef({ bankIdOrderRef: null }))

    dispatch(
      get({
        url: `/bankid/cancelVerification/${bankIdOrderRef}`,
        initiated: cancelOngoingBankIdVerificationRequested,
        fulfilled: () =>
          get({
            url: `/bankId/onFile`,
            initiated: beginBankIdOnFileVerificationRequested,
            fulfilled: onBeginBankIdOnFileVerificationSucceeded,
            rejected: beginBankIdOnFileVerificationRejected,
          }),
        rejected: cancelOngoingBankIdVerificationRejected,
      })
    )
  }

// Polling

export const {
  getBankIdVerificationStatusRequested,
  getBankIdVerificationStatusSucceeded,
  getBankIdVerificationStatusRejected,
} = createActions({
  getBankIdVerificationStatusRequested: () => ({}),
  getBankIdVerificationStatusSucceeded: (requestStatus: IBankIdVerification) =>
    requestStatus,
  getBankIdVerificationStatusRejected: () => ({}),
})

let cancelled = false
const sleep = (delay) =>
  new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      if (cancelled) {
        reject(new Error('Cancelled'))
      } else {
        resolve()
      }
    }, delay)
  })

export const onGetBankIdVerificationStatusSucceeded =
  (requestStatus: IBankIdVerification) =>
  async (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
    if (!requestStatus.isCompleted) {
      dispatch(setBankIdQrData(requestStatus.qrData))

      try {
        await sleep(2000)

        let bankIdOrderRef = getState().ui['bankIdOrderRef']
        if (!bankIdOrderRef) {
          console.log(
            'No bankIdOrderRef found, could be during start of bankId on file'
          )
          await sleep(3000)
        }
        bankIdOrderRef = getState().ui['bankIdOrderRef']

        dispatch(getBankIdVerificationStatus(bankIdOrderRef))
      } catch (error: any) {
        if (error.message === 'Cancelled') {
          console.log('Sleep interrupted due to cancellation')
          dispatch(
            bankIdVerificationEnd({
              bankIdSuccess: false,
              bankIdRequired: true,
              autoStartToken: null,
              desktopAutoStartToken: null,
            })
          )
        }
      }
    } else {
      dispatch(closeModal())
      if (requestStatus.error !== null) {
        dispatch(
          bankIdVerificationEnd({
            bankIdSuccess: false,
            bankIdRequired: true,
            autoStartToken: null,
            desktopAutoStartToken: null,
          })
        )
      } else {
        dispatch(
          bankIdVerificationEnd({
            bankIdSuccess: true,
            bankIdRequired: false,
            autoStartToken: null,
            desktopAutoStartToken: null,
          })
        )
      }
      dispatch(openPublishModal(null, []))
    }
  }

export const getBankIdVerificationStatus = (bankIdOrderRef: string) =>
  get({
    url: `/bankId/${bankIdOrderRef}`,
    initiated: getBankIdVerificationStatusRequested,
    fulfilled: (requestStatus: IBankIdVerification) =>
      onGetBankIdVerificationStatusSucceeded(requestStatus),
    rejected: getBankIdVerificationStatusRejected,
  })

export const {
  cancelBankIdVerificationRequested,
  cancelBankIdVerificationSucceeded,
  cancelBankIdVerificationRejected,
} = createActions({
  cancelBankIdVerificationRequested: () => ({}),
  cancelBankIdVerificationSucceeded: (result: string) => result,
  cancelBankIdVerificationRejected: () => ({}),
})

export const onCancelBankIdVerificationSucceeded =
  () =>
  (dispatch: Dispatch<any>): void => {
    cancelled = true
    dispatch(
      bankIdVerificationEnd({ bankIdSuccess: false, bankIdRequired: true })
    )
    dispatch(cancelBankIdVerificationSucceeded())
    dispatch(closeModal())
    dispatch(
      openAlertContainer('BankId verifiering avbruten av dig', 'success')
    )
    dispatch(openPublishModal(null, []))
  }

export const cancelBankIdVerification = (bankIdOrderRef: string) =>
  get({
    url: `/bankid/cancelVerification/${bankIdOrderRef}`,
    initiated: cancelBankIdVerificationRequested,
    fulfilled: () => onCancelBankIdVerificationSucceeded(),
    rejected: cancelBankIdVerificationRejected,
  })
