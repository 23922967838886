export const isValid = (val) => {
  const regexp = /^\d{0,2}?\:?\d{0,2}$/

  const [hoursStr, minutesStr] = val.split(':')

  if (!regexp.test(val)) {
    return false
  }

  const hours = Number(hoursStr)
  const minutes = Number(minutesStr)

  const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24

  if (!isValidHour(hours)) {
    return false
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false
  }

  return true
}
