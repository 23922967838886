import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { TabTypes } from '../../Content/IContent'
import CreateItemFromImage from './CreateItemFromImage'
import { createItemsFromImage } from '../../Items/Items.action'

const connectedComponent = connect(
  (state: any) => {
    let openListId: string = ''
    let items: []
    let numberOfItems: number = 0
    for (const tabId in state.content.byId) {
      const tab = state.content.byId[tabId]
      if (tab.selected && tab.type === TabTypes.List) {
        openListId = tabId
        items = state.lists.byId[openListId].items
        if (items !== null) {
          numberOfItems = items.length
        }
      }
    }

    return {
      loading: state.ui.loading,
      modalType: state.ui.modalType,
      id: openListId,
      imageUrls: state.ui.imageUrls,
      numberOfItems: numberOfItems
    }
  },
  {
    closeModal,
    createItemsFromImage,
  }
)(CreateItemFromImage)

export default connectedComponent

