import React, { useRef } from 'react'
import ListMenuItem from '../../Components/ListMenuItem'
import styles from './ListMenu.module.scss'
import { openAlertContainer } from '../../UI/UI.actions'
import { useDispatch } from 'react-redux'
import Papa from 'papaparse'
import type { ItemListMenuItemProps } from '../../Components/ListMenuItem/ListMenuItem'
import { useTranslation } from 'react-i18next'

interface ListMenuProps {
  menuItems: ItemListMenuItemProps[]
  importListFromPlZip: (plZipFile: any) => void
  setImportFileSize: (importFileSize: number) => void
  setImportData: (importData: any) => void
  openImportMappingModal: () => void
}

const ListMenu: React.FunctionComponent<ListMenuProps> = ({
  menuItems,
  importListFromPlZip,
  setImportFileSize,
  setImportData,
  openImportMappingModal,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const hiddenFileInput = useRef(null)

  const onClickedImport = () => {
    ;(hiddenFileInput as any).current.click()
  }

  const handleFileSelected = (event) => {
    const fileToImport = event.target.files[0]
    if (fileToImport !== undefined) {
      const fileSize = fileToImport.size
      setImportFileSize(fileSize)
      if (fileToImport.name.slice(-3) === 'csv') {
        Papa.parse(fileToImport, {
          skipEmptyLines: true,
          delimitersToGuess: [
            ';',
            ',',
            '\t',
            '|',
            ';',
            Papa.RECORD_SEP,
            Papa.UNIT_SEP,
          ],
          complete: function (results) {
            setImportData(results)
          },
        })
        openImportMappingModal()
      } else if (fileToImport.name.slice(-5) === 'plzip') {
        importListFromPlZip(fileToImport)
        event.target.value = null
      } else {
        dispatch(openAlertContainer(t('alert_importFormat'), 'error'))
      }
    }
  }

  return (
    <>
      <div className={`container-fluid ${styles.listmenu}`}>
        <div className="d-flex align-items-center pb-1">
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileSelected}
            style={{ display: 'none' }}
            accept=".plzip, .csv"
          />
          {menuItems.map((item, key) => (
            <ListMenuItem
              action={item.name === 'Importera' ? onClickedImport : item.action}
              key={key}
              name={item.name}
              icon={item.icon}
              isDisabled={item.isDisabled}
              isHidden={item.isHidden}
              tooltip={item.tooltip}
              variant={item.variant}
              className={
                key < 1
                  ? `${styles.buttonAction} primary ${styles.primaryAction}`
                  : styles.buttonAction
              }
            />
          ))}
        </div>
      </div>
    </>
  )
}
export default ListMenu
