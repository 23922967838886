import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'

import PublicationResult from './PublicationResult'

const connectedComponent = connect(
  (state: any) => {
    return {
      numberOfFailedItems: state.ui.modalData,
    }
  },
  {
    closeModal,
  }
)(PublicationResult)

export default connectedComponent
