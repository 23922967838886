import React, { useEffect, useRef, useState } from 'react'
import styles from './Header.module.scss'
import { ReactComponent as Logo } from '../../Images/prolister.svg'
import { ReactComponent as MenuIcon } from '../../Icons/menu.svg'
import { ReactComponent as UserIcon } from '../../Icons/user-fill.svg'
import { ReactComponent as ArrowDownIcon } from '../../Icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../Icons/arrow-up.svg'
import { ReactComponent as NewListIcon } from '../../Icons/new-list.svg'
import { ReactComponent as MyListsIcon } from '../../Icons/my-lists.svg'
import { ReactComponent as SellViewIcon } from '../../Icons/home.svg'
import { ReactComponent as LogoutIcon } from '../../Icons/icons-ic-logout.svg'
import { ReactComponent as ChatIcon } from '../../Icons/icons-ic-chat.svg'
import { ReactComponent as SettingsIcon } from '../../Icons/icons-ic-settings.svg'
import HeaderLink from './HeaderLink'
import { TabTypes, type ITab } from '../../Content/IContent'
import { useDispatch } from 'react-redux'
import Papa from 'papaparse'
import { openAlertContainer } from '../../UI/UI.actions'
import { Country, type IUser } from '../../User/IUser'
import { SellListType } from '../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

declare const window: Window & {
  qualtive: {
    present: (arg0: string, options: { user: { id: string } }) => void
  }
}

interface HeaderProps {
  memberId: string | number
  memberAlias: string
  user: IUser
  createList: () => void
  openContent: (content: ITab, name: string) => void
  logout: () => void
  openImportModal: () => void
  openImportMappingModal: () => void
  openSettingsModal: () => void
  selectedListId: string
  importListFromPlZip: (plZipFile: any) => void
  setImportFileSize: (importFileSize: number) => void
  setImportData: (importData: any) => void
  isLoggedIn: boolean
}

const Header: React.FunctionComponent<HeaderProps> = ({
  memberId,
  memberAlias,
  user,
  createList,
  openContent,
  logout,
  openImportModal,
  openImportMappingModal,
  openSettingsModal,
  selectedListId,
  importListFromPlZip,
  setImportFileSize,
  setImportData,
  isLoggedIn,
}) => {
  const { t } = useTranslation()
  const [showLeftNavbar, setShowLeftNavbar] = useState(false)
  const [showRightNavbar, setShowRightNavbar] = useState(false)
  const dispatch = useDispatch()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const leftNavbarRef = useRef<HTMLDivElement>(null)
  const rightNavbarRef = useRef<HTMLDivElement>(null)

  const dropDownItems = [
    {
      link: '/',
      name: t('header_feedback'),
      icon: <ChatIcon />,
      action: () => onClickedQualtiveFeedback(),
    },
    {
      link: '/',
      name: t('header_logout'),
      icon: <LogoutIcon />,
      action: () => logout(),
    },
  ]
  if (user.hasShop || user.country !== Country.Sweden) {
    dropDownItems.unshift({
      link: '/',
      name: t('header_settings'),
      icon: <SettingsIcon />,
      action: () => openSettingsModal(),
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        leftNavbarRef.current &&
        !leftNavbarRef.current.contains(event.target)
      ) {
        setShowLeftNavbar(false)
      }
      if (
        rightNavbarRef.current &&
        !rightNavbarRef.current.contains(event.target)
      ) {
        setShowRightNavbar(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  const handleFileSelected = (event) => {
    const fileToImport = event.target.files[0]
    if (fileToImport !== undefined) {
      const fileSize = fileToImport.size
      setImportFileSize(fileSize)
      if (fileToImport.name.slice(-3) === 'csv') {
        Papa.parse(fileToImport, {
          skipEmptyLines: true,
          delimitersToGuess: [
            ';',
            ',',
            '\t',
            '|',
            ';',
            Papa.RECORD_SEP,
            Papa.UNIT_SEP,
          ],
          complete: function (results) {
            setImportData(results)
          },
        })
        openImportMappingModal()
      } else if (fileToImport.name.slice(-5) === 'plzip') {
        importListFromPlZip(fileToImport)
        event.target.value = null
      } else {
        dispatch(openAlertContainer(t('alert_importFormat'), 'error'))
      }
    }
  }

  const onClickedImport = () => {
    if (selectedListId) {
      openImportModal()
    } else {
      hiddenFileInput.current!.click()
    }
  }

  const closeAllDropdowns = () => {
    setShowLeftNavbar(false)
    setShowRightNavbar(false)
  }

  const handleShowLeftNavbar = () => {
    setShowLeftNavbar(!showLeftNavbar)
  }

  const handleShowRightNavbar = () => {
    setShowRightNavbar(!showRightNavbar)
  }

  const onClickedQualtiveFeedback = () => {
    window.qualtive.present('tradera/vad-tycker-du-om-prolister', {
      user: { id: `${memberId}` },
    })
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div>
          {isLoggedIn && (
            <>
              <div
                className={styles.showOnTablet}
                onClick={handleShowLeftNavbar}
              >
                <MenuIcon className="icon icon-md m-2" />
              </div>
              <div
                ref={leftNavbarRef}
                className={`${styles.navElementsLeft} ${
                  showLeftNavbar && styles.active
                }`}
              >
                <ul>
                  <li className={styles.hideOnTablet}>
                    <HeaderLink
                      linkName={t('header_newList')}
                      dropDown
                      dropDownItems={[
                        {
                          name: t('header_createList'),
                          icon: <NewListIcon />,
                          action: () => createList(),
                          trackingData: ['Header', 'Create list'],
                        },
                        {
                          name: t('header_importList'),
                          icon: <NewListIcon />,
                          action: () => onClickedImport(),
                          trackingData: ['Header', 'Import list'],
                        },
                      ]}
                      icon={<NewListIcon width={25} height={25} />}
                    />
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleFileSelected}
                      style={{ display: 'none' }}
                      accept=".plzip, .csv"
                    />
                  </li>
                  <li className={styles.showOnTablet}>
                    <HeaderLink
                      linkName={t('header_createList')}
                      action={() => createList()}
                      onLinkClick={closeAllDropdowns}
                    />
                  </li>
                  <li className={styles.showOnTablet}>
                    <HeaderLink
                      linkName={t('header_importList')}
                      action={() => onClickedImport()}
                      onLinkClick={closeAllDropdowns}
                    />
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleFileSelected}
                      style={{ display: 'none' }}
                      accept=".plzip, .csv"
                    />
                  </li>
                  <li>
                    <HeaderLink
                      linkName={t('header_yourLists')}
                      action={() =>
                        openContent(
                          {
                            type: TabTypes.MyList,
                            id: 'mylists',
                            selected: true,
                            name: t('header_myLists'),
                          },
                          t('header_myLists')
                        )
                      }
                      onLinkClick={closeAllDropdowns}
                      icon={
                        <MyListsIcon
                          className={styles.hideOnTablet}
                          width={25}
                          height={25}
                        />
                      }
                      trackingData={['Header', 'My lists']}
                    />
                  </li>
                  <li>
                    <HeaderLink
                      linkName={t('header_sellView')}
                      action={() =>
                        openContent(
                          {
                            type: TabTypes.SellView,
                            id: 'sellview',
                            selected: true,
                            name: t('header_sellView'),
                            sellViewType: SellListType.Unsold,
                          },
                          t('header_sellView')
                        )
                      }
                      onLinkClick={closeAllDropdowns}
                      icon={
                        <SellViewIcon
                          className={styles.hideOnTablet}
                          width={25}
                          height={25}
                        />
                      }
                      trackingData={['Header', 'Sellview']}
                    />
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div>
          <Logo />
        </div>
        <div
          ref={rightNavbarRef}
          className={`${styles.navElementsRight} ${
            showRightNavbar && styles.active
          }`}
        >
          <ul>
            <li>
              <HeaderLink
                link="https://tradera.com"
                linkName={t('header_toTradera')}
                onLinkClick={closeAllDropdowns}
              />
            </li>
            <li>
              <HeaderLink
                link="https://info.tradera.com/prolister"
                linkName={t('header_help')}
                onLinkClick={closeAllDropdowns}
              />
            </li>
            {isLoggedIn && (
              <li className={styles.hideOnMobile}>
                <HeaderLink
                  menuAlign="right"
                  linkName={memberAlias}
                  dropDown
                  dropDownItems={dropDownItems}
                />
              </li>
            )}
            {isLoggedIn && (
              <>
                {(user.hasShop || user.country !== Country.Sweden) && (
                  <li className={styles.hideOnDesktop}>
                    <HeaderLink
                      linkName={t('header_settings')}
                      action={() => openSettingsModal()}
                      onLinkClick={closeAllDropdowns}
                    />
                  </li>
                )}
                <li className={styles.hideOnDesktop}>
                  <HeaderLink
                    action={() => onClickedQualtiveFeedback()}
                    linkName={t('header_feedback')}
                    onLinkClick={closeAllDropdowns}
                  />
                </li>
                <li className={styles.hideOnDesktop}>
                  <HeaderLink
                    action={() => logout()}
                    linkName={t('header_logout')}
                    onLinkClick={closeAllDropdowns}
                  />
                </li>
              </>
            )}
          </ul>
        </div>
        <div className={styles.hideOnDesktop} onClick={handleShowRightNavbar}>
          <UserIcon className="icon icon-md" />
          {!showRightNavbar && <ArrowDownIcon className="mr-2" />}
          {showRightNavbar && <ArrowUpIcon className=" mr-2" />}
        </div>
      </div>
    </nav>
  )
}

export default Header
