import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import {
  getUserSucceeded,
  updateColumnOrderSucceeded,
  updatePurchaseTermsSucceeded,
} from './User.actions'
import type { IUser } from './IUser'

export interface IUserState {
  memberId?: string
  numberOfLists?: 0
  columnOrders?: string[]
  hasShop?: boolean
  isCompany?: boolean
  purchaseTerms?: string
}

const initialState: IUserState = {}

const userReducer: Reducer<IUser, IAction<any>> = handleActions(
  {
    [getUserSucceeded.toString()]: (_state, action: IAction<any>) => ({
      ...action.payload,
    }),
    [updateColumnOrderSucceeded.toString()]: (
      state,
      action: IAction<string[]>
    ) => ({
      ...state,
      columnOrders: [...action.payload],
    }),
    [updatePurchaseTermsSucceeded.toString()]: (
      state,
      action: IAction<string[]>
    ) => ({
      ...state,
      purchaseTerms: action.payload,
    }),
  },
  initialState
)

export default userReducer
