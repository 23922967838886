import React from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as Arrow } from '../../Icons/bigger-box.svg'
import Button from '../../Components/Button'
import { TabTypes } from '../../Content/IContent'
import type { IList } from '../../List/IList'
import { openContent } from '../../Content/Content.actions'

interface OpenListRendererProps {
  data: IList
}

const OpenListCellRenderer: React.FunctionComponent<OpenListRendererProps> = ({
  data,
}) => {
  const dispatch = useDispatch()
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Button
        clickHandler={() =>
          dispatch(
            openContent(
              {
                type: TabTypes.List,
                id: data.id,
                selected: true,
                name: data.name,
              },
              data.name
            )
          )
        }
        size="large"
        icon={<Arrow />}
        variant="noborder"
        style={{ paddingRight: 12 }}
      />
    </div>
  )
}

export default OpenListCellRenderer
