import { ReactComponent as PublishedIcon } from '@tradera/blueprint/theme/icons2/check-fill.svg'
import { ReactComponent as FailedIcon } from '@tradera/blueprint/theme/icons2/close-fill.svg'
import { useTranslation } from 'react-i18next'

const PublicationResult = ({ numberOfFailedItems, closeModal }: any) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="d-flex justify-content-center align-items-center p-3">
          <div className="text-center">
            <PublishedIcon className="icon icon-lg" />
          </div>
        </div>
        <div>
          <p>
            {t('modal_publishResult_info')}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://www.tradera.com/my/active'}
              className="ml-1"
            >
              {t('modal_publishResult_infoAppend')}
            </a>
            .
          </p>
          {numberOfFailedItems > 0 && (
            <div className="border-top">
              <div className="text-center my-3">
                <FailedIcon className="icon icon-lg" />
              </div>
              <p>
                {numberOfFailedItems} {t('modal_publishResult_failed')}.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_publishResult_button_close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicationResult
