import React from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import { ReactComponent as DotsIcon } from '../../Icons/dots.svg'

const OpenMenuCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const openContextMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()

    const agEvent: MouseEvent = new MouseEvent('contextmenu', {
      bubbles: true,
      clientX: event.clientX,
      clientY: event.clientY,
    })

    event.currentTarget.dispatchEvent(agEvent)
  }
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <button
        onClick={openContextMenu}
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          cursor: 'pointer',
        }}
        title="Open Menu"
      >
        <DotsIcon />
      </button>
    </div>
  )
}

export default OpenMenuCellRenderer
