import React from 'react'
import { useDispatch } from 'react-redux'
import styles from '../ProlisterGrid.module.scss'
import type { IItem } from '../../Items/IItem'
import Button from '../../Components/Button'
import { openEditCategoryModal } from '../../UI/UI.actions'
import { getCategoryTree } from '../../Infrastructure/Helpers/categoryHelper'

interface CategoryRendererProps {
  data: IItem
}

const CategoryRenderer: React.FunctionComponent<CategoryRendererProps> = ({
  data,
}) => {
  const dispatch = useDispatch()
  const categoryName = data.category ? getCategoryTree(data.category) : ''
  const lastWord = categoryName ? categoryName.split('/').pop() : ''
  const firstPart = categoryName ? categoryName.replace(lastWord!, '') : ''
  return (
    <div className={`${styles.selecter}`}>
      <Button
        testId="create-itemchanges"
        clickHandler={() => dispatch(openEditCategoryModal(data))}
        size="small"
        content={
          <p
            className="text-gray-600"
            style={{
              direction: 'rtl',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {firstPart}
            <span className="text-dark">{lastWord}</span>
          </p>
        }
        variant="noborder"
        style={{ textAlign: 'left' }}
      />
    </div>
  )
}

export default CategoryRenderer
