import React, { useState, useEffect } from 'react'
import type { IItem } from '../../IItem'
import Label from '../../../Components/Label'
import CheckBox from '../../../Components/CheckBox'
import Button from '../../../Components/Button'
import { ReactComponent as Remove } from '../../../Icons/remove.svg'
import type {
  IOption,
  IShippingAlternative,
  IShippingOptions,
} from './IShipping'
import { shippingString } from './IShipping'
import ShippingOption from './ShippingOption'
import ErrorLabel from '../ErrorLabel'
import {
  SHIPPING_TAKEAWAY,
  convertWeight,
  getProductIdFromProductKey,
  getProviderIdFromProductKey,
} from '../../../Infrastructure/Helpers/shippingHelper'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ShippingOptionsProps {
  item: IItem | ISellItem
  updateStateByItem: (param: string, shipping: any) => void
  shippingOptions: IShippingOptions[]
  getShippingOptions: () => Promise<any>
  noValidation?: boolean
}
const ShippingOptions: React.FunctionComponent<ShippingOptionsProps> = ({
  item,
  updateStateByItem,
  shippingOptions,
  getShippingOptions,
  noValidation,
}) => {
  const { t } = useTranslation()
  const [weightOptions, setWeightOptions] = useState<IOption[]>([])
  const [shippingAlternatives, setShippingAlternatives] = useState<
    IShippingAlternative[]
  >([])

  useEffect(() => {
    if (shippingOptions && shippingOptions.length > 0) {
      setWeightOptions(
        shippingOptions?.map((item) => {
          return {
            value: item.weight,
            label: convertWeight(item.weight),
          }
        })
      )
    } else {
      getShippingOptions()
    }
  }, [shippingOptions])

  useEffect(() => {
    getShippingOptions()
    const shipping = item.shippingAlternative.filter(
      (x) => x.id !== SHIPPING_TAKEAWAY
    )
    while (shipping.length < 2) {
      shipping.push({
        id: 0,
        price: 0,
        shippingProductId: 0,
      })
    }
    setShippingAlternatives(shipping)
  }, [])

  const removeShipping = (oldProductKey: string) => {
    const newShipping = item.shippingAlternative.filter(
      (x) =>
        x.shippingProductId !== getProductIdFromProductKey(oldProductKey) &&
        x.id !== getProviderIdFromProductKey(oldProductKey)
    )
    updateStateByItem('shippingAlternative', newShipping)
  }

  const onShippingChanged = (
    oldProductKey: string,
    shippingAlternative: IShippingAlternative
  ) => {
    const newShipping = item.shippingAlternative.filter(
      (x) =>
        x.shippingProductId !== getProductIdFromProductKey(oldProductKey) &&
        x.id !== getProviderIdFromProductKey(oldProductKey)
    )
    newShipping.push(shippingAlternative)
    if (newShipping.filter((x) => x.id !== SHIPPING_TAKEAWAY).length < 3) {
      updateStateByItem('shippingAlternative', newShipping)
    }
  }

  const setTakeawayShipping = (allowTakeaway: boolean) => {
    if (!allowTakeaway) {
      const newShipping = item.shippingAlternative.filter(
        (x) => x.id !== SHIPPING_TAKEAWAY
      )
      updateStateByItem('shippingAlternative', newShipping)
    } else {
      const newShipping = item.shippingAlternative.filter(
        (x) => x.id !== SHIPPING_TAKEAWAY
      )
      newShipping.push({
        id: SHIPPING_TAKEAWAY,
        price: 0,
      })
      updateStateByItem('shippingAlternative', newShipping)
    }
  }

  return (
    <div>
      {item.validationResult &&
        !item.validationResult?.properties.shippingAlternative &&
        !noValidation && (
          <ErrorLabel
            label=""
            description={t('itemsForm_shipping_errorLabel')}
          />
        )}

      {item.shippingAlternative.filter(
        (x) => x.id !== SHIPPING_TAKEAWAY && !x.shippingProductId
      ).length > 0
        ? item.shippingAlternative
            .filter((x) => x.id !== SHIPPING_TAKEAWAY && !x.shippingProductId)
            .map((shipping, index) => {
              return (
                <div key={index}>
                  <Label
                    label={t('itemsForm_shipping_label') + ' ' + (index + 1)}
                  />
                  <div className="row">
                    <div className="col-4 mt-1">
                      <p>
                        {' '}
                        {shippingString[shipping.id] +
                          ' - ' +
                          shipping.price +
                          ' kr'}
                      </p>
                    </div>
                    <div className="col-2">
                      <Button
                        testId={'remove-shipping-btn-' + index}
                        size="small"
                        clickHandler={() => removeShipping('0-' + shipping.id)}
                        icon={<Remove />}
                        variant="noborder"
                      />
                    </div>
                  </div>
                </div>
              )
            })
        : shippingAlternatives.map((shippingAlternative, index) => {
            return (
              <ShippingOption
                key={index}
                weightOptions={weightOptions}
                removeShippingOption={removeShipping}
                label={t('itemsForm_shipping_label') + ' ' + (index + 1)}
                existingWeight={shippingAlternative.weight}
                existingProductId={shippingAlternative.shippingProductId}
                existingProviderId={shippingAlternative.id}
                existingPrice={shippingAlternative.price}
                shippingOptions={shippingOptions}
                shippingAlternative={item.shippingAlternative}
                shippingChanged={onShippingChanged}
                testId={'shipping-option-' + index + '-'}
              />
            )
          })}
      <div className="pt-2">
        <CheckBox
          label={t('itemsForm_shipping_takeaway')}
          checked={item.shippingAlternative.some(
            (shipping) => shipping.id === SHIPPING_TAKEAWAY
          )}
          handleInputChange={(a, b) => setTakeawayShipping(b as boolean)}
          name="noShipping"
          testId={'takeaway-checkbox'}
        />
      </div>
    </div>
  )
}

export default ShippingOptions
