import React, { useState } from 'react'
import Input from '../../Components/Input'
import styles from './EditItemsForm.module.scss'
import Condition from './Condition'
import TextTransformation from './TextTransformation'
import Category from '../ItemForm/Category'
import CheckBox from '../../Components/CheckBox'
import TypeOfSale from './TypeOfSale'
import ItemLength from './ItemLength'
import CategoryDisabled from './CategoryDisabled/CategoryDisabled'
import Label from '../../Components/Label'
import ItemLocation from '../ItemForm/ItemLocation'
import CheckBoxExpander from '../../Components/CheckBoxExpander'
import Shipping from './Shipping'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

interface EditItemsFormProps {
  itemChanges
  user: IUser
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  updateStateByItemChanges: (
    property: string,
    value: string | number | boolean | File[] | string[] | any
  ) => void
}

const EditItemsForm: React.FunctionComponent<EditItemsFormProps> = ({
  itemChanges,
  user,
  handleInputChange,
  handleSelectChange,
  updateStateByItemChanges,
}) => {
  const { t } = useTranslation()
  const [categoryExpanded, setCategoryExpanded] = useState(false)
  const [restartExpanded, setRestartExpanded] = useState(false)
  const [shippingExpanded, setShippingExpanded] = useState(false)
  const [extraExpanded, setExtraExpanded] = useState(false)
  const [itemLocationExpanded, setItemLocationExpanded] = useState(false)
  const [shippingConditionExpanded, setShippingConditionExpanded] =
    useState(false)

  const changeRestarts = (a, b) => {
    if (b) {
      itemChanges.restarts = 2
    } else {
      itemChanges.restarts = 0
    }
    updateStateByItemChanges(a, b)
  }

  return (
    <div className={styles.editItemsForm}>
      <div className="p-3">
        <div className="row">
          <div className="col-12">
            <Label label={t('editItemsForm_textTransform_label')} />
            <TextTransformation
              itemChanges={itemChanges}
              updateStateByItemChanges={updateStateByItemChanges}
              handleInputChange={handleInputChange}
            />
            <Condition
              itemChanges={itemChanges}
              handleInputChange={handleInputChange}
              updateStateByItemChanges={updateStateByItemChanges}
            />
            <CheckBoxExpander
              checked={itemChanges.changeCategory}
              label={t('editItemsForm_category_label')}
              name="changeCategory"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setCategoryExpanded}
            />
            {categoryExpanded && (
              <div className={styles.expandContainer}>
                {itemChanges.changeCategory ? (
                  <Category
                    item={itemChanges}
                    updateStateByItem={updateStateByItemChanges}
                    noLabel={true}
                  />
                ) : (
                  <CategoryDisabled />
                )}
              </div>
            )}
            <TypeOfSale
              itemChanges={itemChanges}
              user={user}
              handleInputChange={handleInputChange}
              updateStateByItemChanges={updateStateByItemChanges}
            />
            <ItemLength
              itemChanges={itemChanges}
              updateStateByItemChanges={updateStateByItemChanges}
            />
            <CheckBoxExpander
              checked={itemChanges.changeRestart}
              label={t('editItemsForm_restart_label')}
              name="changeRestart"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setRestartExpanded}
            />
            {restartExpanded && (
              <div className={styles.expandContainer}>
                <div className="col-md-6">
                  <CheckBox
                    checked={itemChanges.restarts === 2 ? true : false}
                    label={t('editItemsForm_restart_checkbox')}
                    handleInputChange={(a, b) => changeRestarts(a, b)}
                    name="changeRestart"
                  />
                </div>
              </div>
            )}
            <hr className={styles.divider} />
            <Label label={t('editItemsForm_shipping_title')} />
            <Shipping
              itemChanges={itemChanges}
              handleInputChange={handleInputChange}
              updateStateByItemChanges={updateStateByItemChanges}
            />
            <CheckBoxExpander
              checked={itemChanges.changeShipping}
              label={t('editItemsForm_itemLocation_label')}
              name="changeAcceptedBidders"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setItemLocationExpanded}
            />
            {itemLocationExpanded && (
              <div className={styles.expandContainer}>
                <div className="row">
                  <div className="col-md-6">
                    <ItemLocation
                      updateStateByItem={updateStateByItemChanges}
                      item={itemChanges}
                      disable={!itemChanges.changeAcceptedBidders}
                    />
                  </div>
                </div>
              </div>
            )}
            <CheckBoxExpander
              checked={itemChanges.changeShippingCondition}
              label={t('editItemsForm_shippingCondition_label')}
              name="changeShippingCondition"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setShippingConditionExpanded}
            />
            {shippingConditionExpanded && (
              <div className={styles.expandContainer}>
                <Input
                  group="form-group"
                  name="shippingCondition"
                  type="text"
                  value={itemChanges.shippingCondition}
                  onChangeValue={(e) => handleInputChange(e)}
                  disable={!itemChanges.changeShippingCondition}
                />
              </div>
            )}
            <hr className={styles.divider} />
            <Label label="Tillval" />
            <CheckBoxExpander
              checked={itemChanges.changeExtraAdditions}
              label={t('editItemsForm_extraAdditions_label')}
              name="changeExtraAdditions"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setExtraExpanded}
            />
            {extraExpanded && (
              <div className={styles.expandContainer}>
                <CheckBox
                  checked={itemChanges.isHighlighted}
                  label={t('editItemsForm_extraAdditions_highlight')}
                  handleInputChange={updateStateByItemChanges}
                  name="isHighlighted"
                  disable={!itemChanges.changeExtraAdditions}
                />
                <CheckBox
                  checked={itemChanges.rankUp}
                  handleInputChange={updateStateByItemChanges}
                  label={t('editItemsForm_extraAdditions_rankUp')}
                  name="rankUp"
                  disable={!itemChanges.changeExtraAdditions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditItemsForm
