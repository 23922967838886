import type { Reducer} from 'redux';
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import { getTemplatesSucceeded } from '../Template/Template.actions'

import type { IItem } from '../Items/IItem'
import type { ITemplate } from '../Template/ITemplate'

export interface ITemplateItem {
  [key: string]: IItem
}

export const initialState: ITemplateItem = {}

const normalizeItems = (Items: IItem[]) =>
  Items.reduce((_ads, _ad) => {
    _ads[_ad.id!] = _ad
    return _ads
  }, {})

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

export const makeItemsFromTemplate = (template: ITemplate[]) => {
  const templateItem = template.map((template) => template.items)
  const items: IItem[] = [].concat(...(templateItem as any))
  return items
}

const byId: Reducer<ITemplateItem, IAction<any>> = handleActions(
  {
    [getTemplatesSucceeded.toString()]: (
      state: ITemplateItem,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(makeItemsFromTemplate(action.payload)),
    }),
  },
  initialState
)

const allIds = handleActions(
  {
    [getTemplatesSucceeded.toString()]: (
      state: string[] = [],
      action: IAction<any>
    ) => [
      ...state.concat(
        makeItemsFromTemplate(action.payload).map((item: any) => item.id)
      ),
    ],
  },
  []
)

const templateItemReducer = combineReducers({
  byId,
  allIds,
})

export default templateItemReducer
