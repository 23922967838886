import type { IItem, ICategory, IAttribute, ItemType } from '../../Items/IItem'
import type { IShippingAlternative } from '../../Items/ItemForm/ShippingOptions/IShipping'
import { SHIPPING_TAKEAWAY } from './shippingHelper'

export interface IItemExport {
  id?: string
  listId: string
  imageUrls: string
  header: string
  description: string
  category: string
  startingBid: number
  buyNowPrice: number
  reservationPrice: number
  isHighlighted: string
  rankUp: string
  reference: string
  latestUploadDate: Date | string
  condition: string
  uploadOrder: number
  publishResult?: string
  latestObjectId?: string
  restarts: number
  acceptedBidders: string
  advertisementLengthInDays: number
  campaignCode: string
  itemType: ItemType
  attributes?: string
  shippingCondition: string
  endtime: string
  shippingAlternativeFirst: number | null
  shippingCostFirst: number | null
  shippingAlternativeSecond: number | null
  shippingCostSecond: number | null
  shippingFirst: string | null
  shippingSecond: string | null
  shippingTakeaway: boolean
  quantity: number | null
  vat: number | null
}

function getCategoryName(category: ICategory) {
  if (category && category.category && category.category.length === 1) {
    return getCategoryName(category.category[0])
  }
  return `${category.id} : ${category.name}`
}

function formatNewShippingAlternative(
  shippingAlternative: IShippingAlternative
) {
  return (
    shippingAlternative.weight +
    ':' +
    shippingAlternative.id +
    ':' +
    shippingAlternative.shippingProductId +
    ':' +
    shippingAlternative.price
  )
}

const getAttributes = (attributes: IAttribute[]): string => {
  let attributeString = ''

  for (let i = 0; i < attributes.length; i++) {
    if (i > 0) {
      attributeString = attributeString + ';' + attributes[i].id + ':'
    } else {
      attributeString = attributes[i].id + ':'
    }
    for (let j = 0; j < attributes[i].possibleTermValues.length; j++) {
      if (j < attributes[i].possibleTermValues.length - 1) {
        attributeString =
          attributeString + attributes[i].possibleTermValues[j] + ','
      } else {
        attributeString = attributeString + attributes[i].possibleTermValues[j]
      }
    }
  }

  return attributeString
}

export const transformItemsForExport = (items: IItem[]) => {
  let transformedItems: Array<IItemExport> = []

  for (let item of items) {
    const oldShippingAlternatives = item.shippingAlternative.filter(
      (x) => x.id !== SHIPPING_TAKEAWAY && !x.shippingProductId
    )
    const newShippingAlternatives = item.shippingAlternative.filter(
      (x) => x.id !== SHIPPING_TAKEAWAY && x.shippingProductId
    )

    transformedItems?.push({
      id: item.id,
      listId: item.listId,
      imageUrls: item.imageUrls?.toString(),
      header: item.header,
      description: item.description,
      startingBid: item.startingBid!,
      buyNowPrice: item.buyNowPrice!,
      reservationPrice: item.reservationPrice!,
      isHighlighted: item.isHighlighted.toString(),
      rankUp: item.rankUp.toString(),
      reference: item.reference,
      condition: item.condition,
      uploadOrder: item.uploadOrder,
      latestObjectId: item.latestObjectId,
      restarts: item.restarts,
      acceptedBidders: item.acceptedBidders.toString(),
      advertisementLengthInDays: item.advertisementLengthInDays!,
      itemType: item.itemType,
      endtime: item.hasChosenEndTime
        ? item.chosenEndTimeHour + ':' + item.chosenEndTimeMinute
        : '',
      category: item.category !== null ? getCategoryName(item.category!) : null,
      latestUploadDate: item.latestUploadDate!,
      campaignCode: item.campaignCode,
      attributes: getAttributes(item.attributes!),
      shippingCondition: item.shippingCondition,
      shippingFirst: newShippingAlternatives[0]
        ? formatNewShippingAlternative(newShippingAlternatives[0])
        : null,
      shippingSecond: newShippingAlternatives[1]
        ? formatNewShippingAlternative(newShippingAlternatives[1])
        : null,
      shippingTakeaway:
        item.shippingAlternative.filter((x) => x.id === SHIPPING_TAKEAWAY)
          .length > 0,
      shippingAlternativeFirst: oldShippingAlternatives[0]?.id ?? null,
      shippingCostFirst: oldShippingAlternatives[0]?.price ?? null,
      shippingAlternativeSecond: oldShippingAlternatives[1]?.id ?? null,
      shippingCostSecond: oldShippingAlternatives[0]?.price ?? null,
      quantity: item.quantity ?? null,
      vat: item.vat ?? null,
    })
  }
  return transformedItems
}

export const csvExportOptions = (fileName: string) => {
  const setOptions = {
    filename: fileName,
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  }
  return setOptions
}
