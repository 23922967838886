import { connect } from 'react-redux'
import {
  closeModal,
  openEditItemModal,
  openPublishModal,
} from '../../UI/UI.actions'
import EditItem from './EditItem'
import { addImages } from '../../Items/Items.action'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
      user: state.user,
      saving: state.ui['saving'],
      modalType: state.ui.modalType,
      itemFromApi: state.sellItems.byId[state.ui.id],
    }
  },
  {
    closeModal,
    addImages,
    openEditItemModal,
    openPublishModal,
  }
)(EditItem)

export default connectedComponent
