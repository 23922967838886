import React from 'react'
import { ReactComponent as Remove } from '@tradera/blueprint/theme/icons2/trashcan.svg'
import { useTranslation } from 'react-i18next'

interface DeleteListsProps {
  closeModal: () => void
  listIds: string[]
  deleteList: (listIds: string[]) => Promise<any>
}

const DeleteLists: React.FunctionComponent<DeleteListsProps> = ({
  closeModal,
  listIds,
  deleteList,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <Remove className="icon icon-lg" />
        </div>
        <p className="text-left">
          {listIds.length === 1 ? (
            <span>{t('modal_deleteLists_question')}?</span>
          ) : (
            <span>
              {t('modal_deleteLists_descripion')} {listIds.length}{' '}
              {t('modal_deleteLists_descriptionAppend')}?
            </span>
          )}
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_deleteLists_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-danger btn-fluid"
              onClick={() => deleteList(listIds)}
            >
              {t('modal_deleteLists_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteLists
