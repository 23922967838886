import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../Components/Button'
import type { IFileUploadImage, IItem } from '../../Items/IItem'
import type { IUser } from '../../User/IUser'
import type { ISellItem } from '../../SellView/ISellView'
import SellItemForm from '../../SellView/SellItemForm'
import useItem from '../../SellView/SellItem.hook'
import styles from './EditItem.module.scss'
import { ReactComponent as Spinner } from '../../Icons/loading.svg'
import { useTranslation } from 'react-i18next'

interface EditItemProps {
  closeModal: () => void
  openPublishModal: (
    listId: string,
    selectedItems: (IItem | ISellItem)[]
  ) => void
  itemFromApi: ISellItem
  user: IUser
  saving: boolean
  addImages: (images: FormData, id: string) => Promise<any>
  imageList: IFileUploadImage[]
  setImageListDirectly: (imageList: IFileUploadImage[]) => void
  closeClicked: boolean
  clearImages: () => void
}

const EditItem: React.FunctionComponent<EditItemProps> = ({
  closeModal,
  openPublishModal,
  itemFromApi,
  user,
  saving,
  addImages,
  imageList,
  setImageListDirectly,
  closeClicked,
  clearImages,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { item, handleInputChange, handleEditorChange, updateItemState } =
    useItem(itemFromApi)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setIsModalOpen(false)
    }
  }, [])

  useEffect(() => {
    if (closeClicked) {
      setIsModalOpen(false)
      clearImages()
    }
  }, [closeClicked])

  const cancelEdit = () => {
    setIsModalOpen(false)
    clearImages()
    closeModal()
  }

  const imageListChanged = (imageList: IFileUploadImage[]) => {
    if (isModalOpen) {
      setImageListDirectly(imageList)
    }
  }

  const updateImages = (files: File[], id: string) => {
    let formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file)
    })
    addImages(formData, id)
  }

  return (
    <div className={styles.modalContainer}>
      {saving && (
        <div className={styles.loadingOverlay}>
          <Spinner className={styles.iconSpin} />
        </div>
      )}
      <div>
        <SellItemForm
          item={item}
          user={user}
          imageList={imageList}
          handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e, dispatch)
          }
          updateStateByItem={(e, v) => updateItemState(e, v, dispatch)}
          handleEditorChange={(editorState) =>
            handleEditorChange(editorState, dispatch)
          }
          updateImages={(files, id) => updateImages(files, id)}
          handleImageListChange={imageListChanged}
        />
        <div className="modal-footer modal-footer-sticky border-top">
          <div className="row">
            <div className="col-6">
              <Button
                clickHandler={() => cancelEdit()}
                variant="outlined"
                size="normal"
                text={t('modal_editItem_button_cancel')}
                disable={saving}
              />
            </div>
            <div className="col-6">
              <Button
                testId="create-itemchanges"
                clickHandler={() => {
                  openPublishModal('UnsoldItems', [item])
                }}
                variant="primary"
                size="normal"
                text={t('modal_editItem_button_confirm')}
                disable={saving}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditItem
