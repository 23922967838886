import { includes, mergeDeepLeft } from 'ramda'

import configService from '../ConfigService/configService'

export const hasBody = (body: any) => (body ? body : {})

export const baseUrl = (url: string) =>
  !url.startsWith('http')
    ? {
        url: `${configService.config.REACT_APP_API_URL}${url}`,
      }
    : { url }

// Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
export const isMultipart = (multipart: boolean) =>
  multipart ? {} : { 'Content-type': 'application/json' }

// Handle HTTP errors since fetch won't.
export const handleResponse = (response: Response) => {
  const acceptedStatusCodes = [202, 200, 204, 225]
  const requestFailedStatusCodes = [400, 401, 402, 403, 404, 415, 418]
  const serverFailedStatusCodes = [500, 501, 502, 503, 504, 599]
  const { status } = response

  if (
    includes(status, acceptedStatusCodes) ||
    includes(status, requestFailedStatusCodes) ||
    includes(status, serverFailedStatusCodes)
  ) {
    return response
  }
  throw new Error(status.toString())
}

export const withHeaders = async ({
  url,
  options,
  authToken,
  memberId,
  multipart,
}: {
  multipart?: boolean
  url: string
  options: RequestInit
  authToken?: string
  memberId?: string
}) => {
  const headers = isMultipart(multipart ? true : false)
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
    headers['X-Tradera-memberid'] = memberId
  }

  const optionsWithHeaders = mergeDeepLeft(options, {
    headers,
  })

  return {
    url,
    options: optionsWithHeaders,
  }
}
