import { connect } from 'react-redux'

import List from './List'
import {
  getListById,
  moveUpItems,
  moveDownItems,
  moveTopItems,
  moveBottomItems,
  deleteItemFromList,
  reorderList,
  moveUpItem,
  moveDownItem,
  updateListById,
} from '../List/List.actions'
import { openContent } from '../Content/Content.actions'
import { TabTypes } from '../Content/IContent'
import { getTemplateById } from '../Template/Template.actions'
import {
  openEditManyItemsAtOnceModal,
  saveClickOrderOnItemInList,
  openDeleteItemsModal,
  openSelectColumnsModal,
} from '../UI/UI.actions'
import { updateColumnOrder } from '../User/User.actions'
import type { IItemClickOrder } from '../Items/IItem'
import { ReactComponent as EditIcon } from '../Icons/icons-ic-pen.svg'
import { ReactComponent as AddListing } from '../Icons/add-listing.svg'
import { ReactComponent as Duplicate } from '../Icons/duplicate.svg'
import { ReactComponent as Remove } from '../Icons/remove.svg'
import { ReactComponent as MoveUp } from '../Icons/move-up.svg'
import { ReactComponent as MoveDown } from '../Icons/move-down.svg'
import { ReactComponent as RemoveIcon } from '../Icons/remove.svg'
import { ReactComponent as DuplicateIcon } from '../Icons/duplicate.svg'
import { ReactComponent as MoveFirst } from '../Icons/move-first.svg'
import { ReactComponent as MoveLast } from '../Icons/move-last.svg'
import { ReactComponent as ExportList } from '../Icons/csv-export.svg'
import { ReactComponent as SettingIcon } from '../Icons/icons-ic-settings.svg'
import type { IItem } from '../Items/IItem'
import store from '../Store/store'
import { duplicateItems } from '../Items/Items.action'
import type { RootState } from '../Store/rootReducer'
import { ExportToCsv } from 'export-to-csv'
import {
  csvExportOptions,
  transformItemsForExport,
} from '../Infrastructure/Helpers/exportListHelper'
import type { TFunction } from 'i18next'

const getSelectedItems = (state, selectedItem: IItem) => {
  return state.lists.byId[selectedItem.listId]?.items.filter(
    (item) => state.items.byId[item]?.isChecked
  )
}

const getSelectedItemsByListId = (state, id: string) => {
  return state.lists.byId[id]?.items.filter(
    (item) => state.items.byId[item]?.isChecked
  )
}

const getAllItemsInList = (state, id) => {
  let items
  for (const listId in state.lists.byId) {
    if (listId === id) {
      items = state.lists.byId[id].items.map((item) => state.items.byId[item])
    }
  }
  return items
}

const onClickedExport = (state, listId) => {
  const items = getAllItemsInList(state, listId)
  const list = state.lists.byId[listId]
  const csvExporter = new ExportToCsv(csvExportOptions(list.name))
  let itemsTransformedForExport = transformItemsForExport(items)
  csvExporter.generateCsv(itemsTransformedForExport)
  const updatedList = {
    ...list,
    hasBeenExported: true,
  }
  store.dispatch(updateListById(updatedList))
}

const connectedComponent = connect(
  (state: RootState) => ({
    user: state.user,
    saving: state.ui['saving'],
    selectedItems: (id: string) => {
      return state.lists.byId[id]?.items?.filter(
        (item) => state.items.byId[item]?.isChecked
      )
    },
    selectedRawData: (type: TabTypes, id: string) =>
      type === TabTypes.List
        ? state.lists.byId[id]?.items?.map((i) => {
            const item = state.items.byId[i]
            if (item) {
              return item
            }
            return i as any
          })
        : state.templates.byId[id].items.map((i) => state.templateItem.byId[i]),
    list: (type: TabTypes, id: string) =>
      type === TabTypes.List ? state.lists.byId[id] : state.templates.byId[id],
    getToolbarItems: (id: string, t: TFunction) => {
      return [
        {
          name: t('list_toolbarItems_newAd'),
          icon: <AddListing width={18} height={18} />,
          testId: 'new-item',
          tooltip: t('list_toolbarItems_newAd'),
          action: () => {
            store.dispatch(
              openContent(
                {
                  type: TabTypes.Item,
                  id: `newitem-${id}`,
                  selected: true,
                  name: 'Rubrik saknas',
                },
                'Rubrik saknas'
              )
            )
          },
          inVisibleMeny: true,
        },
        {
          name: t('list_toolbarItems_edit'),
          isDisabled: getSelectedItemsByListId(state, id).length === 0,
          icon: <EditIcon width={18} height={18} />,
          tooltip: t('list_toolbarItems_edit'),
          action: () => {
            const selectedItems = getSelectedItemsByListId(state, id)
            if (selectedItems.length === 1) {
              const selectedItem = state.items.byId[selectedItems[0]] as IItem
              store.dispatch(
                openContent(
                  {
                    type: TabTypes.Item,
                    id: selectedItem.id!,
                    name: selectedItem.header,
                    selected: true,
                  },
                  selectedItem.header
                )
              )
            } else {
              store.dispatch(openEditManyItemsAtOnceModal(id, selectedItems))
            }
          },
        },

        {
          name: t('list_toolbarItems_delete'),
          isDisabled: getSelectedItemsByListId(state, id).length === 0,
          icon: <RemoveIcon width={18} height={18} />,
          tooltip: t('list_toolbarItems_delete'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(openDeleteItemsModal(id))
          },
        },
        {
          name: t('list_toolbarItems_duplicate'),
          isDisabled: getSelectedItemsByListId(state, id).length === 0,
          icon: <DuplicateIcon width={18} height={18} />,
          tooltip: t('list_toolbarItems_duplicate'),
          inVisibleMeny: true,
          action: () => {
            console.log('selectedItems', getSelectedItemsByListId(state, id))
            store.dispatch(
              duplicateItems(id, getSelectedItemsByListId(state, id))
            )
          },
        },
        {
          name: t('list_toolbarItems_moveFirst'),
          isDisabled: getSelectedItemsByListId(state, id).length === 0,
          icon: <MoveFirst width={18} height={18} />,
          tooltip: t('list_toolbarItems_moveFirst'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(
              moveTopItems(id, getSelectedItemsByListId(state, id))
            )
          },
        },
        {
          name: t('list_toolbarItems_moveLast'),
          isDisabled: getSelectedItemsByListId(state, id).length === 0,
          icon: <MoveLast width={18} height={18} />,
          tooltip: t('list_toolbarItems_moveLast'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(
              moveBottomItems(id, getSelectedItemsByListId(state, id))
            )
          },
        },
        {
          name: t('list_toolbarItems_moveUp'),
          isDisabled: getSelectedItemsByListId(state, id).length !== 1,
          icon: <MoveUp width={18} height={18} />,
          tooltip: t('list_toolbarItems_moveUp'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(moveUpItem(id, getSelectedItemsByListId(state, id)))
          },
        },
        {
          name: t('list_toolbarItems_moveDown'),
          isDisabled: getSelectedItemsByListId(state, id).length !== 1,
          icon: <MoveDown width={18} height={18} />,
          tooltip: t('list_toolbarItems_moveDown'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(
              moveDownItem(id, getSelectedItemsByListId(state, id))
            )
          },
        },
        {
          name: t('list_toolbarItems_export'),
          isDisabled: false,
          icon: <ExportList className="pb-1" width={40} height={26} />,
          tooltip: t('list_toolbarItems_export'),
          inVisibleMeny: true,
          action: () => {
            onClickedExport(state, id)
          },
        },
        {
          name: t('list_toolbarItems_settings'),
          isDisabled: false,
          icon: <SettingIcon width={18} height={18} />,
          tooltip: t('list_toolbarItems_settings'),
          inVisibleMeny: true,
          action: () => {
            store.dispatch(openSelectColumnsModal())
          },
        },
      ]
    },
    getContextMenu: (type: TabTypes, t: TFunction) =>
      type === TabTypes.List
        ? [
            {
              name: t('list_contextmenu_newAd'),
              icon: <AddListing />,
              action: (selectedItem: IItem) => {
                store.dispatch(
                  openContent(
                    {
                      type: TabTypes.Item,
                      id: `newitem-${selectedItem.listId}`,
                      selected: true,
                      name: selectedItem.header,
                    },
                    selectedItem.header
                  )
                )
              },
            },
            {
              name: t('list_contextmenu_delete'),
              icon: <Remove />,
              action: (selectedItem: IItem) => {
                store.dispatch(openDeleteItemsModal(selectedItem.listId))
              },
            },
            {
              name: t('list_contextmenu_duplicate'),
              icon: <Duplicate />,
              action: (selectedItem: IItem) => {
                const selectedItems = getSelectedItems(state, selectedItem)
                store.dispatch(
                  duplicateItems(selectedItem.listId, selectedItems)
                )
              },
            },
            {
              name: t('list_contextmenu_edit'),
              icon: <EditIcon />,
              action: (selectedItem: IItem) => {
                const selectedItems = getSelectedItems(state, selectedItem)
                selectedItems.length > 1
                  ? store.dispatch(
                      openEditManyItemsAtOnceModal(
                        selectedItem.listId,
                        selectedItems
                      )
                    )
                  : selectedItems.map((i) =>
                      store.dispatch(
                        openContent(
                          {
                            type: TabTypes.Item,
                            id: selectedItem.id!,
                            name: selectedItem.header,
                            selected: true,
                          },
                          selectedItem.header
                        )
                      )
                    )
              },
            },
            {
              name: t('list_contextmenu_moveFirst'),
              icon: <MoveUp />,
              action: (selectedItem: IItem) => {
                const selectedItems = getSelectedItems(state, selectedItem)
                store.dispatch(moveTopItems(selectedItem.listId, selectedItems))
              },
            },
            {
              name: t('list_contextmenu_moveLast'),
              icon: <MoveDown />,
              action: (selectedItem: IItem) => {
                const selectedItems = getSelectedItems(state, selectedItem)
                store.dispatch(
                  moveBottomItems(selectedItem.listId, selectedItems)
                )
              },
            },
            {
              name: t('list_contextmenu_moveUp'),
              icon: <MoveUp />,
              action: (selectedItem: IItem) => {
                const clickedItems = state.ui[
                  'clickedItems'
                ] as IItemClickOrder[]
                store.dispatch(
                  moveUpItems(
                    selectedItem.listId,
                    clickedItems.map((x) => x.itemId!)
                  )
                )
              },
            },
            {
              name: t('list_contextmenu_moveDown'),
              icon: <MoveDown />,
              action: (selectedItem: IItem) => {
                const clickedItems = state.ui[
                  'clickedItems'
                ] as IItemClickOrder[]
                store.dispatch(
                  moveDownItems(
                    selectedItem.listId,
                    clickedItems.map((x) => x.itemId!)
                  )
                )
              },
            },
          ]
        : [
            {
              name: t('list_contextmenu_newAd'),
              icon: <AddListing />,
            },
            {
              name: t('list_contextmenu_delete'),
              icon: <Remove />,
            },
            {
              name: t('list_contextmenu_duplicate'),
              icon: <Duplicate />,
            },
            {
              name: t('list_contextmenu_edit'),
              icon: <EditIcon />,
            },
            {
              name: t('list_contextmenu_moveFirst'),
              icon: <MoveUp />,
            },
            {
              name: t('list_contextmenu_moveLast'),
              icon: <MoveDown />,
            },
            {
              name: t('list_contextmenu_moveUp'),
              icon: <MoveUp />,
            },
            {
              name: t('list_contextmenu_moveDown'),
              icon: <MoveDown />,
            },
          ],
  }),
  {
    getContent: (type: TabTypes, id: string) =>
      type === TabTypes.List ? getListById(id) : getTemplateById(id),
    getListById,
    openContent,
    deleteItemFromList,
    reorderList,
    duplicateItems,
    cellDoubleClick: (type: TabTypes, item: IItem) =>
      type === TabTypes.List
        ? openContent(
            {
              type: TabTypes.Item,
              id: item!.id!,
              selected: true,
              name: item.header,
            },
            item.header
          )
        : openContent(
            {
              type: TabTypes.TemplateItem,
              id: item!.id!,
              selected: true,
              name: item.header,
            },
            item.header
          ),
    updateColumnOrder,
    openEditManyItemsAtOnceModal,
    saveClickOrderOnItemInList,
  }
)(List)

export default connectedComponent
