import React, { useState, useEffect, useCallback } from 'react'
import { getItemsOptionalPriceSummary } from '../../Infrastructure/Helpers/priceCalculation'
import Button from '../../Components/Button'
import Input from '../../Components/Input'
import type { IList, IPublishViewModel } from '../../List/IList'
import type { IFileUploadImage, IItem } from '../../Items/IItem'
import { ReactComponent as WarningIcon } from '@tradera/blueprint/theme/icons2/alert.svg'
import { ReactComponent as CheckIcon } from '@tradera/blueprint/theme/icons2/check-fill.svg'
import { ReactComponent as FailedIcon } from '@tradera/blueprint/theme/icons2/close-fill.svg'
import { useDispatch } from 'react-redux'
import { updateList } from '../../List/List.actions'
import { format, addDays } from 'date-fns'
import type { ISellItem } from '../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

export interface PublishListProps {
  closeModal: () => void
  openBankIdModal: () => void
  publishList: (viewmodel: IPublishViewModel) => void
  republishItems: (
    viewmodel: IPublishViewModel,
    imageList: IFileUploadImage[]
  ) => void
  bankIdVerificationEnd: (values: any) => void
  setUploadInterval: (uploadInterval?: string) => void
  setUploadDateTime: (uploadDateTime?: string) => void
  setUploadItems: (uploadItems: string[]) => void
  setUploadSellItems: (uploadSellItems: ISellItem[]) => void
  list?: IList
  items: (IItem | ISellItem)[]
  selectedItems: (IItem | ISellItem)[]
  bankIdRequired: boolean
  bankIdSuccess: boolean
  uploadInterval: string
  uploadDateTime: string
  closeClicked: boolean
  imageList: IFileUploadImage[]
  clearImages: () => void
}

const getSelectedItemsIds = (items: (IItem | ISellItem)[]) => {
  if (items === undefined || !items || items.length === 0) {
    return []
  }
  if (items.length > 0 && items[0] === undefined) {
    return []
  }
  const newItemIds = items.map((i: any) => i.id)
  return newItemIds
}

const PublishList: React.FunctionComponent<PublishListProps> = ({
  closeModal,
  openBankIdModal,
  list,
  items,
  selectedItems,
  publishList,
  republishItems,
  bankIdRequired,
  bankIdSuccess,
  bankIdVerificationEnd,
  setUploadInterval,
  setUploadDateTime,
  setUploadItems,
  setUploadSellItems,
  uploadInterval,
  uploadDateTime,
  closeClicked,
  imageList,
  clearImages,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [publishInterval, setPublishInterval] = useState<string>()
  const [isDisabledSave, setSaveDisabled] = useState(false)
  const [startDate, setStartDate] = useState<string>()
  const [itemsToPublish, setItemsToPublish] = useState<(IItem | ISellItem)[]>(
    []
  )
  const [timeUnit, setTimeUnit] = useState('Minuter')
  const [firstEndTime, setFirstEndTime] = useState<string>()
  const [lastEndTime, setLastEndTime] = useState<string>()

  const minDate = format(new Date(), 'yyyy-MM-dd HH:mm')
  const maxDate = format(addDays(new Date(), 30), 'yyyy-MM-dd HH:mm')

  //Set initial publish interval and time unit
  useEffect(() => {
    let interval = parseInt(uploadInterval, 10)
    if (isNaN(interval)) {
      setPublishInterval('0')
    } else if (interval % 60 === 0) {
      setPublishInterval((interval / 60).toString())
    } else {
      setTimeUnit('Sekunder')
      setPublishInterval(interval.toString())
    } //check if start time is set and if it is a date
    if (uploadDateTime && !isNaN(Date.parse(uploadDateTime))) {
      setStartDate(format(new Date(uploadDateTime), 'yyyy-MM-dd HH:mm'))
    } else {
      setStartDate(format(new Date(), 'yyyy-MM-dd HH:mm'))
    }
  }, [])

  useEffect(() => {
    if (closeClicked) {
      resetPublish()
    }
  }, [closeClicked])

  function handleDateChange(ev) {
    if (!ev.target['validity'].valid) return
    const date = format(new Date(ev.target['value']), 'yyyy-MM-dd HH:mm')
    setStartDate(date)
  }

  useEffect(() => {
    let itemsToPublish = selectedItems.length > 0 ? selectedItems : []
    if (itemsToPublish.length === 0) {
      const listIsValid = !items.some((x) => !x.validationResult?.isValid)
      itemsToPublish = !listIsValid
        ? items?.filter((x) => x.validationResult?.isValid)
        : items
    }
    setItemsToPublish(itemsToPublish)
  }, [items, selectedItems])

  const publishListClick = () => {
    const interval = parseInterval()
    if (list) {
      const updatedList = {
        ...list,
        publishing: true,
      }
      dispatch(updateList(updatedList))
    }
    const viewModel: IPublishViewModel = {
      listId: list ? list.id : 'UnsoldItems',
      selectedItems: getSelectedItemsIds(itemsToPublish),
      uploadInterval: isNaN(interval) ? 0 : interval,
      uploadDateTime: startDate!.replace(' ', 'T'), //Using new Date(startDate).toISOString() ends up with a date that is 1 hour before the selected time
    }
    if (list) {
      publishList(viewModel)
    } else {
      viewModel.sellItems = itemsToPublish as ISellItem[]
      dispatch(setUploadSellItems(viewModel.sellItems))
      republishItems(viewModel, imageList)
    }
    setSaveDisabled(true)
  }

  const resetPublish = () => {
    bankIdVerificationEnd({ bankIdSuccess: null, bankIdRequired: null })
    setUploadInterval(undefined)
    setUploadDateTime(undefined)
    setUploadItems([])
    setUploadSellItems([])
    clearImages()
    const updatedList = {
      ...list,
      publishing: false,
    }
    dispatch(updateList(updatedList))
  }

  const cancelPublish = () => {
    resetPublish()
    closeModal()
  }

  const parseInterval = useCallback(() => {
    let interval = parseInt(publishInterval!, 10)
    if (isNaN(interval)) {
      return 0
    }
    if (timeUnit === 'Minuter') {
      interval = interval * 60
    }
    return interval
  }, [publishInterval, timeUnit])

  useEffect(() => {
    const interval = parseInterval()
    const itemsWithoutChosenEndTime = itemsToPublish.filter(
      (i) => !i.hasChosenEndTime
    )
    const shortestAdLength =
      itemsWithoutChosenEndTime.length > 0
        ? Math.min(
            ...itemsWithoutChosenEndTime.map(
              (i) => i.advertisementLengthInDays!
            )
          )
        : 0
    const firstEndTime = new Date(startDate as string)
    const firstEndDate = addDays(firstEndTime, shortestAdLength)
    if (firstEndDate instanceof Date && !isNaN(firstEndDate.getTime())) {
      setFirstEndTime(format(firstEndDate, 'yyyy-MM-dd HH:mm:ss'))
    }
    const lastEndTime = new Date(firstEndDate)
    if (itemsWithoutChosenEndTime.length > 1) {
      lastEndTime.setSeconds(
        lastEndTime.getSeconds() +
          interval * Math.max(itemsWithoutChosenEndTime.length - 1, 0)
      )
    }
    if (lastEndTime instanceof Date && !isNaN(lastEndTime.getTime())) {
      setLastEndTime(format(lastEndTime, 'yyyy-MM-dd HH:mm:ss'))
    }
  }, [itemsToPublish, startDate, publishInterval, parseInterval])

  const changePublishInterval = (e) => {
    if (e.length <= 4) {
      const interval = parseInt(e)
      if (
        e.length === 0 ||
        (timeUnit === 'Sekunder' && interval <= 3600) ||
        (timeUnit === 'Minuter' && interval <= 60)
      ) {
        setPublishInterval(e)
      }
    }
  }

  const timeUnitClick = (unit: string) => {
    setTimeUnit(unit)
    setPublishInterval('')
  }

  return (
    <div>
      <div className="p-3">
        {bankIdRequired === true && bankIdSuccess !== false && (
          <div className="row bg-info  mb-1 py-1 px-1">
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                  <WarningIcon className="icon" />
                </div>
                <div className="col-11">
                  <p>{t('modal_publishList_bankId')}.</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary btn-fluid"
                onClick={() => openBankIdModal()}
              >
                {t('modal_publishList_bankId_confirm')} {'->'}
              </button>
            </div>
          </div>
        )}
        {bankIdSuccess === true && (
          <div className="row bg-info mb-1 py-1 px-1">
            <div className="col-1">
              <CheckIcon className="icon" />
            </div>
            <div className="col-11">
              <p>{t('modal_publishList_bankId_confirmed')}!</p>
            </div>
          </div>
        )}
        {bankIdSuccess === false && (
          <div className="row bg-info py-2 px-1">
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                  <FailedIcon className="icon" />
                </div>
                <div className="col-11">
                  <p>{t('modal_publishList_bankId_failed')}.</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary btn-fluid"
                onClick={() => openBankIdModal()}
              >
                {t('modal_publishList_bankId_failedInfo')}
              </button>
            </div>
          </div>
        )}
        <div className="mb-2">
          <div className="row">
            <div className="col-12">
              <p>
                <strong>{t('modal_publishList_numberOfAds')}:</strong>
                <span className="ml-1">{itemsToPublish.length}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="font-weight-bold">
              {t('modal_publishList_startTime')}
            </p>
            <p className="text-muted">{t('modal_publishList_startTimeInfo')}</p>
            <input
              data-testid="start-date-input"
              type="datetime-local"
              min={minDate}
              max={maxDate}
              className="mt-1 p-1"
              value={startDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="col-6">
            <p className="font-weight-bold">
              {t('modal_publishList_interval')}*
            </p>
            <p className="text-muted">{t('modal_publishList_intervalInfo')}</p>
            <div className="d-flex align-items-center gap-x-2">
              <Input
                testId="interval-input"
                type="number"
                name="weight"
                value={publishInterval}
                group="input-group"
                dropdown={true}
                inputAppendDropdown={{
                  selectedValue: timeUnit,
                  items: [
                    {
                      name: 'Minuter',
                      action: () => timeUnitClick('Minuter'),
                    },
                    {
                      name: 'Sekunder',
                      action: () => timeUnitClick('Sekunder'),
                    },
                  ],
                }}
                onChangeValue={(e) => changePublishInterval(e.target.value)}
              />
            </div>
            <p className="text-muted">
              {t('modal_publishList_firstAdEnds')} {firstEndTime}
            </p>
            <p className="text-muted">
              {' '}
              {t('modal_publishList_lastAdEnds')} {lastEndTime}
            </p>
            <p className="text-muted">*{t('modal_publishList_endTimeInfo')}</p>
          </div>
        </div>
        <div className="mt-1 pt-3 border-top">
          <div className="row">
            <div className="col-12">
              <p className="label-secondary mb-2">
                {t('modal_publishList_summary')}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p>
                <strong>{t('modal_publishList_provision')}</strong>{' '}
                <span className="font-italic ml-1 text-muted">
                  {t('modal_publishList_provisionInfo')}
                </span>
              </p>
              <div className="d-flex justify-content-between">
                <p>
                  {t('modal_publishList_provisionMin')} 3 kr,{' '}
                  {t('modal_publishList_provisionMax')} 200 kr
                </p>
                <p>10 %</p>
              </div>
              <p className="text-muted">
                {t('modal_publishList_summaryInfo')}.
              </p>
            </div>
            <div className="col-6">
              <p>
                <strong>{t('modal_publishList_extraAdditions')}</strong>{' '}
                <span className="font-italic ml-1 text-muted">
                  {t('modal_publishList_extraAdditionsInfo')}
                </span>
              </p>
              {getItemsOptionalPriceSummary(t, itemsToPublish).map((item) => (
                <div className="d-flex justify-content-between">
                  <p>{item.name}</p>
                  <p>{item.amount} kr</p>
                </div>
              ))}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <p className="text-muted">
                {t('modal_publishList_monthlyFeeInfo')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-danger btn-fluid"
              onClick={() => cancelPublish()}
            >
              {t('modal_publishList_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <Button
              variant={isDisabledSave ? 'disable' : 'primary'}
              size="normal"
              text={t('modal_publishList_button_confirm')}
              clickHandler={() => publishListClick()}
              disable={isDisabledSave}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishList
