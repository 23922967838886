import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import type { IList } from './IList'
import {
  createListFromTemplateSucceeded,
  createListSucceeded,
  createImportedListSucceeded,
  duplicateListSucceeded,
  getListByIdSucceeded,
  updateList,
  updateListByIdSucceeded,
  importListFromPlZipSucceeded,
  updateListNameSucceeded,
  publishListSucceeded,
  resetCancelPublishListSucceeded,
  moveUpItemInListSucceeded,
  moveDownItemInListSucceeded,
  moveTopItemInListSucceeded,
  moveBottomItemInListSucceeded,
  updateManyItemsAtOnceSucceeded,
  deleteItemsFromListSucceeded,
  reorderListSucceeded,
} from './List.actions'
import {
  createItemSucceeded,
  duplicateItemsSucceeded,
  moveItemsSucceeded,
  updateItemSucceeded,
} from '../Items/Items.action'

const initialState: any = null

const listReducer: Reducer<IList, IAction<any>> = handleActions(
  {
    [createListSucceeded.toString()]: (_state: IList, action: IAction<IList>) =>
      action.payload,
    [createListFromTemplateSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => action.payload,
    [createImportedListSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => ({
      ...action.payload,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [importListFromPlZipSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => ({
      ...action.payload,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [duplicateListSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => ({
      ...action.payload,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [publishListSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => ({
      ...action.payload,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [resetCancelPublishListSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => ({
      ...action.payload,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [createItemSucceeded.toString()]: (state: IList, action: IAction<any>) => ({
      ...state,
      items: state.items.concat(action.payload.id),
      lastChanged: new Date().toISOString(),
    }),
    [updateItemSucceeded.toString()]: (state: IList, action: IAction<any>) => ({
      ...state,
      items: state.items.concat(action.payload.id),
    }),
    [deleteItemsFromListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
      lastChanged: new Date().toISOString(),
    }),
    [duplicateItemsSucceeded.toString()]: (
      state: IList,
      action: IAction<any>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
      lastChanged: new Date().toISOString(),
    }),
    [moveItemsSucceeded.toString()]: (state: IList, action: IAction<any>) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
      lastChanged: new Date().toISOString(),
    }),
    [getListByIdSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [moveUpItemInListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [moveDownItemInListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [moveTopItemInListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [moveBottomItemInListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [reorderListSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [updateManyItemsAtOnceSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      items: action.payload.items.map((item: any) => item.id),
    }),
    [updateList.toString()]: (_state: IList, action: IAction<IList>) =>
      action.payload,
    [updateListByIdSucceeded.toString()]: (
      _state: IList,
      action: IAction<IList>
    ) => action.payload,
    [updateListNameSucceeded.toString()]: (
      state: IList,
      action: IAction<IList>
    ) => ({
      ...state,
      name: action.payload.name,
      lastChanged: new Date().toISOString(),
    }),
  },
  initialState
)

export default listReducer
