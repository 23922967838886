import React, { useState, useRef } from 'react'
import type { MutableRefObject } from 'react'
import { usePopper } from 'react-popper'
import { Portal } from 'react-portal'
import styled from 'styled-components'

interface ImagePreviewProps {
  targetRef: MutableRefObject<null>
  isVisible: boolean
  imageUrls: string[]
  height: number
  width: number
}

const ImagePreview: React.FunctionComponent<ImagePreviewProps> = ({
  targetRef,
  isVisible,
  imageUrls,
  height,
  width,
}) => {
  const popperRef = useRef(null)
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(
    targetRef.current,
    popperRef.current,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  )
  if (!isVisible) return null

  return (
    <Portal>
      <PopperContainer
        ref={popperRef}
        style={{
          ...styles.popper,
        }}
        {...attributes.popper}
      >
        <div ref={setArrowRef} style={styles.arrow} className="arrow" />
        {imageUrls.map((x, i) => (
          <div
            key={`img-cont-${x}${i}`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: height,
              width: width,
              border: '0.5px solid lightgrey',
              marginLeft: 15,
              marginBottom: 15,
              backgroundColor: '#f8f8f8',
            }}
          >
            {' '}
            <img
              src={x}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
              key={`${x}${i}`}
            />
          </div>
        ))}
      </PopperContainer>
    </Portal>
  )
}

const PopperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.35);
  background-color: white;
  z-index: 5;
  max-width: 100vw;
  padding-top: 15px;
  padding-right: 15px;
  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -20px;
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -30px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`

export default ImagePreview
