import React from 'react'
import { useDispatch } from 'react-redux'
import CheckBox from '../../Components/CheckBox'
import { TabTypes } from '../../Content/IContent'
import { selectItemInList } from '../../Items/Items.action'
import type { IList } from '../../List/IList'
import { updateList } from '../../List/List.actions'
import type { ITemplate } from '../../Template/ITemplate'
import { updateTemplate } from '../../Template/Template.actions'

interface CheckBoxSelectRowRendererProps {
  value: boolean
  setValue: () => void
  rowIndex: number
  data: IList | ITemplate
  test: any
  selectedContentType?: TabTypes | string
}

const CheckBoxSelectRowRenderer: React.FunctionComponent<
  CheckBoxSelectRowRendererProps
> = ({ value, setValue, rowIndex, data, test, selectedContentType }) => {
  const dispatch = useDispatch()
  const contentTypeHasItems = selectedContentType?.toString().includes('items')
  const handleSelectedRows = () => {
    const itemToUpdate = {
      ...data,
      isChecked: !value,
    }
    if (contentTypeHasItems) {
      dispatch(selectItemInList(data.id, !value))
    }
    if (!contentTypeHasItems) {
      selectedContentType === TabTypes.List
        ? dispatch(updateList(itemToUpdate))
        : dispatch(updateTemplate(itemToUpdate))
    }
  }
  return (
    <CheckBox
      paddingless
      checked={value}
      label=""
      value={data.id}
      handleInputChange={() => handleSelectedRows()}
      name="isChecked"
    />
  )
}
export default CheckBoxSelectRowRenderer
