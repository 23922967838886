import type { ITab} from '../Content/IContent';
import { TabTypes } from '../Content/IContent'
import { RootState } from '../Store/rootReducer'

export const selectListById = (state, id) => state.lists.byId[id]

export const getTabContent = (state, id) => {
  return state.content.byId[id]
}
export const getItems = (state, id: string, selectedContent: ITab) => {
  if (id) {
    const items =
      selectedContent.type === TabTypes.List
        ? state.lists.byId[id].items
            .map((item) => state.items.byId[item])
            .sort(
              (firstItem, secondItem) =>
                firstItem.UploadOrder - secondItem.UploadOrder
            )
        : state.templates.byId[id].items.map(
            (item) => state.templateItem.byId[item]
          )
    return items
  } else {
    return []
  }
}

export const getItemsClickOrder = (state) => {
  const itemIds = state.ui.clickedItems
  return itemIds
}

export const getListItems = (state, id: string) => {
  const items = state.lists.byId[id].items
    .map((item) => state.items.byId[item])
    .sort(
      (firstItem, secondItem) => firstItem.UploadOrder - secondItem.UploadOrder
    )
  return items
}
