import React, { useState } from 'react'
import Input from '../../../Components/Input'
import Select from '../../../Components/Select'
import CheckBox from '../../../Components/CheckBox'
import type { IItemChanges } from '../../IItem'
import { ITextToChage, ITextPosition } from '../../IItem'
import styles from '../EditItemsForm.module.scss'
import CheckBoxExpander from '../../../Components/CheckBoxExpander'
import { useTranslation } from 'react-i18next'

interface TextTransformationProp {
  updateStateByItemChanges: (property: string, value: any) => void
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  itemChanges: IItemChanges
}

const TextTransformation: React.FunctionComponent<TextTransformationProp> = ({
  updateStateByItemChanges,
  handleInputChange,
  itemChanges,
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const selectTextToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateStateByItemChanges('textToChange', e.currentTarget.value as any)
  }
  const selectTextPosition = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateStateByItemChanges('textPosition', e.currentTarget.value as any)
  }

  return (
    <div>
      <CheckBoxExpander
        checked={itemChanges.changeTypeAndPrice}
        label={t('editItemsForm_textTransform_changeText')}
        name="changeText"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpanded}
      />
      {expanded && (
        <div className={styles.expandContainer}>
          <div className="row">
            <div className="col-6">
              <Select
                name="textToChange"
                defaultValue={ITextToChage.Title}
                onChange={(e) => selectTextToChange(e)}
                disable={!itemChanges.changeText}
              >
                <option value={ITextToChage['Title']}>
                  {t('editItemsForm_textTransform_title')}
                </option>
                <option value={ITextToChage['Description']}>
                  {t('editItemsForm_textTransform_description')}
                </option>
              </Select>
            </div>
            <div className="col-6">
              <Select
                name="textPosition"
                defaultValue={ITextPosition.Last}
                onChange={(e) => selectTextPosition(e)}
                disable={!itemChanges.changeAddInText}
              >
                <option value={ITextPosition['Last']}>
                  {t('editItemsForm_textTransform_last')}
                </option>
                <option value={ITextPosition['First']}>
                  {t('editItemsForm_textTransform_first')}
                </option>
              </Select>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-3">
              <CheckBox
                checked={itemChanges.changeAddInText}
                spacingLeft={false}
                label={t('editItemsForm_textTransform_addToText') + ':'}
                handleInputChange={updateStateByItemChanges}
                name="changeAddInText"
                disable={!itemChanges.changeText}
              />
            </div>
            <div className="col-md-9">
              <Input
                group="form-group"
                name="addText"
                type="text"
                value={itemChanges.addText}
                onChangeValue={(e) => handleInputChange(e)}
                disable={!itemChanges.changeAddInText}
              />
            </div>
          </div>
          <hr className={styles.divider} />
          <div className="row align-items-center">
            <div className="col-md-3">
              <CheckBox
                checked={itemChanges.changeReplaceText}
                label={t('editItemsForm_textTransform_replaceText') + ':'}
                handleInputChange={updateStateByItemChanges}
                name="changeReplaceText"
                disable={!itemChanges.changeText}
              />
            </div>
            <div className="col-md-9">
              <Input
                group="form-group"
                name="replaceText"
                type="text"
                value={itemChanges.replaceText}
                onChangeValue={(e) => handleInputChange(e)}
                disable={!itemChanges.changeReplaceText}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-3">
              <span className="label-small ml-5">
                {t('editItemsForm_textTransform_replaceWith')}
              </span>
            </div>
            <div className="col-md-9">
              <Input
                group="form-group"
                name="newText"
                type="text"
                value={itemChanges.newText}
                onChangeValue={(e) => handleInputChange(e)}
                disable={!itemChanges.changeReplaceText}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TextTransformation
