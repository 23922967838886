import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { beginBankIdVerification } from '../../User/BankId.actions'
import BankIdModal from './BankIdModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
      pending: state.ui.pending,
      modalType: state.ui.modalType,
      personalNumber: state.ui.bankIdPersonalNumber,
    }
  },
  {
    closeModal,
    beginBankIdVerification
  }
)(BankIdModal)

export default connectedComponent
