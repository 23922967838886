import React from 'react'
import Select from '../../../Components/Select'

import type { IItem } from '../../IItem'
import { ISellingLocation } from '../../IItem'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ItemLocationProp {
  item: IItem | ISellItem
  label?: string
  updateStateByItem: (property: string, value: any) => void
  disable?: boolean
}
const ItemLocation: React.FunctionComponent<ItemLocationProp> = ({
  item,
  label,
  updateStateByItem,
  disable,
}) => {
  const { t } = useTranslation()
  const handleSelectInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value)
    updateStateByItem('acceptedBidders', value)
  }
  return (
    <>
      <Select
        name="acceptedBidders"
        label={label}
        labelAlign="top"
        defaultValue={item.acceptedBidders.toString()}
        onChange={(e) => handleSelectInput(e)}
        disable={disable}
      >
        <option value={ISellingLocation['Sverige']}>
          {t('itemsForm_itemLocation_sweden')}
        </option>
        <option value={ISellingLocation['EU']}>
          {t('itemsForm_itemLocation_eu')}
        </option>
        <option value={ISellingLocation['HelaVärlden']}>
          {t('itemsForm_itemLocation_worldwide')}
        </option>
      </Select>
    </>
  )
}
export default ItemLocation
