import React, { useEffect, useState } from 'react'
import type { ICategory, IAttribute, IItem } from '../../IItem'
import Attributes from './Attributes'
import { getCategoryId, getIsValidCategoryLevel } from './Category.helpers'
import CategoryPicker from './CategoryPicker'
import ErrorLabel from '../ErrorLabel'
import Label from '../../../Components/Label'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface IAttributesState {
  [categoryId: string]: IAttribute[]
}
interface ICategoryComponent {
  categories: ICategory[]
  attributes: IAttributesState
  getCategories: () => Promise<any>
  getAttributes: (categoryId: string) => Promise<any>
  updateStateByItem: (key: string, value: any) => void
  item: IItem | ISellItem
  noLabel?: boolean
}

const Category: React.FunctionComponent<ICategoryComponent> = ({
  categories,
  attributes,
  getCategories,
  getAttributes,
  updateStateByItem,
  item,
  noLabel,
}: ICategoryComponent) => {
  const { t } = useTranslation()
  const [secondCategories, setSecondCategories] = useState<ICategory[]>([])
  const [thirdCategories, setThirdCategories] = useState<ICategory[]>([])
  const [fourthCategories, setFourthCategories] = useState<ICategory[]>([])
  const [attributeCategoryId, setAttributeCategoryId] = useState<string>()
  const [defaultAttributes, setDefaultAttributes] = useState([])

  const setSelectedAttributes = (
    selectedAttributes: any,
    categoryId: string
  ) => {
    const attributeState: IAttributesState = selectedAttributes
    const attributesToSend: any[] = []
    //Add selected attributes
    for (const id in attributeState) {
      attributesToSend.push({
        id,
        key: id,
        possibleTermValues: attributeState[id],
        minNumberOfValues: attributes[attributeCategoryId!]!.find(
          (c) => c.id.toString() === id
        )
          ? attributes[attributeCategoryId!]!.find(
              (c) => c.id.toString() === id
            )!.minNumberOfValues
          : 0,
        maxNumberOfValues: attributes[attributeCategoryId!]!.find(
          (c) => c.id.toString() === id
        )
          ? attributes[attributeCategoryId!]!.find(
              (c) => c.id.toString() === id
            )!.maxNumberOfValues
          : 0,
      })
    }
    //Add mandatory attributes even if they are not set (for list validation)
    if (attributes![categoryId!] && attributes![categoryId!].length > 0) {
      attributes[categoryId!]!.forEach((attribute) => {
        if (
          !attributesToSend.find((c) => c.id === attribute.id.toString()) &&
          attribute.minNumberOfValues > 0
        ) {
          let mandatoryAttribute = { ...attribute }
          mandatoryAttribute.possibleTermValues = []
          attributesToSend.push(mandatoryAttribute)
        }
      })
    }
    updateStateByItem('attributes', attributesToSend)
  }

  useEffect(() => {
    getCategories()
  }, [getCategories, categories.length])

  useEffect(() => {
    if (item.category && categories.length > 0) {
      const retrieveAttributes = (c: ICategory) => {
        getAttributes(c.id).then(() => {
          setAttributeCategoryId(c.id)
          if (item.attributes) {
            setDefaultAttributes(item.attributes as any)
          }
        })
      }

      const firstCategory = item.category
      const secondCategory = firstCategory.category
        ? firstCategory.category[0]
        : null
      const thirdCategory =
        secondCategory && secondCategory.category
          ? secondCategory.category[0]
          : null

      const fourthCategory =
        thirdCategory && thirdCategory.category
          ? thirdCategory.category[0]
          : null

      let secondCategories, thirdCategories, fourthCategories

      if (firstCategory.category?.length) {
        secondCategories = categories.find((c) => c.id === firstCategory.id)!
          .category!
        setSecondCategories(secondCategories)
      }
      if (secondCategory) {
        thirdCategories = secondCategories.find(
          (sc) => sc.id === secondCategory?.id
        )?.category
        if (thirdCategories && thirdCategories.length > 0) {
          setThirdCategories(thirdCategories!)
        } else {
          retrieveAttributes(secondCategory)
        }
      }

      if (thirdCategory) {
        fourthCategories = thirdCategories.find(
          (sc) => sc.id === thirdCategory?.id
        )?.category
        if (fourthCategories && fourthCategories.length > 0) {
          setFourthCategories(fourthCategories)
        } else {
          retrieveAttributes(thirdCategory)
        }
      }

      if (fourthCategory) {
        retrieveAttributes(fourthCategory)
      }
    }
  }, [
    categories,
    categories.length,
    getAttributes,
    item.attributes,
    item.category,
  ])

  const updateItemCategory = (c: ICategory) => {
    updateStateByItem('category', c)
  }

  const clearAttributes = () => {
    setAttributeCategoryId('')
    updateStateByItem('attributes', undefined)
  }

  const onGetAttributes = (categoryId: string) => {
    setAttributeCategoryId(categoryId)
    getAttributes(categoryId).then(() => {
      setSelectedAttributes({}, categoryId)
    })
  }

  return (
    <div className="mt-2">
      {item.validationResult &&
      !item.validationResult?.properties.category &&
      !noLabel ? (
        <ErrorLabel
          label={t('itemsForm_category_label') + '*'}
          description={t('itemsForm_category_error')}
        />
      ) : !noLabel ? (
        <Label label={t('itemsForm_category_label') + '*'} />
      ) : (
        ''
      )}
      <div className="row d-flex gap-y-1">
        <div className="col-lg-3">
          <CategoryPicker
            item={item}
            categories={categories}
            number={1}
            defaultValueId={getCategoryId(1, item.category)}
            isValidCategoryLevel={getIsValidCategoryLevel(1, item.category)}
            setSubCategories={setSecondCategories}
            getAttributes={onGetAttributes}
            setAttributeCategoryId={setAttributeCategoryId}
            updateItemCategory={updateItemCategory}
            clearOtherCategoryOptions={() => {
              setThirdCategories([])
              setFourthCategories([])
            }}
            clearAttributes={clearAttributes}
          />
        </div>
        <div className="col-lg-3">
          <CategoryPicker
            item={item}
            categories={secondCategories}
            number={2}
            defaultValueId={getCategoryId(2, item.category)}
            isValidCategoryLevel={getIsValidCategoryLevel(2, item.category)}
            setSubCategories={setThirdCategories}
            getAttributes={onGetAttributes}
            setAttributeCategoryId={setAttributeCategoryId}
            updateItemCategory={updateItemCategory}
            clearOtherCategoryOptions={() => {
              setFourthCategories([])
            }}
            clearAttributes={clearAttributes}
          />
        </div>
        <div className="col-lg-3">
          <CategoryPicker
            item={item}
            categories={thirdCategories}
            number={3}
            defaultValueId={getCategoryId(3, item.category)}
            isValidCategoryLevel={getIsValidCategoryLevel(3, item.category)}
            setSubCategories={setFourthCategories}
            getAttributes={onGetAttributes}
            setAttributeCategoryId={setAttributeCategoryId}
            updateItemCategory={updateItemCategory}
            clearAttributes={clearAttributes}
          />
        </div>
        <div className="col-lg-3">
          <CategoryPicker
            item={item}
            categories={fourthCategories}
            number={4}
            defaultValueId={getCategoryId(4, item.category)}
            isValidCategoryLevel={getIsValidCategoryLevel(4, item.category)}
            setSubCategories={() => {}}
            getAttributes={onGetAttributes}
            setAttributeCategoryId={setAttributeCategoryId}
            updateItemCategory={updateItemCategory}
            clearAttributes={clearAttributes}
          />
        </div>
      </div>
      {attributeCategoryId && (
        <Attributes
          item={item}
          categoryId={attributeCategoryId}
          attributes={attributes}
          setSelectedAttributes={setSelectedAttributes}
          defaultAttributes={defaultAttributes}
        />
      )}
    </div>
  )
}

export default Category
