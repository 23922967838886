import React, { useState } from 'react'
import style from './SettingsModal.module.scss'
import { useTranslation } from 'react-i18next'
import { Language, type IUser, Country } from '../../User/IUser'

const URL = 'https://www.tradera.com'

interface SettingsModalProps {
  closeModal: () => void
  user: IUser
  updatePurchaseTerms: (purchaseTerms: string) => Promise<any>
}

const SettingsModal: React.FunctionComponent<SettingsModalProps> = ({
  closeModal,
  user,
  updatePurchaseTerms,
}) => {
  const { t } = useTranslation()
  const [editedPurchaseTerms, setEditedPurchaseTerms] = useState(
    user.purchaseTerms
  )

  const handlePurchaseTermsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEditedPurchaseTerms(event.target.value)
  }

  const handleSaveAndClose = async () => {
    await updatePurchaseTerms(editedPurchaseTerms)
    closeModal()
  }
  return (
    <div>
      {user.hasShop && (
        <div className="p-3">
          <div className="row d-flex justify-content-between align-items-center my-1">
            <p className="label">{t('modal_settings_button_purchaseTerms')}</p>
            <p>{t('modal_settings_button_purchaseTermsInfo')}</p>
          </div>
          <div className="row">
            <textarea
              value={editedPurchaseTerms}
              onChange={handlePurchaseTermsChange}
              className={style.purchaseTermsTextarea}
              placeholder={
                t('modal_settings_purchaseTerms_placeholder') + '...'
              }
            />
          </div>
        </div>
      )}
      {user.country !== Country.Sweden && (
        <div className="p-3">
          <div className="row d-flex justify-content-between align-items-center my-1">
            <p className="label">{t('modal_settings_adSettings')}</p>
          </div>
          <div className="row d-flex justify-content-between align-items-center py-1 border-bottom">
            <div className="col-8">
              <p>
                <strong>{t('modal_settings_adLanguage')}</strong>
              </p>
              <p className="text-muted">{t('modal_settings_adLanguageInfo')}</p>
            </div>
            <div className="col-4">
              <div className="row d-flex justify-content-between align-items-center">
                <p>{t(Language[user.language])}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL + '/my/settings/selling'}
                  className="d-inline-flex align-items-center align-middle"
                >
                  <p className={style.traderaLink}>
                    {t('modal_settings_adSettingsLink')}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between align-items-center py-1 border-bottom">
            <div className="col-8">
              <p>
                <strong>{t('modal_settings_country')}</strong>
              </p>
            </div>
            <div className="col-4">
              <div className="row d-flex justify-content-between align-items-center">
                <p>{user.country}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL + '/my/settings/localization'}
                  className="d-inline-flex align-items-center align-middle"
                >
                  <p className={style.traderaLink}>
                    {t('modal_settings_adSettingsLink')}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between align-items-center pt-1">
            <div className="col-8">
              <p>
                <strong>{t('modal_settings_currency')}</strong>
              </p>
              <p className="text-muted">{t('modal_settings_currencyInfo')}</p>
            </div>
            <div className="col-4">
              <div className="row d-flex justify-content-between align-items-center">
                <p>{t(user.currency)}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL + '/my/settings/localization'}
                  className="d-inline-flex align-items-center align-middle"
                >
                  <p className={style.traderaLink}>
                    {t('modal_settings_adSettingsLink')}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={closeModal}
            >
              {t('modal_settings_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={handleSaveAndClose}
            >
              {t('modal_settings_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsModal
