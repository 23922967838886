import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import configService from '../../Infrastructure/ConfigService/configService'
import Button from '../../Components/Button'
import styles from './Login.module.scss'
import trackingService from '../../Infrastructure/Tracking/trackingService'
import Footer from '../../Components/Footer'
import Thumbnail from '../../Images/prolister_thumbnail.png'
import Header from '../../Components/Header/Header.container'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { t } = useTranslation()
  useEffect(() => trackingService.trackEvent(['Login', 'Component loaded']), [])
  const loginUrl = `${configService.config.REACT_APP_IDENTITY_BASE_URL}/member/login?ClientId=ProLister&redirectUrl=${window.location.origin}/login-callback`
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <div className="">
      {/* Header */}
      <Header isLoggedIn={false} />
      {/* Main section */}
      <div className={styles.scrollingWrapper}>
        <div className="row align-items-center">
          <div className="col-md-4 order-md-2">
            <div className="row justify-content-center align-items-center p-5">
              <div className={styles.loginWrapper}>
                <h1 className={styles.headingStyle}>{t('login_button')}</h1>
                <p className={styles.textStyle}>{t('login_info')}</p>
                <Button
                  clickHandler={() => ((window as any).location = loginUrl)}
                  variant="primary"
                  size="medium"
                  text={t('login_button')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 order-md-1">
            <div className="row p-5 m-2">
              <div className={styles.playerWrapper}>
                <ReactPlayer
                  className={styles.reactPlayer}
                  url={
                    'https://static.tradera.net/prolister/video/prolister_how_to.mp4'
                  }
                  light={Thumbnail}
                  playing
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
              <p className={styles.textStyle}>{t('login_video')}</p>
            </div>
          </div>
        </div>
        <Footer position={isSmallScreen ? 'static' : 'fixed'} />
      </div>
    </div>
  )
}

export default Login
