import React, {
  useCallback,
  useState,
  createRef,
  useEffect,
  useRef,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from './FileUpload.module.scss'
import { ReactComponent as NewImage } from '@tradera/blueprint/theme/icons2/image.svg'

import { ReactComponent as RotateIcon } from '../../../Icons/icons-ic-rotate.svg'
import { ReactComponent as RemoveIcon } from '../../../Icons/remove.svg'
import Button from '../../../Components/Button'
import { ReactComponent as Spinner } from '../../../Icons/loading.svg'
import type { IItem, IFileUploadImage } from '../../IItem'
import ImagePreview from '../../../Components/ImagePreview'
import { useDispatch } from 'react-redux'
import { openAlertContainer } from '../../../UI/UI.actions'
import type { ISellItem } from '../../../SellView/ISellView'
import { isSellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

const MAX_NUMBER_OF_IMAGES = 10

interface FileUploadProps {
  item: IItem | ISellItem
  imageList: IFileUploadImage[]
  loading: boolean | undefined
  updateImages: (files: File[], id: string) => void
  setImageRotations: (imageRotations: string[]) => void
  addImageUrls: (images: string[]) => void
  onImageListChange: (imageList: IFileUploadImage[]) => void
}

const FileUpload: React.FunctionComponent<FileUploadProps> = ({
  item,
  imageList,
  loading,
  updateImages,
  setImageRotations,
  addImageUrls,
  onImageListChange,
}) => {
  //Images are handled differently depending on if the ad is created now (images saved locally) or edited later (images saved on server). Shop items can have a combination of local and server saved images.
  const newAd = item.id && !item.id.startsWith('newitem') ? false : true

  const { t } = useTranslation()
  const [changeImageIndex, setChangeImageIndex] = useState<number | null>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const maxFileSize = 10000000

  useEffect(() => {
    if (item.imageUrls) {
      const newImageList: IFileUploadImage[] = []
      for (let i = 0; i < item.imageUrls.length; i++) {
        if (!imageList.find((x) => x.url === item.imageUrls[i])) {
          const newImage = {
            url: item.imageUrls[i],
            visible: false,
            file: null,
            rotation: 0,
            ref: createRef(),
          }
          newImageList.push(newImage)
        }
      }
      if (newImageList.length > 0) {
        if (changeImageIndex !== null) {
          const reorderedImageList = [...imageList]
          reorderedImageList.splice(changeImageIndex, 1, newImageList[0])
          onImageListChange(reorderedImageList)
          updateImageUrls(reorderedImageList)
          updateRotation(reorderedImageList)
          setChangeImageIndex(null)
        } else {
          const updatedList = imageList.concat(newImageList)
          onImageListChange(updatedList)
          updateRotation(updatedList)
        }
      }
    }
  }, [imageList, item.imageUrls.length])

  const updateRotation = (imageList) => {
    var rotation = imageList.map(function (image) {
      return image['rotation'].toString()
    })
    if (rotation.some((x) => x > 0)) {
      setImageRotations(rotation)
    } else {
      setImageRotations([])
    }
  }

  const updateImageUrls = (imageList) => {
    var urls = imageList.map(function (image) {
      return image['url']
    })
    addImageUrls(urls)
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFilesWithAllowedFileFormats = acceptedFiles.filter((file) =>
        checkAndFilterImageFormat(file)
      )
      const acceptedImages = acceptedFilesWithAllowedFileFormats.slice(
        0,
        MAX_NUMBER_OF_IMAGES - imageList.length
      )
      if (!newAd && !isSellItem(item) && acceptedImages.length > 0) {
        updateImages([...acceptedImages.map((file: any) => file)], item.id!)
      } else {
        const newImageList: IFileUploadImage[] = []
        acceptedImages!.forEach((image) => {
          const newImage = {
            url: URL.createObjectURL(image),
            visible: false,
            file: image,
            rotation: 0,
            ref: createRef(),
          }
          newImageList.push(newImage)
        })
        const updatedList = imageList.concat(newImageList)
        onImageListChange(updatedList)
        updateRotation(updatedList)
      }
    },
    [imageList.length]
  )

  const checkAndFilterImageFormat = (image: File) => {
    if (!image.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      dispatch(openAlertContainer(t('alert_fileFormat'), 'error'))
      return false
    } else if (image.size > maxFileSize) {
      dispatch(openAlertContainer(t('alert_fileSize'), 'error'))
      return false
    } else {
      return true
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const reorderedImages = Array.from(imageList)
    const [removed] = reorderedImages.splice(result.source.index, 1)
    reorderedImages.splice(result.destination.index, 0, removed)
    onImageListChange(reorderedImages)
    updateRotation(reorderedImages)
    if (!newAd) {
      updateImageUrls(reorderedImages)
    }
  }

  const deleteImage = (index: number) => {
    const updatedImageList = [...imageList]
    updatedImageList.splice(index, 1)
    onImageListChange(updatedImageList)
    updateRotation(updatedImageList)
    if (!newAd && imageList[index].url.startsWith('http')) {
      updateImageUrls(updatedImageList)
    }
  }

  const rotateImage = (image: IFileUploadImage) => {
    let updatedImageList = imageList.map((item) => {
      if (item.url === image.url) {
        const updatedRotation = item.rotation < 3 ? item.rotation + 1 : 0
        return { ...item, rotation: updatedRotation }
      } else {
        return { ...item }
      }
    })
    onImageListChange(updatedImageList)
    updateRotation(updatedImageList)
  }

  const onImageEnter = (image: IFileUploadImage) => {
    let updatedImageList = imageList.map((item) => {
      if (item.url === image.url) {
        return { ...item, visible: true }
      } else {
        return { ...item, visible: false }
      }
    })
    onImageListChange(updatedImageList)
  }

  const onImageLeave = () => {
    let updatedImageList = imageList.map((item) => {
      return { ...item, visible: false }
    })
    onImageListChange(updatedImageList)
  }

  const onImageDoubleClick = (imageIndex: number) => {
    setChangeImageIndex(imageIndex)
    inputFileRef!.current!.click()
  }

  const handleImageChange = (event) => {
    var image = event.target.files[0]
    if (image && checkAndFilterImageFormat(image)) {
      if (!newAd) {
        updateImages([event.target.files[0]], item.id!)
      } else {
        const newImage = {
          url: URL.createObjectURL(image),
          visible: false,
          file: image,
          rotation: 0,
          ref: createRef(),
        }
        const updatedImageList = [...imageList]
        updatedImageList.splice(changeImageIndex!, 1, newImage)
        onImageListChange(updatedImageList)
        updateRotation(updatedImageList)
        setChangeImageIndex(null)
      }
    }
  }

  const onError = (image) => {
    if (!image.errorChecked) {
      let updatedImageList = imageList.map((item) => {
        if (item.url === image.url) {
          return { ...item, errorChecked: true, error: true }
        } else {
          return { ...item }
        }
      })
      onImageListChange(updatedImageList)
    }
  }

  return (
    <div className={`${styles.fileUpload} row`}>
      <div className={`${styles.gridContainer} col-12`}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.grid}
                >
                  {imageList?.map((image, index) => (
                    <div
                      className={styles.imagecontainer}
                      ref={image.ref}
                      key={image.url}
                    >
                      <Draggable
                        key={index}
                        draggableId={`id-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <img
                            onMouseEnter={() => onImageEnter(image)}
                            onMouseLeave={() => onImageLeave()}
                            onDoubleClick={() => onImageDoubleClick(index)}
                            onError={() => onError(image)}
                            src={image.url}
                            key={index}
                            ref={provided.innerRef}
                            alt="item"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${styles.imageItem} ${
                              image.rotation === 1
                                ? styles.rotateimg90
                                : image.rotation === 2
                                ? styles.rotateimg180
                                : image.rotation === 3
                                ? styles.rotateimg270
                                : ''
                            }  d-flex justify-content-center align-items-center`}
                          />
                        )}
                      </Draggable>
                      {image.error && (
                        <p className="size-oslo text-danger mt-1">
                          {t('itemsForm_fileUpload_imageNotExist')}
                        </p>
                      )}
                      <div className="d-flex flex-row justify-content-between">
                        {isSellItem(item) &&
                        imageList[index].url.startsWith('http') ? (
                          <div></div>
                        ) : (
                          <Button
                            size="medium"
                            icon={<RotateIcon width={20} height={20} />}
                            clickHandler={() => rotateImage(image)}
                            variant="noborder"
                          />
                        )}
                        <Button
                          size="medium"
                          icon={<RemoveIcon width={20} height={20} />}
                          clickHandler={() => deleteImage(index)}
                          variant="noborder"
                        />
                      </div>
                      {image.visible && (
                        <ImagePreview
                          isVisible={image.visible}
                          targetRef={image.ref}
                          imageUrls={[image.url]}
                          height={330}
                          width={330}
                        />
                      )}
                    </div>
                  ))}
                  {(imageList === undefined ||
                    imageList?.length < MAX_NUMBER_OF_IMAGES) && (
                    <div
                      {...getRootProps()}
                      className={`${
                        styles.imageItem
                      } d-flex justify-content-center align-items-center ${
                        item.validationResult &&
                        !item.validationResult?.properties.imageUrls
                          ? 'error-border'
                          : ''
                      }`}
                    >
                      <input {...getInputProps()} />
                      <div style={{ cursor: 'pointer' }}>
                        {loading && changeImageIndex === null ? (
                          <Spinner className={styles.iconSpin} />
                        ) : (
                          <>
                            <p
                              className={`${styles.imageItemHeader} ${
                                item.validationResult &&
                                !item.validationResult?.properties.imageUrls
                                  ? 'error-color'
                                  : ''
                              }`}
                            >
                              <NewImage
                                className={`icon icon-md mr-1 ${
                                  item.validationResult &&
                                  !item.validationResult?.properties.imageUrls
                                    ? 'icon-danger'
                                    : ''
                                }`}
                              />
                              {t('itemsForm_fileUpload_chooseImages')}
                            </p>
                            <p className={styles.imageItemDescription}>
                              {t('itemsForm_fileUpload_dragAndDrop')}{' '}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </DragDropContext>
        <input
          type="file"
          hidden={true}
          ref={inputFileRef}
          onChange={handleImageChange}
        />
      </div>
    </div>
  )
}

export default FileUpload
