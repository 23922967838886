import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

interface IOption {
  value: string
  label: string
}
interface IProListerSelectProps {
  options: IOption[]
  onChange: (o: IOption) => void
  isMulti?: boolean
  placeholder?: string
  disabledPlaceholder?: string
  defaultValueId?: string
  defaultMultiIds?: string[]
  errorStyling?: boolean
}

const ProListerSelect = ({
  options,
  onChange,
  isMulti = false,
  placeholder = 'Välj',
  disabledPlaceholder = '',
  defaultValueId = '',
  defaultMultiIds = [],
  errorStyling,
}: IProListerSelectProps) => {
  const { t } = useTranslation()
  const [selected, setIsSelected] = useState(null)
  const [hasValueSelected, sethasValueSelected] = useState(false)
  const isDisabled = !options || options.length === 0

  const customStyles = {
    option: (provided) => ({
      ...provided,
    }),
    control: (styles, state) => {
      return {
        ...styles,
        display: 'flex',
        backgroundColor: 'white',
        width: state.isMulti ? 370 : 200,
        opacity: !state.isDisabled ? 1 : 0.3,
        borderRadius: 0,
        border: errorStyling
          ? '1px solid #da3530 !important'
          : 'solid 1px #b0b0b0',
      }
    },
    singleValue: (styles, state) => {
      const transition = 'opacity 300ms'

      return {
        ...styles,
        transition,
        width: 400,
        overflowX: 'none',
        background: 'white',
      }
    },
    menu: (styles, _state) => ({
      ...styles,
      width: 400,
      position: 'absolute',
      backgroundColor: 'white',
      top: 32,
      left: 0,
      zIndex: '1000',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'black',
      color: 'white',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: 'black',
      color: 'white',
    }),
  }

  const getDefault = () => {
    if (!isMulti) {
      return defaultValueId
        ? options.find((o) => o.value === defaultValueId)
        : null
    } else {
      const defaultValues = defaultMultiIds
        ? options.filter((o) => defaultMultiIds.includes(o.value))
        : null
      return defaultValues
    }
  }

  useEffect(() => {
    if (isMulti && defaultMultiIds.length > 0) {
      const defaultValues = defaultMultiIds
        ? options.filter((o) => defaultMultiIds.includes(o.value))
        : []
      setIsSelected(defaultValues as any)
    }
  }, [defaultMultiIds.length])

  return (
    <Select
      key={`my_unique_select_key__${defaultValueId}${JSON.stringify(options)}`}
      options={
        isMulti && selected && (selected as any).length === 5 ? [] : options
      }
      styles={{
        ...customStyles,
      }}
      isDisabled={isDisabled}
      onChange={(o) => {
        if (isMulti && o.length <= 5) {
          setIsSelected(o)
          return onChange(o)
        }
        sethasValueSelected(true)
        return onChange(o)
      }}
      defaultValue={getDefault()}
      placeholder={
        isDisabled && disabledPlaceholder ? disabledPlaceholder : placeholder
      }
      noOptionsMessage={() => {
        return selected && (selected as any).length === 5
          ? t('select_optionMessage')
          : t('select_optionNoResults')
      }}
      isMulti={isMulti}
      clear
    />
  )
}

export default ProListerSelect
