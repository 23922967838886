import { SellListType } from './ISellView'

export const selectRawData = (state, sellViewType: SellListType) => {
  switch (sellViewType) {
    case SellListType.Active:
      return state.sellView.byId[SellListType.Active]?.items?.map((i) => {
        const item = state.sellItems.byId[i]
        if (item) {
          return item
        }
        return i as any
      })
    case SellListType.Sold:
      return state.orders.allIds
        .map((id) => state.orders.byId[id])
        .filter((order) => order !== undefined)
    case SellListType.Unsold:
      return state.sellView.byId[SellListType.Unsold]?.items?.map((i) => {
        const item = state.sellItems.byId[i]
        if (item) {
          return item
        }
        return i as any
      })
  }
}
