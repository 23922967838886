import { useLocation, useHistory } from 'react-router-dom'

interface ILoginCallbackProps {
  returnedFromLoginPage: (params: any) => any
}

const LoginCallback = ({ returnedFromLoginPage }: ILoginCallbackProps) => {
  const location = useLocation()
  const history = useHistory()


  const callbackParameters = Object.fromEntries(
    location.search
      .replace('?', '')
      .split('&')
      .map((x) => {
        const kv = x.split('=')
        return [kv[0], kv[1]]
      })
  )

  returnedFromLoginPage(callbackParameters).then(x => {
    history.push('/')
  })

  return null
}

export default LoginCallback
