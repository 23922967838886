import { ReactComponent as FailedIcon } from '@tradera/blueprint/theme/icons2/close-fill.svg'
import { ReactComponent as InfoIcon } from '@tradera/blueprint/theme/icons2/info.svg'
import { useTranslation } from 'react-i18next'

const PublishErrorMessage = ({ closeModal, errorMessage }: any) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <FailedIcon className="icon icon-lg" />
        </div>
        <p className="text-left"> {t('modal_publishErrorMessage_info')}.</p>
        <div>
          <InfoIcon className="icon mr-1" />
          <strong>{t('modal_publishErrorMessage_icon')}</strong>
        </div>
        <p className="text-left mt-1" style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_publishErrorMessage_button_close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishErrorMessage
