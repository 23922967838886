import { createActions } from 'redux-actions'
import { deleteRequest, get, post } from '../Infrastructure/Network'
import type { IItem, IMoveParams } from './IItem'
import type { IList } from '../List/IList'
import type { Dispatch } from '@reduxjs/toolkit'
import { closeTab } from '../Content/Content.actions'
import {
  openAlertContainer,
  openCreateItemFromImageModal,
  isPending,
  isCompleted,
  setSaving,
} from '../UI/UI.actions'

export const { createItemRequested, createItemSucceeded, createItemRejected } =
  createActions({
    createItemRequested: () => ({}),
    createItemSucceeded: (createdItem: IItem) => createdItem,
    createItemRejected: (_error: any) => ({}),
  })

export const onCreateItem =
  (item: IItem) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(createItemSucceeded(item))
    dispatch(closeTab(`newitem-${item.listId}`))
    dispatch(openAlertContainer('Annonsen sparades i listan', 'success'))
  }

export const createItem = (body: FormData) =>
  post({
    multipart: true,
    url: '/Item',
    body: body,
    initiated: createItemRequested,
    fulfilled: (item: IItem) => onCreateItem(item),
    rejected: createItemRejected,
  })

export const { getItemsRequested, getItemsSucceeded, getItemsRejected } =
  createActions({
    getItemsSucceeded: (items: IItem[]) => items,
    getItemsRequested: () => ({}),
    getItemsRejected: (error: any) => ({}),
  })

export const getItems = (listId: string) =>
  get({
    url: `/Item/${listId}`,
    initiated: getItemsRequested,
    fulfilled: getItemsSucceeded,
    rejected: getItemsRejected,
  })

export const { updateItemRequested, updateItemSucceeded, updateItemRejected } =
  createActions({
    updateItemRequested: () => ({}),
    updateItemSucceeded: (item: IItem) => item,
    updateItemRejected: (error: any) => ({}),
  })

export const onUpdateItemInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setSaving(true))
  }

export const onUpdateItem =
  (item: IItem, showToast: boolean) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(updateItemSucceeded(item))
    dispatch(setSaving(false))
    dispatch(closeTab(item.id!))
    if (showToast) {
      dispatch(
        openAlertContainer(
          'Annonsen uppdaterades och sparades i listan',
          'success'
        )
      )
    }
  }

export const onUpdateItemRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setSaving(false))
  }

export const updateItem = (Item: IItem, showToast: boolean) =>
  post({
    url: `/Item/UpdateItem/${Item.id}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, showToast),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: Item,
  })

export const updateFieldIsHighlighted = (itemId: string, value: boolean) =>
  post({
    url: `/item/UpdateFieldIsHighlighted/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const updateFieldRankUp = (itemId: string, value: boolean) =>
  post({
    url: `/item/UpdateFieldRankUp/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const updateFieldType = (itemId: string, value: string) =>
  post({
    url: `/item/UpdateFieldType/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const updateFieldCondition = (itemId: string, value: string) =>
  post({
    url: `/item/UpdateFieldCondition/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const updateFieldAcceptedBidders = (itemId: string, value: number) =>
  post({
    url: `/item/UpdateFieldAcceptedBidders/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const updateFieldAdvertisementLengthInDays = (
  itemId: string,
  value: number
) =>
  post({
    url: `/item/UpdateFieldAdvertisementLengthInDays/${itemId}`,
    initiated: () => onUpdateItemInitiated(),
    fulfilled: (item: IItem) => onUpdateItem(item, false),
    rejected: (error: any) => onUpdateItemRejected(error),
    body: value,
  })

export const {
  createItemsFromImageRequested,
  createItemsFromImageSucceeded,
  createItemsFromImageRejected,
} = createActions({
  createItemsFromImageRequested: () => ({}),
  createItemsFromImageSucceeded: (item: IItem) => item,
  createItemsFromImageRejected: (error: any) => ({}),
})

export const onCreateItemsFromImage =
  (item: IItem) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(createItemsFromImageSucceeded(item))
    dispatch(createItemSucceeded(item))
    dispatch(openAlertContainer('Annonsen sparades i listan', 'success'))
  }

export const createItemsFromImage = (item: IItem) =>
  post({
    url: `/Item/createitemfromimage`,
    body: item,
    initiated: createItemsFromImageRequested,
    fulfilled: (item: IItem) => onCreateItemsFromImage(item),
    rejected: createItemsFromImageRejected,
  })

export const { getItemRequested, getItemSucceeded, getItemRejected } =
  createActions({
    getItemRequested: () => ({}),
    getItemSucceeded: (item: IItem) => item,
    getItemRejected: () => ({}),
  })

export const getItem = (id: string) =>
  get({
    url: `/Item/${id}`,
    initiated: getItemRequested,
    fulfilled: getItemSucceeded,
    rejected: getItemRejected,
  })

export const { selectItemInList } = createActions({
  selectItemInList: (id: string, isChecked) => ({
    id,
    isChecked,
  }),
})

export const { validateItemFromList } = createActions({
  validateItemFromList: (id: string, validationResult) => ({
    id,
    validationResult,
  }),
})

export const { saveClickOrderOnItemInList } = createActions({
  saveClickOrderOnItemInList: (
    id: string,
    selectTime: string,
    listId: string
  ) => ({
    id,
    selectTime,
    listId,
  }),
})

export const { resetClickOrderOnItemInList } = createActions({
  resetClickOrderOnItemInList: () => ({}),
})

export const { checkHighlightOnItemInList } = createActions({
  checkHighlightOnItemInList: (id: string, isChecked) => ({
    id,
    isChecked,
  }),
})

export const { checkRankUpOnItemInList } = createActions({
  checkRankUpOnItemInList: (id: string, isChecked) => ({
    id,
    isChecked,
  }),
})

export const {
  duplicateItemsRequested,
  duplicateItemsSucceeded,
  duplicateItemsRejected,
} = createActions({
  duplicateItemsRequested: () => ({}),
  duplicateItemsSucceeded: (list) => list,
  duplicateItemsRejected: () => ({}),
})

export const duplicateItems = (listId: string, itemIds: string[]) =>
  post({
    url: `/Item/duplicateitems/${listId}`,
    body: itemIds.toString(),
    initiated: duplicateItemsRequested,
    fulfilled: (list: IList) => duplicateItemsSucceeded(list),
    rejected: duplicateItemsRejected,
  })

export const { moveItemsRequested, moveItemsSucceeded, moveItemsRejected } =
  createActions({
    moveItemsRequested: () => ({}),
    moveItemsSucceeded: (list) => list,
    moveItemsRejected: () => ({}),
  })

export const moveItems = (params: IMoveParams) =>
  post({
    url: `/Item/moveitems`,
    body: params,
    initiated: moveItemsRequested,
    fulfilled: (list: IList) => moveItemsSucceeded(list),
    rejected: moveItemsRejected,
  })

export const { deleteItemRequested, deleteItemSucceeded, deleteItemRejected } =
  createActions({
    deleteItemRequested: () => ({}),
    deleteItemSucceeded: (itemIds: string[], listId) => ({ itemIds, listId }),
    deleteItemRejected: () => ({}),
  })

export const deleteItem = (ids: string[], listId: string) =>
  deleteRequest({
    url: `/Item?${ids.map((id) => `itemids=${id}`).join('&')}`,
    initiated: deleteItemRequested,
    fulfilled: () => deleteItemSucceeded(ids, listId),
    rejected: deleteItemRejected,
  })

export const { addImagesRequested, addImagesSucceeded, addImagesRejected } =
  createActions({
    addImagesRequested: () => ({}),
    addImagesSucceeded: (images: string[]) => images,
    addImagesRejected: () => ({}),
  })

export const addImages = (files, id: string) =>
  post({
    url: `/images`,
    body: files,
    multipart: true,
    initiated: onAddImagesInitiated,
    fulfilled: (images: string[]) => onAddImagesfulfilled(id, images),
    rejected: onAddImagesRejected,
  })

export const onAddImagesInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isPending())
    dispatch(addImagesRequested)
  }
export const onAddImagesfulfilled =
  (id: string, images: string[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted())
    dispatch(addImagesSucceeded({ id, images }))
  }
export const onAddImagesRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted())
    dispatch(addImagesRejected(error))
  }

export const {
  addImagesForCreateItemFromImageRequested,
  addImagesForCreateItemFromImageSucceeded,
  addImagesForCreateItemFromImagesRejected,
} = createActions({
  addImagesForCreateItemFromImageRequested: () => ({}),
  addImagesForCreateItemFromImageSucceeded: (images: string[]) => images,
  addImagesForCreateItemFromImageRejected: () => ({}),
})

export const onAddImagesForCreateItemFromImageSucceeded =
  (id: string, images: string[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(addImagesForCreateItemFromImageSucceeded(images))
    if (images!.length > 0) {
      dispatch(openCreateItemFromImageModal(id, images))
    }
  }

export const addImagesForCreateItemFromImage = (files, id: string) =>
  post({
    url: `/images`,
    body: files,
    multipart: true,
    initiated: addImagesRequested,
    fulfilled: (images: string[]) =>
      onAddImagesForCreateItemFromImageSucceeded(id, images),
    rejected: addImagesRejected,
  })

export const {
  deleteImageRequested,
  deleteImageSucceeded,
  deleteImageRejected,
} = createActions({
  deleteImageRequested: () => ({}),
  deleteImageSucceeded: (imageUrl: string) => imageUrl,
  deleteImageRejected: () => ({}),
})

export const deleteImage = (imageUrl: string, id: string) =>
  deleteRequest({
    url: `/images`,
    body: `"${imageUrl}"`,
    initiated: deleteImageRequested,
    fulfilled: () => deleteImageSucceeded({ id, imageUrl }),
    rejected: deleteImageRejected,
  })

export const { saveItemWip } = createActions({
  saveItemWip: (item: IItem) => item,
})
