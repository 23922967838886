import { connect } from 'react-redux'

import { getShippingOptions } from '../../../UI/UI.actions'
import ShippingOptions from './ShippingOptions'

const connectedComponent = connect(
  (state: any) => ({
    shippingOptions: state.ui.shippingOptions,
  }),
  {
    getShippingOptions,
  }
)(ShippingOptions)

export default connectedComponent
