import { createActions } from 'redux-actions'
import { get, put, post } from '../Infrastructure/Network'
import type { IUser } from './IUser'

export const { getUserRequested, getUserSucceeded, getUserRejected } =
  createActions({
    getUserSucceeded: (user: IUser) => user,
    getUserRequested: () => ({}),
    getUserRejected: (error: any) => ({}),
  })

export const getUser = () =>
  get({
    url: `/user`,
    initiated: getUserRequested,
    fulfilled: getUserSucceeded,
    rejected: getUserRejected,
  })

export const {
  updateColumnOrderRequested,
  updateColumnOrderSucceeded,
  updateColumnOrderRejected,
} = createActions({
  updateColumnOrderRequested: () => ({}),
  updateColumnOrderSucceeded: (columns: string[]) => columns,
  updateColumnOrderRejected: () => ({}),
})

export const updateColumnOrder = (columns: string[]) =>
  put({
    url: `/user`,
    initiated: () => updateColumnOrderSucceeded(columns),
    fulfilled: () => updateColumnOrderSucceeded(columns),
    rejected: updateColumnOrderRejected,
    body: columns,
  })

export const {
  updatePurchaseTermsRequested,
  updatePurchaseTermsSucceeded,
  updatePurchaseTermsRejected,
} = createActions({
  updatePurchaseTermsRequested: () => ({}),
  updatePurchaseTermsSucceeded: (terms: string) => terms,
  updatePurchaseTermsRejected: () => ({}),
})

export const updatePurchaseTerms = (terms: string) =>
  post({
    url: `/user/savepurchaseterms`,
    initiated: () => updatePurchaseTermsSucceeded(terms),
    fulfilled: () => updatePurchaseTermsSucceeded(terms),
    rejected: updatePurchaseTermsRejected,
    body: terms,
  })
