import { connect } from 'react-redux'

import Authentication from './Authentication'
import type { IAuthenticationState } from './Authentication.reducer'
import { goToLoginPage, refreshLoginCredentials } from './Authentication.action'
import { getUser } from '../User/User.actions'
import type { RootState } from '../Store/rootReducer'
import isBefore from 'date-fns/isBefore'

const connectedComponent = connect(
  (state: RootState) => {
    const authenticationState: IAuthenticationState = state.authentication
    const hasValidClassicApiToken = authenticationState.classicApiToken
      ? isBefore(
          Date.now(),
          new Date(authenticationState.classicApiToken.expiration)
        )
      : false

    const user = state.user
    const authenticationMemberId = authenticationState.memberId

    return {
      isLoggedIn: hasValidClassicApiToken,
      refreshToken: authenticationState.refreshToken,
      user,
      authenticationMemberId,
    }
  },
  {
    goToLoginPage,
    refreshLoginCredentials,
    getUser,
  }
)(Authentication)

export default connectedComponent
