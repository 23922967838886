import { includes } from 'ramda'

import { closeModal, openErrorMessageModal } from '../../../src/UI/UI.actions'
import type { IBaseRequest, IRequestArgs } from './types'
import { handleResponse, withHeaders, baseUrl } from './Helpers'

const fetchArguments = async ({
  url,
  authToken,
  multipart,
  memberId,
  ...options
}: IRequestArgs) => {
  if (includes(options.method, ['POST', 'PUT'])) {
    options = {
      ...options,
      body: multipart ? options.body : JSON.stringify(options.body),
    }
  }
  return await withHeaders({
    url: { ...baseUrl(url) }.url,
    multipart,
    authToken,
    memberId,
    options,
  })
}
export const fetchRequest = async ({
  _fetch = fetch,
  ...args
}: IRequestArgs) => {
  const { url, options } = await fetchArguments(args)

  return await _fetch(url, options)
}

export const baseRequest: IBaseRequest =
  ({ method }) =>
  ({
    aborted,
    fulfilled,
    initiated,
    rejected,
    customBearerToken,
    ...request
  }) =>
  async (dispatch, getState: any) => {
    const fetchRequestConfig = { method, ...request }
    const authenticationState = getState().authentication
    if (authenticationState && authenticationState.classicApiToken) {
      fetchRequestConfig['authToken'] =
        authenticationState.classicApiToken.value
      fetchRequestConfig['memberId'] = authenticationState.memberId
    }
    dispatch(initiated())
    try {
      const response = await fetchRequest(fetchRequestConfig)
      const handled = handleResponse(response)

      //Handle errors
      if (handled.status >= 400 && handled.status < 600) {
        const json = await handled.json()
        //Check if error is publish error
        if (json.message?.includes('errorCode')) {
          return dispatch(rejected(handled.status, json))
        } else {
          dispatch(closeModal())
          dispatch(openErrorMessageModal(handled.status))
          return dispatch(rejected(handled.status, json))
        }
      } else {
        if (includes(method, ['PUT', 'DELETE'])) {
          return dispatch(fulfilled())
        }

        const headers = response.headers
        const contentType = headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          const json = await handled.json()
          return dispatch(fulfilled(json))
        }

        return dispatch(fulfilled(handled.body))
      }
    } catch (error: any) {
      if (aborted && error.name === 'AbortError') {
        return dispatch(aborted())
      }
    }
  }
