import type { IAttribute, IItem } from '../../../IItem'
import ErrorLabel from '../../ErrorLabel'
import ProListerSelect from '../../../../Components/ProListerSelect'
import styles from '../CategoryPicker.module.scss'
import { useEffect, useState } from 'react'
import { ReactComponent as MultiColorIcon } from '../../../../Icons/multicolor.svg'
import { ReactComponent as BronzeIcon } from '../../../../Icons/bronze.svg'
import { ReactComponent as SilverIcon } from '../../../../Icons/silver.svg'
import { ReactComponent as GoldIcon } from '../../../../Icons/gold.svg'
import Label from '../../../../Components/Label'
import type { ISellItem } from '../../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'

interface IAttributesState {
  [categoryId: string]: IAttribute[]
}

interface IAttributesProps {
  item: IItem | ISellItem
  categoryId: string
  attributes: IAttributesState
  defaultAttributes?: IAttribute[]
  setSelectedAttributes: (a: IAttributesState, categoryId: string) => void
}

enum Ids {
  ClothingSize = 1,
  Color = 2,
  Brand = 3,
  Quantity = 6,
  Design = 7,
  Motif = 9,
}

const colorMapping = {
  multi: {},
  red: '#e70f2e',
  yellow: '#ffcf3c',
  orange: '#fb863b',
  beige: '#d4be8d',
  turquoise: '#38cbce',
  brown: '#875421',
  green: '#90d43b',
  blue: '#3f99fa',
  purple: '#9c56ff',
  pink: '#ff57cf',
  black: '#000000',
  gray: '#aaa',
  white: '#fff',
  silver: {},
  gold: {},
  bronze: {},
}

const toSelectableTabs = (
  a: IAttribute,
  selectAction: (a: IAttribute, selectedValues: string[]) => void,
  selectedValues: string[],
  displayColor = false,
  t: TFunction
) => {
  const onSelected = (selectedValue: string) => {
    let newValues = [...selectedValues]
    if (selectedValues.includes(selectedValue)) {
      newValues = newValues.filter((x) => x !== selectedValue)
    } else {
      if (a.maxNumberOfValues > selectedValues.length) {
        newValues = newValues.concat([selectedValue])
      }
    }
    if (selectedValues.length !== newValues.length) {
      selectAction(a, newValues)
    }
  }

  const getIcon = (v: string) => {
    switch (v) {
      case 'multi':
        return <MultiColorIcon />
      case 'bronze':
        return (
          <BronzeIcon
            style={{
              height: 'calc(100% + 2px)',
              width: 32,
              position: 'absolute',
              top: -1,
            }}
          />
        )
      case 'silver':
        return (
          <SilverIcon
            style={{
              height: 'calc(100% + 2px)',
              width: 32,
              position: 'absolute',
              top: -1,
            }}
          />
        )
      case 'gold':
        return (
          <GoldIcon
            style={{
              height: 'calc(100% + 2px)',
              width: 32,
              position: 'absolute',
              top: -1,
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', flex: '1 1 10%' }}>
      {a.possibleTermValues.map((v, i) => (
        <div
          style={{ display: 'flex', marginRight: 10, marginBottom: 10 }}
          key={`value${v}-${i}`}
        >
          {displayColor && (
            <div
              onClick={() => onSelected(v)}
              style={{
                background: colorMapping[v],
                height: 32,
                width: 32,
                border: '1px solid #b0b0b0',
                borderRight: 'none',
                cursor: 'pointer',
                position: 'relative',
              }}
            >
              {getIcon(v)}
            </div>
          )}
          <div
            onClick={() => onSelected(v)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 32,
              border: '1px solid #b0b0b0',
              borderLeft: !displayColor ? '1px solid #b0b0b0' : 'none',
              cursor: 'pointer',
              padding: '0 10px',
              fontSize: '1em',
              minWidth: 60,
              maxWidth: displayColor ? 60 : 'inherit',
              color: selectedValues.includes(v) ? 'white' : 'black',
              backgroundColor: selectedValues.includes(v) ? 'black' : 'white',
            }}
            className={styles.attributeHover}
          >
            {t(`attributes:attribute_af-${a.key}_${v}`, v)}
          </div>
        </div>
      ))}
    </div>
  )
}

const toDisplayedAttribute = (
  attribute: IAttribute,
  selectAction: (a: IAttribute, selectedValues: string[]) => void,
  selected,
  t: TFunction
) => {
  const selectedValues = selected[attribute.id] ? selected[attribute.id] : []
  if (attribute.id === Ids.ClothingSize) {
    return toSelectableTabs(attribute, selectAction, selectedValues, false, t)
  }
  if (attribute.id === Ids.Color) {
    return toSelectableTabs(attribute, selectAction, selectedValues, true, t)
  }
  if (attribute.id === Ids.Brand) {
    const defaultMultiIds =
      selectedValues && selectedValues.length > 0
        ? attribute.possibleTermValues.filter((p) => selectedValues.includes(p))
        : []
    return (
      <ProListerSelect
        options={attribute.possibleTermValues.map((p) => {
          return {
            value: p,
            label: p,
          }
        })}
        isMulti
        onChange={(o: any) => {
          selectAction(
            attribute,
            o.map((x) => x.value)
          )
        }}
        defaultMultiIds={defaultMultiIds}
        placeholder={t('itemsForm_attributes_search')}
      />
    )
  }
  if (attribute.id === Ids.Quantity) {
    return toSelectableTabs(attribute, selectAction, selectedValues, false, t)
  }
  if (attribute.id === Ids.Design) {
    return toSelectableTabs(attribute, selectAction, selectedValues, false, t)
  }
  if (attribute.id === Ids.Motif) {
    return toSelectableTabs(attribute, selectAction, selectedValues, false, t)
  }
  return toSelectableTabs(attribute, selectAction, selectedValues, false, t)
}

const toDisplayedAttributeSection = (
  a: IAttribute,
  selectAction: (a: IAttribute, selectedValues: string[]) => void,
  selected,
  item,
  t: TFunction
) => {
  const selectedOnAttribute = (selected) => {
    const selectedValues = selected[a.id] ? selected[a.id] : []
    const selectedOnAttribute =
      selectedValues && selectedValues.length > 0
        ? a.possibleTermValues.filter((p) => selectedValues.includes(p))
        : []

    return selectedOnAttribute.length
  }
  return (
    <div>
      <div>
        {(selectedOnAttribute(selected) < a.minNumberOfValues &&
          a.minNumberOfValues > 0) ||
        (Object.keys(selected).length === 0 && a.minNumberOfValues > 0) ? (
          <ErrorLabel
            label={t('attributes:attribute_' + a.key)}
            description={
              t('itemsForm_attributes_errorLabelDescription') +
              ` ${a.minNumberOfValues}`
            }
          />
        ) : (
          <Label
            label={t('attributes:attribute_' + a.key)}
            description={
              a.maxNumberOfValues !== 0
                ? `(` +
                  t('itemsForm_attributes_labelDescription') +
                  ` ${a.maxNumberOfValues})`
                : ''
            }
          />
        )}
      </div>
      <div>{toDisplayedAttribute(a, selectAction, selected, t)}</div>
    </div>
  )
}

const Attributes = ({
  categoryId,
  attributes,
  setSelectedAttributes,
  defaultAttributes = [],
  item,
}: IAttributesProps) => {
  const { t } = useTranslation()
  const [selected, setIsSelected] = useState({})

  const selectAction = (attribute: IAttribute, selectedValues: string[]) => {
    const newSelection = {
      ...selected,
      [attribute.id]: selectedValues,
    }
    setIsSelected(newSelection)
    setSelectedAttributes(newSelection, categoryId)
  }
  const attributesList =
    attributes && attributes[categoryId] ? attributes[categoryId] : []

  useEffect(() => {
    if (defaultAttributes.length > 0 && attributesList.length > 0) {
      let allSelected = {}

      for (let s of defaultAttributes) {
        allSelected = {
          ...allSelected,
          [s.id]: s.possibleTermValues,
        }
      }
      setIsSelected(allSelected)
    }
  }, [defaultAttributes, attributes[categoryId]])
  return attributesList.length > 0 ? (
    <div>
      {attributes[categoryId]
        .sort((a, b) => b.minNumberOfValues - a.minNumberOfValues)
        .map((a) => (
          <div
            key={`attribute-${a.id}-${categoryId}-${JSON.stringify(selected)}`}
          >
            {toDisplayedAttributeSection(a, selectAction, selected, item, t)}
          </div>
        ))}
    </div>
  ) : null
}

export default Attributes
