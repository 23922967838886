import type React from 'react'
import { useState } from 'react'
import { validateItem } from '../Infrastructure/Helpers/validation'
import type { ISellItem } from './ISellView'
import { ItemType } from '../Items/IItem'
import { saveSellItem } from './SellView.actions'

const getDefaultItem = (): ISellItem => ({
  id: '55',
  totalBids: 0,
  campaignCode: '',
  campaignCodeValid: false,
  header: 'test',
  isChecked: false,
  imageUrls: [],
  imageRotations: [],
  description: '',
  startingBid: 0,
  buyNowPrice: 0,
  reservationPrice: 0,
  isHighlighted: false,
  rankUp: false,
  reference: '',
  condition: '',
  latestObjectId: 0,
  restarts: 0,
  acceptedBidders: 4,
  advertisementLengthInDays: 7,
  shippingAlternative: [],
  itemType: ItemType.Auction,
  chosenEndTimeHour: 0,
  chosenEndTimeMinute: 0,
  hasChosenEndTime: false,
  shippingCondition: '',
})

const useItem = (specificItem: ISellItem) => {
  const [item, setItem] = useState<ISellItem>(
    specificItem ? specificItem : getDefaultItem()
  )

  const updateItemState = (key: any, value: any, dispatch: any) => {
    setItem((item) => {
      const newItem: ISellItem = {
        ...item,
        [key]: value,
      }
      const resultOfValidation = validateItem(newItem)
      newItem.validationResult = resultOfValidation

      dispatch(saveSellItem(newItem))
      return newItem
    })
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dispatch: any
  ) => {
    event.persist()
    updateItemState(event.target.name, event.target.value, dispatch)
  }

  const handleEditorChange = (editorState: string, dispatch: any) => {
    updateItemState('description', editorState, dispatch)
  }

  return {
    item,
    handleInputChange,
    handleEditorChange,
    updateItemState,
    setItem,
  }
}

export default useItem
