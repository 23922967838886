import React, { useState } from 'react'
import RadioButton from '../../../Components/RadioButton'
import type { IItemChanges } from '../../IItem'
import styles from '../EditItemsForm.module.scss'
import CheckBoxExpander from '../../../Components/CheckBoxExpander'
import { useTranslation } from 'react-i18next'

interface ConditionProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateStateByItemChanges: (property: string, value: any) => void
  itemChanges: IItemChanges
}

const Condition: React.FunctionComponent<ConditionProps> = ({
  handleInputChange,
  updateStateByItemChanges,
  itemChanges,
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  return (
    <div>
      <CheckBoxExpander
        checked={itemChanges.changeTypeAndPrice}
        label={t('editItemsForm_condition_label')}
        name="changeCondition"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpanded}
      />
      {expanded && (
        <div className={styles.expandContainer}>
          <div className="row">
            <div className="ml-1">
              <RadioButton
                name="condition"
                value="Mint"
                checked={
                  itemChanges.condition === 'Mint' &&
                  itemChanges.changeCondition
                    ? true
                    : false
                }
                label={t('editItemsForm_condition_mint')}
                handleInputChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="ml-2">
              <RadioButton
                name="condition"
                value="Used"
                checked={
                  itemChanges.condition === 'Used' &&
                  itemChanges.changeCondition
                    ? true
                    : false
                }
                label={t('editItemsForm_condition_used')}
                handleInputChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Condition
