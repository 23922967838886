import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import {
  beginBankIdVerificationSucceeded,
  cancelBankIdVerificationSucceeded,
  getBankIdVerificationStatusSucceeded,
} from './BankId.actions'
import type { IBankIdVerification } from './IBankIdVerification'

const initialState = null

const bankIdReducer: Reducer<IBankIdVerification, IAction<any>> = handleActions(
  {
    [beginBankIdVerificationSucceeded.toString()]: (_state, action: IAction<any>) => ({
      ...action.payload,
    }),
    [getBankIdVerificationStatusSucceeded.toString()]: (_state, action: IAction<any>) => ({
      ...action.payload,
    }),
    [cancelBankIdVerificationSucceeded.toString()]: (_state, action: IAction<any>) => ({
      ...action.payload,
    }),
  },
  initialState
)

export default bankIdReducer
