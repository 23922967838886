import { connect } from 'react-redux'
import Header from './Header'
import { createList } from '../../List/List.actions'
import { openContent } from '../../Content/Content.actions'
import { logout } from '../../Authentication/Authentication.action'
import {
  openImportModal,
  openImportMappingModal,
  setImportFileSize,
  setImportData,
  openSettingsModal,
} from '../../UI/UI.actions'
import type { RootState } from '../../Store/rootReducer'
import { TabTypes } from '../../Content/IContent'
import { importListFromPlZip } from '../../List/List.actions'

const connectedComponent = connect(
  (state: RootState) => {
    let selectedListId, listName
    for (const tabId in state.content.byId) {
      const tab = state.content.byId[tabId]
      if (tab.selected && tab.type === TabTypes.List) {
        selectedListId = tabId
        listName = tab.name
      }
    }
    let itemIds, items
    for (const listId in state.lists.byId) {
      if (listId === selectedListId) {
        itemIds = state.lists.byId[listId].items
        items = state.lists.byId[selectedListId].items.map(
          (item) => state.items.byId[item]
        )
      }
    }

    return {
      user: state.user,
      memberAlias: state.authentication.memberAlias,
      memberId: state.authentication.memberId,
      selectedListId,
      listName,
      items,
    }
  },
  {
    createList,
    openContent,
    logout,
    openImportModal,
    openImportMappingModal,
    openSettingsModal,
    importListFromPlZip,
    setImportFileSize,
    setImportData,
  }
)(Header)

export default connectedComponent
