import React, { useState, useEffect, useRef } from 'react'
import { isValid } from './validate'
import styles from './TimeInput.module.scss'

interface TimeInputProps {
  initTime: string
  onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  onBlurHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TimeInput: React.FunctionComponent<TimeInputProps> = ({
  initTime,
  onTimeChange,
  name,
  onBlurHandler,
}) => {
  const [time, setTime] = useState(initTime)
  const _input = useRef<HTMLInputElement>(null)

  useEffect(() => {
    _input.current!.focus()
  }, [])

  let lastVal = ''

  const onChangeHandler = (val) => {
    if (val === time) {
      return
    }
    if (isValid(val)) {
      if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
        val = val + ':'
      }
      if (val.length === 2 && lastVal.length === 3) {
        val = val.slice(0, 1)
      }
      if (val.length > 5) {
        return false
      }
      lastVal = val
      setTime(val)
      if (val.length === 5) {
        onTimeChange(val)
      }
    }
  }

  return (
    <input
      name={name ? name : undefined}
      className={styles.timeInput}
      value={time}
      onChange={(e) => onChangeHandler(e.target.value)}
      onBlur={onBlurHandler ? (e) => onBlurHandler(e) : undefined}
      ref={_input}
      autoComplete="off"
    />
  )
}

export default TimeInput
