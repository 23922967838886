import type { SHIPPING_OPTIONS } from '../../../Infrastructure/Helpers/shippingHelper'

export interface IOption {
  label: string
  value: number
}

export interface IStringOption {
  label: string
  value: string
}

export interface IShippingOptions {
  weight: number
  products: IShippingProduct[]
}

export interface IShippingProduct {
  id: number
  name: string
  price: number
  weight: number
  shippingProvider: SHIPPING_OPTIONS
  shippingProviderId: number
  toCountry: string
  fromCountry: string
  packageRequirements: IPackageRequirements
  dimensionsExceededPenalty: number
  weightExceededPenalty: number
  displayPenaltyWarning: boolean
  deliveryInformation: IDeliveryInformation
  termsAndConditions: string
  vatPercent: number
}

export type Insurance = {
  totalAmount?: number
  amountPerKilogram?: number
  hasReimbursementUpToPackageCost?: boolean
  totalAmountUpToItemValue?: number
}

export type Restriction =
  | 'maxLength'
  | 'maxVolume'
  | 'minHeight'
  | 'likeABox'
  | 'likeAShoeBox'
  | 'likeALargeShoeBox'
  | 'max'
  | 'likeAnA4'
  | 'maxSumOfLengthAndHeight'
  | 'maxLengthPlusCircumference'
  | 'maxSumOfAllSides'
  | 'minArea'

export interface IPackageRequirements {
  restrictions: Restriction
}

export interface IDeliveryInformation {
  insurance: Insurance
}

export interface IPackage {
  weight: number
  products: IShippingProduct[]
}

export interface IShippingAlternative {
  id: number
  price: number
  weight?: number
  shippingProductId?: number
}

export interface IShippingOptions {
  weight: number
  products: IShippingProduct[]
}

export enum IShipping {
  posten = 1,
  DHL = 2,
  Bussgods = 3,
  'Annat Fraktsätt' = 6,
  Schenker = 7,
  'Avhämtning' = 8,
  'Postnord Paket' = 12,
  'Postnord Frimärke' = 13,
  'Postnord övrigt' = 1,
}

type MapTypeFromNumber = {
  [id: number]: string
}

export const shippingString: MapTypeFromNumber = {
  1: 'Posten',
  2: 'DHL',
  3: 'Bussgods',
  6: 'Annat Fraktsätt',
  7: 'Schenker',
  8: 'Avhämtning',
  12: 'Postnord Paket',
  13: 'Postnord Frimärke',
}

export enum IShippingUnits {
  Kg = 'Kg',
  G = 'Gram',
}
