import type { TFunction } from 'i18next'
import type {
  IPackageRequirements,
  Insurance,
} from '../../Items/ItemForm/ShippingOptions/IShipping'

export const convertWeight = (weight: number) => {
  if (weight < 1) {
    return weight * 1000 + ' g'
  }
  return weight + ' kg'
}

export const getProductIdFromProductKey = (productKey: string): number => {
  const productId = productKey.split('-')[0]
  return parseInt(productId)
}

export const getProviderIdFromProductKey = (productKey: string): number => {
  const providerId = productKey.split('-')[1]
  return parseInt(providerId)
}

//Old shipping alternatives name mapping
export const shippingMappingTable = {
  1: 'Posten',
  2: 'DHL',
  3: 'BussGods',
  6: 'Annat Fraktsätt',
  7: 'Schenker',
  8: 'Avhämtning',
  12: 'Postnord Paket',
  13: 'Postnord Frimärke',
  14: 'Postnord Övrigt',
}

export const SHIPPING_OTHER = 6
export const SHIPPING_TAKEAWAY = 8

export enum SHIPPING_OPTIONS {
  ALTERNATIVE = 'Alternative',
  ALTERNATIVE_DENMARK = 'AlternativeDenmark',
  BRING_DENMARK = 'BringDenmark',
  BUSSGODS = 'Bussgods',
  COMBINED_SHIPPING = 'CombinedShipping',
  DHL = 'DHL',
  FEDEX = 'FedEx',
  NOT_SPECIFIED = 'NotSpecified',
  POSTNORD = 'Posten',
  POSTNORD_PARCEL = 'PostNordParcel',
  POSTNORD_STAMP = 'PostNordStamp',
  POSTNORD_STAMP_DENMARK = 'PostNordStampDenmark',
  SCHENKER = 'SchenkerPrivpak',
  TAKE_AWAY = 'Takeaway',
  TAKE_AWAY_ONLY = 'TakeawayOnly',
  UPS = 'Ups',
}

export const getProviderName = (
  t: TFunction,
  shippingProvider: SHIPPING_OPTIONS
) => {
  switch (shippingProvider) {
    case SHIPPING_OPTIONS.POSTNORD:
    case SHIPPING_OPTIONS.POSTNORD_PARCEL:
      return 'PostNord'
    case SHIPPING_OPTIONS.POSTNORD_STAMP:
    case SHIPPING_OPTIONS.POSTNORD_STAMP_DENMARK:
      return t('shippingProviders_postNordStamp')
    case SHIPPING_OPTIONS.DHL:
      return t('shippingProviders_dhl')
    case SHIPPING_OPTIONS.SCHENKER:
      return t('shippingProviders_schenkerPrivpak')
    case SHIPPING_OPTIONS.BRING_DENMARK:
      return t('shippingProviders_bringDenmark')
    case SHIPPING_OPTIONS.BUSSGODS:
      return t('shippingProviders_bussgods')
    case SHIPPING_OPTIONS.ALTERNATIVE:
    case SHIPPING_OPTIONS.ALTERNATIVE_DENMARK:
      return t('shippingProviders_alternative')
    case SHIPPING_OPTIONS.TAKE_AWAY:
    case SHIPPING_OPTIONS.TAKE_AWAY_ONLY:
      return t('shippingProviders_takeaway')
    default:
      return null
  }
}

const mailboxPlusOptions = [10, 20, 21, 31, 41, 51]
const mailboxOptions = [22, 32, 42, 52]
const isMailboxPlusShipping = (shippingType: string, productId: number) => {
  if (shippingType !== SHIPPING_OPTIONS.POSTNORD_PARCEL) {
    return null
  }

  return mailboxPlusOptions.includes(productId)
}
const isMailboxShipping = (shippingType: string, productId: number) => {
  if (shippingType !== SHIPPING_OPTIONS.POSTNORD_PARCEL) {
    return null
  }

  return mailboxOptions.includes(productId)
}

const traceableShippingOptions = [
  SHIPPING_OPTIONS.BRING_DENMARK,
  SHIPPING_OPTIONS.DHL,
  SHIPPING_OPTIONS.SCHENKER,
  SHIPPING_OPTIONS.POSTNORD_PARCEL,
]
const isTraceableShipping = (shippingType: SHIPPING_OPTIONS) =>
  traceableShippingOptions.includes(shippingType)

const isPickupPointShipping = (
  shippingType: SHIPPING_OPTIONS,
  productId: number
) => {
  if (
    shippingType === SHIPPING_OPTIONS.POSTNORD_STAMP ||
    shippingType === SHIPPING_OPTIONS.POSTNORD_STAMP_DENMARK ||
    shippingType === SHIPPING_OPTIONS.ALTERNATIVE ||
    shippingType === SHIPPING_OPTIONS.ALTERNATIVE_DENMARK
  ) {
    return null
  }

  return true
}

export const getByProductIdAndShippingType = (
  t: TFunction,
  shippingType: SHIPPING_OPTIONS,
  productId: number
) => {
  if (!productId) {
    return null
  }

  let key = 'shipping_name'
  if (isTraceableShipping(shippingType)) {
    key += '_traceable'
  }
  if (isMailboxPlusShipping(shippingType, productId)) {
    key += '_mailbox-plus'
  } else if (isMailboxShipping(shippingType, productId)) {
    key += '_mailbox'
  } else if (isPickupPointShipping(shippingType, productId)) {
    key += '_pickup-point'
  }

  switch (key) {
    case 'shipping_name_traceable_mailbox-plus':
      return t('shipping_name_traceable_mailbox-plus')
    case 'shipping_name_traceable_mailbox':
      return t('shipping_name_traceable_mailbox')
    case 'shipping_name_traceable_pickup-point':
      return t('shipping_name_traceable_pickup-point')
    case 'shipping_name_mailbox-plus':
      return t('shipping_name_mailbox-plus')
    case 'shipping_name_mailbox':
      return t('shipping_name_mailbox')
    case 'shipping_name_pickup-point':
      return t('shipping_name_pickup-point')
    default:
      return ''
  }
}

export const getInsuranceTexts = (insurance: Insurance, t: TFunction) => {
  const insuranceTexts: string[] = []

  if (insurance?.totalAmount) {
    insuranceTexts.push(
      t('shippingproduct_insurance_totalAmount', {
        totalAmount: formatNumberWithSeparators(insurance.totalAmount),
      })
    )
  }

  if (insurance?.amountPerKilogram) {
    insuranceTexts.push(
      t('shippingproduct_insurance_amountPerKilogram', {
        amountPerKilogram: insurance.amountPerKilogram,
      })
    )
  }

  if (insurance?.hasReimbursementUpToPackageCost) {
    insuranceTexts.push(
      t('shippingproduct_insurance_hasReimbursementUpToPackageCost')
    )
  }

  if (insurance?.totalAmountUpToItemValue) {
    insuranceTexts.push(
      t('shippingproduct_insurance_totalAmountUpToItemValue', {
        totalAmount: formatNumberWithSeparators(
          insurance.totalAmountUpToItemValue
        ),
      })
    )
  }

  return insuranceTexts
}

export const formatNumberWithSeparators = (
  number: number,
  separator: string = '\u2006'
): string => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator)
}

type Restriction =
  | 'maxLength'
  | 'maxVolume'
  | 'minHeight'
  | 'likeABox'
  | 'likeAShoeBox'
  | 'likeALargeShoeBox'
  | 'max'
  | 'likeAnA4'
  | 'maxSumOfLengthAndHeight'
  | 'maxLengthPlusCircumference'
  | 'maxSumOfAllSides'
  | 'minArea'

export const getRestrictionText = (
  restriction: Restriction,
  packageRequirements: IPackageRequirements,
  t: TFunction
) => {
  switch (restriction) {
    case 'maxLength':
      return t('shippingproduct_requirement_maxLength', {
        maxLength: Math.round(packageRequirements[restriction] * 100),
      })
    case 'maxVolume':
      return t('shippingproduct_requirement_maxVolume', {
        maxVolume: packageRequirements[restriction],
      })
    case 'minHeight':
      return t('shippingproduct_requirement_minHeight', {
        minHeight: Math.round(packageRequirements[restriction] * 100),
      })
    case 'likeABox':
      return t('shippingproduct_requirement_likeABox')
    case 'likeAShoeBox':
      return t('shippingproduct_requirement_likeAShoeBox', {
        maxLength: Math.round(packageRequirements['maxLength'] * 100),
        maxWidth: Math.round(packageRequirements['maxWidth'] * 100),
        maxHeight: Math.round(packageRequirements['maxHeight'] * 100),
      })
    case 'likeALargeShoeBox':
      return t('shippingproduct_requirement_likeALargeShoeBox', {
        maxLength: Math.round(packageRequirements['maxLength'] * 100),
        maxWidth: Math.round(packageRequirements['maxWidth'] * 100),
        maxHeight: Math.round(packageRequirements['maxHeight'] * 100),
      })
    case 'max':
      return t('shippingproduct_requirement_max', {
        maxLength: Math.round(packageRequirements['maxLength'] * 100),
        maxWidth: Math.round(packageRequirements['maxWidth'] * 100),
        maxHeight: Math.round(packageRequirements['maxHeight'] * 100),
      })
    case 'likeAnA4':
      return t('shippingproduct_requirement_likeAnA4')
    case 'maxSumOfLengthAndHeight':
      return t('shippingproduct_requirement_maxSumOfLengthAndHeight', {
        maxSumOfLengthAndHeight: Math.round(
          packageRequirements[restriction] * 100
        ),
      })
    case 'maxLengthPlusCircumference':
      return t('shippingproduct_requirement_maxLengthPlusCircumference', {
        maxLengthPlusCircumference: Math.round(
          packageRequirements[restriction] * 100
        ),
      })
    case 'maxSumOfAllSides':
      return t('shippingproduct_requirement_maxSumOfAllSides', {
        maxSumOfAllSides: Math.round(packageRequirements[restriction] * 100),
      })
    case 'minArea':
      return t('shippingproduct_requirement_minArea', {
        minWidth: Math.round(packageRequirements['minWidth'] * 100),
        minLength: Math.round(packageRequirements['minLength'] * 100),
      })
    default:
      return null
  }
}
