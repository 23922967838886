import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { updateColumnOrder } from '../../User/User.actions'

import SelectColumnsModal from './SelectColumnsModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      columnOrders: state.user ? state.user.columnOrders : [],
      user: state.user,
    }
  },
  {
    closeModal,
    updateColumnOrder,
  }
)(SelectColumnsModal)

export default connectedComponent
