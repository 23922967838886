import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { IItem } from './IItem'
import {
  getItemsSucceeded,
  createItemSucceeded,
  getItemSucceeded,
  updateItemSucceeded,
  deleteImageSucceeded,
  duplicateItemsSucceeded,
  moveItemsSucceeded,
  selectItemInList,
  validateItemFromList,
  checkHighlightOnItemInList,
  checkRankUpOnItemInList,
} from './Items.action'
import ItemReducer from './Item.reducer'
import {
  duplicateListSucceeded,
  createImportedListSucceeded,
  getListByIdSucceeded,
  getListsSucceeded,
  importListFromPlZipSucceeded,
  publishListSucceeded,
  moveUpItemInListSucceeded,
  moveDownItemInListSucceeded,
  moveTopItemInListSucceeded,
  moveBottomItemInListSucceeded,
  reorderListSucceeded,
  updateManyItemsAtOnceSucceeded,
  deleteItemsFromListSucceeded,
} from '../List/List.actions'
import type { IList } from '../List/IList'
import { validateItem } from '../Infrastructure/Helpers/validation'

export interface IItemsState {
  [key: string]: IItem
}

export const initialState: IItemsState = {}

export const validate = (item: IItem) => {
  const resultOfValidation = validateItem(item)
  return {
    ...item,
    validationResult: resultOfValidation,
  }
}

const normalizeItems = (items: IItem[], oldState: IItemsState) =>
  items.reduce((_ads, _ad) => {
    _ad.isChecked = oldState[_ad.id!] ? oldState[_ad.id!]?.isChecked : false
    _ads[_ad.id!] = validate(_ad)
    return _ads
  }, {})

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

export const makeItemsFromLists = (lists: IList[]) => {
  const listItems = lists.map((list) => list.items)
  const items: IItem[] = [].concat(...(listItems as any))
  return items
}

const byId: Reducer<IItemsState, IAction<any>> = handleActions(
  {
    [getItemsSucceeded.toString()]: (
      _state: IItemsState,
      action: IAction<any>
    ) => normalizeItems(action.payload, _state),
    [getItemSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [updateItemSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [deleteImageSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [selectItemInList.toString()]: (
      state: IItemsState,
      action: IAction<{ id: string; isChecked: boolean }>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [validateItemFromList.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [checkHighlightOnItemInList.toString()]: (
      state: IItemsState,
      action: IAction<{ id: string; isChecked: boolean; property: string }>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [checkRankUpOnItemInList.toString()]: (
      state: IItemsState,
      action: IAction<{ id: string; isChecked: boolean; property: string }>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [duplicateListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
    [createImportedListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
    [getListsSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(makeItemsFromLists(action.payload), state),
    }),
    [getListByIdSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
    [publishListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
    [duplicateItemsSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
    [moveItemsSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      const { fromList, toList } = action.payload
      const normalizedFromList = normalizeItems(fromList.items, state)
      const normalizedToList = normalizeItems(toList.items, state)
      return {
        ...state,
        ...normalizedFromList,
        ...normalizedToList,
      }
    },
    [deleteItemsFromListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [moveUpItemInListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [moveDownItemInListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [moveBottomItemInListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [reorderListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [moveTopItemInListSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [updateManyItemsAtOnceSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      var items = action.payload.items
      var sorteditems = items.sort(
        (firstItem: IItem, secondItem: IItem) =>
          firstItem.uploadOrder - secondItem.uploadOrder
      )
      return {
        ...state,
        ...normalizeItems(sorteditems, state),
      }
    },
    [createItemSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: ItemReducer(state[action.payload.id], action),
    }),
    [importListFromPlZipSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(action.payload.items, state),
    }),
  },
  initialState
)

const allIds = handleActions(
  {
    [getItemsSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<any>
    ) => [...action.payload.map((a: IItem) => a.id)],
    [createItemSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => [...state, action.payload.id],
    [getListByIdSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => [...state.concat(action.payload.items.map((item: any) => item.id))],
    [duplicateItemsSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state.concat(action.payload.items.map((item: any) => item.id))],
    [publishListSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => {
      const newState = [...state]
      const newItemIds = action.payload.items.map((i: any) => i.id)

      for (let id of newItemIds) {
        if (!newState.includes(id)) {
          newState.push(id)
        }
      }
      return newState
    },
    [duplicateListSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state.concat(action.payload.items.map((item: any) => item.id))],
    [createImportedListSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => [...state.concat(action.payload.items.map((item: any) => item.id))],
    [getListsSucceeded.toString()]: (state: string[], action: IAction<any>) => [
      ...state.concat(
        makeItemsFromLists(action.payload).map((item: any) => item.id)
      ),
    ],
    [importListFromPlZipSucceeded.toString()]: (
      state: string[],
      action: IAction<IList>
    ) => {
      const newState = [...state]
      const newItemIds = action.payload.items.map((i: any) => i.id)

      for (let id of newItemIds) {
        if (!newState.includes(id)) {
          newState.push(id)
        }
      }
      return newState
    },
  },
  []
)

const ItemListsReducer = combineReducers({
  byId,
  allIds,
})

export default ItemListsReducer
