import React, { useEffect } from 'react'

const Authentication = ({
  children,
  isLoggedIn,
  refreshToken,
  goToLoginPage,
  refreshLoginCredentials,
  user,
  authenticationMemberId,
  getUser,
}) => {
  if (!isLoggedIn) {
    if (!refreshToken) {
      goToLoginPage()
    } else {
      refreshLoginCredentials(refreshToken)
    }
  }
  useEffect(() => {
    if (isLoggedIn && !user?.memberId && authenticationMemberId) {
      getUser()
    }
  }, [isLoggedIn, user])
  return <>{isLoggedIn ? React.Children.only(children) : null}</>
}

export default Authentication
