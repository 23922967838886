import { connect } from 'react-redux'

import Category from './Category'
import { getCategories, getAttributes } from '../../../UI/UI.actions'

const connectedComponent = connect(
  (state: any) => ({
    categories: state.ui.categories,
    attributes: state.ui.attributes,
  }),
  {
    getCategories,
    getAttributes,
  }
)(Category)

export default connectedComponent
