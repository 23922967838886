import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditCategoryForm from '../../Items/EditCategoryForm'
import Button from '../../Components/Button'
import type { IItem } from '../../Items/IItem'
import useItem from '../../Items/Item/Item.hook'
import { selectItemWipById } from '../../Items/Item/Item.selector'
import { useTranslation } from 'react-i18next'

interface EditCategoryModalProps {
  closeModal: () => void
  itemToUpdate: IItem
  updateItem: (item: IItem, showToast: boolean) => void
}

const EditCategoryModal: React.FunctionComponent<EditCategoryModalProps> = ({
  closeModal,
  itemToUpdate,
  updateItem,
}) => {
  const { t } = useTranslation()
  const itemfromApi: IItem = useSelector((state) =>
    selectItemWipById(state, itemToUpdate.id)
  )
  const [isDisabledSave, setSaveDisabled] = useState(false)
  const { item, updateStateByItem } = useItem(itemfromApi)
  const dispatch = useDispatch()

  const updateCurrentItem = (item: IItem, deletedImages: string[]) => {
    updateItem(item, false)
  }

  // eslint-disable-next-line no-lone-blocks
  {
    if (item) {
      return (
        <div>
          <div className="pt-2 px-1 pb-7">
            <EditCategoryForm
              item={item}
              updateStateByItem={(e, v) => {
                updateStateByItem(e, v, dispatch)
              }}
            />
          </div>
          <div className="modal-footer modal-footer-sticky border-top mt-7">
            <div className="row">
              <div className="col-12">
                <Button
                  testId="create-item"
                  clickHandler={() => {
                    updateCurrentItem(item, [])
                    setSaveDisabled(true)
                    closeModal()
                  }}
                  variant={isDisabledSave ? 'disable' : 'primary'}
                  size="normal"
                  text={t('modal_editCategory_button')}
                  disable={isDisabledSave}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default EditCategoryModal
