import { connect } from 'react-redux'
import MyList from './MyList'
import {
  getLists,
  duplicateList,
  createListFromTemplate,
} from '../List/List.actions'
import { openContent } from '../Content/Content.actions'
import { TabTypes } from '../Content/IContent'
import type { RootState } from '../Store/rootReducer'
import {
  getTemplates,
  duplicateTemplate,
  deleteTemplate,
} from '../Template/Template.actions'
import { updateColumnOrder } from '../User/User.actions'
import { ReactComponent as EditIcon } from '../Icons/icons-ic-pen.svg'
import { ReactComponent as RemoveIcon } from '../Icons/remove.svg'
import { ReactComponent as DuplicateIcon } from '../Icons/duplicate.svg'
import { ReactComponent as ImportListIcon } from '../Icons/import-lists.svg'
import { ReactComponent as NewListIcon } from '../Icons/new-list.svg'
import { ReactComponent as Duplicate } from '../Icons/duplicate.svg'
import { ReactComponent as Remove } from '../Icons/remove.svg'
import { ReactComponent as ExportList } from '../Icons/export-list.svg'
import type { ITemplate } from '../Template/ITemplate'
import type { IList } from '../List/IList'
import type { IItem } from '../Items/IItem'
import store from '../Store/store'
import { openDeleteListsModal } from '../UI/UI.actions'
import { ExportToCsv } from 'export-to-csv'
import {
  transformItemsForExport,
  csvExportOptions,
} from '../Infrastructure/Helpers/exportListHelper'
import {
  setImportFileSize,
  setImportData,
  openImportMappingModal,
} from '../UI/UI.actions'
import { importListFromPlZip, updateListById } from '../List/List.actions'
import type { ItemListMenuItemProps } from '../Components/ListMenuItem/ListMenuItem'
import type { TFunction } from 'i18next'

const getIsCheckedStates = (state) => {
  if (!state.allIds) {
    return []
  }
  const selected: string[] = state.allIds.filter(
    (id) => state.byId[id].isChecked && state.byId[id]
  )
  return selected.map((selectedId) => state.byId[selectedId])
}

const getAllItemsInList = (state, id) => {
  let itemIds, items
  for (const listId in state.lists.byId) {
    if (listId === id) {
      itemIds = state.lists.byId[listId].items
      items = state.lists.byId[id].items.map((item) => state.items.byId[item])
    }
  }
  return items
}

const onClickedExport = (items: IItem[], list) => {
  const csvExporter = new ExportToCsv(csvExportOptions(list.name))
  let itemsTransformedForExport = transformItemsForExport(items)
  csvExporter.generateCsv(itemsTransformedForExport)
  const updatedList = {
    ...list,
    hasBeenExported: true,
  }
  store.dispatch(updateListById(updatedList))
}

const connectedComponent = connect(
  (state: RootState) => {
    const isListsDisabled = getIsCheckedStates(state.lists).length === 0
    const isDisabledIfNotOneItem =
      getIsCheckedStates(state.lists).length === 0 ||
      getIsCheckedStates(state.lists).length > 1
    const isTemplatesDisabled = getIsCheckedStates(state.templates).length === 0
    return {
      user: state.user,
      loadingLists: state.ui['loadingLists'],
      selectedRawData: (type: TabTypes) => {
        const rawData =
          type === TabTypes.List
            ? state.lists.allIds.map((id) => state.lists.byId[id])
            : state.templates.allIds.map((id) => state.templates.byId[id])
        return rawData
      },
      getMenuItems: (type: TabTypes, t: TFunction): ItemListMenuItemProps[] =>
        type === TabTypes.List
          ? [
              {
                name: t('myList_menuItem_edit'),
                icon: <EditIcon width={18} height={18} />,
                isDisabled: isDisabledIfNotOneItem,
                variant: 'outline',
                action: () => {
                  const selectedLists = getIsCheckedStates(state.lists)
                  return selectedLists.map((list) =>
                    store.dispatch(
                      openContent(
                        {
                          type: TabTypes.List,
                          name: list.name,
                          id: list.id,
                          selected: true,
                        },
                        list.name
                      )
                    )
                  )
                },
              },
              {
                name: t('myList_menuItem_duplicate'),
                icon: <DuplicateIcon width={18} height={18} />,
                isDisabled: isListsDisabled,
                tooltip: t('myList_menuItem_duplicate'),
                action: () => {
                  const selectedLists = getIsCheckedStates(state.lists)
                  return selectedLists.map((list) =>
                    store.dispatch(duplicateList(list.id))
                  )
                },
              },
              {
                name: t('myList_menuItem_remove'),
                icon: <RemoveIcon width={18} height={18} />,
                isDisabled: isListsDisabled,
                tooltip: t('myList_menuItem_remove'),
                action: () => {
                  const deleteListsIds = state.lists.allIds.filter((id) => {
                    return state.lists.byId[id].isChecked
                  })
                  store.dispatch(openDeleteListsModal(deleteListsIds))
                },
              },
              {
                name: t('myList_menuItem_import'),
                icon: <ImportListIcon width={18} height={18} />,
                isDisabled: false,
                tooltip: t('myList_menuItem_import'),
                action: () => {},
              },
              {
                name: t('myList_menuItem_export'),
                icon: <ExportList width={18} height={18} />,
                isDisabled: isDisabledIfNotOneItem,
                tooltip: t('myList_menuItem_export'),
                action: () => {
                  const selectedLists = getIsCheckedStates(state.lists)
                  return selectedLists.map((list) => {
                    const selectedItems = getAllItemsInList(state, list.id)
                    onClickedExport(selectedItems, list)
                  })
                },
              },
            ]
          : [
              {
                name: t('myList_menuItem_edit'),
                icon: <EditIcon width={18} height={18} />,
                isDisabled: isTemplatesDisabled,
                action: () => {
                  const selectedTemplate = getIsCheckedStates(state.templates)
                  return selectedTemplate.map((template) =>
                    store.dispatch(
                      openContent(
                        {
                          type: TabTypes.Template,
                          name: template.name,
                          id: template.id,
                          selected: true,
                        },
                        template.name
                      )
                    )
                  )
                },
              },
              {
                name: t('myList_menuItem_duplicate'),
                icon: <DuplicateIcon width={18} height={18} />,
                isDisabled: isTemplatesDisabled,
                action: () => {
                  const selectedTemplate = getIsCheckedStates(state.templates)
                  return selectedTemplate.map((template) =>
                    store.dispatch(duplicateTemplate(template.id))
                  )
                },
              },
              {
                name: t('myList_menuItem_remove'),
                icon: <RemoveIcon width={18} height={18} />,
                isDisabled: isTemplatesDisabled,
                action: () => {
                  const deleteTemplatesIds = state.templates.allIds.filter(
                    (id) => {
                      return state.templates.byId[id].isChecked
                    }
                  )
                  store.dispatch(deleteTemplate(deleteTemplatesIds))
                },
              },
              {
                name: t('myList_menuItem_createListFromTemplate'),
                icon: <NewListIcon width={18} height={18} />,
                isDisabled: isTemplatesDisabled,
                action: () => {
                  const selectedTemplate = getIsCheckedStates(state.templates)
                  return selectedTemplate.map((template) =>
                    store.dispatch(createListFromTemplate(template.id))
                  )
                },
              },
              {
                name: t('myList_menuItem_importList'),
                isDisabled: false,
                icon: <ImportListIcon width={18} height={18} />,
              },
            ],
      getContextMenu: (type: TabTypes, t: TFunction) =>
        type === TabTypes.List
          ? [
              {
                name: t('myList_contextMenu_exportList'),
                icon: <ExportList />,
                hidden: isDisabledIfNotOneItem,
                action: (selectedList: IList | ITemplate) => {
                  const selectedItems = getAllItemsInList(
                    state,
                    selectedList.id
                  )
                  onClickedExport(selectedItems, selectedList)
                },
              },
              {
                name: t('myList_contextMenu_rename'),
                icon: <EditIcon />,
                hidden: isDisabledIfNotOneItem,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(
                    openContent(
                      {
                        type: TabTypes.List,
                        name: selectedContent.name,
                        id: selectedContent.id,
                        selected: true,
                      },
                      selectedContent.name
                    )
                  ),
              },
              {
                name: t('myList_contextMenu_edit'),
                icon: <EditIcon />,
                hidden: isDisabledIfNotOneItem,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(
                    openContent(
                      {
                        type: TabTypes.List,
                        name: selectedContent.name,
                        id: selectedContent.id,
                        selected: true,
                      },
                      selectedContent.name
                    )
                  ),
              },
              {
                name: t('myList_contextMenu_duplicate'),
                icon: <Duplicate />,
                action: (selectedContent: IList | ITemplate) => {
                  const selectedLists = getIsCheckedStates(state.lists)
                  return selectedLists.map((list) =>
                    store.dispatch(duplicateList(list.id))
                  )
                },
              },
              {
                name: t('myList_contextMenu_remove'),
                icon: <Remove />,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(openDeleteListsModal([selectedContent.id])),
              },
            ]
          : [
              {
                name: t('myList_contextMenu_editTemplate'),
                icon: <EditIcon />,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(
                    openContent(
                      {
                        type: TabTypes.Template,
                        name: selectedContent.name,
                        id: selectedContent.id,
                        selected: true,
                      },
                      selectedContent.name
                    )
                  ),
              },
              {
                name: t('myList_contextMenu_duplicateTemplate'),
                icon: <Duplicate />,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(duplicateTemplate(selectedContent.id)),
              },
              {
                name: t('myList_contextMenu_removeTemplate'),
                icon: <Remove />,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(deleteTemplate([selectedContent.id])),
              },
              {
                name: t('myList_contextMenu_createListFromTemplate'),
                icon: <NewListIcon />,
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(createListFromTemplate(selectedContent.id)),
              },
            ],
    }
  },
  {
    cellDoubleClick: (type: TabTypes, content: IList | ITemplate) =>
      type === TabTypes.List
        ? openContent(
            {
              type: TabTypes.List,
              id: content.id,
              name: content.name,
              selected: true,
            },
            content.name
          )
        : openContent(
            {
              type: TabTypes.Template,
              id: content.id,
              name: content.name,
              selected: true,
            },
            content.name
          ),
    getContent: (type: TabTypes) =>
      type === TabTypes.List ? getLists() : getTemplates(),
    updateContent: (type: TabTypes, content: IList | ITemplate) => {},
    openContent,
    setImportFileSize,
    setImportData,
    openImportMappingModal,
    importListFromPlZip,
    updateColumnOrder,
  }
)(MyList)

export default connectedComponent
