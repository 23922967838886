import type React from 'react'
import { useState } from 'react'
import type { IItemChanges } from '../Items/IItem'
import { ITextPosition, ITextToChage, ItemType } from '../Items/IItem'
import { saveItemChangesWip } from './ItemChanges.action'

const defaultItem: IItemChanges = {
  listId: '',
  itemIds: [],

  changeText: false,
  changeAddInText: false,
  textToChange: ITextToChage.Title,
  textPosition: ITextPosition.Last,
  addText: '',
  changeReplaceText: false,
  replaceText: '',
  newText: '',

  changeCategory: false,

  changeCondition: false,
  condition: '',

  changeTypeAndPrice: false,
  itemType: ItemType.Auction,
  changeStartingBid: false,
  startingBid: 0,
  changeBuyNowPrice: false,
  buyNowPrice: 0,
  changeReservationPrice: false,
  reservationPrice: 0,
  changeVat: false,
  vat: 0,
  changeQuantity: false,
  quantity: 0,

  changeAdvertismentLength: false,
  advertisementLengthInDays: 7,
  chosenEndTimeHour: 0,
  chosenEndTimeMinute: 0,
  hasChosenEndTime: false,

  changeRestart: false,
  restarts: 0,

  changeShipping: false,
  shippingAlternative: [],

  changeShippingFirst: false,
  shippingFirst: undefined,

  changeShippingSecond: false,
  shippingSecond: undefined,

  changeShippingTakeaway: false,
  shippingTakeaway: false,

  changeExtraAdditions: false,
  isHighlighted: false,
  rankUp: false,

  changeAcceptedBidders: false,
  acceptedBidders: 4,

  changeShippingCondition: false,
  shippingCondition: '',
}

const useItemChanges = (itemIds: string[], listId: string) => {
  const initializeItemChanges = (itemIds: string[], listId: string) => {
    const newItemChanges: IItemChanges = {
      ...defaultItem,
      itemIds: itemIds,
      listId: listId,
    }
    return newItemChanges
  }
  const [itemChanges, setItemChanges] = useState<IItemChanges>(
    initializeItemChanges(itemIds, listId)
  )

  const updateItemChangesState = (key: any, value: any, dispatch: any) => {
    setItemChanges((itemChanges) => {
      const newItemChanges: IItemChanges = {
        ...itemChanges,
        [key]: value,
      }
      dispatch(saveItemChangesWip(newItemChanges))
      return newItemChanges
    })
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dispatch: any
  ) => {
    event.persist()
    updateItemChangesState(event.target.name, event.target.value, dispatch)
  }

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    dispatch: any
  ) => {
    event.persist()
    updateItemChangesState(event.target.name, event.target.value, dispatch)
  }

  const updateStateByItemChanges = (
    property: string,
    value: string | number | boolean | string[],
    dispatch: any
  ) => {
    return updateItemChangesState(property, value, dispatch)
  }

  return {
    itemChanges,
    handleInputChange,
    handleSelectChange,
    updateStateByItemChanges,
    setItemChanges,
  }
}

export default useItemChanges
