import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { deleteList } from '../../List/List.actions'

import DeleteLists from './DeleteLists'

const connectedComponent = connect(
  (state: any) => {
    return {
      listIds: state.lists.allIds.filter(
        (id) => state.lists.byId[id]?.isChecked
      ),
    }
  },
  {
    closeModal,
    deleteList,
  }
)(DeleteLists)

export default connectedComponent
