import React, { useEffect } from 'react'
import Button from '../../../Components/Button'
import Input from '../../../Components/Input'
import type { ICategory, IItem } from '../../IItem'
import { ReactComponent as Checkmark } from '@tradera/blueprint/theme/icons2/check.svg'
import { ReactComponent as Close } from '@tradera/blueprint/theme/icons2/close.svg'
import ErrorLabel from '../ErrorLabel'
import Label from '../../../Components/Label'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'
interface CampaignCodeProp {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateStateByItem: (property: string, value: any) => void
  getCampaignCodeValid: (
    campaignCode: string,
    categoryId: number
  ) => Promise<any>
  campaignCodeValid: boolean
  item: IItem | ISellItem
}

const CampaignCode: React.FunctionComponent<CampaignCodeProp> = ({
  handleInputChange,
  updateStateByItem,
  getCampaignCodeValid,
  campaignCodeValid,
  item,
}) => {
  const { t } = useTranslation()
  const changeCampaignCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    updateStateByItem('campaignCode', e.target.value)

    if (e.target.value.length > 0 && item.category !== undefined) {
      let categoryId = getCategoryId(item.category)
      getCampaignCodeValid(e.target.value, categoryId)
      if (campaignCodeValid || !campaignCodeValid) {
        updateStateByItem('campaignCodeValid', campaignCodeValid)
      }
    }
  }

  function getCategoryId(category: ICategory) {
    if (category && category.category && category.category.length === 1) {
      return getCategoryId(category.category[0])
    }

    return category.id
  }

  useEffect(() => {
    updateStateByItem('campaignCodeValid', campaignCodeValid)
  }, [getCampaignCodeValid, campaignCodeValid])

  return (
    <>
      {item.campaignCode &&
      item.campaignCode.length > 0 &&
      item.validationResult &&
      !item.campaignCodeValid ? (
        <ErrorLabel
          label={t('itemsForm_campaignCode_label')}
          description={t('itemsForm_campaignCode_error')}
        />
      ) : (
        <Label label={t('itemsForm_campaignCode_label')} />
      )}
      <div className="row d-flex align-items-center">
        <div className="col-md-6">
          <Input
            group="input-group"
            type="text"
            value={`${item.campaignCode ? item.campaignCode : ''}`}
            name="campaignCode"
            onChangeValue={(e) => changeCampaignCode(e)}
          />
        </div>
        <div className="col-1 d-none d-md-block">
          {item.campaignCode && item.campaignCode.length > 0 && (
            <Button
              clickHandler={() => {
                updateStateByItem('campaignCode', '')
                updateStateByItem('campaignCodeValid', true)
              }}
              icon={<Close className="icon" />}
              variant="noborder"
            />
          )}
        </div>
        <div className="col-md-5">
          {item.campaignCode &&
            item.campaignCode.length > 0 &&
            item.campaignCodeValid && (
              <div
                className="bg-info px-2 d-flex align-items-center"
                style={{ height: 35 }}
              >
                <Checkmark className="icon" />{' '}
                <span className="ml-1">
                  {t('itemsForm_campaignCode_success')}
                </span>
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export default CampaignCode
