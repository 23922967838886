import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import type { IItem } from '../Items/IItem'
import type { IList } from '../List/IList'
import ContextMenu from './ContextMenu/index'
import type {
  CellContextMenuEvent,
  CellDoubleClickedEvent,
  CellEditingStoppedEvent,
  ValueSetterParams,
  SelectionChangedEvent,
} from 'ag-grid-community'
import type { ITemplate } from '../Template/ITemplate'
import type { TabTypes } from '../Content/IContent'
import ImportingListModalMinimized from './ImportingListModalMinimized'
import ImageDropZoneCreateItem from './ImageDropZoneCreateItem'
import type { IUser } from '../User/IUser'
import type { ISellItem } from '../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ProListerGridProps {
  rawData: IItem[] | IList[] | ITemplate[]
  columns: any
  selectedContentType?: TabTypes | string
  selectedContent: IItem | IList | ITemplate | ISellItem
  updateContent?: (content: IList | ITemplate) => void
  menuItems: {
    link?: string
    name: string
    action?: () => void
    icon?: React.ReactNode
  }[]
  onCellContextMenu: (row: IList | IItem | ITemplate | ISellItem) => void
  onCellDoubleClicked?: (row: IList | IItem | ITemplate) => void
  onCellEditingStopped?: (event: ValueSetterParams) => void
  onGridApi: (api: any) => void
  onColumnMoved?: (a) => void
  onRowDragEnd?: (a) => void
  onSelectionChanged: (event: SelectionChangedEvent) => void
  paddingBottom: number
  updateColumnOrder?: (columns: string[]) => any
  user: IUser
  height?: string
}

const ProListerGrid: React.FunctionComponent<ProListerGridProps> = ({
  rawData,
  columns,
  menuItems,
  onCellContextMenu,
  onCellDoubleClicked,
  selectedContent,
  selectedContentType,
  updateContent,
  onCellEditingStopped,
  onGridApi,
  onColumnMoved,
  onRowDragEnd,
  onSelectionChanged,
  paddingBottom,
  updateColumnOrder,
  user,
  height,
}) => {
  const { t } = useTranslation()
  const [refForPopup, setrefForPopup] = useState<any>(null)
  const [showPopperItem, setShowPopperItem] = useState(false)
  const [showImageDropZone, setShowImageDropZone] = useState(false)
  const [gridApi, setGridApi]: any = useState()

  const numberOfFailedItems = rawData
    ?.map((i: any) => i?.publishResult?.toLowerCase() !== 'ok')
    .filter((x) => x).length

  const onGridReady = (params) => {
    setGridApi(params.api)
    onGridApi(params.api)

    var myListColumn = params.columnApi
      .getColumns()
      .find((item) => item.getColId() === 'openList')
    if (myListColumn !== undefined) {
      const columnState = JSON.parse(
        localStorage.getItem('prolisterListGridColumnState')!
      )
      if (columnState) {
        params.columnApi.applyColumnState(columnState)
      }
    }

    if (numberOfFailedItems > 0) {
      //Show failed items in top of list
      var publishResultColumn = params.columnApi
        .getColumns()
        .find((item) => item.getColId() === 'publishResult')
      if (publishResultColumn !== undefined) {
        var sort = [
          {
            colId: 'publishResult',
            sort: 'asc',
          },
        ]
        params.columnApi.applyColumnState(sort)
      }
    }
  }

  const onColumnResizeEvent = (params) => {
    if (params.finished === true) {
      var myListColumn = params.columnApi
        .getAllColumns()
        .find((item) => item.getColId() === 'openList')

      if (myListColumn === undefined) {
        const newColumnOrders = params.columnApi
          .getAllColumns()!
          .map((c) => c.getColId() + ':' + c.getActualWidth())

        if (updateColumnOrder !== undefined && newColumnOrders.length > 0) {
          updateColumnOrder(newColumnOrders)
        }
      } else {
        const columnState = JSON.stringify(params.columnApi.getColumnState())
        localStorage.setItem('prolisterListGridColumnState', columnState)
      }
    }
  }

  const handleRowItemClicked = (event: CellContextMenuEvent) => {
    event.node.setSelected(true)
    setrefForPopup(event.event!.target)
    setShowPopperItem(true)
    onCellContextMenu(event.data as IItem)
  }

  const rowClassRules = {
    selected: function (params) {
      if (params.data.isChecked) {
        return true
      }
      return false
    },
    notselected: function (params) {
      if (!params.data.isChecked) {
        return true
      }
      return false
    },
  }

  const cellClassRules = {
    'error-border': function (params) {
      if (
        params.colDef.field === 'category' &&
        params.data.validationResult &&
        params.data.validationResult.properties['attributes'] === false
      ) {
        return true
      } else if (
        params.data.validationResult &&
        params.data.validationResult.properties[params.colDef.field] === false
      ) {
        return true
      }
      return false
    },
  }

  const onEditNameClicked = () => {
    const lists = rawData as unknown as IList[]
    const selectedLists = lists.filter((list) => list.isChecked)
    if (selectedLists.length === 1) {
      const indexOfList = lists.indexOf(selectedLists[0])
      gridApi?.startEditingCell({
        rowIndex: indexOfList,
        colKey: 'name',
      })
    }
  }

  const columnDefs = columns.map((c, i) => ({
    field: c.name,
    sortable: c.sortable !== undefined ? c.sortable : true,
    rowDrag: c.rowDrag,
    headerName: t(c.title),
    headerCheckboxSelection: c.selection,
    valueSetter: (e: ValueSetterParams) => {
      if (onCellEditingStopped !== undefined) {
        onCellEditingStopped(e)
      }
      return false
    },
    cellStyle: c.cellStyle !== undefined ? c.cellStyle : { display: 'inline' },
    cellClassRules: cellClassRules,
    onCellDoubleClicked: (data: CellDoubleClickedEvent) => {
      if (onCellDoubleClicked !== undefined) {
        onCellDoubleClicked(data.data)
      }
      return false
    },
    valueFormatter: c.valueFormatter as any,
    cellRenderer: c.cellRenderer,
    comparator: c.comparator,
    cellRendererParams: { selectedContentType, user },
    width: c.width,
    resizable: c.resizable !== undefined ? c.resizable : true,
    lockPosition: c.lockPosition ? c.lockPosition : false,
    editable: c.editable,
    pinned: c.name === 'isChecked' ? 'left' : null,
    lockPinned: c.name === 'isChecked' ? true : false,
  }))

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: height ? height : '100%',
          width: '100%',
          flex: 1,
          flexDirection: 'column',
          paddingBottom: paddingBottom,
        }}
        onClick={() => setShowPopperItem(showPopperItem && false)}
      >
        <AgGridReact
          rowData={rawData}
          rowDragManaged={true}
          rowDragMultiRow={true}
          onSelectionChanged={onSelectionChanged}
          rowSelection={'multiple'}
          singleClickEdit={true}
          rowClassRules={rowClassRules}
          getRowId={(params) => params.data.id}
          headerHeight={40}
          rowHeight={32}
          onGridReady={onGridReady}
          onColumnResized={onColumnResizeEvent}
          preventDefaultOnContextMenu={true}
          onCellContextMenu={(event) => handleRowItemClicked(event)}
          onCellEditingStopped={(e: CellEditingStoppedEvent) => {}}
          onColumnMoved={(event) => {
            if (!onColumnMoved) {
              return
            }
            onColumnMoved(event)
          }}
          onRowDragEnd={(event) => {
            onRowDragEnd!(event)
          }}
          onRowDragLeave={(event) => {
            onRowDragEnd!(event)
          }}
          maintainColumnOrder={true}
          animateRows={true}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
          suppressRowHoverHighlight={true}
          overlayNoRowsTemplate={t('prolisterGrid_noRows')}
          columnDefs={columnDefs}
        />
        <ImageDropZoneCreateItem
          showImageDropZone={showImageDropZone}
          toggleImageDropZone={(value: boolean) => setShowImageDropZone(value)}
        />
      </div>

      <ContextMenu
        showPopper={showPopperItem}
        refForPopup={refForPopup}
        menuItems={menuItems}
        selectedContent={selectedContent}
        toggleContextMenu={(value: boolean) => setShowPopperItem(value)}
        editNameClicked={onEditNameClicked}
      />

      <ImportingListModalMinimized />
    </>
  )
}

export default ProListerGrid
