import type { Dispatch } from 'react'
import { createActions } from 'redux-actions'
import type { RootState } from '../Store/rootReducer'
import { openAlertContainer } from '../UI/UI.actions'
import { TabTypes } from './IContent'
import type { ITab } from './IContent'

export const { addContent, closeContent, changeTab } = createActions({
  addContent: (tab: ITab) => tab,
  closeContent: (tabToCloseId: string, nextTabToOpenId: string) => ({
    tabToCloseId,
    nextTabToOpenId,
  }),
  changeTab: (tabId: string) => tabId,
})

export const closeTab =
  (tabToCloseId: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState): void => {
    const getNextTabToOpenIdIfCurrentIsSelected = () => {
      const tabToCloseIsSelected =
        getState().content.byId[tabToCloseId]?.selected
      const orderOftabIds = getState().content.allIds as string[]
      let id

      if (tabToCloseIsSelected && orderOftabIds.length > 1) {
        const indexOfSelectedTab = orderOftabIds.findIndex(
          (x) => x === tabToCloseId
        )
        const isLastInList = indexOfSelectedTab === orderOftabIds.length - 1

        id = isLastInList
          ? orderOftabIds[orderOftabIds.length - 2]
          : orderOftabIds[indexOfSelectedTab + 1]
      }

      return id
    }

    dispatch(
      closeContent(tabToCloseId, getNextTabToOpenIdIfCurrentIsSelected())
    )
  }

export const openContent =
  (content: ITab, name: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const orderOftabIds = getState().content.allIds as string[]
    if (orderOftabIds.length === 6) {
      dispatch(
        openAlertContainer('Max 6 flikar kan vara öppnade samtidigt', 'error')
      )
    } else {
      //Fixes bug where list name is not updated in tab when clicking on open list arrow after a list has been renamed
      if (content.type === TabTypes.List) {
        const list = getState().lists.byId[content.id]
        if (list) {
          name = list?.name
        }
      }
      dispatch(
        addContent({
          type: content.type,
          id: content.id,
          selected: content.selected,
          name,
          sellViewType: content.sellViewType,
        })
      )
    }
  }
