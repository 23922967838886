import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ReactComponent as NewImage } from '../../Icons/new-image.svg'
import styles from './ImageDropZoneCreateItem.module.scss'
import { useDispatch } from 'react-redux'
import { addImagesForCreateItemFromImage } from '../../Items/Items.action'
import { useTranslation } from 'react-i18next'

interface ImageDropZoneCreateItemProps {
  showImageDropZone: boolean
  toggleImageDropZone: (value: boolean) => void
  id: string
  openImageErrorMessageModal: () => void
}
const ImageDropZoneCreateItem: React.FunctionComponent<
  ImageDropZoneCreateItemProps
> = ({
  showImageDropZone,
  toggleImageDropZone,
  id,
  openImageErrorMessageModal,
}) => {
  const { t } = useTranslation()
  const [selectedImageUrls, setSelectedImageUrls] = useState<string[]>()
  const dispatch = useDispatch()
  const maxFileSize = 10000000

  const updateImages = (files: File[], id: string) => {
    let form_data = new FormData()
    let fileSize = 0
    files.forEach((file) => {
      fileSize = file.size
      if (fileSize > maxFileSize) {
        dispatch(openImageErrorMessageModal)
        return
      }
    })

    if (fileSize < maxFileSize) {
      files.forEach((file) => {
        form_data.append('files', file)
      })

      dispatch(addImagesForCreateItemFromImage(form_data, id))
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    {
      updateImages(acceptedFiles, 'undefined')
    }
  }, [])

  useEffect(() => {
    toggleImageDropZone(id !== '')
  }, [id])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const onDragEnd = (result) => {
    return
  }

  const generateKey = () => {
    return `$testfile_${new Date().getTime()}`
  }
  return (
    <>
      {showImageDropZone ? (
        <div className={styles.fileUpload}>
          <div className="d-flex">
            <DragDropContext onDragEnd={onDragEnd} key={generateKey()}>
              <Droppable
                droppableId="droppable"
                direction="horizontal"
                key={generateKey()}
              >
                {(provided, snapshot) => (
                  <>
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedImageUrls?.map((item, index) => (
                        <>
                          <div className={styles.imagecontainer}>
                            <Draggable
                              key={`id-${index}`}
                              draggableId={`id-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <img
                                  src={item}
                                  key={index}
                                  ref={provided.innerRef}
                                  alt="item"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${styles.imageItem} d-flex justify-content-center align-items-center`}
                                />
                              )}
                            </Draggable>
                          </div>
                        </>
                      ))}
                    </div>
                    {provided.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
            {selectedImageUrls === undefined && (
              <div
                {...getRootProps()}
                className={`${styles.imageItem} d-flex justify-content-center align-items-center`}
              >
                <input {...getInputProps()} />
                <div style={{ cursor: 'pointer' }}>
                  <p className={styles.imageItemHeader}>
                    <NewImage /> {t('prolisterGrid_imageDropZone')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ImageDropZoneCreateItem
