import { configureStore } from '@reduxjs/toolkit'
//import Reactotron from '../Config/ReactotronConfig'

import rootReducer from './rootReducer'

import authenticationMiddleware from '../Authentication/Authentication.middleware'
import itemMiddleware from '../Items/Item.middleware'

//TODO: Reactron is not working with the latest react-scripts but obviously maintainers will update the project early 2023 https://github.com/infinitered/reactotron/issues/1235
// let enhancers
// if (process.env.NODE_ENV === 'development') {
//   enhancers = [Reactotron.createEnhancer!()]
// }

const store = configureStore({
  reducer: rootReducer,
  //enhancers: enhancers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authenticationMiddleware(),
      itemMiddleware()
    ),
  devTools: process.env.NODE_ENV !== 'production',
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch

export default store
