import React, { useState } from 'react'
import Input from '../../../Components/Input'
import { ItemType, type IItemChanges } from '../../IItem'
import CheckBox from '../../../Components/CheckBox'
import CheckBoxExpander from '../../../Components/CheckBoxExpander'
import styles from '../EditItemsForm.module.scss'
import type { IUser } from '../../../User/IUser'
import Select from '../../../Components/Select'
import VAT_OPTIONS from '../../../Infrastructure/Helpers/optionsHelper'
import Option from '../../../Components/Option'
import { useTranslation } from 'react-i18next'

interface ItemProps {
  itemChanges: IItemChanges
  user: IUser
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateStateByItemChanges: (
    property: string,
    value: string | number | boolean
  ) => void
}

const TypeOfSale: React.FunctionComponent<ItemProps> = ({
  itemChanges,
  user,
  handleInputChange,
  updateStateByItemChanges,
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const isAuction = itemChanges.itemType === ItemType.Auction
  const isFixedPrice = itemChanges.itemType === ItemType.FixedPrice
  const isShopItem = itemChanges.itemType === ItemType.ShopItem

  const changeSellType = (type: IItemChanges['itemType']) => {
    updateStateByItemChanges('itemType', type)

    if (type === ItemType.FixedPrice) {
      updateStateByItemChanges('startingBid', 0)
      updateStateByItemChanges('buyNowPrice', 0)
      updateStateByItemChanges('reservationPrice', 0)
    } else if (type === ItemType.ShopItem) {
      updateStateByItemChanges('buyNowPrice', 0)
      updateStateByItemChanges('quantity', 0)
      updateStateByItemChanges('vat', 0)
    } else {
      updateStateByItemChanges('buyNowPrice', 0)
    }
  }
  const changeBuyNowPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    updateStateByItemChanges('buyNowPrice', Number(e.target.value))
  }
  const changeReservationPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    updateStateByItemChanges('reservationPrice', Number(e.target.value))
  }
  const changeVAT = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const vat = parseInt(e.currentTarget.value)
    updateStateByItemChanges('vat', vat)
  }
  return (
    <>
      <CheckBoxExpander
        checked={itemChanges.changeTypeAndPrice}
        label={t('editItemsForm_typeOfSale_label')}
        name="changeTypeAndPrice"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpanded}
      />
      {expanded && (
        <div className={styles.expandContainer}>
          <div className="row d-flex align-items-center mb-2">
            <div className="col-md-9">
              <div
                className="form-control-radio-switch"
                style={{ maxWidth: '50rem' }}
              >
                <Option
                  id={'auction'}
                  title={t('editItemsForm_typeOfSale_option_auction')}
                  onClick={() => changeSellType(ItemType.Auction)}
                  checked={isAuction}
                  disabled={false}
                />
                <Option
                  id={'buyNow'}
                  title={t('editItemsForm_typeOfSale_option_buyNow')}
                  onClick={() => changeSellType(ItemType.FixedPrice)}
                  checked={isFixedPrice}
                  disabled={false}
                />
                {user.hasShop && (
                  <Option
                    id={'shopItem'}
                    title={t('editItemsForm_typeOfSale_option_shopItem')}
                    onClick={() => changeSellType(ItemType.ShopItem)}
                    checked={isShopItem}
                    disabled={false}
                  />
                )}
              </div>
            </div>
          </div>
          {isFixedPrice && (
            <>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeBuyNowPrice}
                    label={t('editItemsForm_typeOfSale_buyNowPrice')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeBuyNowPrice"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend="kr"
                    type="number"
                    value={`${itemChanges.buyNowPrice}`}
                    name="buyNowPrice"
                    onChangeValue={(e) => handleInputChange(e)}
                    disable={!itemChanges.changeBuyNowPrice}
                  />
                </div>
              </div>
              {user.isCompany && (
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <CheckBox
                      checked={itemChanges.changeVat}
                      label={t('editItemsForm_typeOfSale_vat')}
                      handleInputChange={updateStateByItemChanges}
                      name="changeVat"
                      disable={!itemChanges.changeTypeAndPrice}
                    />
                  </div>
                  <div className="col-md-4">
                    <Select
                      onChange={(e) => changeVAT(e)}
                      defaultValue={
                        itemChanges.vat ? itemChanges.vat.toString() : '7'
                      }
                      disable={!itemChanges.changeVat}
                    >
                      {VAT_OPTIONS.map((item, key) => (
                        <option key={`${item.value}${key}`} value={item.value}>
                          {`${item.label}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </>
          )}
          {isAuction && (
            <>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeStartingBid}
                    label={t('editItemsForm_typeOfSale_auctionPrice')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeStartingBid"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend="kr"
                    type="number"
                    value={itemChanges.startingBid?.toString()}
                    name="startingBid"
                    onChangeValue={(e) => handleInputChange(e)}
                    disable={!itemChanges.changeStartingBid}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeBuyNowPrice}
                    label={t('editItemsForm_typeOfSale_buyNowPrice')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeBuyNowPrice"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend="kr"
                    errorText={t('editItemsForm_typeOfSale_buyNowPriceError')}
                    type="number"
                    error={
                      itemChanges.buyNowPrice! > 0 &&
                      itemChanges.buyNowPrice! <= itemChanges.startingBid!
                    }
                    value={itemChanges.buyNowPrice?.toString()}
                    name="buyNowPrice"
                    onChangeValue={(e) => changeBuyNowPrice(e)}
                    disable={!itemChanges.changeBuyNowPrice}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeReservationPrice}
                    label={t('editItemsForm_typeOfSale_reservationPrice')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeReservationPrice"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend="kr"
                    errorText={t(
                      'editItemsForm_typeOfSale_reservationPriceError'
                    )}
                    type="number"
                    error={
                      itemChanges.reservationPrice! > 0 &&
                      itemChanges.reservationPrice! <= itemChanges.startingBid!
                    }
                    value={itemChanges.reservationPrice?.toString()}
                    name="reservationPrice"
                    onChangeValue={(e) => changeReservationPrice(e)}
                    disable={!itemChanges.changeReservationPrice}
                  />
                </div>
              </div>
              {user.isCompany && (
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <CheckBox
                      checked={itemChanges.changeVat}
                      label={t('editItemsForm_typeOfSale_vat')}
                      handleInputChange={updateStateByItemChanges}
                      name="changeVat"
                      disable={!itemChanges.changeTypeAndPrice}
                    />
                  </div>
                  <div className="col-md-4">
                    <Select
                      onChange={(e) => changeVAT(e)}
                      defaultValue={
                        itemChanges.vat ? itemChanges.vat.toString() : '7'
                      }
                      disable={!itemChanges.changeVat}
                    >
                      {VAT_OPTIONS.map((item, key) => (
                        <option key={`${item.value}${key}`} value={item.value}>
                          {`${item.label}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </>
          )}
          {isShopItem && (
            <>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeBuyNowPrice}
                    label={t('editItemsForm_typeOfSale_shopItemPrice')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeBuyNowPrice"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend="kr"
                    type="number"
                    value={`${itemChanges.buyNowPrice}`}
                    name="buyNowPrice"
                    onChangeValue={(e) => handleInputChange(e)}
                    disable={!itemChanges.changeBuyNowPrice}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeVat}
                    label={t('editItemsForm_typeOfSale_vat')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeVat"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Select
                    onChange={(e) => changeVAT(e)}
                    defaultValue={
                      itemChanges.vat ? itemChanges.vat.toString() : '7'
                    }
                    disable={!itemChanges.changeVat}
                  >
                    {VAT_OPTIONS.map((item, key) => (
                      <option key={`${item.value}${key}`} value={item.value}>
                        {`${item.label}`}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <CheckBox
                    checked={itemChanges.changeQuantity}
                    label={t('editItemsForm_typeOfSale_quantity')}
                    handleInputChange={updateStateByItemChanges}
                    name="changeQuantity"
                    disable={!itemChanges.changeTypeAndPrice}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    group="input-group"
                    inputAppend={t('editItemsForm_typeOfSale_quantityAppend')}
                    type="number"
                    value={`${itemChanges.quantity}`}
                    name="quantity"
                    onChangeValue={(e) => handleInputChange(e)}
                    disable={!itemChanges.changeQuantity}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
export default TypeOfSale
