import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import { type IOrder } from './ISellView'
import {
  getOrdersSucceeded,
  setOrderAsPaidSucceeded,
  setOrderAsShippedSucceeded,
} from './SellView.actions'

export interface IOrderState {
  [key: string]: IOrder
}

export const initialState: IOrderState = {}

const normalizeItems = (items: IOrder[]) =>
  items.reduce((_ads, _ad) => {
    _ads[_ad.id!] = _ad
    return _ads
  }, {})

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

const byId: Reducer<IOrderState, IAction<any>> = handleActions(
  {
    [getOrdersSucceeded.toString()]: (
      state: IOrderState,
      action: IAction<IOrder[]>
    ) => {
      return normalizeItems(action.payload)
    },
    [setOrderAsPaidSucceeded.toString()]: (
      state: IOrderState,
      action: IAction<any>
    ) => {
      const orderId = action.payload
      if (state[orderId]) {
        const newSatate = {
          ...state,
          [orderId]: {
            ...state[orderId],
            status: 'Paid',
          },
        }
        return newSatate
      }

      return state
    },
    [setOrderAsShippedSucceeded.toString()]: (
      state: IOrderState,
      action: IAction<any>
    ) => {
      const orderId = action.payload
      if (state[orderId]) {
        const newSatate = {
          ...state,
          [orderId]: {
            ...state[orderId],
            status: 'Shipped',
          },
        }
        return newSatate
      }

      return state
    },
  },
  initialState
)

const allIds = handleActions(
  {
    [getOrdersSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => {
      return action.payload.map((order) => order.id)
    },
  },
  []
)

const OrdersReducer = combineReducers({
  byId,
  allIds,
})

export default OrdersReducer
