import React from 'react'
import { useDispatch } from 'react-redux'
import type { IItem } from '../../Items/IItem'
import Button from '../../Components/Button'
import { openEditCategoryModal } from '../../UI/UI.actions'

interface IAttributesRenderer {
  data: IItem
}
const AttributesRenderer: React.FunctionComponent<IAttributesRenderer> = ({
  data,
}) => {
  const dispatch = useDispatch()

  return (
    <div>
      <Button
        clickHandler={() => dispatch(openEditCategoryModal(data))}
        size="small"
        content={<Attributes data={data} />}
        variant="noborder"
        style={{
          padding: 0,
        }}
      />
    </div>
  )
}

export default AttributesRenderer

type Props = {
  data: IItem
}

const Attributes = ({ data }: Props) => {
  const selectedAttributes = data.attributes
    ? data.attributes
        .filter(function (x) {
          return x.possibleTermValues.length > 0
        })
        .sort(function (a, b) {
          return b.minNumberOfValues - a.minNumberOfValues
        })
    : []
  return (
    <p
      style={{
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {selectedAttributes.map((attribute, index) =>
        attribute.possibleTermValues.map((possibleTerm, i) => (
          <span key={possibleTerm} style={{ textTransform: 'capitalize' }}>
            {possibleTerm +
              (i < attribute.possibleTermValues.length - 1 ||
              index < selectedAttributes.length - 1
                ? ', '
                : '')}
          </span>
        ))
      )}
    </p>
  )
}
