import React from 'react'
import { ReactComponent as PublishedIcon } from '../../Icons/published.svg'
import { ReactComponent as FailedIcon } from '../../Icons/fail.svg'
import { useTranslation } from 'react-i18next'

interface IPublicationResultRendererProps {
  value: string
}

const PublicationResultRenderer: React.FunctionComponent<
  IPublicationResultRendererProps
> = ({ value }) => {
  const { t } = useTranslation()
  if (!value) {
    value = ''
  }
  const hasPublishedSuccessfully =
    value.toLowerCase() === 'ok' || value.toLowerCase() === 'published'
  const isNewItem = value.toLocaleLowerCase() === ''
  const text = hasPublishedSuccessfully
    ? t('prolisterGrid_renderers_publicationResult_published')
    : isNewItem
    ? t('prolisterGrid_renderers_publicationResult_unpublished')
    : t('prolisterGrid_renderers_publicationResult_failed')
  const icon = hasPublishedSuccessfully ? (
    <PublishedIcon />
  ) : isNewItem ? null : (
    <FailedIcon />
  )
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 8 }}>{icon}</span>
      {text}
    </div>
  )
}

export default PublicationResultRenderer
