import { connect } from 'react-redux'
import {
  closeModal,
  setImportFileSize,
  openImportMappingModal,
  setImportData,
  setImportToList,
} from '../../UI/UI.actions'
import { importListFromPlZip } from '../../List/List.actions'

import ImportListModal from './ImportListModal'
import { TabTypes } from '../../Content/IContent'

const connectedComponent = connect(
  (state: any) => {
    let openListId: string = ''
    for (const tabId in state.content.byId) {
      const tab = state.content.byId[tabId]
      if (tab.selected && tab.type === TabTypes.List) {
        openListId = tabId
      }
    }

    return {
      modalType: state.ui.modalType,
      openListId,
    }
  },
  {
    closeModal,
    importListFromPlZip,
    setImportFileSize,
    openImportMappingModal,
    setImportData,
    setImportToList,
  }
)(ImportListModal)

export default connectedComponent
