import { connect } from 'react-redux'
import { closeModal, openLoadingModal } from '../../UI/UI.actions'
import { cancelPublishList } from '../../List/List.actions'
import LoadingModal from './LoadingModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      message: state.ui.modalData,
    }
  },
  {
    closeModal,
    cancelPublishList,
    openLoadingModal,
  }
)(LoadingModal)

export default connectedComponent
