import * as Sentry from '@sentry/react'

class LoggingService {
  logWarning([title, data]: ErrorData) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(data).forEach((key) => {
          scope.setExtra(key, data[key])
        })
        scope.setLevel('warning')
        Sentry.captureMessage(title)
      })
    } else {
      console.log('Warning title: ' + title + ' data: ', data)
    }
  }
}

export default new LoggingService()
