import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import { completeLoginFlowWithRefreshToken } from './Authentication.action'
import type { ILoginFlowWithRefreshTokenResult } from './Authentication.action'

export interface IAuthenticationState {
  memberToken?: string
  memberId?: string
  memberAlias?: string
  memberFirstName?: string
  memberLastName?: string
  refreshToken?: string
  accessToken?: {
    value: string
    expiresIn: number
  }
  clientToken?: {
    value: string
    expiresIn: number
  }
  classicApiToken?: {
    value: string
    expiration: string
  }
}

const persistedRefreshToken = localStorage.getItem('refreshToken') ?? ''

const initialState: IAuthenticationState = {
  refreshToken: persistedRefreshToken,
}

const authenticationReducer: Reducer<any, IAction<any>> = handleActions(
  {
    [completeLoginFlowWithRefreshToken.toString()]: (
      state: any,
      action: IAction<ILoginFlowWithRefreshTokenResult>
    ) => ({
      ...state,
      accessToken: {
        ...state.accessToken,
        value: action.payload.accessToken,
        expiresIn: action.payload.accessTokenExpiresIn,
      },
      classicApiToken: {
        ...state.classicApiToken,
        value: action.payload.classicApiToken,
        expiration: action.payload.classicApiTokenExpiryTime,
      },
      refreshToken: action.payload.refreshToken,
      memberId: action.payload.memberId,
      memberAlias: action.payload.memberAlias,
      memberFirstName: action.payload.memberFirstName,
      memberLastName: action.payload.memberLastName,
    }),
  },
  initialState
)

export default authenticationReducer
