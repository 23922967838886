import { connect } from 'react-redux'
import { closeModal } from '../UI/UI.actions'
import ResponsiveModal from './ResponsiveModal'

const connectedComponent = connect(
  (state: any) => {
    const modalType = state.ui.modalType
    const title = state.ui.title
    const isLarge = state.ui.isLarge
    const linkUrl = state.ui.linkUrl
    const linkText = state.ui.linkText
    return {
      isModalOpen: state.ui.isModalOpen,
      modalType,
      title,
      isLarge,
      linkUrl,
      linkText,
    }
  },
  {
    closeModal,
  }
)(ResponsiveModal)

export default connectedComponent
