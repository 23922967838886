import React, { useEffect, useRef, useState } from 'react'
import type { IList } from '../List/IList'
import ProListerGrid from '../ProListerGrid'
import { activeColumns, unsoldColumns } from '../ProListerGrid/GridMetaData'
import type { ITemplate } from '../Template/ITemplate'
import type { SelectionChangedEvent } from 'ag-grid-community'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Spinner } from '../Icons/loading.svg'
import styles from './SellView.module.scss'
import type { IUser } from '../User/IUser'
import type { ItemListMenuItemProps } from '../Components/ListMenuItem/ListMenuItem'
import Option from '../Components/Option'
import type { IOrder, ISellItem } from './ISellView'
import { SellListType } from './ISellView'
import { selectItemInList, deselectAll } from './SellView.actions'
import Toolbar from './Toolbar'
import OrderGrid from './OrderGrid'
import type { ITab } from '../Content/IContent'
import { selectRawData } from './SellView.selector'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

interface SellViewProps {
  selectedTab: ITab | undefined
  loadingLists: boolean
  user: IUser
  getContent: (type: SellListType) => void
  onGridApi: (id: string, api: any) => void
  getMenuItems: (type: SellListType, t: TFunction) => ItemListMenuItemProps[]
  getContextMenu: (
    type: SellListType,
    t: TFunction
  ) => {
    name: string
    link?: string
    icon: React.ReactNode
    action?: (selectedItem: IList | ITemplate) => void
  }[]
}

const SellView: React.FunctionComponent<SellViewProps> = ({
  selectedTab,
  loadingLists,
  user,
  getContent,
  getMenuItems,
  getContextMenu,
  onGridApi,
}) => {
  const { t } = useTranslation()
  const [selectedContent, setSelectedContent] = useState<ISellItem>()
  const [selectedSellViewType, setSelectedSellViewType] =
    useState<SellListType>(selectedTab?.sellViewType || SellListType.Active)
  const menu = getMenuItems(selectedSellViewType, t)
  const contextMenu = getContextMenu(selectedSellViewType, t)
  const [gridApi, setGridApi]: any = useState()
  const dispatch = useDispatch()
  const rawData = useSelector((state) =>
    selectRawData(state, selectedSellViewType)
  )
  const [expandedRowsState, setExpandedRowsState] = useState<{}>({})
  const prevRawDataRef = useRef<IOrder[] | null>(null)
  const [orderGridInitialized, setOrderGridInitialized] =
    useState<boolean>(false)

  const onGridApiReady = (id: string, api: any) => {
    setGridApi(api)
    onGridApi(id, api)
  }

  const onCellContextMenu = (content: ISellItem) => {
    setSelectedContent(content)
  }

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    if (event.api !== null) {
      var selectedRows = event.api !== null ? event.api.getSelectedRows() : ''
      var selection = selectedRows as Array<ISellItem>

      event.api.forEachNode((node) => {
        var item = node.data as ISellItem

        const selectedItemIndex = selection.findIndex((x) => x.id === item.id)
        const isChecked = selectedItemIndex > -1 ? true : false
        if ((!item.isChecked && isChecked) || (item.isChecked && !isChecked)) {
          dispatch(selectItemInList(item.id, isChecked))
        }
      })
    }
  }

  useEffect(() => {
    getContent(selectedSellViewType)
  }, [])

  useEffect(() => {
    if (
      selectedSellViewType === SellListType.Sold &&
      !orderGridInitialized &&
      Array.isArray(rawData) &&
      (!prevRawDataRef.current ||
        (prevRawDataRef.current.length !== rawData.length &&
          rawData.length > 0))
    ) {
      setOrderGridInitialized(true)
      const newExpandedRows = {}
      rawData.forEach((order) => {
        newExpandedRows[order.id] = true
      })
      setExpandedRowsState(newExpandedRows)
    }

    prevRawDataRef.current = rawData
  }, [rawData, selectedSellViewType, orderGridInitialized])

  const changeSellViewType = (type: SellListType) => {
    dispatch(deselectAll())
    setSelectedSellViewType(type)
    getContent(type)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={`card ${styles.listContainer}`}>
        <div>
          <div
            className="form-control-radio-switch mx-2 mt-2 mb-1"
            style={{ maxWidth: '30rem' }}
          >
            <Option
              id={'active'}
              title={t('sellView_activeTab')}
              onClick={() => changeSellViewType(SellListType.Active)}
              checked={selectedSellViewType === SellListType.Active}
              disabled={false}
            />
            <Option
              id={'sold'}
              title={t('sellView_soldTab')}
              onClick={() => changeSellViewType(SellListType.Sold)}
              checked={selectedSellViewType === SellListType.Sold}
              disabled={false}
            />
            <Option
              id={'unsold'}
              title={t('sellView_unsoldTab')}
              onClick={() => changeSellViewType(SellListType.Unsold)}
              checked={selectedSellViewType === SellListType.Unsold}
              disabled={false}
            />
          </div>
          {!loadingLists ? (
            <>
              {(selectedSellViewType === SellListType.Active ||
                selectedSellViewType === SellListType.Unsold) && (
                <>
                  <Toolbar menuItems={menu} />
                  <ProListerGrid
                    user={user}
                    paddingBottom={20}
                    onCellContextMenu={(content) =>
                      onCellContextMenu(content as ISellItem)
                    }
                    rawData={rawData as any}
                    selectedContent={selectedContent!}
                    columns={
                      selectedSellViewType === SellListType.Active
                        ? activeColumns
                        : unsoldColumns
                    }
                    menuItems={contextMenu as any}
                    onSelectionChanged={(event) =>
                      onSelectionChanged(event as SelectionChangedEvent)
                    }
                    onGridApi={(api) => onGridApiReady('sellview', api)}
                    height="calc(100vh - 200px)"
                  />
                </>
              )}
              {selectedSellViewType === SellListType.Sold && (
                <div className="mt-5">
                  <OrderGrid
                    orders={rawData as unknown as IOrder[]}
                    expandedRows={expandedRowsState}
                    setExpandedRows={setExpandedRowsState}
                  />
                </div>
              )}
            </>
          ) : (
            <div className={styles.loadingContainer}>
              <Spinner className={styles.iconSpin} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SellView
