import { connect } from 'react-redux'
import SellView from './SellView'
import { openContent } from '../Content/Content.actions'
import { TabTypes } from '../Content/IContent'
import type { RootState } from '../Store/rootReducer'
import { ReactComponent as CloseIcon } from '../Icons/close-icon.svg'
import type { ITemplate } from '../Template/ITemplate'
import type { IList } from '../List/IList'
import store from '../Store/store'
import {
  openAlertContainer,
  openCancelSellItemsModal,
  openEditItemModal,
  openEditShopItemModal,
  openPublishModal,
} from '../UI/UI.actions'
import type { ItemListMenuItemProps } from '../Components/ListMenuItem/ListMenuItem'
import { getOrders, getTraderaLists } from './SellView.actions'
import type { ISellItem } from './ISellView'
import { SellListType } from './ISellView'
import { ReactComponent as OpenIcon } from '@tradera/blueprint/theme/icons2/open.svg'
import { ItemType } from '../Items/IItem'
import type { TFunction } from 'i18next'

const URL = 'https://www.tradera.com'

const getCheckedItems = (type: SellListType, state: any): ISellItem[] => {
  if (!state.sellItems) {
    return []
  }

  const itemsById = state.sellView.byId[type]?.items
  if (!itemsById) {
    return []
  }

  return itemsById
    .map((id) => state.sellItems!.byId[id])
    .filter((item) => item?.isChecked)
}

const connectedComponent = connect(
  (state: RootState) => {
    return {
      user: state.user,
      loadingLists: state.ui['loadingLists'],
      selectedTab: Object.values(state.content.byId).find(
        (tab) => tab.selected
      ),
      getMenuItems: (
        type: SellListType,
        t: TFunction
      ): ItemListMenuItemProps[] =>
        type === SellListType.Active
          ? [
              {
                name: t('sellView_menuItem_cancel'),
                icon: <CloseIcon width={14} height={14} />,
                isDisabled: getCheckedItems(type, state).length === 0,
                variant: 'outline',
                action: () => {
                  store.dispatch(openCancelSellItemsModal(type))
                },
              },
            ]
          : [
              {
                name: t('sellView_menuItem_restart'),
                isDisabled:
                  getCheckedItems(type, state).length === 0 ||
                  getCheckedItems(type, state).filter((i) => i.isRestarted)
                    .length === getCheckedItems(type, state).length,
                variant: 'primary',
                action: () => {
                  if (
                    getCheckedItems(type, state).filter((i) => i.isRestarted)
                      .length > 0
                  ) {
                    store.dispatch(
                      openAlertContainer(
                        'Annonserna som redan startats om har exkluderats',
                        'info'
                      )
                    )
                  }
                  store.dispatch(
                    openPublishModal(
                      'UnsoldItems',
                      getCheckedItems(type, state).filter((i) => !i.isRestarted)
                    )
                  )
                },
              },
            ],
      getContextMenu: (type: SellListType, t: TFunction) => {
        const selectedItems = getCheckedItems(type, state)

        switch (type) {
          case SellListType.Active:
            return [
              {
                name: t('sellView_contextMenu_edit'),
                icon: <OpenIcon width={14} height={14} />,
                hidden: !(
                  selectedItems.length === 1 &&
                  selectedItems[0].itemType !== ItemType.ShopItem
                ),
                action: () => {
                  window.open(
                    `${URL}/selling/edit/${selectedItems[0].id}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                },
              },
              {
                name: t('sellView_contextMenu_update'),
                icon: null,
                hidden: !(
                  selectedItems.length === 1 &&
                  selectedItems[0].itemType === ItemType.ShopItem
                ),
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(openEditShopItemModal([selectedContent!.id])),
              },
              {
                name: t('sellView_contextMenu_cancel'),
                icon: null,
                hidden: !(
                  selectedItems.length === 1 && selectedItems[0].totalBids === 0
                ),
                action: (selectedContent: IList | ITemplate) =>
                  store.dispatch(
                    openCancelSellItemsModal([selectedContent!.id])
                  ),
              },
            ]

          case SellListType.Unsold:
            return [
              {
                name: t('sellView_contextMenu_restart'),
                icon: null,
                disabled: !(
                  selectedItems.length > 0 &&
                  selectedItems.filter((i) => i.isRestarted).length === 0
                ),
                action: () => {
                  store.dispatch(
                    openPublishModal(
                      'UnsoldItems',
                      selectedItems.filter((i) => !i.isRestarted)
                    )
                  )
                },
              },
              {
                name: t('sellView_contextMenu_changeAndRestart'),
                icon: null,
                disabled: !(
                  selectedItems.length === 1 && !selectedItems[0].isRestarted
                ),
                action: () => {
                  store.dispatch(openEditItemModal([selectedItems[0]!.id]))
                },
              },
            ]

          default:
            return []
        }
      },
      getContent: (type: SellListType) => {
        switch (type) {
          case SellListType.Active: {
            if (state.sellView.allIds.length === 0) {
              store.dispatch(getTraderaLists())
            }
            break
          }
          case SellListType.Sold: {
            if (state.orders.allIds.length === 0) {
              store.dispatch(getOrders())
            }
            break
          }
          case SellListType.Unsold: {
            if (state.sellView.allIds.length === 0) {
              store.dispatch(getTraderaLists())
            }
            break
          }
        }
      },
    }
  },
  {
    cellDoubleClick: (type: TabTypes, content: IList | ITemplate) =>
      type === TabTypes.List
        ? openContent(
            {
              type: TabTypes.List,
              id: content.id,
              name: content.name,
              selected: true,
            },
            content.name
          )
        : openContent(
            {
              type: TabTypes.Template,
              id: content.id,
              name: content.name,
              selected: true,
            },
            content.name
          ),
  }
)(SellView)

export default connectedComponent
