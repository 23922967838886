import { ReactComponent as Spinner } from '@tradera/blueprint/theme/icons2/loading.svg'
import { ReactComponent as BankIdIcon } from '@tradera/blueprint/theme/icons-colored/bankid.svg'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import styles from './BankIdVerifyModal.module.scss'

interface BankIdVerifyModalProps {
  cancelBankIdVerification: (bankIdOrderRef: string) => any
  beginBankIdOnFileVerification: () => void
  bankIdOrderRef: any
  qrData: string
  autoStartToken: string
  desktopAutoStartToken: string
}

const launchBankIdAppliction = (autoStartToken: string) => {
  window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
}

const BankIdVerifyModal: React.FunctionComponent<BankIdVerifyModalProps> = ({
  bankIdOrderRef,
  cancelBankIdVerification,
  beginBankIdOnFileVerification,
  qrData,
  autoStartToken,
  desktopAutoStartToken,
}) => {
  const { t } = useTranslation()

  const handleOpenOnDesktopClick = () => {
    beginBankIdOnFileVerification()
  }

  const handleOpenOnMobileClick = () => {
    launchBankIdAppliction(autoStartToken)
  }

  useEffect(() => {
    if (desktopAutoStartToken) {
      launchBankIdAppliction(desktopAutoStartToken)
    }
  }, [desktopAutoStartToken])

  return (
    <div>
      <div className="p-3">
        <div className="text-center">
          <BankIdIcon className="icon icon-xxl mb-2" />
          <p>{t('modal_bankIdVerify_text')}</p>
          {qrData && (
            <img
              src={`data:image/png;base64, ${qrData}`}
              style={{ width: '200px' }}
            />
          )}
          {!qrData && <Spinner className="iconSpin" />}
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <div className={styles.hideOnDesktop}>
              <button
                className="btn btn-outline-primary btn-fluid"
                onClick={handleOpenOnMobileClick}
              >
                {t('modal_bankIdVerify_openOnDevice_mobile')}
              </button>
            </div>
            <div className={styles.hideOnMobile}>
              <button
                className="btn btn-outline-primary btn-fluid"
                onClick={handleOpenOnDesktopClick}
              >
                {t('modal_bankIdVerify_openOnDevice_desktop')}
              </button>
            </div>
            <p
              role="button"
              className={styles.cancelButton}
              onClick={() => cancelBankIdVerification(bankIdOrderRef)}
            >
              {t('modal_bankIdVerify_button_cancel')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankIdVerifyModal
