import React from 'react'
import Input from '../../../Components/Input'
import { ItemType, type IItem } from '../../IItem'
import Label from '../../../Components/Label'
import type { IUser } from '../../../User/IUser'
import Select from '../../../Components/Select'
import Option from '../../../Components/Option'
import VAT_OPTIONS from '../../../Infrastructure/Helpers/optionsHelper'
import { isSellItem, type ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ItemProps {
  item: IItem | ISellItem
  user: IUser
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateStateByItem: (property: string, value: string | number) => void
}

const TypeOfSale: React.FunctionComponent<ItemProps> = ({
  item,
  user,
  handleInputChange,
  updateStateByItem,
}) => {
  const { t } = useTranslation()
  const isAuction = item.itemType === ItemType.Auction
  const isFixedPrice = item.itemType === ItemType.FixedPrice
  const isShopItem = item.itemType === ItemType.ShopItem

  const changeSellType = (type: IItem['itemType']) => {
    updateStateByItem('itemType', type)

    if (type === ItemType.FixedPrice) {
      updateStateByItem('startingBid', 0)
      updateStateByItem('buyNowPrice', 0)
      updateStateByItem('reservationPrice', 0)
    } else {
      updateStateByItem('buyNowPrice', 0)
    }
  }
  const changeBuyNowPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    updateStateByItem('buyNowPrice', Number(e.target.value))
  }
  const changeReservationPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e)
    updateStateByItem('reservationPrice', Number(e.target.value))
  }
  const changeVAT = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const vat = parseInt(e.currentTarget.value)
    updateStateByItem('vat', vat)
  }
  return (
    <>
      <div className="row d-flex align-items-center mb-2">
        <div className="col-md-8">
          <div
            className="form-control-radio-switch"
            style={{ maxWidth: '50rem' }}
          >
            <Option
              id={'auction'}
              title={t('itemsForm_typeOfSale_option_auction')}
              onClick={() => changeSellType(ItemType.Auction)}
              checked={isAuction}
              disabled={isSellItem(item) && isShopItem}
            />
            <Option
              id={'buyNow'}
              title={t('itemsForm_typeOfSale_option_buyNow')}
              onClick={() => changeSellType(ItemType.FixedPrice)}
              checked={isFixedPrice}
              disabled={isSellItem(item) && isShopItem}
            />
            <Option
              id={'shopItem'}
              title={t('itemsForm_typeOfSale_option_shopItem')}
              onClick={() => changeSellType(ItemType.ShopItem)}
              checked={isShopItem}
              disabled={!user.hasShop || (isSellItem(item) && !isShopItem)}
            />
          </div>
        </div>
      </div>
      {isFixedPrice && (
        <div className="row">
          <div className="col-md-3">
            <Input
              group="input-group"
              inputAppend="kr"
              label={t('itemsForm_typeOfSale_buyNowPrice') + '*'}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.buyNowPrice
                  ? t('itemsForm_typeOfSale_price_errorLabel')
                  : ''
              }
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.buyNowPrice
              }
              value={`${item.buyNowPrice}`}
              name="buyNowPrice"
              onChangeValue={(e) => handleInputChange(e)}
            />
          </div>
          {user.isCompany && (
            <div className="col-md-3">
              <Select
                onChange={(e) => changeVAT(e)}
                label={t('itemsForm_typeOfSale_vat')}
                defaultValue={item.vat ? item.vat.toString() : ''}
              >
                {VAT_OPTIONS.map((item, key) => (
                  <option key={`${item.value}${key}`} value={item.value}>
                    {`${item.label}`}
                  </option>
                ))}
              </Select>
            </div>
          )}
          <div className="col-md-3">
            <Label label={t('itemsForm_typeOfSale_buyNowPrice_addLength')} />
            <p>{t('itemsForm_typeOfSale_buyNowPrice_addLengthInfo')}</p>
          </div>
        </div>
      )}
      {isAuction && (
        <div className="row">
          <div className="col-md-3">
            <Input
              group="input-group"
              inputAppend="kr"
              label={t('editItemsForm_typeOfSale_auctionPrice') + '*'}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.startingBid
                  ? t('itemsForm_typeOfSale_price_errorLabel')
                  : ''
              }
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.startingBid
              }
              value={item.startingBid?.toString()}
              name="startingBid"
              onChangeValue={(e) => handleInputChange(e)}
            />
          </div>
          <div className="col-md-3">
            <Input
              group="input-group"
              inputAppend="kr"
              label={t('itemsForm_typeOfSale_auction_buyNowPrice')}
              errorText={'itemsForm_typeOfSale_buyNowPriceError'}
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.buyNowPrice
              }
              value={item.buyNowPrice?.toString()}
              name="buyNowPrice"
              onChangeValue={(e) => changeBuyNowPrice(e)}
            />
          </div>
          <div className="col-md-3">
            <Input
              group="input-group"
              inputAppend="kr"
              label={t('itemsForm_typeOfSale_reservationPrice')}
              errorText={t('itemsForm_typeOfSale_reservationPriceError')}
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.reservationPrice
              }
              value={item.reservationPrice?.toString()}
              name="reservationPrice"
              onChangeValue={(e) => changeReservationPrice(e)}
            />
          </div>
          {user.isCompany && (
            <div className="col-md-3">
              <Select
                onChange={(e) => changeVAT(e)}
                label={t('itemsForm_typeOfSale_vat')}
                defaultValue={item.vat ? item.vat.toString() : ''}
              >
                {VAT_OPTIONS.map((item, key) => (
                  <option key={`${item.value}${key}`} value={item.value}>
                    {`${item.label}`}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </div>
      )}
      {isShopItem && (
        <div className="row">
          <div className="col-md-4">
            <Input
              group="input-group"
              inputAppend="kr"
              label={t('itemsForm_typeOfSale_shopItemPrice') + '*'}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.buyNowPrice
                  ? t('itemsForm_typeOfSale_shopItemPriceError')
                  : ''
              }
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.buyNowPrice
              }
              value={`${item.buyNowPrice}`}
              name="buyNowPrice"
              onChangeValue={(e) => handleInputChange(e)}
            />
          </div>
          <div className="col-md-4">
            <Select
              onChange={(e) => changeVAT(e)}
              label={t('itemsForm_typeOfSale_vat')}
              defaultValue={item.vat ? item.vat.toString() : ''}
            >
              {VAT_OPTIONS.map((item, key) => (
                <option key={`${item.value}${key}`} value={item.value}>
                  {`${item.label}`}
                </option>
              ))}
            </Select>
          </div>
          <div className="col-md-4">
            <Input
              group="input-group"
              inputAppend="st"
              label={t('itemsForm_typeOfSale_quantity')}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.quantity
                  ? t('itemsForm_typeOfSale_quantityError')
                  : ''
              }
              type="number"
              error={
                item.validationResult &&
                !item.validationResult?.properties.quantity
              }
              value={`${item.quantity}`}
              name="quantity"
              onChangeValue={(e) => handleInputChange(e)}
            />
          </div>
        </div>
      )}
    </>
  )
}
export default TypeOfSale
