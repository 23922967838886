export const importProperties = [
  {
    key: 'header',
    label: 'modal_importMapping_header',
    autoMap: ['Header', 'Tradera name', 'Rubrik', 'Standard name sv'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'description',
    label: 'modal_importMapping_description',
    autoMap: [
      'Description',
      'Tradera description',
      'Beskrivning',
      'Standard description sv',
    ],
    hidden: false,
    forShop: false,
  },
  {
    key: 'condition',
    label: 'modal_importMapping_condition',
    autoMap: ['Condition', 'Skick'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'category',
    label: 'modal_importMapping_category',
    autoMap: ['Category', 'Tradera category', 'Kategori'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'attributes',
    label: 'modal_importMapping_attributes',
    autoMap: ['Attributes', 'Attribut'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'imageUrls',
    label: 'modal_importMapping_imageUrls',
    autoMap: ['ImageUrls', 'Bilder', 'Images'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'startingBid',
    label: 'modal_importMapping_startingBid',
    autoMap: ['StartingBid', 'Tradera price auction', 'Utropspris'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'buyNowPrice',
    label: 'modal_importMapping_buyNowPrice',
    autoMap: [
      'BuyNowPrice',
      'Tradera price buynow',
      'Köp nu pris',
      'Tradera price store',
    ],
    hidden: false,
    forShop: false,
  },
  {
    key: 'reservationPrice',
    label: 'modal_importMapping_reservationPrice',
    autoMap: ['ReservationPrice', 'Tradera price reserve', 'Reservationspris'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'advertisementLengthInDays',
    label: 'modal_importMapping_advertisementLengthInDays',
    autoMap: ['AdvertisementLengthInDays', 'Annonslängd'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'itemType',
    label: 'modal_importMapping_itemType',
    autoMap: ['ItemType', 'Annonstyp'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'isHighlighted',
    label: 'modal_importMapping_isHighlighted',
    autoMap: ['IsHighlighted', 'Highlight'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'rankUp',
    label: 'modal_importMapping_rankUp',
    autoMap: ['RankUp', 'Rank up'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'reference',
    label: 'modal_importMapping_reference',
    autoMap: ['Reference', 'Referens'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'restarts',
    label: 'modal_importMapping_restarts',
    autoMap: ['Restarts', 'Omstarter'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'shippingFirst',
    label: 'modal_importMapping_shippingFirst',
    autoMap: ['ShippingFirst', 'Fraktsätt #1'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'shippingSecond',
    label: 'modal_importMapping_shippingSecond',
    autoMap: ['ShippingSecond', 'Fraktsätt #2'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'shippingTakeaway',
    label: 'modal_importMapping_shippingTakeaway',
    autoMap: ['ShippingTakeaway', 'Erbjud avhämtning'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'shippingAlternativeFirst',
    label: 'modal_importMapping_shippingAlternativeFirst',
    autoMap: [
      'ShippingAlternativeFirst',
      'Tradera shipping primary method',
      'Fraktsätt #1',
    ],
    hidden: true,
    forShop: false,
  },
  {
    key: 'shippingCostFirst',
    label: 'modal_importMapping_shippingCostFirst',
    autoMap: [
      'ShippingCostFirst',
      'Tradera shipping primary cost',
      'Fraktkostnad #1',
    ],
    hidden: true,
    forShop: false,
  },
  {
    key: 'shippingAlternativeSecond',
    label: 'modal_importMapping_shippingAlternativeSecond',
    autoMap: [
      'ShippingAlternativeSecond',
      'Tradera shipping secondary method',
      'Fraktsätt #2',
    ],
    hidden: true,
    forShop: false,
  },
  {
    key: 'shippingCostSecond',
    label: 'modal_importMapping_shippingCostSecond',
    autoMap: [
      'ShippingCostSecond',
      'Tradera shipping secondary cost',
      'Fraktkostnad #2',
    ],
    hidden: true,
    forShop: false,
  },
  {
    key: 'shippingCondition',
    label: 'modal_importMapping_shippingCondition',
    autoMap: ['ShippingCondition', 'Fraktvillkor'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'acceptedBidders',
    label: 'modal_importMapping_acceptedBidders',
    autoMap: ['AcceptedBidders', 'Accepterade budgivare'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'endtime',
    label: 'modal_importMapping_endtime',
    autoMap: ['EndTime', 'Vald sluttid', 'Sluttid'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'campaignCode',
    label: 'modal_importMapping_campaignCode',
    autoMap: ['CampaignCode', 'Kampanjkod'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'vat',
    label: 'modal_importMapping_vat',
    autoMap: ['VAT', 'Moms', 'Tax'],
    hidden: false,
    forShop: false,
  },
  {
    key: 'quantity',
    label: 'modal_importMapping_quantity',
    autoMap: ['Quantity', 'Lagersaldo'],
    hidden: false,
    forShop: true,
  },
]

export interface IImportMapping {
  key: string
  label: string
  value: number
  hidden: boolean
  forShop: boolean
}
