import React from 'react'
import type { ISellItem } from '../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface IUnsoldStatusRendererProps {
  data: ISellItem
}

const UnsoldStatusRenderer: React.FunctionComponent<
  IUnsoldStatusRendererProps
> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div>
      {!data.isRestarted ? (
        <span
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          className="dark-background status-label text-white"
        >
          {t('prolisterGrid_renderers_unsoldStatus_notSold')}
        </span>
      ) : (
        <span
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          className="yellow-background status-label"
        >
          {t('prolisterGrid_renderers_unsoldStatus_restarted')}
        </span>
      )}
    </div>
  )
}

export default UnsoldStatusRenderer
