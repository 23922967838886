import { useState, useEffect } from 'react'
import Button from '../../Components/Button'
import { ReactComponent as Spinner } from '../../Icons/loading.svg'
import styles from './ImportingListModalMinimized.module.scss'
import IconButton from '@material-ui/core/IconButton'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

interface ImportingListModalMinimizedProps {
  closeMinimizeModal: () => void
  isMinimize: any
  importMinimizeLoading: any
  importFileSize: number
}

const ImportingListModalMinimized: React.FunctionComponent<ImportingListModalMinimizedProps> = ({
  closeMinimizeModal,
  isMinimize,
  importMinimizeLoading,
  importFileSize,
}) => {
  const [completed, setCompleted] = useState(0)
  const [isShow, setIsShow] = useState(false)
  let i = 0

  useEffect(() => {
    setInterval(function () {
      i = i === 90 ? 90 : i + 15
      setCompleted(i)
    }, 100)
  }, [])

  const fileSizeText = (importFileSize) => {
    let size = importFileSize / 1024
    if (size < 1000) {
      return `${Math.round(size)} KB`
    } else if (size < 100000) {
      return `${Math.round(size / 1024)} MB`
    } else {
      return `${Math.round(size / 1024 / 1024)} GB`
    }
  }

  return (
    <>
      {isMinimize && (
        <div
          className={
            isShow === true
              ? styles.importMainMinimizedExpandMore
              : styles.importMainMinimizedExpandLess
          }
        >
          <h5
            style={{
              position: 'relative',
              marginTop: '20px',
              marginLeft: '30px',
              marginBottom: '15px',
              display: 'inline-block',
            }}
          >
            {'importerar annonser'}{' '}
            <Spinner
              className={styles.importIconSpin}
              style={{ width: '30px', marginTop: '0px' }}
            />{' '}
          </h5>
          {isShow && (
            <IconButton
              onClick={() => setIsShow(false)}
              aria-label="ExpandLess"
              style={{
                marginTop: '20px',
                marginLeft: '220px',
                marginBottom: '15px',
                display: 'inline-block',
              }}
            >
              <ExpandLess />
            </IconButton>
          )}
          {!isShow && (
            <IconButton
              onClick={() => setIsShow(true)}
              aria-label="ExpandMore"
              style={{
                marginTop: '20px',
                marginLeft: '220px',
                marginBottom: '15px',
                display: 'inline-block',
              }}
            >
              <ExpandMore />
            </IconButton>
          )}
          {isShow && (
            <div className={styles.importMinimized}>
              <div
                className={styles.importingMinimized}
                style={{ display: 'flex', margin: '0 auto' }}
              >
                {importMinimizeLoading ? (
                  <progress id="file" value={completed} max="100" />
                ) : (
                  ''
                )}
              </div>
              <h5 className={styles.importText}>{`Importerar ${fileSizeText(
                importFileSize
              )}`}</h5>
              <div className={styles.importButtonDanger}>
                <Button
                  clickHandler={() => closeMinimizeModal()}
                  variant="outlined"
                  size="small"
                  text="Avbryt"
                  style={{ width: '40%', color: 'red' }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ImportingListModalMinimized
