import { connect } from 'react-redux'
import {
  closeModal,
  openPublishModal,
  openPublishValidModal,
} from '../../UI/UI.actions'

import PublishValid from './PublishValid'

const connectedComponent = connect(
  (state: any) => {
    return {
      list: state.lists.byId[state.ui.id],
      items: state.lists.byId[state.ui.id].items.map(
        (item) => state.items.byId[item]
      ),
    }
  },
  {
    closeModal,
    openPublishModal,
    openPublishValidModal,
  }
)(PublishValid)

export default connectedComponent
