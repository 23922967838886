import React from 'react'

interface DescriptionRendererProps {
  value: string
}

const DescriptionRenderer: React.FunctionComponent<
  DescriptionRendererProps
> = ({ value }) => {
  if (value === 'null') return <div></div>
  return <div dangerouslySetInnerHTML={{ __html: value }}></div>
}

export default DescriptionRenderer
