import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import configService from '../ConfigService/configService'

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${configService.config.REACT_APP_API_URL}/translation/{{lng}}/{{ns}}`,
    },
    lng: 'sv',
    fallbackLng: 'sv',
    react: {
      useSuspense: true,
    },
    ns: ['prolister', 'categories', 'attributes'],
    defaultNS: 'prolister',
  })
export default i18next
