import React, { useEffect, useState } from 'react'
import { Editor as EditorWysiwyg } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './Editor.module.scss'
import usePrevious from '../../Infrastructure/Helpers/usePrevious'
import type { IItem } from '../../Items/IItem'
import type { ISellItem } from '../../SellView/ISellView'

interface EditorProps {
  item: IItem | ISellItem
  handleEditorChange: (editorState: string) => void
}

const Editor: React.FunctionComponent<EditorProps> = ({
  item,
  handleEditorChange,
}) => {
  let value = item.description === null ? '' : item.description
  const previousContent = usePrevious({ value })

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(value))
  )
  const [hasLoadedState, setHasLoadedState] = useState(false)

  const updateEditorState = (newEditorState: EditorState) => {
    let newEditorHtml: string = stateToHTML(newEditorState.getCurrentContent())
    const hasReachedMaxLength = newEditorHtml.length > 7000
    setEditorState(hasReachedMaxLength ? editorState : newEditorState)
    handleEditorChange(
      hasReachedMaxLength
        ? stateToHTML(editorState.getCurrentContent())
        : newEditorHtml
    )
  }

  useEffect(() => {
    const useWorkaroundToGetAroundReceivingStateAfterEditorInitialization =
      !hasLoadedState &&
      previousContent !== undefined &&
      (previousContent as any).value === '' &&
      value &&
      value.length > 0 &&
      value !== '<p><br></p>'
    if (useWorkaroundToGetAroundReceivingStateAfterEditorInitialization) {
      setHasLoadedState(true)
      setEditorState(EditorState.createWithContent(stateFromHTML(value)))
    }
  }, [previousContent, hasLoadedState, value])

  return (
    <EditorWysiwyg
      editorState={editorState}
      toolbar={{
        options: ['inline'],
        inline: {
          options: ['bold', 'italic', 'strikethrough'],
        },
        test: {
          component: <h1>hej</h1>,
        },
      }}
      onEditorStateChange={(editorState) => updateEditorState(editorState)}
      toolbarClassName={styles.editorToolbar}
      wrapperClassName="wrapperClassName"
      editorClassName={`${styles.editor} ${
        item.validationResult && !item.validationResult?.properties.description
          ? 'error-border'
          : ''
      }`}
    />
  )
}

export default Editor
