import { connect } from 'react-redux'
import { closeModal, minimize } from '../../UI/UI.actions'
import { createImportedList } from '../../List/List.actions'
import ImportMappingModal from './ImportMappingModal'
import { TabTypes } from '../../Content/IContent'

const connectedComponent = connect(
  (state: any) => {
    let openListId: string = ''
    if (state.ui.importToList) {
      for (const tabId in state.content.byId) {
        const tab = state.content.byId[tabId]
        if (tab.selected && tab.type === TabTypes.List) {
          openListId = tabId
        }
      }
    }
    return {
      user: state.user,
      importData: state.ui.importData,
      openListId,
    }
  },
  {
    createImportedList,
    closeModal,
    minimize,
  }
)(ImportMappingModal)

export default connectedComponent
