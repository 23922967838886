import React from 'react'
import { ItemType, type IItem } from '../../Items/IItem'
import { useTranslation } from 'react-i18next'

interface CreateItemFromImageProps {
  closeModal: () => void
  loading: boolean | undefined
  id: string
  imageUrls: string[]
  numberOfItems: number
  createItemsFromImage: (item: IItem) => void
}

const defaultItem: IItem = {
  listId: '',
  campaignCode: '',
  campaignCodeValid: false,
  title: '',
  isChecked: false,
  imageUrls: [],
  imageRotations: [],
  header: '',
  description: '',
  startingBid: 0,
  buyNowPrice: 0,
  reservationPrice: 0,
  isHighlighted: false,
  rankUp: false,
  reference: '',
  condition: '',
  uploadOrder: 0,
  latestObjectId: '',
  restarts: 0,
  acceptedBidders: 4,
  advertisementLengthInDays: 7,
  exposure: null,
  shippingAlternative: [],
  itemType: ItemType.Auction,
  chosenEndTimeHour: 0,
  chosenEndTimeMinute: 0,
  hasChosenEndTime: false,
  shippingCondition: '',
}

const CreateItemFromImage: React.FunctionComponent<
  CreateItemFromImageProps
> = ({
  closeModal,
  loading,
  id,
  imageUrls,
  numberOfItems,
  createItemsFromImage,
}) => {
  const { t } = useTranslation()
  const createItems = () => {
    let uploadOrder = numberOfItems + 1
    imageUrls.forEach((image) => {
      const images = [image]
      const newItem: IItem = {
        ...defaultItem,
        imageUrls: images,
        listId: id,
        uploadOrder: uploadOrder,
      }
      uploadOrder++
      createItemsFromImage(newItem)
    })
    closeModal()
  }

  const createOneItem = () => {
    let uploadOrder = numberOfItems + 1
    const newItem: IItem = {
      ...defaultItem,
      imageUrls: imageUrls.slice(0, 5),
      listId: id,
      uploadOrder: uploadOrder,
    }
    createItemsFromImage(newItem)
    closeModal()
  }

  return (
    <div>
      <div className="p-3">
        <p>
          {t('modal_createItemFromImage_info')} {imageUrls!.length}{' '}
          {t('modal_createItemFromImage_infoAppend')}?
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => createOneItem()}
            >
              {t('modal_createItemFromImage_button_createOne')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => createItems()}
            >
              {t('modal_createItemFromImage_button_createMultiple')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateItemFromImage
