import type { ISellItem } from '../SellView/ISellView'

export interface IList {
  id: string
  isChecked: boolean
  name: string
  numberOfitems: number
  lastOpened: Date
  lastChanged: Date
  createdDate: Date
  publicationDate?: Date
  startDate?: Date
  publicationResult?: string
  itemIds: string[]
  items: string[]
  columnOrder: string[]
  message?: any
  hasBeenExported: boolean
  publishing: boolean
  publishStarted?: Date
}

export interface IPublishViewModel {
  listId: string
  selectedItems: string[]
  uploadInterval: number
  uploadDateTime: string
  sellItems?: ISellItem[]
}

export interface IImportViewModel {
  listId?: string
  items: any[]
}

export enum PublishExceptionCode {
  Unknown = 'Unknown',
  BankIdVerificationNeeded = 'BankIdVerificationNeeded',
  UserCancelled = 'UserCancelled',
  PublishLocked = 'PublishLocked',
}

export interface IPublishException {
  errorCode: PublishExceptionCode
  listId: string
  selectedItems?: string[]
  uploadInterval: number
  uploadDateTime: string
  personalNumber?: string
  message?: string
  sellItems?: ISellItem[]
}
