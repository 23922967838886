import type { IValidationResult } from '../Infrastructure/Helpers/validation'
import type { IShippingAlternative } from './ItemForm/ShippingOptions/IShipping'

export interface IItem {
  id?: string
  listId: string
  isChecked: boolean
  imageUrls: string[]
  imageRotations?: string[]
  title: string
  header: string
  description: string
  category?: ICategory
  startingBid?: number
  buyNowPrice?: number
  reservationPrice?: number
  isHighlighted: boolean
  rankUp: boolean
  reference: string
  latestUploadDate?: Date | string
  condition: string
  uploadOrder: number
  chosenEndTime?: Date | string
  chosenEndTimeHour: number
  chosenEndTimeMinute: number
  hasChosenEndTime: boolean
  latestObjectId?: string
  restarts: 0 | 2
  acceptedBidders: ISellingLocation
  advertisementLengthInDays?: number
  exposure?: any
  shippingAlternative: IShippingAlternative[]
  campaignCode: string
  campaignCodeValid: boolean
  itemType: ItemType
  validationResult?: IValidationResult
  attributes?: IAttribute[]
  publishResult?: String
  shippingCondition: string
  vat?: number
  quantity?: number
}

export interface IMoveParams {
  fromListId: string
  toListId: string
  itemIds: string[]
}

export enum ISellingLocation {
  Sverige = 1,
  HelaVärlden = 3,
  EU = 4,
}

export interface IPriceSummary {
  name: string
  amount: number
  indicator?: string
  isPrice: boolean
}

export interface ICategory {
  id: string
  name: string
  category?: ICategory[]
  isLastCategoryLevel: boolean
}

export interface IAttribute {
  id: number
  name: string
  key: string
  description: string
  minNumberOfValues: number
  maxNumberOfValues: number
  possibleTermValues: string[]
}

export interface IAttributesState {
  [categoryId: string]: IAttribute[]
}

export interface IImageRotation {
  fileName: string
  rotation: number
}

export interface IImageVisibility {
  url: string
  visible: boolean
}

export interface IFileUploadImage {
  url: string
  file?: File | null
  rotation: number
  visible: boolean
  ref: any
  error?: boolean | undefined
  errorChecked?: boolean | undefined
}

export enum ITextToChage {
  Title = 'Title',
  Description = 'Description',
  ShippingCondition = 'ShippingCondition',
}

export enum ITextPosition {
  Last = 'Last',
  First = 'First',
}

export interface IItemClickOrder {
  itemId?: string
  selectTime: Date | string
  listId: string
}

export enum ItemType {
  Auction = 'Auction',
  FixedPrice = 'FixedPrice',
  ShopItem = 'ShopItem',
}

export interface IItemChanges {
  listId: string
  itemIds: string[]

  changeText: boolean
  changeAddInText: boolean
  textToChange?: ITextToChage
  textPosition?: ITextPosition
  addText: string
  changeReplaceText: boolean
  replaceText: string
  newText: string

  changeCategory: boolean
  category?: ICategory
  attributes?: IAttribute[]

  changeCondition: boolean
  condition: string

  changeTypeAndPrice: boolean
  itemType: ItemType
  changeStartingBid: boolean
  startingBid?: number
  changeShipping: boolean
  changeBuyNowPrice: boolean
  buyNowPrice?: number
  changeReservationPrice: boolean
  reservationPrice?: number
  changeVat: boolean
  vat?: number
  changeQuantity: boolean
  quantity?: number

  changeAdvertismentLength: boolean
  advertisementLengthInDays?: number
  chosenEndTimeHour: number
  chosenEndTimeMinute: number
  hasChosenEndTime: boolean

  changeRestart: boolean
  restarts: number

  shippingAlternative: IShippingAlternative[]

  changeShippingFirst: false
  shippingFirst: IShippingAlternative | undefined

  changeShippingSecond: false
  shippingSecond: IShippingAlternative | undefined

  changeShippingTakeaway: false
  shippingTakeaway: false

  changeExtraAdditions: boolean
  isHighlighted: boolean
  rankUp: boolean

  changeAcceptedBidders: boolean
  acceptedBidders: ISellingLocation

  changeShippingCondition: boolean
  shippingCondition: string
}
