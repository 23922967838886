import React, { useState, useRef, useEffect } from 'react'
import styles from './Toolbar.module.scss'
import type { IItem, IPriceSummary } from '../../Items/IItem'
import { type ITab } from '../../Content/IContent'
import type { IList } from '../IList'
import { ReactComponent as DotsIcon } from '../../Icons/dots.svg'
import { ReactComponent as Spinner } from '../../Icons/loading.svg'
import { ReactComponent as CheckMark } from '../../Icons/ic_check.svg'
import type { ItemListMenuItemProps } from '../../Components/ListMenuItem/ListMenuItem'
import ItemListMenuItem from '../../Components/ListMenuItem/ListMenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { getItems } from '../List.selector'
import {
  openAlertContainer,
  openPublishModal,
  openPublishSelectedModal,
  openPublishValidModal,
} from '../../UI/UI.actions'
import PriceSummary from '../../Components/PriceSummary'
import { getItemsOptionalPrice } from '../../Infrastructure/Helpers/priceCalculation'
import { isBefore, addMinutes, isAfter } from 'date-fns'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'

interface ToolbarProps {
  list: IList
  selectedContent: ITab
  selectedItems: string[]
  saving: boolean
  actions: ItemListMenuItemProps[]
}

const getSummaryItems = (items: IItem[], t: TFunction) => {
  return [
    {
      name: t('list_toolbar_summaryItems_ads'),
      amount: items.length,
      indicator: 'st',
      isPrice: false,
    },
    {
      name: t('list_toolbar_summaryItems_extraAdditions'),
      amount: getItemsOptionalPrice(items),
      isPrice: true,
    },
  ]
}

const Toolbar: React.FC<ToolbarProps> = ({
  list,
  saving,
  selectedContent,
  selectedItems,
  actions,
}) => {
  const { t } = useTranslation()
  const [showMenuActions, setShowMenuActions] = useState<boolean>(false)
  const [wasButtonClicked, setWasButtonClicked] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const toolbarRef = useRef<HTMLDivElement>(null)
  const toggleButtonRef = useRef(null)
  const dispatch = useDispatch()

  const items: IItem[] = useSelector((state) =>
    getItems(state, list?.id, selectedContent)
  )

  const summaryItems: IPriceSummary[] = getSummaryItems(items, t)
  const [highlightSummary, setHighlightSummary] = useState<boolean>(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wasButtonClicked) {
        setWasButtonClicked(false)
        return
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenuActions(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wasButtonClicked])

  const onClickedPublish = (selectedListId: string) => {
    const listIsValid = !items?.some((x) => !x.validationResult?.isValid)
    const validItems = items?.filter((x) => x.validationResult?.isValid)
    if (items.length === 0) {
      dispatch(openAlertContainer(t('alert_emptyList'), 'error'))
    } else if (validItems.length === 0) {
      dispatch(openAlertContainer(t('alert_unvalidList'), 'error'))
    } else if (selectedItems.length > 0) {
      dispatch(openPublishSelectedModal(selectedListId))
    } else if (!listIsValid) {
      dispatch(openPublishValidModal(selectedListId))
    } else {
      dispatch(openPublishModal(selectedListId, []))
    }
  }

  return (
    <div className={styles.container}>
      <div ref={toolbarRef} className={styles.toolbarContainer}>
        {actions.map((action, index) => (
          <ItemListMenuItem
            key={action.name}
            className={
              index < 2
                ? `${styles.buttonAction} primary ${styles.primaryAction}`
                : styles.buttonAction
            }
            testId={action.testId}
            name={action.name}
            icon={action.icon}
            action={action.action}
            isDisabled={action.isDisabled}
            tooltip={index < 2 ? undefined : action.tooltip}
          />
        ))}
        <div className={styles.menuContainer}>
          <button
            ref={toggleButtonRef}
            aria-haspopup="true"
            aria-controls="menuDropdown"
            className={styles.menuButton}
            onClick={() => {
              setWasButtonClicked(true)
              setShowMenuActions(!showMenuActions)
            }}
          >
            <DotsIcon width={21} height={21} />
          </button>
          {showMenuActions && (
            <div
              ref={dropdownRef}
              className={styles.dropdown}
              id="menuDropdown"
            >
              <ul>
                {actions.slice(2).map((action) => (
                  <li key={action.name}>
                    <button
                      className={`${
                        action.isDisabled ? styles.isDisabled : ''
                      }`}
                      disabled={action.isDisabled}
                      onClick={() => {
                        action.action && action.action()
                        setShowMenuActions(false) // Close the dropdown
                      }}
                    >
                      <span>{action.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={styles.spinnerContainer}>
        {saving ? (
          <div>
            <Spinner className={styles.iconSpin} />
            <span className={styles.savingText}>
              {t('list_toolbar_saving')}...
            </span>
          </div>
        ) : (
          <div>
            <CheckMark width={14} height={14} />
            <span className={styles.savingText}>{t('list_toolbar_saved')}</span>
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>
        <PriceSummary highlight={highlightSummary} items={summaryItems} />
        <div className={styles.iconColor}>
          <button
            className={styles.primaryButton}
            disabled={
              list.publishing &&
              isAfter(
                addMinutes(new Date(list?.publishStarted!), 30),
                new Date()
              )
            }
            onClick={() => {
              onClickedPublish(list.id)
            }}
          >
            {!list.publishing ||
            isBefore(
              addMinutes(new Date(list?.publishStarted!), 30),
              new Date()
            ) ? (
              <>
                {!list.publicationDate ? (
                  <>{t('list_toolbar_publish')}</>
                ) : (
                  <>{t('list_toolbar_publishAgain')}</>
                )}
              </>
            ) : (
              <>
                <Spinner className={styles.iconSpin} />{' '}
                {t('list_toolbar_publishing')}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Toolbar
