import React from 'react'
import styles from './Footer.module.scss'
import { ReactComponent as Logo } from '../../Icons/round-logo.svg'
import { useTranslation } from 'react-i18next'

interface FooterProps {
  position: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
}

const Footer = ({ position }: FooterProps) => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  const wrapperStyles: React.CSSProperties = {
    position: position,
  }
  return (
    <footer className={styles.footer} style={wrapperStyles}>
      <div className="row d-flex w-100 justify-content-center align-items-center m-5">
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-2">
              <div className={styles.footerItem}>© {year} Tradera</div>
            </div>
            <div className={`${styles.footerItem} col-lg-2`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://info.tradera.com/anvandaravtal/"
              >
                {t('footer_terms')}
              </a>
            </div>
            <div className={`${styles.footerItem} col-lg-2`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://info.tradera.com/sakerhet/integritetspolicy/"
              >
                {t('footer_policy')}
              </a>
            </div>
            <div className={`${styles.footerItem} col-lg-2`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://info.tradera.com/cookies-webb-beacons-och-liknande-teknik/"
              >
                {t('footer_cookies')}
              </a>
            </div>
            <div className={`${styles.footerItem} col-lg-2`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tradera.com/info/om-oss"
              >
                {t('footer_about')}
              </a>
            </div>
            <div className={`${styles.footerItem} col-lg-2`}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://careers.tradera.com/"
              >
                {t('footer_job')}
              </a>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
      <Logo
        className={position === 'fixed' ? styles.logo : styles.staticLogo}
      />
    </footer>
  )
}

export default Footer
