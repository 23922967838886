import { SHIPPING_TAKEAWAY } from '../../Infrastructure/Helpers/shippingHelper'
import type { ICellRendererParams } from 'ag-grid-community'

const ShippingCostRenderer = (props: ICellRendererParams) => {
  const shippingAlternatives = props.data?.shippingAlternative?.filter(
    (x) => x.id !== SHIPPING_TAKEAWAY
  )

  const shipping =
    props.colDef?.field === 'shippingCostFirst'
      ? shippingAlternatives[0]
      : props.colDef?.field === 'shippingCostSecond'
      ? shippingAlternatives[1]
      : undefined

  if (!shipping || shipping.price === undefined || shipping.price === null)
    return

  return <span>{shipping.price + ' kr'}</span>
}

export default ShippingCostRenderer
