import { saveItemWip } from '../Items/Items.action'
import type { RootState } from '../Store/rootReducer'

const itemMiddleware = () => (store) => (next) => async (action) => {
  if (
    action.type === saveItemWip.toString() &&
    action.payload.id === undefined
  ) {
    const state: RootState = store.getState()

    let temporaryId = ''
    for (const id in state.content.byId) {
      if (id.startsWith('newitem')) {
        temporaryId = id
      }
    }

    action.payload.id = temporaryId
  }

  return next(action)
}

export default itemMiddleware
