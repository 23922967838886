import { connect } from 'react-redux'
import { closeModal, openEditCategoryModal } from '../../UI/UI.actions'
import { updateItem, getItem } from '../../Items/Items.action'
import EditCategoryModal from './EditCategoryModal'
import { TabTypes } from '../../Content/IContent'

const connectedComponent = connect(
  (state: any) => {
    let openListId: string = ''
    for (const tabId in state.content.byId) {
      const tab = state.content.byId[tabId]
      if (tab.selected && tab.type === TabTypes.List) {
        openListId = tabId
      }
    }

    return {
      loading: state.ui.loading,
      modalType: state.ui.modalType,
      id: openListId,
      itemToUpdate: state.ui.id,
    }
  },
  {
    closeModal,
    getItem,
    updateItem,
    openEditCategoryModal,
  }
)(EditCategoryModal)

export default connectedComponent
