import { ItemType, type IItem } from '../../Items/IItem'
import type { ISellItem } from '../../SellView/ISellView'

export interface IValidationResult {
  isValid: boolean
  id: string
  properties: { [key: string]: boolean }
}

export const validateItem = (item: IItem | ISellItem) => {
  const newItem = item.id && !item.id.startsWith('newitem') ? false : true

  const isAuction = item.itemType === ItemType.Auction
  const isFixedPrice = item.itemType === ItemType.FixedPrice
  const isShopItem = item.itemType === ItemType.ShopItem

  const validationResult: IValidationResult = {
    isValid: true,
    id: item.id!,
    properties: {},
  }

  const validateAttribute = (item: IItem | ISellItem) => {
    let validationResult = true
    let attributeList = item.attributes ? item.attributes : []
    if (attributeList.length === 0) {
      return validationResult
    }
    attributeList.forEach((attributeType) => {
      if (
        attributeType.minNumberOfValues >
        attributeType.possibleTermValues.length
      ) {
        validationResult = false
      }
    })
    return validationResult
  }

  const validateCategory = (item: IItem | ISellItem) => {
    const firstCategory = item.category ? item.category : null
    if (firstCategory === null) {
      return false
    }

    const secondCategory = firstCategory!.category
      ? firstCategory!.category[0]
      : null
    const thirdCategory =
      secondCategory && secondCategory.category
        ? secondCategory.category[0]
        : null
    const fourthCategory =
      thirdCategory && thirdCategory.category ? thirdCategory.category[0] : null

    if (
      firstCategory?.isLastCategoryLevel === true ||
      secondCategory?.isLastCategoryLevel === true ||
      thirdCategory?.isLastCategoryLevel === true ||
      fourthCategory?.isLastCategoryLevel === true
    ) {
      return true
    }
    return false
  }

  const validateCampaign = (item: IItem | ISellItem) => {
    if (item.campaignCode && item.campaignCode.length > 0) {
      return item.campaignCodeValid
    } else {
      return true
    }
  }

  //TODO! Solve this in a more elegant way. New and existing ads need different validation rules.
  if (newItem) {
    validationResult.properties['header'] = item.header
      ? item.header.length <= 80
      : true
    validationResult.properties['reference'] = item.reference
      ? item.reference.length <= 80
      : true
    validationResult.properties['shippingCondition'] = item.shippingCondition
      ? item.shippingCondition.length <= 500
      : true
    validationResult.properties['condition'] = true
    validationResult.properties['description'] = item.description
      ? item.description.length <= 7000
      : true
    validationResult.properties['startingBid'] = true
    if (
      isAuction &&
      item.startingBid! > 0 &&
      item.buyNowPrice !== null &&
      item.buyNowPrice! > 0 &&
      item.buyNowPrice! < item.startingBid!
    ) {
      validationResult.properties['buyNowPrice'] = false
    } else {
      validationResult.properties['buyNowPrice'] = true
    }
    if (isShopItem) {
      validationResult.properties['buyNowPrice'] = true
    }
    if (
      isAuction &&
      item.reservationPrice !== null &&
      item.startingBid! > 0 &&
      item.reservationPrice! > 0 &&
      item.reservationPrice! < item.startingBid!
    ) {
      validationResult.properties['reservationPrice'] = false
    } else {
      validationResult.properties['reservationPrice'] = true
    }
    validationResult.properties['shippingAlternative'] = true
    validationResult.properties['itemType'] =
      isAuction || isFixedPrice || isShopItem
    validationResult.properties['category'] = true
    validationResult.properties['attributes'] = true
    validationResult.properties['restarts'] = item.restarts <= 2
    validationResult.properties['campaignCode'] = true
    validationResult.properties['imageUrls'] = true
    validationResult.properties['quantity'] = true
  } else {
    validationResult.properties['header'] =
      item.header !== null &&
      item.header !== undefined &&
      item.header.length > 0 &&
      item.header.length <= 80
    validationResult.properties['description'] =
      item.description !== null &&
      item.description !== '' &&
      item.description !== undefined &&
      item.description.length <= 7000
    validationResult.properties['condition'] =
      item.condition === 'Used' || item.condition === 'Mint'
    validationResult.properties['imageUrls'] =
      item.imageUrls !== null &&
      item.imageUrls !== undefined &&
      (item.imageUrls.length > 0 ||
        (item.imageRotations !== undefined
          ? item.imageRotations?.length > 0
          : false))
    validationResult.properties['startingBid'] =
      item.startingBid !== 0 || isFixedPrice || isShopItem
    validationResult.properties['buyNowPrice'] =
      (isFixedPrice && item.buyNowPrice! > 0) ||
      (isAuction &&
        (item.buyNowPrice! === 0 ||
          item.buyNowPrice === null ||
          (item.buyNowPrice !== null && item.buyNowPrice!.toString() === '') ||
          item.buyNowPrice! > item.startingBid!)) ||
      (isShopItem && item.buyNowPrice! > 0)
    if (
      isAuction &&
      item.reservationPrice !== null &&
      item.startingBid! > 0 &&
      item.reservationPrice! > 0 &&
      item.reservationPrice! < item.startingBid!
    ) {
      validationResult.properties['reservationPrice'] = false
    } else {
      validationResult.properties['reservationPrice'] = true
    }

    validationResult.properties['shippingAlternative'] =
      item.shippingAlternative.length > 0 &&
      item.shippingAlternative.some((shipping) => shipping.id !== 0)
    validationResult.properties['itemType'] =
      isAuction || isFixedPrice || isShopItem
    validationResult.properties['category'] = validateCategory(item)
    validationResult.properties['attributes'] = validateAttribute(item)
    validationResult.properties['restarts'] = item.restarts <= 2
    validationResult.properties['campaignCode'] = validateCampaign(item)
    validationResult.properties['reference'] = item.reference
      ? item.reference.length <= 80
      : true
    validationResult.properties['shippingCondition'] = item.shippingCondition
      ? item.shippingCondition.length <= 500
      : true
    if (isShopItem && item.quantity! === 0) {
      validationResult.properties['quantity'] = false
    } else {
      validationResult.properties['quantity'] = true
    }
  }

  for (const property in validationResult.properties) {
    if (!validationResult.properties[property]) {
      validationResult.isValid = false
    }
  }

  return validationResult
}
