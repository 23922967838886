import type { SellListType } from '../SellView/ISellView'

export enum TabTypes {
  MyList,
  List,
  Item,
  Template,
  MyTemplates,
  TemplateItem,
  SellView,
}

export interface ITab {
  type: TabTypes
  id: string
  selected: boolean
  name: string
  sellViewType?: SellListType
}
