import React, { useState } from 'react'

interface CheckBoxProps {
  label: string
  checked?: boolean
  value?: string | number
  handleInputChange: (
    property: string,
    value: string | number | boolean
  ) => void
  name: string
  paddingless?: boolean
  spacingLeft?: boolean
  disable?: boolean
  styleGrid?: boolean
  testId?: string
}

const CheckBox: React.FunctionComponent<CheckBoxProps> = ({
  label,
  checked,
  handleInputChange,
  name,
  paddingless,
  spacingLeft,
  disable,
  styleGrid,
  testId,
}) => {
  const [isChecked, setIsChecked] = useState(checked ? checked : false)
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked)
    handleInputChange(name, e.target.checked)
  }
  return (
    <div
      className={`form-group form-check ${styleGrid ? 'p-2' : 'mt-1'} ${
        paddingless ? 'p-0 m-0' : ''
      } ${spacingLeft ? 'ml-2' : ''}`}
    >
      <input
        checked={isChecked}
        name={name}
        onChange={(e) => handleCheckboxChange(e)}
        disabled={disable}
        data-testid={testId ? testId : ''}
        type="checkbox"
        className="form-check-input"
        id={name}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default CheckBox
