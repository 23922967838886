import { ReactComponent as Spinner } from '@tradera/blueprint/theme/icons2/loading.svg'
import { useTranslation } from 'react-i18next'

interface ImportingListProps {
  closeModal: () => void
  minimize: () => void
  loading: boolean | undefined
}

const ImportingPlzipListModal: React.FunctionComponent<ImportingListProps> = ({
  closeModal,
  minimize,
  loading,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="d-flex justify-content-center align-items-center p-3">
          {loading ? (
            <div className="text-center">
              <Spinner className="icon icon-lg iconSpin" />
              <p>{t('modal_importPlzip_loader')}...</p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <p>{t('modal_importPlzip_info')}</p>
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_importPlzip_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => minimize()}
            >
              {t('modal_importPlzip_button_minimize')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportingPlzipListModal
