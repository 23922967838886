import type { Dispatch } from '@reduxjs/toolkit'
import { createActions } from 'redux-actions'
import { get, post, deleteRequest, put } from '../Infrastructure/Network'
import { TabTypes } from '../Content/IContent'
import { openContent, closeTab } from '../Content/Content.actions'
import type {
  IList,
  IImportViewModel,
  IPublishViewModel,
  IPublishException,
} from './IList'
import { PublishExceptionCode } from './IList'
import type { IItem } from '../Items/IItem'
import type { IItemChanges } from '../Items/IItem'
import {
  closeModal,
  openPublishErrorMessageModal,
  openPublicationResultModal,
  openPublishModal,
  openPublishProgressModal,
  isPending,
  isCompleted,
  openImportingModal,
  openImportingPlzipModal,
  closeMinimizeModal,
  openAlertContainer,
  bankIdIsRequired,
  bankIdVerificationEnd,
  setImportData,
  setImportToList,
  setUploadInterval,
  setLoadingLists,
  setUploadDateTime,
  setUploadItems,
} from '../UI/UI.actions'
import loggingService from '../Infrastructure/Tracking/loggingService'

export const { getListsRequested, getListsSucceeded, getListsRejected } =
  createActions({
    getListsSucceeded: (lists: IList[]) => lists,
    getListsRequested: () => ({}),
    getListsRejected: (error: any) => ({}),
  })

export const onGetListsInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(true))
  }

export const onGetListsSucceeded =
  (lists: IList[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
    dispatch(getListsSucceeded(lists))
  }

export const onGetListsRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
  }

export const getLists = () =>
  get({
    url: `/list/lists`,
    initiated: () => onGetListsInitiated(),
    fulfilled: (lists: IList[]) => onGetListsSucceeded(lists),
    rejected: (error: any) => onGetListsRejected(error),
  })

export const { createListRequested, createListSucceeded, createListRejected } =
  createActions({
    createListRequested: () => ({}),
    createListSucceeded: (list: IList) => list,
    createListRejected: (_error: any) => ({}),
  })

export const onCreateListSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(createListSucceeded(list))
    dispatch(
      openContent(
        {
          type: TabTypes.List,
          id: list.id,
          selected: true,
          name: list.name,
        },
        list.name
      )
    )
  }

export const createList = () =>
  post({
    url: '/list',
    initiated: createListRequested,
    fulfilled: (list: IList) => onCreateListSucceeded(list),
    rejected: createListRejected,
  })

export const {
  createImportedListRequested,
  createImportedListSucceeded,
  createImportedListRejected,
} = createActions({
  createImportedListRequested: () => ({}),
  createImportedListSucceeded: (list: IList) => list,
  createImportedListRejected: () => ({}),
})

export const onCreateImportedListInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(isPending())
    dispatch(openImportingModal())
    dispatch(createImportedListRequested)
  }

export const onCreateImportedListSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted)
    dispatch(closeModal())
    dispatch(setImportData(null))
    dispatch(setImportToList(false))
    dispatch(
      openAlertContainer(
        'Listan importerades! Importen godkänner endast korrekt formattering, kontrolla därför gärna listan.',
        'info'
      )
    )
    dispatch(closeMinimizeModal())
    dispatch(createImportedListSucceeded(list))
    dispatch(
      openContent(
        {
          type: TabTypes.List,
          id: list.id,
          selected: true,
          name: list.name,
        },
        list.name
      )
    )
  }

export const onCreateImportedListRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted)
    dispatch(createImportedListRejected)
    dispatch(openAlertContainer('Import av listan misslyckades', 'error'))
    dispatch(setImportData(null))
    dispatch(setImportToList(false))
    dispatch(closeModal())
  }

export const createImportedList = (viewModel: IImportViewModel) =>
  post({
    url: `/list/CreateImportedList`,
    body: viewModel,
    initiated: () => onCreateImportedListInitiated(),
    fulfilled: (list: IList) => onCreateImportedListSucceeded(list),
    rejected: (error: any) => onCreateImportedListRejected(error),
  })

export const {
  getListByIdRequested,
  getListByIdSucceeded,
  getListByIdRejected,
} = createActions({
  getListByIdRequested: () => ({}),
  getListByIdSucceeded: (list: IList) => list,
  getListByIdRejected: (_error: any) => _error,
})

export const getListById = (id: string) =>
  get({
    url: `/list/${id}`,
    initiated: getListByIdRequested,
    fulfilled: getListByIdSucceeded,
    rejected: getListByIdRejected,
  })

export const {
  duplicateListRequested,
  duplicateListSucceeded,
  duplicateListRejected,
} = createActions({
  duplicateListRequested: () => ({}),
  duplicateListSucceeded: (list: IList) => list,
  duplicateListRejected: () => ({}),
})

export const duplicateList = (id: string) =>
  post({
    url: `/list/duplicate/${id}`,
    initiated: duplicateListRequested,
    fulfilled: (list: IList) => duplicateListSucceeded(list),
    rejected: duplicateListRejected,
  })

export const { deleteListRequested, deleteListSucceeded, deleteListRejected } =
  createActions({
    deleteListRequested: () => ({}),
    deleteListSucceeded: (listIds: string[]) => listIds,
  })

export const onDeleteListSucceeded =
  (ids: string[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(deleteListSucceeded(ids))
    dispatch(closeModal())
  }

export const deleteList = (ids: string[]) =>
  deleteRequest({
    url: `/list?${ids.map((id) => `listids=${id}`).join('&')}`,
    initiated: deleteListRequested,
    fulfilled: () => onDeleteListSucceeded(ids),
    rejected: duplicateListRejected,
  })

export const {
  createListFromTemplateRequested,
  createListFromTemplateSucceeded,
  createListFromTemplateRejected,
} = createActions({
  createListFromTemplateRequested: () => ({}),
  createListFromTemplateSucceeded: (list: IList) => list,
  createListFromTemplateRejected: () => ({}),
})

export const createListFromTemplate = (templateId: string) =>
  post({
    url: `/list/template/${templateId}`,
    initiated: createListFromTemplateRequested,
    fulfilled: (list: IList) => createListFromTemplateSucceeded(list),
    rejected: createListFromTemplateRejected,
  })

export const { updateList } = createActions({
  updateList: (list: IList) => list,
})

export const {
  updateListByIdRequested,
  updateListByIdSucceeded,
  updateListByIdRejected,
} = createActions({
  updateListByIdRequested: () => ({}),
  updateListByIdSucceeded: (list: IList) => list,
  updateListByIdRejected: () => ({}),
})

export const updateListById = (list: IList) =>
  put({
    url: `/list/${list.id}`,
    initiated: updateListByIdRequested,
    fulfilled: () => updateListByIdSucceeded(list),
    rejected: updateListByIdRejected,
    body: list,
  })

export const {
  importListFromPlZipRequested,
  importListFromPlZipSucceeded,
  importListFromPlZipRejected,
} = createActions({
  importListFromPlZipRequested: () => ({}),
  importListFromPlZipSucceeded: (list: IList) => list,
  importListFromPlZipRejected: () => ({}),
})

export const onImportListSucceded =
  (list: IList, listId: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted)
    dispatch(importListFromPlZipSucceeded(list))
    dispatch(closeModal())
    if (listId) {
      dispatch(
        openAlertContainer(
          'Listan har blivit ihopslagen. Obs! Bilder som innehåller Å, Ä, Ö går ej att importera.',
          'success'
        )
      )
    } else {
      dispatch(
        openAlertContainer(
          'Listan har blivit importerad. Obs! Bilder som innehåller Å, Ä, Ö går ej att importera.',
          'success'
        )
      )
    }
    dispatch(closeMinimizeModal())
    dispatch(
      openContent(
        {
          type: TabTypes.List,
          id: list.id,
          selected: true,
          name: list.name,
        },
        list.name
      )
    )
  }
export const onImportListInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isPending())
    dispatch(openImportingPlzipModal())
    dispatch(importListFromPlZipRequested)
  }

export const onImportListRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    if (error > 400 && error < 600) {
      dispatch(isCompleted)
      dispatch(importListFromPlZipRejected)
      dispatch(openAlertContainer('Import av listan misslyckades', 'error'))
      dispatch(closeModal())
    } else {
      dispatch(closeModal())
    }
  }

export const importListFromPlZip = (file: any, listId: string = '') => {
  let formData = new FormData()
  formData.append('files', file)
  if (listId) {
    formData.append('listId', listId)
  }

  return post({
    url: `/list/import`,
    body: formData,
    multipart: true,
    initiated: () => onImportListInitiated(),
    fulfilled: (list: IList) => onImportListSucceded(list, listId),
    rejected: (error: number) => onImportListRejected(error),
  })
}

export const {
  updateListNameRequested,
  updateListNameSucceeded,
  updateListNameRejected,
} = createActions({
  updateListNameRequested: () => ({}),
  updateListNameSucceeded: (id, name) => ({ id, name }),
  updateListNameRejected: () => ({}),
})

export const updateListName = (listId: string, name: string) =>
  put({
    url: `/list/UpdateName/${listId}`,
    initiated: updateListNameRequested,
    fulfilled: () => updateListNameSucceeded(listId, name),
    rejected: updateListNameRejected,
    body: name,
  })

export const {
  publishListRequested,
  publishListSucceeded,
  publishListRejected,
} = createActions({
  publishListRequested: () => ({ pending: true }),
  publishListSucceeded: (list) => list,
  publishListRejected: (error, listId, bankId) => ({ error, listId, bankId }),
})

export const onPublishListSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeTab(list.id))
    dispatch(publishListSucceeded(list))
    dispatch(closeModal())
    let numberOfFailedItems = 0
    if (
      list.publicationResult &&
      list.publicationResult.toLowerCase() === 'failed'
    ) {
      numberOfFailedItems = list.items
        .map((i: any) => i.publishResult !== 'Ok')
        .filter((x) => x).length
    }
    dispatch(
      bankIdVerificationEnd({ bankIdSuccess: null, bankIdRequired: null })
    )
    dispatch(setUploadInterval(undefined))
    dispatch(setUploadDateTime(undefined))
    dispatch(setUploadItems([]))
    dispatch(openPublicationResultModal(numberOfFailedItems))
    dispatch(openAlertContainer('Publicering lyckades', 'success'))
    dispatch(
      openContent(
        {
          type: TabTypes.List,
          id: list.id,
          selected: true,
          name: list.name,
        },
        list.name
      )
    )
  }

export const onPublishListRejected =
  (error: any, publishException: IPublishException) =>
  (dispatch: Dispatch<any>): void => {
    if (
      publishException.errorCode ===
      PublishExceptionCode.BankIdVerificationNeeded
    ) {
      dispatch(
        bankIdIsRequired(
          true,
          publishException.listId,
          publishException.personalNumber
        )
      )
      dispatch(closeModal())
      dispatch(setUploadInterval(publishException.uploadInterval))
      dispatch(setUploadDateTime(publishException.uploadDateTime))
      dispatch(setUploadItems(publishException.selectedItems))
      dispatch(openPublishModal(publishException.listId, []))
    } else if (
      publishException.errorCode === PublishExceptionCode.UserCancelled
    ) {
      dispatch(closeModal())
      dispatch(resetCancelPublishList(publishException.listId))
      dispatch(openAlertContainer('Publicering avbruten', 'error'))
    } else if (
      publishException.errorCode === PublishExceptionCode.PublishLocked
    ) {
      dispatch(
        openAlertContainer('Publicering av denna lista pågår redan', 'error')
      )
    } else {
      loggingService.logWarning(['Publish error', publishException])
      dispatch(publishListRejected(error, publishException))
      dispatch(closeModal())
      dispatch(openPublishErrorMessageModal(publishException.message))
    }
  }

export const onPublishListInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(openPublishProgressModal())
    dispatch(publishListRequested)
  }

export const publishList = (viewModel: IPublishViewModel) =>
  post({
    url: `/list/PublishList`,
    body: viewModel,
    initiated: () => onPublishListInitiated(),
    fulfilled: (list: IList) => onPublishListSucceeded(list),
    rejected: (error: number, errorMessage: any) =>
      onPublishListRejected(error, errorMessage),
  })

export const {
  cancelPublishListRequested,
  cancelPublishListSucceeded,
  cancelPublishListRejected,
} = createActions({
  cancelPublishListRequested: () => ({}),
  cancelPublishListSucceeded: (list) => list,
  cancelPublishListRejected: () => ({}),
})

export const onCancelPublishListSucceded =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isCompleted)
    dispatch(closeModal())
    dispatch(openAlertContainer('Avbrytande av publicering lyckades', 'succes'))
  }

export const onCancelPublishListRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    if (error > 400 && error < 600) {
      dispatch(isCompleted)
      dispatch(closeModal())
      dispatch(
        openAlertContainer('Avbrytande av publicering misslyckades', 'error')
      )
    }
  }

export const cancelPublishList = () =>
  post({
    url: `/list/cancelPublish`,
    initiated: cancelPublishListRequested,
    fulfilled: () => onCancelPublishListSucceded(),
    rejected: (error: number) => onCancelPublishListRejected(error),
  })

export const {
  resetCancelPublishListRequested,
  resetCancelPublishListSucceeded,
  resetCancelPublishListRejected,
} = createActions({
  resetCancelPublishListRequested: () => ({}),
  resetCancelPublishListSucceeded: (list) => list,
  resetCancelPublishListRejected: () => ({}),
})

export const resetCancelPublishList = (listId: string) =>
  post({
    url: `/list/resetcancelPublish/${listId}`,
    initiated: resetCancelPublishListRequested,
    fulfilled: (list: IList) => resetCancelPublishListSucceeded(list),
    rejected: (error: number) => resetCancelPublishListRejected(error),
  })

export const {
  moveUpItemInListRequested,
  moveUpItemInListSucceeded,
  moveUpItemInListRejected,
} = createActions({
  moveUpItemInListRequested: () => ({}),
  moveUpItemInListSucceeded: (list) => list,
  moveUpItemInListRejected: () => ({}),
})

export const moveUpItem = (listId: string, itemId: string) =>
  post({
    url: `/list/moveup/${listId}`,
    body: itemId.toString(),
    initiated: moveUpItemInListRequested,
    fulfilled: (list: IList) => moveUpItemInListSucceeded(list),
    rejected: moveUpItemInListRejected,
  })
export const moveUpItems = (listId: string, itemIds: string[]) =>
  post({
    url: `/list/movemanyup/${listId}`,
    body: itemIds.toString(),
    initiated: moveUpItemInListRequested,
    fulfilled: (list: IList) => moveUpItemInListSucceeded(list),
    rejected: moveUpItemInListRejected,
  })

export const {
  moveDownItemInListRequested,
  moveDownItemInListSucceeded,
  moveDownItemInListRejected,
} = createActions({
  moveDownItemInListRequested: () => ({}),
  moveDownItemInListSucceeded: (list) => list,
  moveDownItemInListRejected: () => ({}),
})

export const moveDownItem = (listId: string, itemId: string) =>
  post({
    url: `/list/movedown/${listId}`,
    body: itemId.toString(),
    initiated: moveDownItemInListRequested,
    fulfilled: (list: IList) => moveDownItemInListSucceeded(list),
    rejected: moveDownItemInListRejected,
  })

export const moveDownItems = (listId: string, itemIds: string[]) =>
  post({
    url: `/list/movemanydown/${listId}`,
    body: itemIds.toString(),
    initiated: moveDownItemInListRequested,
    fulfilled: (list: IList) => moveDownItemInListSucceeded(list),
    rejected: moveDownItemInListRejected,
  })

export const {
  moveTopItemInListRequested,
  moveTopItemInListSucceeded,
  moveTopItemInListRejected,
} = createActions({
  moveTopItemInListRequested: () => ({}),
  moveTopItemInListSucceeded: (list) => list,
  moveTopItemInListRejected: () => ({}),
})

export const moveTopItem = (listId: string, itemId: string) =>
  post({
    url: `/list/movetotop/${listId}`,
    body: itemId.toString(),
    initiated: moveTopItemInListRequested,
    fulfilled: (list: IList) => moveTopItemInListSucceeded(list),
    rejected: moveTopItemInListRejected,
  })

export const moveTopItems = (listId: string, itemIds: string[]) =>
  post({
    url: `/list/movemanytotop/${listId}`,
    body: itemIds.toString(),
    initiated: moveTopItemInListRequested,
    fulfilled: (list: IList) => moveTopItemInListSucceeded(list),
    rejected: moveTopItemInListRejected,
  })

export const {
  moveBottomItemInListRequested,
  moveBottomItemInListSucceeded,
  moveBottomItemInListRejected,
} = createActions({
  moveBottomItemInListRequested: () => ({}),
  moveBottomItemInListSucceeded: (list) => list,
  moveBottomItemInListRejected: () => ({}),
})

export const moveBottomItem = (listId: string, itemId: string) =>
  post({
    url: `/list/movetobottom/${listId}`,
    body: itemId.toString(),
    initiated: moveBottomItemInListRequested,
    fulfilled: (list: IList) => moveBottomItemInListSucceeded(list),
    rejected: moveBottomItemInListRejected,
  })

export const moveBottomItems = (listId: string, itemIds: string[]) =>
  post({
    url: `/list/movemanytobottom/${listId}`,
    body: itemIds.toString(),
    initiated: moveBottomItemInListRequested,
    fulfilled: (list: IList) => moveBottomItemInListSucceeded(list),
    rejected: moveBottomItemInListRejected,
  })

export const {
  reorderListRequested,
  reorderListSucceeded,
  reorderListRejected,
} = createActions({
  reorderListRequested: () => ({}),
  reorderListSucceeded: (list) => list,
  reorderListRejected: () => ({}),
})

export const reorderList = (listId: string, itemIds: string[]) =>
  post({
    url: `/list/reorder/${listId}`,
    body: itemIds.toString(),
    initiated: reorderListRequested,
    fulfilled: (list: IList) => reorderListSucceeded(list),
    rejected: reorderListRejected,
  })

export const {
  updateManyItemsAtOnceRequested,
  updateManyItemsAtOnceSucceeded,
  updateManyItemsAtOnceRejected,
} = createActions({
  updateManyItemsAtOnceRequested: () => ({ pending: true }),
  updateManyItemsAtOnceSucceeded: (list) => list,
  updateManyItemsAtOnceRejected: (error) => error,
})

export const onUpdateManyItemsAtOnceSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeTab(list.id))
    dispatch(updateManyItemsAtOnceSucceeded(list))
    dispatch(closeModal())
    dispatch(
      openContent(
        {
          type: TabTypes.List,
          id: list.id,
          selected: true,
          name: list.name,
        },
        list.name
      )
    )
  }

export const onUpdateManyItemsAtOnceRejected =
  (error: any, errorMessage: any) =>
  (dispatch: Dispatch<any>): void => {
    if (error > 400 && error < 600) {
      dispatch(isCompleted)
      dispatch(updateManyItemsAtOnceRejected(error, errorMessage))
      dispatch(closeModal())
    }
  }

export const onUpdateManyItemsAtOnceInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isPending())
    dispatch(updateManyItemsAtOnceRequested)
  }

export const updateManyItemsAtOnce = (
  listId: string,
  updateValues: IItemChanges
) =>
  post({
    url: `/list/updateitemsinlist/${listId}`,
    body: updateValues,
    initiated: onUpdateManyItemsAtOnceInitiated,
    fulfilled: (list: IList) => onUpdateManyItemsAtOnceSucceeded(list),
    rejected: (error: number, errorMessage: any) =>
      onUpdateManyItemsAtOnceRejected(error, errorMessage),
  })

export const {
  createItemFromImageInitiatedRequested,
  createItemFromImageInitiatedSucceeded,
  createItemFromImageInitiatedRejected,
} = createActions({
  createItemFromImageInitiatedRequested: () => ({ pending: true }),
  createItemFromImageInitiatedSucceeded: (list) => list,
  createItemFromImageInitiatedRejected: (error) => error,
})

export const onCreateItemFromImageSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(createItemFromImageInitiatedSucceeded(list))
  }

export const onCreateItemFromImageRejected =
  (error: any, errorMessage: any) =>
  (dispatch: Dispatch<any>): void => {
    if (error > 400 && error < 600) {
      dispatch(isCompleted)
      dispatch(createItemFromImageInitiatedRejected(error, errorMessage))
    }
  }

export const onCreateItemFromImageInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(isPending())
    dispatch(createItemFromImageInitiatedRequested)
  }

export const createItemFromImage = (item: IItem) =>
  post({
    url: `/list/createitemfromimage/`,
    body: item,
    initiated: onCreateItemFromImageInitiated,
    fulfilled: (list: IList) => onCreateItemFromImageSucceeded(list),
    rejected: (error: number, errorMessage: any) =>
      onCreateItemFromImageRejected(error, errorMessage),
  })

export const {
  deleteItemsFromListRequested,
  deleteItemsFromListSucceeded,
  deleteItemsFromListRejected,
} = createActions({
  deleteItemsFromListRequested: () => ({}),
  deleteItemsFromListSucceeded: (list: IList) => list,
  deleteItemsFromListRejected: () => ({}),
})

export const onDeleteItemFromListSucceeded =
  (list: IList) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(deleteItemsFromListSucceeded(list))
    dispatch(closeModal())
  }

export const deleteItemFromList = (itemIds: string[], listId: string) =>
  post({
    url: `/list/deleteitemsfromlist/${listId}`,
    body: itemIds.toString(),
    initiated: deleteItemsFromListRequested,
    fulfilled: (list: IList) => onDeleteItemFromListSucceeded(list),
    rejected: deleteItemsFromListRejected,
  })
