import { useRef, useState } from 'react'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'

const ImportListModal = ({
  openListId,
  closeModal,
  importListFromPlZip,
  setImportFileSize,
  openImportMappingModal,
  setImportData,
  setImportToList,
}) => {
  const { t } = useTranslation()
  const hiddenFileInput = useRef(null)
  const [importIntoCurrentList, setImportIntoCurrentList] = useState(false)

  const onClickedSelectFile = (_event, importIntoCurrentList = false) => {
    setImportIntoCurrentList(importIntoCurrentList)
    setImportToList(importIntoCurrentList)
    ;(hiddenFileInput as any).current.click()
  }

  const handleFileSelected = (event) => {
    let fileToImport = event.target.files[0]
    if (fileToImport !== undefined) {
      const fileSize = fileToImport.size
      setImportFileSize(fileSize)
      if (fileToImport.name.slice(-3) === 'csv') {
        Papa.parse(fileToImport, {
          skipEmptyLines: true,
          delimitersToGuess: [
            ';',
            ',',
            '\t',
            '|',
            ';',
            Papa.RECORD_SEP,
            Papa.UNIT_SEP,
          ],
          complete: function (results) {
            setImportData(results)
          },
        })
        openImportMappingModal()
      } else {
        importListFromPlZip(
          fileToImport,
          importIntoCurrentList ? openListId : null
        ).then((x) => closeModal())
      }
    }
  }

  return (
    <div>
      <div className="p-3">
        <p className="text-left">{t('modal_import_info')}</p>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileSelected}
          style={{ display: 'none' }}
          accept=".plzip, .csv"
        />
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={(e) => onClickedSelectFile(e, true)}
            >
              {t('modal_import_button_currentList')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={(e) => onClickedSelectFile(e)}
            >
              {t('modal_import_button_newList')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportListModal
