import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { ITab } from './IContent'
import { addContent, closeContent, changeTab } from './Content.actions'
import ContentReducer from './Content.reducer'
import { updateListNameSucceeded } from '../List/List.actions'
import { updateTemplateNameSucceeded } from '../Template/Template.actions'

export interface ITabsState {
  [key: string]: ITab
}

export const initialState: ITabsState = {}

const deselectAllTabs = (state) => {
  let deleteSelectTabs = {}
  for (const tab in state) {
    deleteSelectTabs[tab] = {
      ...state[tab],
      selected: false,
    }
  }
  return deleteSelectTabs
}

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

const getStateWithNewTabName = (state: ITabsState, action: IAction<any>) => {
  const newState = {}
  for (const id in state) {
    newState[id] = {
      ...state[id],
      name: action.payload.id === id ? action.payload.name : state[id].name,
    }
  }
  return {
    ...newState,
  }
}

const byId: Reducer<ITabsState, IAction<any>> = handleActions(
  {
    [addContent.toString()]: (state: ITabsState, action: IAction<ITab>) => {
      if (state[action.payload.id]) {
        let selectedState = deselectAllTabs(state)
        selectedState[action.payload.id].selected = true
        return { ...selectedState }
      }

      const newState = deselectAllTabs(state)

      return {
        ...newState,
        [action.payload.id]: ContentReducer(
          newState[action.payload.id],
          action
        ),
      }
    },
    [closeContent.toString()]: (state: ITabsState, action: IAction<any>) => {
      const newState = removeProperty(state, action.payload.tabToCloseId)
      if (action.payload.nextTabToOpenId) {
        return {
          ...newState,
          [action.payload.nextTabToOpenId]: {
            ...newState[action.payload.nextTabToOpenId],
            selected: true,
          },
        }
      }
      return newState
    },
    [changeTab.toString()]: (state: ITabsState, action: IAction<any>) => {
      const newState = {}
      for (const tab in state) {
        newState[tab] = {
          ...state[tab],
          selected: state[tab].id === action.payload,
        }
      }
      return {
        ...newState,
      }
    },
    [updateListNameSucceeded.toString()]: (
      state: ITabsState,
      action: IAction<any>
    ) => getStateWithNewTabName(state, action),
    [updateTemplateNameSucceeded.toString()]: (
      state: ITabsState,
      action: IAction<any>
    ) => getStateWithNewTabName(state, action),
  },
  initialState
)

const allIds = handleActions(
  {
    [addContent.toString()]: (state: string[], action: IAction<ITab>) => {
      if (state.includes(action.payload.id)) {
        return [...state]
      }
      if (state.length === 6) {
        return [...state]
      }
      return [...state, action.payload.id]
    },
    [closeContent.toString()]: (state: string[], action: IAction<any>) =>
      [...state].filter((id) => id !== action.payload.tabToCloseId),
  },
  []
)

const contentsReducer = combineReducers({
  byId,
  allIds,
})

export default contentsReducer
