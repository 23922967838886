import React, { useEffect, useState } from 'react'
import type { IItemChanges } from '../../IItem'
import CheckBox from '../../../Components/CheckBox'
import CheckBoxExpander from '../../../Components/CheckBoxExpander'
import styles from '../EditItemsForm.module.scss'
import type {
  IOption,
  IShippingOptions,
} from '../../ItemForm/ShippingOptions/IShipping'
import ShippingOption from './ShippingOption'
import { convertWeight } from '../../../Infrastructure/Helpers/shippingHelper'
import { useTranslation } from 'react-i18next'

interface ItemProps {
  shippingOptions: IShippingOptions[]
  getShippingOptions: () => Promise<any>
  itemChanges: IItemChanges
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateStateByItemChanges: (property: string, value: any) => void
}

const Shipping = ({
  shippingOptions,
  getShippingOptions,
  itemChanges,
  updateStateByItemChanges,
}: ItemProps) => {
  const { t } = useTranslation()
  const [expandedFirst, setExpandedFirst] = useState(false)
  const [expandedSecond, setExpandedSecond] = useState(false)
  const [expandedTakeaway, setExpandedTakeaway] = useState(false)
  const [weightOptions, setWeightOptions] = useState<IOption[]>([])
  const [selectedFirstProduct, setSelectedFirstProduct] = useState<string>()
  const [selectedSecondProduct, setSelectedSecondProduct] = useState<string>()

  useEffect(() => {
    getShippingOptions()
  }, [])

  useEffect(() => {
    if (shippingOptions && shippingOptions.length > 0) {
      setWeightOptions(
        shippingOptions?.map((item, index) => {
          return {
            value: item.weight,
            label: convertWeight(item.weight),
          }
        })
      )
    } else {
      getShippingOptions()
    }
  }, [shippingOptions])

  return (
    <>
      <CheckBoxExpander
        checked={itemChanges.changeTypeAndPrice}
        label={t('editItemsForm_shipping_label') + ' 1'}
        name="changeShippingFirst"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpandedFirst}
      />
      {expandedFirst && (
        <div className={styles.expandContainer}>
          <ShippingOption
            weightOptions={weightOptions}
            shippingOptions={shippingOptions}
            updateStateByItemChanges={updateStateByItemChanges}
            testId={'shipping-option-1'}
            name="shippingFirst"
            onOptionSelected={setSelectedFirstProduct}
            takenProductKey={selectedSecondProduct}
          />
        </div>
      )}
      <CheckBoxExpander
        checked={itemChanges.changeTypeAndPrice}
        label={t('editItemsForm_shipping_label') + ' 2'}
        name="changeShippingSecond"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpandedSecond}
      />
      {expandedSecond && (
        <div className={styles.expandContainer}>
          <ShippingOption
            weightOptions={weightOptions}
            shippingOptions={shippingOptions}
            updateStateByItemChanges={updateStateByItemChanges}
            testId={'shipping-option-2'}
            name="shippingSecond"
            onOptionSelected={setSelectedSecondProduct}
            takenProductKey={selectedFirstProduct}
          />
        </div>
      )}
      <CheckBoxExpander
        checked={itemChanges.changeShippingTakeaway}
        label={t('editItemsForm_shipping_takeaway')}
        name="changeShippingTakeaway"
        updateStateByItemChanges={updateStateByItemChanges}
        expandedCallback={setExpandedTakeaway}
      />
      {expandedTakeaway && (
        <div className={styles.expandContainer}>
          <CheckBox
            label={t('editItemsForm_shipping_takeaway')}
            checked={itemChanges.shippingTakeaway}
            handleInputChange={updateStateByItemChanges}
            name="shippingTakeaway"
            disable={!itemChanges.changeShippingTakeaway}
          />
        </div>
      )}
    </>
  )
}
export default Shipping
