import type { IConfiguration } from './IConfiguration'

/* eslint-disable */
class ConfigService {
  private configuration: IConfiguration

  constructor() {
    const runtimeConfiguration = (window as any)
      .APP_CONFIGURATION as IConfiguration

    const envVariables = (process as any).env

    const compiletimeConfiguration: IConfiguration = {
      REACT_APP_API_URL: envVariables.REACT_APP_API_URL || '',
      REACT_APP_IDENTITY_BASE_URL:
        envVariables.REACT_APP_IDENTITY_BASE_URL || '',
      REACT_APP_APP_KEY_FOR_TRADERA_WEB_API:
        envVariables.REACT_APP_APP_KEY_FOR_TRADERA_WEB_API || '',
      REACT_APP_TRADERA_WEB_URL: envVariables.REACT_APP_TRADERA_WEB_URL || '',
    }

    this.configuration = this.isValidRunTimeConfig(runtimeConfiguration)
      ? runtimeConfiguration
      : compiletimeConfiguration
  }

  set setCompiletimeConfiguration(configuration: Partial<IConfiguration>) {
    this.configuration = {
      ...this.configuration,
      ...configuration,
    }
  }

  get config(): IConfiguration {
    return {
      ...this.configuration,
    }
  }

  private isValidRunTimeConfig = (c: IConfiguration) =>
    c &&
    c.REACT_APP_API_URL &&
    (c.REACT_APP_API_URL.startsWith('http') ||
      c.REACT_APP_API_URL.startsWith('/'))
}
/* eslint-enable */

export default new ConfigService()
