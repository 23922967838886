import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import UI from './UI/UI.container'
import { Provider } from 'react-redux'
import store from './Store/store'
import reportWebVitals from './reportWebVitals'
import './index.scss'
import './Styles/_grid.scss'
import './Config/ReactotronConfig'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import './Infrastructure/Translation/i18n'
import './Infrastructure/Translation/i18nListener'
// import enableTestingWithoutBackend from './Infrastructure/Testing/testWithoutBackend'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'

// enableTestingWithoutBackend()
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f8e4f8e4367a4eea9b2e69739a54b8be@o67204.ingest.sentry.io/5740064',
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // https://github.com/getsentry/sentry-javascript/issues/2798
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'console') {
        return null
      }
      return breadcrumb
    },
  })
}
const render = () => {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <UI />
        </I18nextProvider>
      </Provider>
    </Suspense>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./UI/UI.container', render)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
