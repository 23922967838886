import type { ICategory } from '../../Items/IItem'

export function getCategoryName(category: ICategory) {
  if (category?.category?.length === 1) {
    return getCategoryName(category.category[0])
  }
  return category.name
}

export function getCategoryTree(category: ICategory) {
  let name = category?.name
  if (category?.category?.length === 1) {
    let secondLevelCategory = category?.category[0]
    name = name + ' / ' + secondLevelCategory.name
    if (secondLevelCategory.category?.length === 1) {
      let thirdLevelCategory = secondLevelCategory.category[0]
      name = name + ' / ' + thirdLevelCategory.name
      if (thirdLevelCategory.category?.length === 1) {
        let fourthLevelCategory = thirdLevelCategory.category[0]
        name = name + ' / ' + fourthLevelCategory.name
      }
    }
  }
  return name
}
