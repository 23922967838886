import type { IItem } from '../../Items/IItem'
import type { ISellItem } from '../../SellView/ISellView'
import type { TFunction } from 'i18next'

export interface IOptionalPrice {
  name: string
  amount: number
}

export const getItemsOptionalPriceSummary = (
  t: TFunction,
  items: (IItem | ISellItem)[]
) => {
  let optionalPricesSummary: IOptionalPrice[] = []
  let rankUp = 0
  let reservationPrice = 0
  let highlighted = 0

  items.forEach((item) => {
    if (item) {
      if (item.rankUp) {
        rankUp += 45
      }
      if (item.reservationPrice) {
        reservationPrice += 40
      }
      if (item.isHighlighted) {
        highlighted += 15
      }
    }
  })

  if (rankUp > 0) {
    optionalPricesSummary.push({
      name: t('priceCalculation_rankUp'),
      amount: rankUp,
    })
  }
  if (reservationPrice > 0) {
    optionalPricesSummary.push({
      name: t('priceCalculation_restart'),
      amount: reservationPrice,
    })
  }
  if (highlighted > 0) {
    optionalPricesSummary.push({
      name: t('priceCalculation_highlight'),
      amount: highlighted,
    })
  }

  return optionalPricesSummary
}

export const getItemsOptionalPrice = (items: IItem[]) => {
  let priceForOptional: number = 0

  items.forEach((item) => {
    if (item) {
      if (item.rankUp) {
        priceForOptional += 45
      }
      if (item.reservationPrice) {
        priceForOptional += 40
      }
      if (item.isHighlighted) {
        priceForOptional += 15
      }
    }
  })
  return priceForOptional
}

export const getItemOptionalPrice = (item) => {
  let priceForOptional: number = 0

  if (item) {
    if (item.rankUp) {
      priceForOptional += 45
    }
    if (item.reservationPrice) {
      priceForOptional += 40
    }
    if (item.isHighlighted) {
      priceForOptional += 15
    }
  }

  return priceForOptional
}
