import React from 'react'
import { useDispatch } from 'react-redux'
import { updateFieldAcceptedBidders } from '../../Items/Items.action'
import type { IItem } from '../../Items/IItem'
import { ISellingLocation } from '../../Items/IItem'
import Select from '../../Components/Select'
import { useTranslation } from 'react-i18next'

interface AcceptedBiddersElementRendererProps {
  data: IItem
}

const AcceptedBiddersElementRenderer: React.FunctionComponent<
  AcceptedBiddersElementRendererProps
> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSelectedRows = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value)
    const itemToUpdate = {
      ...data,
      acceptedBidders: value,
    }
    dispatch(
      updateFieldAcceptedBidders(
        data.id ? data.id : '',
        itemToUpdate.acceptedBidders
      )
    )
  }

  return (
    /*  <div className={`${styles.selecter}`}>
             <select
                 className={`${styles.selecter}`}
                 name="acceptedBidders"
                 value={data.acceptedBidders}
                 onChange={(e) => handleSelectedRows(e)}
             >
                 <option value={ISellingLocation['Sverige']}>Sverige</option>
                 <option value={ISellingLocation['EU']}>EU</option>
                 <option value={ISellingLocation['HelaVärlden']}>Hela världen</option>
             </select>
         </div> */
    <div style={{ width: '100%', padding: '0px', boxShadow: 'none' }}>
      <Select
        onChange={(e) => handleSelectedRows(e)}
        value={data.acceptedBidders ? data.acceptedBidders.toString() : ''}
        styleGrid={true}
      >
        <option value={ISellingLocation['Sverige']}>
          {t('prolisterGrid_renderers_acceptedBidders_sweden')}
        </option>
        <option value={ISellingLocation['EU']}>
          {t('prolisterGrid_renderers_acceptedBidders_eu')}
        </option>
        <option value={ISellingLocation['HelaVärlden']}>
          {t('prolisterGrid_renderers_acceptedBidders_worldwide')}
        </option>
      </Select>
    </div>
  )
}

export default AcceptedBiddersElementRenderer
