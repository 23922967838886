import React, { useState } from 'react'
import Button from '../Button'
import CheckBox from '../CheckBox/CheckBox'
import { ReactComponent as ArrowDownIcon } from '@tradera/blueprint/theme/icons2/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '@tradera/blueprint/theme/icons2/arrow-up.svg'

interface CheckBoxProps {
  label: string
  checked?: boolean
  name: string
  updateStateByItemChanges: (
    property: string,
    value: string | number | boolean | File[] | string[] | any
  ) => void
  expandedCallback?: (expanded: boolean) => void
}

const CheckBoxExpander: React.FunctionComponent<CheckBoxProps> = ({
  label,
  checked,
  name,
  updateStateByItemChanges,
  expandedCallback,
}) => {
  const [expanded, setExpanded] = useState(false)
  const iconClick = async (expand: boolean) => {
    setExpanded(expand)
    expandedCallback && expandedCallback(expand)
  }
  const [value, setValue] = useState(checked)
  const checkboxClick = async (bool: boolean) => {
    updateStateByItemChanges(name, bool)
    setValue(bool)
    if (bool && !expanded) {
      setExpanded(bool)
      expandedCallback && expandedCallback(bool)
    }
  }

  return (
    <div className="flex">
      <div className="d-inline-block">
        <CheckBox
          checked={value}
          label={label}
          handleInputChange={() => checkboxClick(!value)}
          name={name}
        />
      </div>
      <div className="d-inline-block">
        <Button
          style={{}}
          size="small"
          variant="noborder"
          clickHandler={() => iconClick(!expanded)}
          icon={
            expanded ? (
              <ArrowUpIcon className="icon" />
            ) : (
              <ArrowDownIcon className="icon" />
            )
          }
        />
      </div>
    </div>
  )
}

export default CheckBoxExpander
