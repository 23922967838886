import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import type { ToastOptions } from 'react-toastify'
import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'
import { resetAlertContainer } from '../../UI/UI.actions'
import { useDispatch } from 'react-redux'

const success = '#008059'
const info = '#000'
const StyledContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast--success {
    background: ${success} !important;
  }
  .Toastify__toast--info {
    background: ${info} !important;
  }
`

const alertSettings: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  draggable: false,
  toastId: Math.random(),
}

const alertTypes = {
  default: 'default',
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
}

const AlertContainer = ({ message, alertType }) => {
  const dispatch = useDispatch()
  switch (alertType) {
    case alertTypes.default:
      toast(message, alertSettings)
      break
    case alertTypes.success:
      toast.success(message, alertSettings)
      break
    case alertTypes.info:
      toast.info(message, alertSettings)
      break
    case alertTypes.warning:
      toast.warning(message, alertSettings)
      break
    case alertTypes.error:
      toast.error(message, alertSettings)
      break
  }

  useEffect(() => {
    dispatch(resetAlertContainer())
  })

  return <StyledContainer newestOnTop={true} />
}
export default AlertContainer
