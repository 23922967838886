import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import {
  openImportModal,
  openImportMappingModal,
  minimize,
  closeModal,
  closeMinimizeModal,
  getCategoriesSucceeded,
  getShippingOptionsSucceeded,
  getAttributesSucceeded,
  getCampaignCodeValidSucceeded,
  openBankIdModal,
  openBankIdVerifyModal,
  openPublishModal,
  openPublishSelectedModal,
  openPublishValidModal,
  openDeleteItemsModal,
  openDeleteListsModal,
  openCancelSellItemsModal,
  openPublicationResultModal,
  openPublishProgressModal,
  openLoadingModal,
  openErrorMessageModal,
  openImageErrorMessageModal,
  openPublishErrorMessageModal,
  openSellItemsErrorModal,
  openEditManyItemsAtOnceModal,
  openEditShopItemModal,
  openEditItemModal,
  openCreateItemFromImageModal,
  openEditCategoryModal,
  openSelectColumnsModal,
  openSettingsModal,
  isPending,
  isCompleted,
  setLoadingLists,
  openImportingModal,
  openImportingPlzipModal,
  openAlertContainer,
  resetAlertContainer,
  setImportFileSize,
  setImportData,
  setImportToList,
  setSaving,
  bankIdIsRequired,
  bankIdVerificationEnd,
  setBankIdOrderRef,
  setBankIdQrData,
  setAutoStartToken,
  setDesktopAutoStartToken,
  setUploadInterval,
  setUploadDateTime,
  setUploadItems,
  setUploadSellItems,
} from './UI.actions'
import {
  addImagesForCreateItemFromImageSucceeded,
  saveClickOrderOnItemInList,
  resetClickOrderOnItemInList,
} from '../Items/Items.action'
import type { IAttribute, ICategory, IItemClickOrder } from '../Items/IItem'
import type { IShippingOptions } from '../Items/ItemForm/ShippingOptions/IShipping'
import type { ISellItem } from '../SellView/ISellView'

export interface IUIState {
  isModalOpen: boolean
  modalType: string
  modalData?: any
  modalMessage?: string
  title: string
  height: number
  width?: number
  id?: string
  categories: ICategory[]
  attributes: { [categoryId: string]: IAttribute }
  loading?: boolean
  loadingLists?: boolean
  saving?: boolean
  importMinimizeLoading?: boolean
  isMinimize?: boolean
  imageUrls?: string[]
  campaignCodeValid: boolean
  alertType?: string
  importFileSize?: number
  bankIdRequired: boolean
  bankIdSuccess?: boolean
  bankIdListId: string
  bankIdPersonalNumber?: string
  uploadInterval?: string
  uploadDateTime?: string
  uploadItems: string[]
  uploadSellItems: ISellItem[]
  clickedItems: IItemClickOrder[]
  importData?: any
  importToList?: boolean
}

const initialState: IUIState = {
  isModalOpen: false,
  modalType: '',
  title: '',
  height: 0,
  width: 0,
  categories: [],
  attributes: {},
  modalData: '',
  imageUrls: [],
  importFileSize: 0,
  campaignCodeValid: false,
  bankIdRequired: false,
  bankIdListId: 'test',
  clickedItems: [],
  uploadItems: [],
  uploadSellItems: [],
}

const uiReducer: Reducer<IUIState, IAction<any>> = handleActions(
  {
    [isPending.toString()]: (state: IUIState, _action) => ({
      ...state,
      loading: true,
    }),
    [isCompleted.toString()]: (state: IUIState, _action) => ({
      ...state,
      loading: false,
      isMinimize: false,
      importMinimizeLoading: false,
    }),
    [setSaving.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      saving: action.payload,
    }),

    [openImportModal.toString()]: (state: IUIState, _action) => ({
      ...state,
      modalType: 'import',
      title: 'modal_import_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openImportMappingModal.toString()]: (state: IUIState, _action) => ({
      ...state,
      modalType: 'importMapping',
      title: 'modal_importMapping_title',
      isLarge: true,
      isModalOpen: true,
      linkUrl: 'https://info.tradera.com/prolister/csv/',
      linkText: 'Guide',
      importData: state.importData,
      importToList: state.importToList,
    }),
    [openImportingModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'importing',
      title: 'modal_importing_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openImportingPlzipModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'importingPlzip',
      title: 'modal_importPlzip_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openPublishModal.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      modalType: 'publishList',
      title: 'modal_publishList_title',
      isLarge: true,
      isModalOpen: true,
      id: action.payload.id,
      selectedItems: action.payload.selectedItems,
    }),
    [openPublishSelectedModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'publishSelected',
      title: 'modal_publishSelected_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openPublishValidModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'publishValid',
      title: 'modal_publishValid_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openDeleteItemsModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'deleteItems',
      title: 'modal_deleteItems_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openDeleteListsModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'deleteLists',
      title: 'modal_deleteLists_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openCancelSellItemsModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'cancelSellItems',
      title: 'modal_cancelSellItems_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openPublishProgressModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'publishProgress',
      title: 'modal_publishProgress_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openLoadingModal.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      modalType: 'loadingModal',
      title: action.payload.message,
      isLarge: false,
      isModalOpen: true,
      modalData: action.payload.message,
    }),
    [openPublicationResultModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'publishResult',
      title: 'modal_publishResult_title',
      isLarge: false,
      isModalOpen: true,
      modalData: action.payload.numberOfFailedItems,
    }),
    [openPublishErrorMessageModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'publishErrorMessage',
      title: 'modal_publishErrorMessage_title',
      isLarge: false,
      isModalOpen: true,
      modalData: action.payload.errorMessage,
    }),
    [openSellItemsErrorModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'sellItemsError',
      title: 'modal_sellItemsError_title',
      isLarge: false,
      isModalOpen: true,
      modalData: action.payload.errorItems,
      modalMessage: action.payload.message,
    }),
    [openBankIdModal.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      modalType: 'bankIdInit',
      title: 'modal_bankId_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openBankIdVerifyModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'bankIdVerify',
      title: 'modal_bankIdVerify_title',
      isLarge: false,
      isModalOpen: true,
    }),
    [openErrorMessageModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'errorMessage',
      title: 'modal_errorMessage_title',
      isLarge: false,
      isModalOpen: true,
      modalData: action.payload.errorCode,
    }),
    [openImageErrorMessageModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'errorMessage',
      title: 'modal_imageErrorMessage_title',
      isLarge: false,
      isModalOpen: true,
      modalData: 1010,
    }),
    [openEditManyItemsAtOnceModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'editManyItemsAtOnce',
      title: 'modal_editManyItemsAtOnce_title',
      isLarge: true,
      isModalOpen: true,
      id: action.payload.id,
      items: action.payload.items,
    }),
    [openEditShopItemModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'editShopItem',
      title: 'modal_editShopItem_title',
      isLarge: true,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openEditItemModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'editItem',
      title: 'modal_editItem_title',
      isLarge: true,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openCreateItemFromImageModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'createItemFromImage',
      title: 'modal_createItemFromImage_title',
      isLarge: false,
      isModalOpen: true,
      id: action.payload.id,
      imageUrls: state.imageUrls,
    }),
    [openEditCategoryModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'editCategoryModal',
      title: 'modal_editCategory_title',
      isLarge: true,
      isModalOpen: true,
      id: action.payload.id,
    }),
    [openSelectColumnsModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'selectColumnsModal',
      title: 'modal_selectColumns_title',
      isLarge: true,
      isModalOpen: true,
    }),
    [openSettingsModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      modalType: 'settingsModal',
      title: 'modal_settings_title',
      isLarge: true,
      isModalOpen: true,
    }),
    [closeModal.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      isModalOpen: false,
      loading: false,
      linktext: undefined,
      linkUrl: undefined,
    }),
    [minimize.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      isModalOpen: false,
      importMinimizeLoading: true,
      isMinimize: true,
    }),
    [closeMinimizeModal.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      isMinimize: false,
      isModalOpen: false,
      importMinimizeLoading: false,
    }),
    [getCategoriesSucceeded.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => {
      const categories = action.payload as ICategory[]

      return {
        ...state,
        categories,
      }
    },
    [getShippingOptionsSucceeded.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => {
      const shippingOptions = action.payload as IShippingOptions[]
      return {
        ...state,
        shippingOptions,
      }
    },
    [getAttributesSucceeded.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      attributes: {
        ...state.attributes,
        [action.payload.categoryId]: action.payload.attributes,
      },
    }),
    [getCampaignCodeValidSucceeded.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        campaignCodeValid: action.payload,
      }
    },
    [addImagesForCreateItemFromImageSucceeded.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      imageUrls: action.payload,
    }),
    [openAlertContainer.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      message: action.payload.message,
      alertType: action.payload.alertType,
    }),
    [resetAlertContainer.toString()]: (state: IUIState) => ({
      ...state,
      message: '',
      alertType: '',
    }),
    [setImportFileSize.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      importFileSize: action.payload,
    }),
    [setImportData.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      importData: action.payload,
    }),
    [setImportToList.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      importToList: action.payload,
    }),
    [setUploadInterval.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      uploadInterval: action.payload,
    }),
    [setUploadDateTime.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      uploadDateTime: action.payload,
    }),
    [setUploadItems.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      uploadItems: action.payload,
    }),
    [setUploadSellItems.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      uploadSellItems: action.payload,
    }),
    [setLoadingLists.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      loadingLists: action.payload,
    }),
    [bankIdIsRequired.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      bankIdRequired: action.payload.bankIdRequired,
      id: action.payload.listId,
      bankIdListId: action.payload.listId,
      bankIdPersonalNumber: action.payload.personalNumber,
      uploadInterval: action.payload.uploadInterval,
      uploadDateTime: action.payload.uploadDateTime,
    }),
    [bankIdVerificationEnd.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      bankIdRequired: action.payload.bankIdRequired,
      bankIdSuccess: action.payload.bankIdSuccess,
      autoStartToken: action.payload.autoStartToken,
      desktopAutoStartToken: action.payload.desktopAutoStartToken,
      bankIdPersonalNumber: '',
    }),
    [setBankIdOrderRef.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      bankIdOrderRef: action.payload.bankIdOrderRef,
    }),
    [setBankIdQrData.toString()]: (state: IUIState, action: IAction<any>) => ({
      ...state,
      qrData: action.payload.qrData,
    }),
    [setAutoStartToken.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      autoStartToken: action.payload.autoStartToken,
    }),
    [setDesktopAutoStartToken.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      desktopAutoStartToken: action.payload.desktopAutoStartToken,
    }),
    [saveClickOrderOnItemInList.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      clickedItems: [...state.clickedItems, action.payload.id],
    }),
    [resetClickOrderOnItemInList.toString()]: (
      state: IUIState,
      action: IAction<any>
    ) => ({
      ...state,
      clickedItems: [],
    }),
  },
  initialState
)

export default uiReducer
