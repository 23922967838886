import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'

import SellItemsError from './SellItemsError'

const connectedComponent = connect(
  (state: any) => {
    return {
      errorItems: state.ui.modalData,
      message: state.ui.modalMessage,
    }
  },
  {
    closeModal,
  }
)(SellItemsError)

export default connectedComponent
