import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import type { IItem } from '../../Items/IItem'
import type { IImportViewModel } from '../../List/IList'
import type { IImportMapping } from '../../Infrastructure/Helpers/importListHelper'
import { importProperties } from '../../Infrastructure/Helpers/importListHelper'
import styles from './ImportMappingModal.module.scss'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

interface IDefaultOption {
  key: string
  option: IOption
}

interface IOption {
  label: string
  value: number
}

interface ImportMappingProps {
  closeModal: () => void
  createImportedList: (viewModel: IImportViewModel) => void
  importData: any
  openListId?: string
  user: IUser
}

const ImportMappingModal: React.FunctionComponent<ImportMappingProps> = ({
  closeModal,
  createImportedList,
  importData,
  openListId,
  user,
}) => {
  const { t } = useTranslation()
  const [mapping, setMapping] = useState<IImportMapping[]>([])
  const [options, setOptions] = useState<IOption[]>([])
  const [defaultOptions, setDefaultOptions] = useState<IDefaultOption[]>([])

  useEffect(() => {
    if (importData && importData.data.length > 0) {
      setOptions(
        importData.data[0]?.map((item, index) => {
          return {
            value: index,
            label: item,
          }
        })
      )
      const newDefaultOptions: IDefaultOption[] = []
      const initialMapping: IImportMapping[] = []
      importProperties.forEach((property) => {
        let mappingItem: IImportMapping = {
          key: property.key,
          label: property.label,
          value: -1,
          hidden: property.hidden,
          forShop: property.forShop,
        }
        const headers = importData.data[0].map((element) => {
          return element.toLowerCase()
        })
        for (let i = 0; i < property.autoMap.length; i++) {
          const indexOfAlternative = _.indexOf(
            headers,
            property.autoMap[i].toLowerCase()
          )
          if (indexOfAlternative > -1) {
            const defaultOption: IDefaultOption = {
              key: property.key,
              option: {
                label: property.autoMap[i],
                value: indexOfAlternative,
              },
            }
            newDefaultOptions.push(defaultOption)
            mappingItem.value = indexOfAlternative
            break
          }
        }
        initialMapping.push(mappingItem)
      })
      setDefaultOptions(newDefaultOptions)
      setMapping(initialMapping)
    }
  }, [importData])

  const importList = () => {
    const importedItems: IItem[] = []
    for (let i = 1; i < importData.data.length; i++) {
      let importedItem: any = {}
      importProperties.forEach((property) => {
        importedItem[property.key] =
          importData.data[i][mapping.find((x) => x.key === property.key)!.value]
      })
      importedItems.push(importedItem)
    }
    const importViewModel: IImportViewModel = {
      items: importedItems,
      listId: openListId,
    }
    createImportedList(importViewModel)
  }

  return (
    <div>
      <div className="p-3">
        <div className="row">
          <div className="col">
            <p className="font-weight-bold">
              {t('modal_importMapping_columns')}
            </p>
          </div>
          <div className="col">
            <p className="font-weight-bold">
              {t('modal_importMapping_columnsInfo')}
            </p>
          </div>
        </div>
        <hr />
        <div>
          {mapping
            .filter((x) => !x.hidden && (!x.forShop || user.hasShop))
            .map((item, index) => {
              return (
                <div key={item.key}>
                  <div className="row align-items-center flex mb-2">
                    <div className="col">
                      <span className="align-middle">{t(item.label)}</span>
                    </div>
                    <div className="col">
                      {importData && importData.data.length > 0 && (
                        <Select
                          options={options}
                          onChange={(e) => (item.value = e.value)}
                          defaultValue={
                            defaultOptions.find((x) => x.key === item.key)
                              ?.option
                          }
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: 0,
                            }),
                          }}
                          clear
                        />
                      )}
                    </div>
                  </div>
                  {item.key === 'condition' && (
                    <div>
                      <p>
                        <span className="text-muted size-oslo">
                          * {t('modal_importMapping_conditionInfo')}
                        </span>
                      </p>
                    </div>
                  )}
                  {item.key === 'acceptedBidders' && (
                    <div>
                      <p>
                        <span className="text-muted size-oslo">
                          * {t('modal_importMapping_acceptedBiddersInfo')}
                        </span>
                      </p>
                    </div>
                  )}
                  {item.key !== 'shippingCostFirst' &&
                  item.key !== 'shippingAlternativeSecond' &&
                  item.key !== 'shippingCostFirst' &&
                  item.key !== 'shippingAlternativeFirst' ? (
                    <hr className={styles.divider} />
                  ) : (
                    <br />
                  )}
                </div>
              )
            })}
        </div>
        <p>
          <span className="text-muted size-oslo">
            * {t('modal_importMapping_info')}.
          </span>
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_importMapping_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => importList()}
            >
              {t('modal_importMapping_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportMappingModal
