import React, { useRef, useState } from 'react'
import styles from './TabItem.module.scss'
import type { TabTypes } from '../IContent'
import { ReactComponent as ClearIcon } from '../../Icons/close-icon.svg'
import _uniqueId from 'lodash/uniqueId'
import useTabItem from './useTabItem'
import { useDispatch } from 'react-redux'
import usePrevious from '../../Infrastructure/Helpers/usePrevious'

interface TabItemProps {
  name: string
  active?: boolean
  changeTab: (currentItem: number) => any
  closeTab: (currentItem: number) => any
  currentItem: number
  tabType: TabTypes
  id?: string
}

const TabItem: React.FunctionComponent<TabItemProps> = ({
  name,
  active,
  changeTab,
  closeTab,
  currentItem,
  tabType,
  id,
}) => {
  const [inputId] = useState(_uniqueId('input-'))
  const [showChangeNameInput, setShowChangeNameInput] = useState(false)
  const [entityName, setName] = useState(name)
  const previousContent: any = usePrevious({ name })
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  if (previousContent && previousContent.name !== name && name !== entityName) {
    setName(name)
  }

  const { icon, canEditName, onNameChanged } = useTabItem(tabType)

  return (
    <li
      className={`nav-item nav-link ${active ? 'active ' : ''} ${
        styles.tabItem
      }`}
      style={{
        borderBottomColor: !active ? '#b0b0b0' : '#fff',
        borderBottomWidth: !active ? 1 : 0,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon && icon}
      {!showChangeNameInput && (
        <button
          onClick={() => changeTab(currentItem)}
          onDoubleClick={() => {
            if (canEditName) {
              setShowChangeNameInput(true)
              ;(inputRef!.current as any).focus()
            }
          }}
          className={styles.changeTabButton}
          style={{ fontWeight: active ? 'bold' : 'initial' }}
        >
          {entityName}
        </button>
      )}
      <input
        type="text"
        ref={inputRef}
        name="update-name"
        className={`form-control`}
        id={inputId}
        value={entityName}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            ;(inputRef!.current as any).blur()
          }
        }}
        onBlur={() => {
          setShowChangeNameInput(false)
          dispatch(onNameChanged(id, entityName))
        }}
        maxLength={80}
        style={{
          paddingLeft: showChangeNameInput ? 8 : 0,
          paddingRight: 0,
          height: 20,
          width: showChangeNameInput ? 120 : 0,
          fontSize: 13,
          fontWeight: 'bold',
          border: 'none',
          boxShadow: 'none',
          color: showChangeNameInput ? 'black' : 'transparent',
        }}
      />
      <button
        onClick={() => closeTab(currentItem)}
        className={styles.clearButton}
        data-testid={`close-${name}`}
      >
        <ClearIcon />
      </button>
    </li>
  )
}
export default TabItem
