import React from 'react'
import { useDispatch } from 'react-redux'
import { updateItem } from '../../Items/Items.action'
import { ItemType, type IItem } from '../../Items/IItem'
import Select from '../../Components/Select'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

interface ItemTypeElementRendererProps {
  data: IItem
  user: IUser
}

const ItemTypeElementRenderer: React.FunctionComponent<
  ItemTypeElementRendererProps
> = ({ data, user }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSelectedRows = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = ItemType.Auction
    const targetValue = e.currentTarget.value
    if (targetValue === ItemType.FixedPrice) {
      value = ItemType.FixedPrice
    } else if (targetValue === ItemType.ShopItem) {
      value = ItemType.ShopItem
    }
    const itemToUpdate = {
      ...data,
      itemType: value,
    }
    dispatch(updateItem(itemToUpdate, false))
  }

  return (
    <div style={{ width: '100%', padding: '0px', boxShadow: 'none' }}>
      <Select
        onChange={(e) => handleSelectedRows(e)}
        value={data.itemType}
        styleGrid={true}
      >
        <option value="Auction">
          {t('prolisterGrid_renderers_itemType_auction')}
        </option>
        <option value="FixedPrice">
          {t('prolisterGrid_renderers_itemType_fixedPrice')}
        </option>
        {user.hasShop ? (
          <option value="ShopItem">
            {t('prolisterGrid_renderers_itemType_shopItem')}
          </option>
        ) : (
          <option disabled value="ShopItem">
            {t('prolisterGrid_renderers_itemType_shopItem')}
          </option>
        )}
      </Select>
    </div>
  )
}

export default ItemTypeElementRenderer
