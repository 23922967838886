import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import styles from './Input.module.scss'

import { ReactComponent as Notify } from '../../Icons/help-notify.svg'

interface InputProps {
  error?
  label?: string
  type: 'email' | 'password' | 'text' | 'number'
  placeholder?: string
  value?: string
  hint?: string
  testId?: string
  errorText?: string
  name: string
  labelAlign?: 'top' | 'left'
  group: 'form-group' | 'input-group'
  reference?: any
  errors?: any
  dropdown?: boolean
  labelDescription?: string
  inputAppend?: string
  inputAppendDropdown?: {
    selectedValue: string
    items: {
      name: string
      action: () => void
    }[]
  }
  maxLength?: number
  disable?: boolean
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input: React.FunctionComponent<InputProps> = ({
  label,
  type,
  placeholder,
  value,
  hint,
  errorText,
  name,
  labelAlign,
  group,
  reference,
  onChangeValue,
  labelDescription,
  inputAppend,
  maxLength = undefined,
  inputAppendDropdown,
  disable,
  error,
  testId,
}) => {
  const [id] = useState(_uniqueId('input-'))
  const [showDropdown, setShowDropdown] = useState(false)
  const preventDashForNumberInputs = (e) => {
    if (
      type === 'number' &&
      (e.key === '-' || e.key === 'ArrowUp' || e.key === 'ArrowDown')
    ) {
      e.preventDefault()
    }
  }
  return (
    <div className={`${styles.input} ${error ? styles.error : ''}`}>
      <div
        className={`form-group ${
          labelAlign && labelAlign === 'left' ? 'row' : ''
        }`}
      >
        <div className="d-flex justify-content-between align-items-center my-1">
          {label && (
            <div
              className={`${
                labelAlign && labelAlign === 'left'
                  ? 'col-5 col-form-label'
                  : ''
              }`}
            >
              {label && <span className={`${'label-small'}`}>{label}</span>}
              {labelDescription && (
                <span
                  className={`${'text-small text-muted ml-1'} ${
                    error ? 'text-danger' : ''
                  }`}
                >
                  {labelDescription}
                </span>
              )}
            </div>
          )}
          {hint && <span className="text-small text-muted">{hint}</span>}
        </div>
        {group === 'form-group' && (
          <>
            <input
              data-testid={testId ? testId : ''}
              ref={reference || null}
              type={type}
              name={name}
              className={`form-control ${
                labelAlign && labelAlign === 'left' ? 'col-7' : ''
              }`}
              id={id}
              aria-describedby="emailHelp"
              value={value ? value : ''}
              onKeyDown={preventDashForNumberInputs}
              onWheel={(e) => e.currentTarget.blur()}
              onChange={(e) => onChangeValue(e)}
              placeholder={placeholder ? placeholder : ''}
              disabled={disable ? true : false}
              maxLength={maxLength}
              min={type === 'number' ? 0 : undefined}
            />
          </>
        )}

        {group === 'input-group' && (
          <>
            <div
              className={`input-group p-0 ${
                labelAlign && labelAlign === 'left' ? 'col-6' : ''
              }`}
            >
              <input
                data-testid={testId ? testId : ''}
                ref={reference || null}
                type={type}
                name={name}
                id={id}
                value={value === '0' ? '' : value}
                onKeyDown={preventDashForNumberInputs}
                className="form-control"
                placeholder={placeholder}
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => onChangeValue(e)}
                min={type === 'number' ? 0 : undefined}
                disabled={disable ? true : false}
                maxLength={maxLength}
              />
              {inputAppend && (
                <div className="input-group-append">
                  <span
                    className={`input-group-text ${styles.groupText} ${
                      error ? 'error-border' : ''
                    }`}
                    id="basic-addon2"
                  >
                    {inputAppend}
                  </span>
                </div>
              )}
              {inputAppendDropdown && (
                <div className="input-group-append" style={{ width: '50%' }}>
                  <button
                    data-testid={testId ? testId + '-button' : ''}
                    className="btn btn-outline-secondary dropdown-toggle text-dark"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {inputAppendDropdown.selectedValue}
                  </button>
                  <div
                    style={{
                      minWidth: '50px',
                      backgroundColor: 'white',
                      left: 'auto',
                    }}
                    className={`dropdown-menu ${showDropdown ? 'show' : ''}`}
                  >
                    <div>
                      {inputAppendDropdown.items.map((item, key) => (
                        <button
                          data-testid={
                            testId ? testId + '-option-' + item.name : ''
                          }
                          style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingLeft: '12px',
                            width: '100%',
                            border: '0px',
                            backgroundColor: 'white',
                            textAlign: 'left',
                          }}
                          key={key}
                          onClick={() => {
                            item.action()
                            setShowDropdown(false)
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-11">
              <div>
                {error ? (
                  <small className="form-text d-flex justify-content-end error">
                    {errorText}
                  </small>
                ) : (
                  ''
                )}
                {hint && (
                  <small
                    id="emailHelp"
                    className="form-text text-muted d-flex justify-content-end"
                  >
                    {hint}
                  </small>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Input
