import React from 'react'
import ProListerSelect from '../../../Components/ProListerSelect'
import { useTranslation } from 'react-i18next'

interface ICategoryDisabledComponent {}

const CategoryDisabled: React.FunctionComponent<
  ICategoryDisabledComponent
> = ({}: ICategoryDisabledComponent) => {
  const { t } = useTranslation()
  return (
    <div className="row d-flex gap-y-1">
      <div className="col-lg-3">
        <ProListerSelect
          options={[]}
          onChange={() => {}}
          placeholder="Välj"
          disabledPlaceholder={t('categoryPicker_step') + ` 1`}
        />
      </div>
      <div className="col-lg-3">
        <ProListerSelect
          options={[]}
          onChange={() => {}}
          placeholder="Välj"
          disabledPlaceholder={t('categoryPicker_step') + ` 2`}
        />
      </div>
      <div className="col-lg-3">
        <ProListerSelect
          options={[]}
          onChange={() => {}}
          placeholder="Välj"
          disabledPlaceholder={t('categoryPicker_step') + ` 3`}
        />
      </div>
      <div className="col-lg-3">
        <ProListerSelect
          options={[]}
          onChange={() => {}}
          placeholder="Välj"
          disabledPlaceholder={t('categoryPicker_step') + ` 4`}
        />
      </div>
    </div>
  )
}

export default CategoryDisabled
