import React, { Dispatch, SetStateAction, useState } from 'react'
import { TabTypes } from '../IContent'
import { ReactComponent as ListIcon } from '../../Icons/list.svg'
import { ReactComponent as MyListsIcon } from '../../Icons/tab-my-lists.svg'
import { ReactComponent as TemplateIcon } from '../../Icons/template.svg'
import { ReactComponent as ItemIcon } from '../../Icons/icons-ic-sale-fill.svg'
import { ReactComponent as SellViewIcon } from '../../Icons/home.svg'
import { updateListName } from '../../List/List.actions'
import { updateTemplateName } from '../../Template/Template.actions'

const useTabItem = (tabType: TabTypes) => {
  let icon
  let canEditName: boolean = false
  let onNameChanged
  switch (tabType) {
    case TabTypes.List:
      {
        icon = <ListIcon />
        canEditName = true
        onNameChanged = updateListName
      }
      break
    case TabTypes.Template:
      {
        icon = <TemplateIcon />
        canEditName = true
        onNameChanged = updateTemplateName
      }
      break
    case TabTypes.MyList:
      {
        icon = <MyListsIcon />
      }
      break
    case TabTypes.SellView:
      {
        icon = <SellViewIcon height={16} width={16} />
      }
      break
    case TabTypes.MyTemplates:
      {
        icon = <TemplateIcon />
      }
      break
    case TabTypes.Item:
      {
        icon = <ItemIcon />
        canEditName = false
      }
      break
    default:
      {
      }
      break
  }

  return {
    icon,
    canEditName,
    onNameChanged,
  }
}

export default useTabItem
