import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { ISellList } from './ISellView'
import {
  cancelItemsSucceeded,
  getTraderaListsSucceeded,
  republishItemsSucceeded,
} from './SellView.actions'

export interface ISellListsState {
  [key: string]: ISellList
}

export const initialState: ISellListsState = {}

const normalizeLists = (lists: ISellList[]) =>
  lists.reduce((_lists, _list) => {
    _lists[_list.id] = _list
    _lists[_list.id].items = _lists[_list.id].items.map((item) => item.id)
    return _lists
  }, {})

const byId: Reducer<ISellListsState, IAction<any>> = handleActions(
  {
    [getTraderaListsSucceeded.toString()]: (
      _state: ISellListsState,
      action: IAction<any>
    ) => normalizeLists(action.payload),
    [cancelItemsSucceeded.toString()]: (
      _state: ISellListsState,
      action: IAction<any>
    ) => normalizeLists(action.payload),
    [republishItemsSucceeded.toString()]: (
      _state: ISellListsState,
      action: IAction<any>
    ) => normalizeLists(action.payload),
  },
  initialState
)

const allIds = handleActions(
  {
    [getTraderaListsSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<any>
    ) => [...action.payload.map((l: ISellList) => l.id)],
    [cancelItemsSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<any>
    ) => [...action.payload.map((l: ISellList) => l.id)],
    [republishItemsSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<any>
    ) => [...action.payload.map((l: ISellList) => l.id)],
  },
  []
)

const SellListsReducer = combineReducers({
  byId,
  allIds,
})

export default SellListsReducer
