import { connect } from 'react-redux'
import { closeModal, closeMinimizeModal } from '../../UI/UI.actions'
import ImportingList from './ImportingListModalMinimized'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
      isMinimize: state.ui.isMinimize,
      importMinimizeLoading: state.ui.importMinimizeLoading,
      importFileSize: state.ui.importFileSize
    }
  },
  {
    closeModal,
    closeMinimizeModal,
  }
)(ImportingList)

export default connectedComponent
