import React, { useState, useEffect, useRef } from 'react'
import PriceSummary from '../../Components/PriceSummary'
import { getItemOptionalPrice } from '../../Infrastructure/Helpers/priceCalculation'
import type { IItem, IPriceSummary } from '../../Items/IItem'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'

interface CreateItemMenuProps {
  item: IItem
  saveItem: () => void
  closeItem: () => void
  disableSaveButton?: boolean
}

const getSummaryItems = (item: IItem, t: TFunction) => {
  return [
    {
      name: t('itemMenu_summaryItems_extraAdditions'),
      amount: getItemOptionalPrice(item),
      isPrice: true,
    },
    {
      name: t('itemMenu_summaryItems_otherCosts'),
      amount: 0,
      isPrice: true,
    },
  ]
}

const CreateItemMenu: React.FunctionComponent<CreateItemMenuProps> = ({
  item,
  saveItem,
  closeItem,
  disableSaveButton,
}) => {
  const { t } = useTranslation()
  const summaryItems: IPriceSummary[] = getSummaryItems(item, t)
  const [highlightSummary, setHighlightSummary] = useState<boolean>(false)
  const [previousAmount, setPreviousAmount] = useState<number | undefined>(
    undefined
  )
  const timer = useRef<null | NodeJS.Timeout>(null)
  useEffect(() => {
    summaryItems.forEach((item) => {
      if (item.isPrice && item.amount !== previousAmount) {
        if (previousAmount !== undefined) {
          setHighlightSummary(true)
        }
        setPreviousAmount(item.amount)
        timer.current = setTimeout(() => {
          setHighlightSummary(false)
          clearTimeout(timer.current as NodeJS.Timeout)
        }, 2000)
      }
    })
    return () => {}
  }, [item])

  return (
    <div className="py-2 px-2 px-md-4 border-bottom">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-x-2">
            <button
              className="btn btn-primary"
              disabled={disableSaveButton}
              onClick={() => saveItem()}
            >
              {t('itemMenu_save')}
            </button>

            <button
              className="btn btn-outline-primary"
              onClick={() => closeItem()}
            >
              {t('itemMenu_cancel')}
            </button>
          </div>
          <div>
            <PriceSummary highlight={highlightSummary} items={summaryItems} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateItemMenu
