import type { IValidationResult } from '../Infrastructure/Helpers/validation'
import type { IAttribute, ICategory, ItemType } from '../Items/IItem'
import type { IShippingAlternative } from '../Items/ItemForm/ShippingOptions/IShipping'

export enum SellListType {
  Active = 'Active',
  Sold = 'Sold',
  Unsold = 'Unsold',
}

export interface ISellList {
  id: SellListType
  items: string[]
}

export interface ISellItem {
  id: string
  latestObjectId: number
  header: string
  description: string
  itemType: ItemType
  imageUrls: string[]
  imageRotations?: string[]
  startingBid?: number
  buyNowPrice?: number
  maxBid?: number
  totalBids: number
  publishDate?: Date | string
  vat?: number
  quantity?: number
  isChecked: boolean
  shippingCondition?: string
  condition?: string
  category?: ICategory
  reference?: string
  reservationPrice?: number
  advertisementLengthInDays?: number
  chosenEndTimeHour: number
  chosenEndTimeMinute: number
  hasChosenEndTime: boolean
  rankUp?: boolean
  restarts: 0 | 2
  isHighlighted?: boolean
  acceptedBidders: number
  shippingAlternative: IShippingAlternative[]
  attributes?: IAttribute[]
  validationResult?: IValidationResult
  campaignCode?: string
  campaignCodeValid: false
  isRestarted?: boolean
}

export interface IOrder {
  id: string
  purchaseOrderId: string
  buyer: string
  status: string
  date: Date
  orderItems: IOrderItem[]
  isChecked: boolean
}

export interface IOrderItem {
  id: string
  title: string
  vatRate: number
  quantity: number
  unitPrice: number
  status: string
}

export enum OrderStatus {
  NotPaid = 'Ej betald',
  Paid = 'Betald',
  ShippingBooked = 'Frakt bokad',
  Shipped = 'Skickad',
}

export function isSellItem(obj: any): obj is ISellItem {
  return 'totalBids' in obj
}

export interface ISellTransaction {
  id: string
  name: string
  list: ISellList
  isChecked: boolean
}

export interface ICancelItemsParams {
  listId: string
  items: ISellItem[]
}

export interface ISellViewResult {
  lists: ISellList[]
  errorItems: number[]
}
