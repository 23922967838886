import { connect } from 'react-redux'
import { closeModal, openPublishProgressModal } from '../../UI/UI.actions'
import { cancelPublishList } from '../../List/List.actions'
import PublishProgress from './PublishProgress'

const connectedComponent = connect(
  (state: any) => {
    return {}
  },
  {
    closeModal,
    cancelPublishList,
    openPublishProgressModal,
  }
)(PublishProgress)

export default connectedComponent
