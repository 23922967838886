import type { ICategory } from '../../IItem'

const getCategoryId = (level: number, category?: ICategory, depth = 1) => {
  if (!category) {
    return ''
  }
  if (level === 1) {
    return category ? category.id : ''
  }
  if (level === depth) {
    return category.id
  }
  return category.category && category.category.length > 0
    ? getCategoryId(level, category.category[0], depth + 1)
    : ''
}

const getIsValidCategoryLevel = (
  level: number,
  category?: ICategory,
  depth = 1
) => {
  if (!category) {
    return ''
  }
  if (level === 1) {
    if (category.name !== '' || category.isLastCategoryLevel) {
      return true
    }
    return false
  }
  if (level === depth) {
    //return category.isLastCategoryLevel
    if (category.name !== '' || category.isLastCategoryLevel) {
      return true
    }
    return false
  }
  return category.category && category.category.length > 0
    ? getIsValidCategoryLevel(level, category.category[0], depth + 1)
    : false
}

export { getCategoryId, getIsValidCategoryLevel }
