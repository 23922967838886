import { useState } from 'react'
import { ReactComponent as Spinner } from '@tradera/blueprint/theme/icons2/loading.svg'
import { useTranslation } from 'react-i18next'

interface PublishProgressProps {
  cancelPublishList: () => void
  closeModal: () => void
}

const PublishProgress: React.FunctionComponent<PublishProgressProps> = ({
  cancelPublishList,
  closeModal,
}) => {
  const { t } = useTranslation()
  const [disableSaveButton, setDisableSaveButton] = useState(false)

  const onCancel = () => {
    closeModal()
    setDisableSaveButton(true)
    cancelPublishList()
  }

  return (
    <div>
      <div className="p-3">
        <div className="d-flex justify-content-center align-items-center p-3">
          <div className="text-center">
            <Spinner className="icon icon-lg iconSpin" />
            <p>{t('modal_publishProgress_info')}...</p>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-outline-primary btn-fluid"
              disabled={disableSaveButton}
              onClick={() => onCancel()}
            >
              {t('modal_publishProgress_button_cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishProgress
