import React, { useState, useRef } from 'react'
import styles from './HeaderLink.module.scss'
import { useOnClickOutside } from './ClickOutside.hook'
import trackingService from '../../../Infrastructure/Tracking/trackingService'

interface HeaderLinkProps {
  link?: string
  linkName: string
  icon?: React.ReactNode
  dropDown?: boolean
  action?: () => void
  onLinkClick?: () => void
  disable?: boolean
  menuAlign?: 'left' | 'right'
  trackingData?: TrackingData
  dropDownItems?: {
    name: string
    link?: string
    icon?: React.ReactNode
    action?: () => void
    trackingData?: TrackingData
  }[]
}

const HeaderLink: React.FunctionComponent<HeaderLinkProps> = ({
  link,
  linkName,
  icon,
  dropDown,
  dropDownItems,
  menuAlign,
  action,
  onLinkClick,
  disable,
  trackingData,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropDownRef = useRef(null)

  useOnClickOutside(dropDownRef, () => setShowDropdown(false))

  const style = disable
    ? {
        background: 'white',
        opacity: 0.3,
        cursor: 'default',
      }
    : {}

  return (
    <div
      className={styles.navLink}
      style={style}
      role="button"
      onClick={(_e: React.SyntheticEvent) => {
        if (disable) {
          return
        }
        if (trackingData) {
          trackingService.trackEvent(trackingData)
        }
        if (dropDown) {
          setShowDropdown(!showDropdown)
        }
        if (onLinkClick) {
          onLinkClick()
        }
        if (!dropDown) {
          if (action) {
            action()
          }
        }
      }}
    >
      {icon && icon}
      {dropDown ? (
        <div className="dropdown" ref={dropDownRef}>
          <div
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {linkName}
          </div>
          <div
            className={`dropdown-menu ${
              menuAlign ? `dropdown-menu-${menuAlign}` : ''
            } ${styles.dropdownMenu} ${showDropdown ? styles.show : ''}`}
          >
            {dropDownItems &&
              dropDownItems.map((item, key) => (
                <div key={key}>
                  <button
                    onClick={() => {
                      if (item.trackingData) {
                        trackingService.trackEvent(item.trackingData)
                      }
                      if (item.action) {
                        item.action()
                        setShowDropdown(false)
                      }
                    }}
                    className={`dropdown-item ${styles.dropdownItem}`}
                  >
                    {item.icon && item.icon}
                    {item.name}
                  </button>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <a target="_blank" rel="noreferrer" href={link}>
          {linkName}
        </a>
      )}
    </div>
  )
}

export default HeaderLink
