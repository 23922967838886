import React from 'react'
import type { IList } from '../../List/IList'
import type { IItem } from '../../Items/IItem'
import { ReactComponent as FailedIcon } from '../../Icons/warning_big.svg'
import { useTranslation } from 'react-i18next'

interface PublishValidProps {
  closeModal: () => void
  openPublishModal: (listId: string, selectedItems: IItem[]) => void
  list: IList
  items: IItem[]
}

const PublishValid: React.FunctionComponent<PublishValidProps> = ({
  closeModal,
  openPublishModal,
  list,
  items,
}) => {
  const { t } = useTranslation()
  const unvalidItems = items?.filter((x) => !x.validationResult?.isValid)
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <FailedIcon className="icon icon-lg" />
        </div>
        <p className="text-left">
          {t('modal_publishValid_info')} {unvalidItems.length}{' '}
          {unvalidItems.length === 1 ? (
            <span> {t('modal_publishValid_unvalidAd')}. </span>
          ) : (
            <span> {t('modal_publishValid_unvalidAds')}. </span>
          )}{' '}
          {t('modal_publishValid_rules')}.
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => openPublishModal(list.id, [])}
            >
              {t('modal_publishValid_button_confirm')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_publishValid_button_cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishValid
