import { createActions } from 'redux-actions'
import { get } from '../Infrastructure/Network'
import type { IAttribute, ICategory } from '../Items/IItem'
import type { IItem } from '../Items/IItem'
import type { IShippingOptions } from '../Items/ItemForm/ShippingOptions/IShipping'
import type { ISellItem } from '../SellView/ISellView'

export const {
  openImportModal,
  openImportMappingModal,
  minimize,
  closeMinimizeModal,
  closeModal,
  openBankIdModal,
  openBankIdVerifyModal,
  openPublishModal,
  openPublishSelectedModal,
  openPublishValidModal,
  openDeleteItemsModal,
  openDeleteListsModal,
  openCancelSellItemsModal,
  openPublicationResultModal,
  openErrorMessageModal,
  openImageErrorMessageModal,
  openPublishErrorMessageModal,
  openSellItemsErrorModal,
  openEditManyItemsAtOnceModal,
  openEditShopItemModal,
  openEditItemModal,
  openCreateItemFromImageModal,
  openEditCategoryModal,
  openSelectColumnsModal,
  openSettingsModal,
  isPending,
  isCompleted,
  setLoadingLists,
  openImportingModal,
  openImportingPlzipModal,
  openAlertContainer,
  resetAlertContainer,
  setImportFileSize,
  setImportData,
  setImportToList,
  setSaving,
  bankIdIsRequired,
  bankIdVerificationEnd,
  setBankIdOrderRef,
  setBankIdQrData,
  setAutoStartToken,
  setDesktopAutoStartToken,
  openPublishProgressModal,
  openLoadingModal,
  saveClickOrderOnItemInList,
  setUploadInterval,
  setUploadDateTime,
  setUploadItems,
  setUploadSellItems,
} = createActions({
  openImportModal: () => ({}),
  openImportMappingModal: () => ({}),
  openImportingModal: () => ({}),
  openImportingPlzipModal: () => ({}),
  openBankIdModal: () => ({}),
  openBankIdVerifyModal: () => ({}),
  openPublishModal: (id: string, selectedItems: IItem[]) => ({
    id,
    selectedItems,
  }),
  openPublishSelectedModal: (id: string) => ({ id }),
  openPublishValidModal: (id: string) => ({ id }),
  openDeleteItemsModal: (id: string) => ({ id }),
  openCancelSellItemsModal: (id: string) => ({ id }),
  openDeleteListsModal: (listIds: string[]) => ({ listIds }),
  openPublishProgressModal: () => ({}),
  openLoadingModal: (message: string) => ({ message }),
  openPublicationResultModal: (numberOfFailedItems = 0) => ({
    numberOfFailedItems,
  }),
  openErrorMessageModal: (errorCode: any) => ({ errorCode }),
  openImageErrorMessageModal: () => ({}),
  openPublishErrorMessageModal: (errorMessage: any) => ({ errorMessage }),
  openSellItemsErrorModal: (errorItems: number[], message: string) => ({
    errorItems,
    message,
  }),
  openEditManyItemsAtOnceModal: (id: string, items: IItem[]) => ({ id, items }),
  openEditShopItemModal: (id: string) => ({ id }),
  openEditItemModal: (id: string) => ({ id }),
  openCreateItemFromImageModal: (listId: string, imageUrls: string[]) => ({}),
  openEditCategoryModal: (id: string, item: IItem) => ({ id }),
  openSelectColumnsModal: (columnOrders: string[]) => ({ columnOrders }),
  openSettingsModal: (purchaseTerms: string) => ({ purchaseTerms }),
  setImportFileSize: (importFileSize: number) => importFileSize,
  setImportData: (importData: any) => importData,
  setImportToList: (importToList: boolean) => importToList,
  setSaving: (saving: boolean) => saving,
  bankIdIsRequired: (
    bankIdRequired: boolean,
    listId: string,
    personalNumber: string,
    uploadInterval: string,
    uploadDateTime: string
  ) => ({
    bankIdRequired: bankIdRequired,
    listId: listId,
    personalNumber: personalNumber,
    uploadInterval: uploadInterval,
    uploadDateTime: uploadDateTime,
  }),
  bankIdVerificationEnd: (values: any) => values,
  setBankIdOrderRef: (bankIdOrderRef: string) => bankIdOrderRef,
  setBankIdQrData: (qrData: string) => ({ qrData }),
  setAutoStartToken: (autoStartToken: string) => ({ autoStartToken }),
  setDesktopAutoStartToken: (autoStartToken: string) => ({
    desktopAutoStartToken: autoStartToken,
  }),
  closeMinimizeModal: () => ({}),
  minimize: () => ({}),
  closeModal: () => ({}),
  isPending: () => ({}),
  isCompleted: () => ({}),
  setLoadingLists: (isLoading: boolean) => isLoading,
  openAlertContainer: (message: string, alertType: string) => ({
    message,
    alertType,
  }),
  resetAlertContainer: () => ({}),
  setUploadInterval: (uploadInterval?: string) => uploadInterval,
  setUploadDateTime: (uploadDateTime?: string) => uploadDateTime,
  setUploadItems: (uploadItems: string[]) => uploadItems,
  setUploadSellItems: (uploadSellItems: ISellItem[]) => uploadSellItems,
  //saveClickOrderOnItemInList: (itemId: string, selectTime: string, listId: string) => ({ itemId, selectTime, listId }),
  //saveClickOrderOnItemInList: (clickOrderItem: IItemClickOrder) => ({ clickOrderItem }),
})

export const {
  getShippingOptionsRequested,
  getShippingOptionsSucceeded,
  getShippingOptionsRejected,
} = createActions({
  getShippingOptionsSucceeded: (shippingOptions: IShippingOptions[]) =>
    shippingOptions,
  getShippingOptionsRequested: () => ({}),
  getShippingOptionsRejected: (error: any) => ({}),
})

export const getShippingOptions = () =>
  get({
    url: `/item/shippingoptions`,
    initiated: getShippingOptionsRequested,
    fulfilled: getShippingOptionsSucceeded,
    rejected: getShippingOptionsRejected,
  })

export const {
  getCategoriesRequested,
  getCategoriesSucceeded,
  getCategoriesRejected,
} = createActions({
  getCategoriesSucceeded: (categories: ICategory[]) => categories,
  getCategoriesRequested: () => ({}),
  getCategoriesRejected: (error: any) => ({}),
})

export const getCategories = () =>
  get({
    url: `/item/categories`,
    initiated: getCategoriesRequested,
    fulfilled: getCategoriesSucceeded,
    rejected: getCategoriesRejected,
  })

export const {
  getAttributesRequested,
  getAttributesSucceeded,
  getAttributesRejected,
} = createActions({
  getAttributesSucceeded: (categoryId: string, attributes: IAttribute[]) => ({
    categoryId,
    attributes,
  }),
  getAttributesRequested: () => ({}),
  getAttributesRejected: (error: any) => ({}),
})

export const getAttributes = (categoryId: string) =>
  get({
    url: `/item/attributes/${categoryId}`,
    initiated: getAttributesRequested,
    fulfilled: (attributes: IAttribute[]) =>
      getAttributesSucceeded(categoryId, attributes),
    rejected: getAttributesRejected,
  })

export const {
  getCampaignCodeValidRequested,
  getCampaignCodeValidSucceeded,
  getCampaignCodeValidRejected,
} = createActions({
  getCampaignCodeValidSucceeded: (campaignCodeValid: boolean) =>
    campaignCodeValid,
  getCampaignCodeValidRequested: () => ({}),
  getCampaignCodeValidRejected: (error: any) => ({}),
})

export const getCampaignCodeValid = (
  campaignCode: string,
  categoryId: number
) =>
  get({
    url: `/item/campaignCodeValid?${`campaignCode=${campaignCode}&categoryId=${categoryId}`}`,
    initiated: getCampaignCodeValidRequested,
    fulfilled: (campaignCodeValid: boolean) =>
      getCampaignCodeValidSucceeded(campaignCodeValid),
    rejected: getCampaignCodeValidRejected,
  })
