import { connect } from 'react-redux'

import LoginCallback from './LoginCallback'
import { returnedFromLoginPage } from '../Authentication.action'

const connectedComponent = connect((state: any) => ({}), {
  returnedFromLoginPage,
})(LoginCallback)

export default connectedComponent
