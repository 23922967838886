import React, { useEffect, useState } from 'react'
import ItemMenu from '../ItemMenu'
import ItemForm from '../ItemForm'
import useItem from './Item.hook'
import type { IItem, IFileUploadImage } from '../IItem'
import { useDispatch, useSelector } from 'react-redux'
import { selectItemWipById } from './Item.selector'
import removeProperty from '../../Infrastructure/Helpers/removeProperty'
import Footer from '../../Components/Footer'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

interface ItemProps {
  id: string
  user: IUser
  createItem: (item: FormData) => Promise<any>
  closeTab: (id: string) => void
  getItem: (id: string) => void
  updateItem: (item: IItem, showToast: boolean) => void
  addImages: (images: FormData, id: string) => Promise<any>
}

const Item: React.FunctionComponent<ItemProps> = ({
  id,
  user,
  createItem,
  closeTab,
  getItem,
  updateItem,
  addImages,
}) => {
  const { t } = useTranslation()
  const [imageList, setImageList] = useState<IFileUploadImage[]>([])
  const itemfromApi: IItem = useSelector((state) =>
    selectItemWipById(state, id)
  )
  const [fromApi, setFromApi] = useState(false)
  const [isCreatingItem, setIsCreatingItem] = useState(false)
  const [isDisabledSave, setSaveDisabled] = useState(false)

  const saveItem = (item: IItem) => {
    let form_data = new FormData()
    if (item.id && item.id.startsWith('newitem')) {
      item = removeProperty(item, 'id')
    }
    if (imageList) {
      imageList.forEach((image) => {
        if (image.file) {
          form_data.append(`files`, image.file)
        }
      })
    }
    for (let key in item) {
      const objectsToStringify = [
        'category',
        'attributes',
        'shippingalternative',
      ]
      if (objectsToStringify.includes(key.toLowerCase())) {
        form_data.append(key, JSON.stringify(item[key]))
      } else {
        form_data.append(key, item[key])
      }
    }
    if (!isCreatingItem) {
      setIsCreatingItem(true)
      createItem(form_data).then(() => {
        setIsCreatingItem(false)
      })
    }
  }

  const updateCurrentItem = (item: IItem, deletedImages: string[]) => {
    updateItem(item, true)
  }

  const updateImages = (files: File[], id: string) => {
    let form_data = new FormData()
    files.forEach((file) => {
      form_data.append('files', file)
    })
    addImages(form_data, id)
  }

  const {
    item,
    handleInputChange,
    handleEditorChange,
    updateStateByItem,
    setItems,
  } = useItem(itemfromApi)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id?.includes('newitem')) {
      if (!itemfromApi) {
        getItem(id)
      }
    }

    if (itemfromApi && !id.startsWith('newitem')) {
      setItems(itemfromApi)
      setFromApi(true)
    }
    setSaveDisabled(false)
  }, [getItem, id, itemfromApi, setItems])
  if (item) {
    return (
      <div style={{ width: '100%' }}>
        <ItemMenu
          item={item}
          closeItem={() => closeTab(id)}
          saveItem={() => {
            if (!isDisabledSave) {
              fromApi
                ? updateCurrentItem(item, [])
                : saveItem({ ...item, listId: id.replace('newitem-', '') })
            }
            setSaveDisabled(true)
          }}
          disableSaveButton={isDisabledSave}
        />
        <div
          style={{
            overflowY: 'scroll',
            flex: 1,
            position: 'fixed',
            top: 200,
            bottom: 0,
            width: '100%',
          }}
        >
          <ItemForm
            item={item}
            user={user}
            imageList={imageList}
            handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, dispatch)
            }
            updateStateByItem={(e, v) => updateStateByItem(e, v, dispatch)}
            handleEditorChange={(editorState) =>
              handleEditorChange(editorState, dispatch)
            }
            updateImages={(files, id) => updateImages(files, id)}
            handleImageListChange={setImageList}
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex mb-3 mx-2 mx-md-4 gap-x-2">
                  <button
                    className="btn btn-primary"
                    disabled={isDisabledSave}
                    onClick={() => {
                      fromApi
                        ? updateCurrentItem(item, [])
                        : saveItem({
                            ...item,
                            listId: id.replace('newitem-', ''),
                          })
                      setSaveDisabled(true)
                    }}
                  >
                    {t('item_save')}
                  </button>

                  <button
                    className="btn btn-outline-primary"
                    onClick={() => closeTab(id)}
                  >
                    {t('item_cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer position="static" />
        </div>
      </div>
    )
  }
  return null
}
export default Item
