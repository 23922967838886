import React, { useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import type { IOrder } from '../ISellView'
import { OrderStatus } from '../ISellView'
import ImageCellRenderer from '../../ProListerGrid/Renderers/ImageCellRenderer'
import { ReactComponent as DotsIcon } from '../../Icons/dots.svg'
import { ReactComponent as OpenIcon } from '@tradera/blueprint/theme/icons2/open.svg'
import { ContextMenu } from 'primereact/contextmenu'
import { setOrderAsPaid, setOrderAsShipped } from '../SellView.actions'
import { useDispatch } from 'react-redux'
import Button from '../../Components/Button'
import styles from './OrderGrid.module.scss'
import { useTranslation } from 'react-i18next'

const URL = 'https://www.tradera.com'

interface OrderGridProps {
  orders: IOrder[]
  expandedRows: {}
  setExpandedRows: (expandedRows: {}) => void
}

const OrderGrid: React.FunctionComponent<OrderGridProps> = ({
  orders,
  expandedRows,
  setExpandedRows,
}) => {
  const { t } = useTranslation()
  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null)
  const cm = useRef<ContextMenu>(null)
  const dispatch = useDispatch()
  const menuModel = [
    {
      label: t('sellView_orderGrid_markAsPaid'),
      disabled: OrderStatus[selectedOrder?.status!] !== OrderStatus.NotPaid,
      icon: null,
      command: () => dispatch(setOrderAsPaid(selectedOrder!.id)),
    },
    {
      label: t('sellView_orderGrid_markAsShipped'),
      disabled: OrderStatus[selectedOrder?.status!] === OrderStatus.Shipped,
      icon: null,
      command: () => dispatch(setOrderAsShipped(selectedOrder!.id)),
    },
    {
      separator: true,
    },
    {
      label: t('sellView_orderGrid_bookShipping'),
      disabled:
        OrderStatus[selectedOrder?.status!] === OrderStatus.Shipped ||
        OrderStatus[selectedOrder?.status!] === OrderStatus.ShippingBooked,
      icon: <OpenIcon width={14} height={14} style={{ marginRight: 10 }} />,
      command: () =>
        openLink(
          `${URL}/my/shipping?purchaseOrderId=${selectedOrder?.purchaseOrderId}`
        ),
    },
    {
      separator: true,
    },
    {
      label: t('sellView_orderGrid_leaveFeedback'),
      icon: <OpenIcon width={14} height={14} style={{ marginRight: 10 }} />,
      command: () => openLink(`${URL}/my/leave-feedback/seller`),
    },
    {
      separator: true,
    },
    {
      label: t('sellView_orderGrid_viewOrder'),
      icon: <OpenIcon width={14} height={14} style={{ marginRight: 10 }} />,
      command: () =>
        openLink(
          `${URL}/my/sold/buyer-info?purchaseOrderId=${selectedOrder?.purchaseOrderId}`
        ),
    },
    {
      separator: true,
    },
    {
      label: t('sellView_orderGrid_viewSoldList'),
      icon: <OpenIcon width={14} height={14} style={{ marginRight: 10 }} />,
      command: () => openLink(`${URL}/my/sold`),
    },
  ]

  const openLink = (url: string) => {
    window.open(url, '_blank')
  }

  const imageBodyTemplate = (data) => {
    return <ImageCellRenderer value={data.imageUrls} />
  }

  const menuBodyTemplate = (rowData) => {
    return (
      <div className={styles.menuButtonContainer}>
        <button
          onClick={(e) => {
            if (cm.current) {
              setSelectedOrder(rowData)
              cm.current.show(e)
            }
          }}
          className={styles.menuButton}
          title="Open Menu"
        >
          <DotsIcon />
        </button>
      </div>
    )
  }

  const statusOrderBodyTemplate = (rowData) => {
    return (
      <span
        className={`${styles.statusBadge} status-label ${getOrderStatusStyle(
          rowData
        )}`}
      >
        {OrderStatus[rowData.status]}
      </span>
    )
  }

  const getOrderStatusStyle = (order) => {
    let statusValue = OrderStatus[order.status]

    switch (statusValue) {
      case OrderStatus.ShippingBooked:
        return 'green-background text-white'

      case OrderStatus.Shipped:
        return 'dark-background text-white'

      case OrderStatus.Paid:
        return 'green-background text-white'

      case OrderStatus.NotPaid:
        return 'yellow-background'

      default:
        return null
    }
  }

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.date)
    const formatter = new Intl.DateTimeFormat('sv-SE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'Europe/Stockholm',
    })
    return formatter.format(date)
  }

  const orderLinkBodyTemplate = (rowData) => {
    const orderUrl = `${URL}/item/${rowData.itemId}`

    return (
      <a
        href={orderUrl}
        target="blank"
        className="link-color"
        style={{ textDecoration: 'none' }}
      >
        {rowData.itemId}
      </a>
    )
  }

  const titleBodyTemplate = (rowData) => {
    return <div className={styles.titleContainer}>{rowData.title}</div>
  }

  const priceBodyTemplate = (rowData) => {
    return <div>{rowData.unitPrice} kr</div>
  }

  const vatBodyTemplate = (rowData) => {
    return <div>{rowData.vatRate}%</div>
  }

  const shippingBodyTemplate = (rowData) => {
    return (
      <div className="d-inline-block">
        {OrderStatus[rowData.status] === OrderStatus.Paid && (
          <Button
            clickHandler={() =>
              openLink(
                `${URL}/my/shipping?purchaseOrderId=${rowData.purchaseOrderId}`
              )
            }
            variant="primary"
            size="xsmall"
            text={t('sellView_orderGrid_button_bookShipping')}
          />
        )}
      </div>
    )
  }

  const rowExpansionTemplate = (data: IOrder) => {
    return (
      <div className="nestedDataTableContainer">
        <DataTable value={data.orderItems}>
          <Column
            field="itemId"
            header={t('sellView_orderGrid_columnHeader_itemId')}
            body={orderLinkBodyTemplate}
            style={{ width: '8rem' }}
          ></Column>
          <Column
            field="imageUrls"
            header={t('sellView_orderGrid_columnHeader_imageUrls')}
            body={imageBodyTemplate}
            style={{ width: '16rem' }}
          ></Column>
          <Column
            field="title"
            header={t('sellView_orderGrid_columnHeader_title')}
            body={titleBodyTemplate}
            style={{
              width: '30rem',
            }}
          ></Column>
          <Column
            field="unitPrice"
            header={t('sellView_orderGrid_columnHeader_unitPrice')}
            body={priceBodyTemplate}
            style={{ width: '6rem' }}
          ></Column>
          <Column
            field="vatRate"
            header={t('sellView_orderGrid_columnHeader_vatRate')}
            body={vatBodyTemplate}
            style={{ width: '6rem' }}
          ></Column>
          <Column
            field="quantity"
            header={t('sellView_orderGrid_columnHeader_quantity')}
          ></Column>
        </DataTable>
      </div>
    )
  }

  return (
    <>
      <ContextMenu
        model={menuModel}
        ref={cm}
        onHide={() => setSelectedOrder(null)}
        style={{ width: '280px', borderRadius: '0px' }}
      />
      <DataTable
        value={orders}
        scrollable
        scrollHeight="calc(100vh - 200px)"
        size="small"
        showGridlines
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
        onContextMenu={(e) => cm.current?.show(e.originalEvent)}
        contextMenuSelection={selectedOrder || undefined}
        onContextMenuSelectionChange={(e) => setSelectedOrder(e.value)}
        style={{ padding: 0 }}
      >
        <Column
          style={{ width: '3rem', backgroundColor: '#f9fafb' }}
          body={menuBodyTemplate}
        />
        <Column expander={true} style={{ width: '3rem' }} />
        <Column
          field="id"
          header={t('sellView_orderGrid_columnHeader_id')}
          style={{ width: '8rem' }}
          sortable
        ></Column>
        <Column
          field="status"
          header={t('sellView_orderGrid_columnHeader_status')}
          style={{ width: '8rem' }}
          body={statusOrderBodyTemplate}
          sortable
        ></Column>
        <Column
          field="date"
          header={t('sellView_orderGrid_columnHeader_date')}
          body={dateBodyTemplate}
          style={{ width: '16rem' }}
          sortable
        ></Column>
        <Column
          field="buyer"
          header={t('sellView_orderGrid_columnHeader_buyer')}
          style={{ width: '10rem' }}
          sortable
        ></Column>
        <Column body={shippingBodyTemplate}></Column>
      </DataTable>
    </>
  )
}
export default OrderGrid
