import { connect } from 'react-redux'
import { closeModal, openEditShopItemModal } from '../../UI/UI.actions'
import EditShopItem from './EditShopItem'
import { addImages } from '../../Items/Items.action'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
      user: state.user,
      saving: state.ui['saving'],
      modalType: state.ui.modalType,
      itemFromApi: state.sellItems.byId[state.ui.id],
    }
  },
  {
    closeModal,
    addImages,
    openEditShopItemModal,
  }
)(EditShopItem)

export default connectedComponent
