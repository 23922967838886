import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { updatePurchaseTerms } from '../../User/User.actions'

import SettingsModal from './SettingsModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      user: state.user,
    }
  },
  {
    updatePurchaseTerms,
    closeModal,
  }
)(SettingsModal)

export default connectedComponent
