import React from 'react'
import Input from '../../Components/Input'
import { ItemType, type IFileUploadImage } from '../../Items/IItem'
import Label from '../../Components/Label'
import type { IUser } from '../../User/IUser'
import type { ISellItem } from '../ISellView'
import ErrorLabel from '../../Items/ItemForm/ErrorLabel'
import Condition from '../../Items/ItemForm/Condition'
import TypeOfSale from '../../Items/ItemForm/TypeOfSale'
import ShippingOptions from '../../Items/ItemForm/ShippingOptions'
import ItemLocation from '../../Items/ItemForm/ItemLocation'
import FileUpload from '../../Items/ItemForm/FileUpload'
import Editor from '../../Components/Editor'
import Category from '../../Items/ItemForm/Category'
import ItemLength from '../../Items/ItemForm/ItemLength'
import CheckBox from '../../Components/CheckBox'
import { ReactComponent as InfoIcon } from '../../Icons/info.svg'
import CampaignCode from '../../Items/ItemForm/CampaignCode'
import { useTranslation } from 'react-i18next'

interface SellItemFormProps {
  item: ISellItem
  user: IUser
  imageList: IFileUploadImage[]
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleEditorChange: (editorState: string) => void
  updateStateByItem: (property: string, value: any) => void
  updateImages: (files: File[], id: string) => void
  handleImageListChange: (imageList: IFileUploadImage[]) => void
}
const SellItemForm: React.FunctionComponent<SellItemFormProps> = ({
  item,
  user,
  imageList,
  handleInputChange,
  handleEditorChange,
  updateStateByItem,
  updateImages,
  handleImageListChange,
}) => {
  const { t } = useTranslation()
  const isShopItem = item.itemType === ItemType.ShopItem
  const changeRestarts = (a, b) => {
    if (b) {
      item.restarts = 2
    } else {
      item.restarts = 0
    }
    updateStateByItem(a, b)
  }
  return (
    <div className="container">
      <div className="mx-2 my-2">
        <div className="row">
          <div className="col-12">
            {item.validationResult &&
            !item.validationResult?.properties.imageUrls ? (
              <ErrorLabel
                label={t('sellItemForm_images_label') + '*'}
                description={t('sellItemForm_images_labelError')}
              />
            ) : (
              <Label
                label={t('sellItemForm_images_label') + '*'}
                description={t('sellItemForm_images_labelDescription')}
              />
            )}
            <FileUpload
              item={item}
              imageList={imageList}
              updateImages={(files, id) => updateImages(files, id)}
              setImageRotations={(imageRotations: string[]) =>
                updateStateByItem('imageRotations', imageRotations)
              }
              onImageListChange={(imageList: IFileUploadImage[]) =>
                handleImageListChange(imageList)
              }
              addImageUrls={(images: string[]) =>
                updateStateByItem('imageUrls', images)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <Input
              value={item.header}
              group="form-group"
              name="header"
              type="text"
              error={
                item.validationResult &&
                !item.validationResult?.properties.header
              }
              label={t('sellItemForm_header_label') + '*'}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.header
                  ? t('sellItemForm_header_labelError')
                  : ''
              }
              hint={
                `(${item.header ? item.header.length : 0}/80 ` +
                t('sellItemForm_hint') +
                `)`
              }
              maxLength={80}
              onChangeValue={(e) => handleInputChange(e)}
              placeholder={t('sellItemForm_header_placeholder')}
            />
          </div>
          <div className="col-md-5">
            <Input
              group="form-group"
              name="reference"
              type="text"
              error={
                item.validationResult &&
                !item.validationResult?.properties.reference
              }
              label={t('sellItemForm_reference_label')}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.reference
                  ? t('sellItemForm_reference_labelError')
                  : '(' + t('sellItemForm_reference_labelDescription') + ')'
              }
              hint={`(${item.reference ? item.reference.length : 0}/60 tecken)`}
              maxLength={60}
              value={item.reference}
              onChangeValue={(e) => handleInputChange(e)}
              placeholder={t('sellItemForm_reference_placeholder')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {item.validationResult &&
            !item.validationResult?.properties.description ? (
              <ErrorLabel
                label={t('sellItemForm_description_label') + '*'}
                description={t('sellItemForm_description_labelError')}
              />
            ) : (
              <Label label={t('sellItemForm_description_label') + '*'} />
            )}
            <Editor
              handleEditorChange={(editorState: string) =>
                handleEditorChange(editorState)
              }
              item={item}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 ">
            <Category item={item} updateStateByItem={updateStateByItem} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {item.validationResult &&
            !item.validationResult?.properties.condition ? (
              <ErrorLabel
                label={t('sellItemForm_condition_label') + '*'}
                description={t('sellItemForm_condition_labelError')}
              />
            ) : (
              <Label label={t('sellItemForm_condition_label') + '*'} />
            )}
            <Condition item={item} handleInputChange={handleInputChange} />
          </div>
        </div>
      </div>
      <div className="mx-2 my-2">
        <div className="row">
          <div className="col-md-12">
            <TypeOfSale
              item={item}
              user={user}
              handleInputChange={handleInputChange}
              updateStateByItem={updateStateByItem}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <ItemLength item={item} updateStateByItem={updateStateByItem} />
          </div>
        </div>
        {!isShopItem && (
          <div className="row">
            <div className="col-12">
              <Label label={t('sellItemForm_additions_label')} />
              <div className="row">
                <div className="col-4">
                  <CheckBox
                    checked={item.isHighlighted}
                    label={t('sellItemForm_highlight_label')}
                    handleInputChange={updateStateByItem}
                    name="isHighlighted"
                  />
                </div>
                <div className="col-4">
                  <CheckBox
                    checked={item.rankUp}
                    handleInputChange={updateStateByItem}
                    label={t('sellItemForm_rankUp_label')}
                    name="rankUp"
                  />
                </div>
                <div className="col-4">
                  <div className="flex gap-x-1 align-items-center row pl-1">
                    <CheckBox
                      checked={item.restarts === 2 ? true : false}
                      label={t('sellItemForm_restart_label')}
                      value={item.restarts}
                      handleInputChange={(a, b) => changeRestarts(a, b)}
                      name="changeRestart"
                    />
                    <div className="tooltip_container mb-1">
                      <div className="tooltip_icon">
                        <InfoIcon
                          style={{
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      </div>
                      <div className="tooltip">
                        {t('sellItemForm_restart_tooltip')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mx-2 my-2">
        <ShippingOptions updateStateByItem={updateStateByItem} item={item} />
        <div className="row">
          <div className="col-md-9">
            <Input
              value={item.shippingCondition}
              group="form-group"
              name="shippingCondition"
              type="text"
              label={t('sellItemForm_shippingCondition_label')}
              labelDescription={
                item.validationResult &&
                !item.validationResult?.properties.shippingCondition
                  ? t('sellItemForm_shippingCondition_errorLabel')
                  : ''
              }
              maxLength={500}
              hint={
                `(${
                  item.shippingCondition ? item.shippingCondition.length : 0
                }/500 ` +
                t('sellItemForm_hint') +
                `)`
              }
              onChangeValue={handleInputChange}
              placeholder={t('sellItemForm_shippingCondition_placeholder')}
            />
          </div>
          <div className="col-md-3">
            <ItemLocation
              updateStateByItem={updateStateByItem}
              item={item}
              label={t('sellItemForm_itemLocation_label')}
            />
          </div>
        </div>
      </div>
      {!isShopItem && (
        <div className="mx-2 my-2">
          <div className="row">
            <div className="col-md-6">
              <CampaignCode
                item={item}
                handleInputChange={handleInputChange}
                updateStateByItem={updateStateByItem}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SellItemForm
