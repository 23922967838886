export interface IUser {
  memberId: string
  numberOfLists: 0
  columnOrders: string[]
  hasShop: boolean
  isCompany: boolean
  purchaseTerms: string
  currency: string
  language: Language
  country: string
}

export enum Country {
  Sweden = 'Sverige',
}

export enum Language {
  sv = 'language_sv',
  da = 'language_da',
  nl = 'language_nl',
  en = 'language_en',
}
