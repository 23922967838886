import React from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as Arrow } from '../../Icons/bigger-box.svg'
import Button from '../../Components/Button'
import type { IItem } from '../../Items/IItem'
import { openContent } from '../../Content/Content.actions'
import { TabTypes } from '../../Content/IContent'

interface DragRendererProps {
  data: IItem
}

const DragCellRenderer: React.FunctionComponent<DragRendererProps> = ({
  data,
}) => {
  const dispatch = useDispatch()
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Button
        clickHandler={() =>
          dispatch(
            openContent(
              {
                type: TabTypes.Item,
                id: data.id!,
                name: data.header,
                selected: true,
              },
              data.header
            )
          )
        }
        size="large"
        icon={<Arrow />}
        variant="noborder"
        style={{ paddingRight: 12 }}
      />
    </div>
  )
}

export default DragCellRenderer
