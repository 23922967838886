import { ReactComponent as FailedIcon } from '@tradera/blueprint/theme/icons2/close-fill.svg'
import { useTranslation } from 'react-i18next'

const ErrorMessage = ({ errorCode, closeModal }: any) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <FailedIcon className="icon icon-lg" />
        </div>
        <p className="text-left">
          {errorCode === 1010 && t('modal_errorMessage_1010')}
          {errorCode >= 400 && errorCode < 500 && t('modal_errorMessage_400')}
          {errorCode >= 500 && errorCode < 1000 && t('modal_errorMessage_500')}
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_errorMessage_button_close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage
