import React from 'react'
import Input from '../../Components/Input'
import Condition from './Condition'
import Category from './Category'
import CheckBox from '../../Components/CheckBox'
import { type IItem, type IFileUploadImage, ItemType } from '../../Items/IItem'
import Editor from '../../Components/Editor'
import TypeOfSale from './TypeOfSale'
import ItemLength from './ItemLength'
import FileUpload from './FileUpload'
import ErrorLabel from './ErrorLabel'
import ItemLocation from './ItemLocation'
import CampaignCode from './CampaignCode'
import Label from '../../Components/Label'
import { ReactComponent as InfoIcon } from '../../Icons/info.svg'
import ShippingOptions from './ShippingOptions'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'
import styles from './ItemForm.module.scss'

interface ItemFormProps {
  item: IItem
  user: IUser
  imageList: IFileUploadImage[]
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleEditorChange: (editorState: string) => void
  updateStateByItem: (
    property: string,
    value: string | number | boolean | File[] | string[] | any
  ) => void
  updateImages: (files: File[], id: string) => void
  handleImageListChange: (imageList: IFileUploadImage[]) => void
}
const ItemForm: React.FunctionComponent<ItemFormProps> = ({
  item,
  user,
  imageList,
  handleInputChange,
  handleEditorChange,
  updateStateByItem,
  updateImages,
  handleImageListChange,
}) => {
  const { t } = useTranslation()
  const isShopItem = item.itemType === ItemType.ShopItem
  const isFixedPrice = item.itemType === ItemType.FixedPrice

  const changeRestarts = (a, b) => {
    if (b) {
      item.restarts = 2
    } else {
      item.restarts = 0
    }
    updateStateByItem(a, b)
  }
  const changeEndDate = (a, b) => {
    // Triggar när välj sluttid checkas i eller ur
    updateStateByItem(a, b)
    if (b === false) {
      updateStateByItem('chosenEndTimeHour', 0)
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-9">
          <div className={`${styles.formContainer} mx-2 mx-md-4 my-2`}>
            <div className="row">
              <div className="col-12">
                {item.validationResult &&
                !item.validationResult?.properties.imageUrls ? (
                  <ErrorLabel
                    label={t('itemsForm_images_label') + '*'}
                    description={t('itemsForm_images_labelError')}
                  />
                ) : (
                  <Label
                    label={t('itemsForm_images_label') + '*'}
                    description={t('itemsForm_images_labelDescription')}
                  />
                )}
                <FileUpload
                  item={item}
                  imageList={imageList}
                  updateImages={(files, id) => updateImages(files, id)}
                  setImageRotations={(imageRotations: string[]) =>
                    updateStateByItem('imageRotations', imageRotations)
                  }
                  onImageListChange={(imageList: IFileUploadImage[]) =>
                    handleImageListChange(imageList)
                  }
                  addImageUrls={(images: string[]) =>
                    updateStateByItem('imageUrls', images)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <Input
                  value={item.header}
                  group="form-group"
                  name="header"
                  type="text"
                  error={
                    item.validationResult &&
                    !item.validationResult?.properties.header
                  }
                  label={t('itemsForm_header_label') + '*'}
                  labelDescription={
                    item.validationResult &&
                    !item.validationResult?.properties.header
                      ? t('itemsForm_header_labelError')
                      : ''
                  }
                  hint={
                    `(${item.header ? item.header.length : 0}/80 ` +
                    t('itemsForm_hint') +
                    `)`
                  }
                  maxLength={80}
                  onChangeValue={(e) => handleInputChange(e)}
                  placeholder={t('itemsForm_header_placeholder')}
                />
              </div>
              <div className="col-md-5">
                <Input
                  group="form-group"
                  name="reference"
                  type="text"
                  error={
                    item.validationResult &&
                    !item.validationResult?.properties.reference
                  }
                  label={t('itemsForm_reference_label')}
                  labelDescription={
                    item.validationResult &&
                    !item.validationResult?.properties.reference
                      ? t('itemsForm_reference_labelError')
                      : '(' + t('itemsForm_reference_labelDescription') + ')'
                  }
                  hint={
                    `(${item.reference ? item.reference.length : 0}/60 ` +
                    t('itemsForm_hint') +
                    `)`
                  }
                  maxLength={60}
                  value={item.reference}
                  onChangeValue={(e) => handleInputChange(e)}
                  placeholder={t('itemsForm_reference_placeholder')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {item.validationResult &&
                !item.validationResult?.properties.description ? (
                  <ErrorLabel
                    label={t('itemsForm_description_label') + '*'}
                    description={t('itemsForm_description_labelError')}
                  />
                ) : (
                  <Label label={t('itemsForm_description_label') + '*'} />
                )}
                <Editor
                  handleEditorChange={(editorState: string) =>
                    handleEditorChange(editorState)
                  }
                  item={item}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 ">
                <Category item={item} updateStateByItem={updateStateByItem} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {item.validationResult &&
                !item.validationResult?.properties.condition ? (
                  <ErrorLabel
                    label={t('itemsForm_condition_label') + '*'}
                    description={t('itemsForm_condition_labelError')}
                  />
                ) : (
                  <Label label={t('itemsForm_condition_label') + '*'} />
                )}
                <Condition item={item} handleInputChange={handleInputChange} />
              </div>
            </div>
          </div>
          <div className={`${styles.formContainer} mx-2 mx-md-4 my-2`}>
            <div className="row">
              <div className="col-md-12">
                <TypeOfSale
                  item={item}
                  user={user}
                  handleInputChange={handleInputChange}
                  updateStateByItem={updateStateByItem}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <ItemLength item={item} updateStateByItem={updateStateByItem} />
              </div>
            </div>
            {!isShopItem && (
              <div className="row">
                <div className="col-12">
                  <Label label={t('itemsForm_extraAdditions_label')} />
                  <div className="row">
                    <div className="col-3">
                      <CheckBox
                        checked={item.isHighlighted}
                        label={t('itemsForm_extraAdditions_highlight')}
                        handleInputChange={updateStateByItem}
                        name="isHighlighted"
                      />
                    </div>
                    <div className="col-3">
                      <CheckBox
                        checked={item.rankUp}
                        handleInputChange={updateStateByItem}
                        label={t('itemsForm_extraAdditions_rankUp')}
                        name="rankUp"
                      />
                    </div>
                    <div className="col-3">
                      <div className="flex gap-x-1 align-items-center row pl-1">
                        <CheckBox
                          checked={item.restarts === 2 ? true : false}
                          label={t('itemsForm_extraAdditions_restart')}
                          value={item.restarts}
                          handleInputChange={(a, b) => changeRestarts(a, b)}
                          name="changeRestart"
                        />
                        <div className="tooltip_container mb-1">
                          <div className="tooltip_icon">
                            <InfoIcon
                              style={{
                                width: '18px',
                                height: '18px',
                              }}
                            />
                          </div>
                          <div className="tooltip">
                            {t('itemsForm_extraAdditions_restartInfo')}
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isShopItem && !isFixedPrice && (
                      <div className="col-3">
                        <CheckBox
                          name="hasChosenEndTime"
                          label={t('itemsForm_extraAdditions_endTime')}
                          checked={item.hasChosenEndTime}
                          handleInputChange={(a, b) => changeEndDate(a, b)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`${styles.formContainer} mx-2 mx-md-4 my-2`}>
            <ShippingOptions
              updateStateByItem={updateStateByItem}
              item={item}
            />
            <div className="row">
              <div className="col-md-9">
                <Input
                  value={item.shippingCondition}
                  group="form-group"
                  name="shippingCondition"
                  type="text"
                  label={t('itemsForm_shippingCondition_label')}
                  labelDescription={
                    item.validationResult &&
                    !item.validationResult?.properties.shippingCondition
                      ? t('itemsForm_shippingCondition_labelError')
                      : ''
                  }
                  maxLength={500}
                  hint={
                    `(${
                      item.shippingCondition ? item.shippingCondition.length : 0
                    }/500 ` +
                    t('itemsForm_hint') +
                    `)`
                  }
                  onChangeValue={handleInputChange}
                  placeholder={t('itemsForm_shippingCondition_placeholder')}
                />
              </div>
              <div className="col-md-3">
                <ItemLocation
                  updateStateByItem={updateStateByItem}
                  item={item}
                  label={t('itemsForm_itemLocation_label')}
                />
              </div>
            </div>
          </div>
          {!isShopItem && (
            <div className="mx-2 mx-md-4 my-2">
              <div className="row">
                <div className="col-md-6">
                  <CampaignCode
                    item={item}
                    handleInputChange={handleInputChange}
                    updateStateByItem={updateStateByItem}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ItemForm
