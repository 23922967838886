import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Button from '../../../Components/Button'
import { ReactComponent as Remove } from '../../../Icons/remove.svg'
import type {
  IOption,
  IPackageRequirements,
  IShippingOptions,
  IShippingProduct,
  IStringOption,
  Insurance,
  Restriction,
} from '../../ItemForm/ShippingOptions/IShipping'
import { useDispatch } from 'react-redux'
import { openAlertContainer } from '../../../UI/UI.actions'
import {
  getByProductIdAndShippingType,
  getInsuranceTexts,
  getProductIdFromProductKey,
  getProviderIdFromProductKey,
  getProviderName,
  getRestrictionText,
} from '../../../Infrastructure/Helpers/shippingHelper'

interface ShippingOptionProps {
  weightOptions: IOption[]
  shippingOptions: IShippingOptions[]
  updateStateByItemChanges: (property: string, value: any) => void
  testId?: string
  name: string
  onOptionSelected: (takenKey: string) => void
  takenProductKey?: string
}
const ShippingOption: React.FunctionComponent<ShippingOptionProps> = ({
  weightOptions,
  shippingOptions,
  updateStateByItemChanges,
  testId,
  name,
  onOptionSelected,
  takenProductKey,
}) => {
  const { t } = useTranslation()
  const [weight, setWeight] = useState<number | null>()
  const [price, setPrice] = useState<number | null>()
  const [productOptions, setProductOptions] = useState<IStringOption[]>([])
  const [productOption, setProductOption] = useState<IOption | null>()
  const [product, setProduct] = useState<IShippingProduct>()

  const dispatch = useDispatch()

  const getProductOptionsByWeight = useCallback(
    (weight: number): IStringOption[] => {
      const chosenShipping = shippingOptions?.find(
        (item) => item.weight === weight
      )
      return chosenShipping?.products
        ? chosenShipping?.products?.map((item) => {
            return {
              value: item.id + '-' + item.shippingProviderId,
              label:
                getProviderName(t, item.shippingProvider) +
                ' - ' +
                getByProductIdAndShippingType(
                  t,
                  item.shippingProvider,
                  item.id
                ) +
                ' (' +
                item.price +
                ' kr)',
            }
          })
        : []
    },
    [shippingOptions, t]
  )

  const productOptionChanged = (productKey: string) => {
    if (productKey !== takenProductKey) {
      const newProductOption = productOptions.find(
        (x) => x.value === productKey
      )
      setProductOption(newProductOption as any)
      const newProduct = shippingOptions
        ?.find((x) => x.weight === weight)
        ?.products?.find(
          (x) =>
            x.id === getProductIdFromProductKey(productKey) &&
            x.shippingProviderId === getProviderIdFromProductKey(productKey)
        )
      setProduct(newProduct)
      const price = newProduct?.price as any
      setPrice(price)
      updateStateByItemChanges(name, {
        id: getProviderIdFromProductKey(productKey),
        weight: weight as number,
        shippingProductId: getProductIdFromProductKey(productKey),
        price: price,
      })
      onOptionSelected(productKey)
    } else {
      dispatch(openAlertContainer(t('alert_shippingTaken'), 'error'))
      setProductOption(null)
      setProduct(undefined)
    }
  }

  const priceChanged = (newPrice: number) => {
    if (!product?.shippingProviderId || !weight || !product?.id) return
    setPrice(newPrice)
    updateStateByItemChanges(name, {
      id: product?.shippingProviderId,
      weight: weight as number,
      shippingProductId: product?.id,
      price: newPrice,
    })
  }

  const weightChanged = (weight: number) => {
    setProductOption(null)
    setProduct(undefined)
    setPrice(null)
    setWeight(weight)
    const productOptions = getProductOptionsByWeight(weight)
    setProductOptions(productOptions)
    setPrice(0)
  }

  const resetShippingOption = () => {
    setProductOption(null)
    setProduct(undefined)
    setPrice(null)
  }

  return (
    <div>
      <div className="row">
        <div className="col-2">
          <Select
            data-testid="weight-select"
            placeholder={t('editItemsForm_shipping_chooseWeight')}
            value={weightOptions.find((x) => x.value === weight) as any}
            onChange={(e) => weightChanged(e.value)}
            options={weightOptions}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 0,
              }),
            }}
          />
        </div>
        <div className="col-6">
          <Select
            placeholder={t('editItemsForm_shipping_chooseShipping')}
            value={productOption as any}
            onChange={(e) => productOptionChanged(e.value)}
            options={productOptions}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 0,
              }),
            }}
          />
        </div>
        <div className="col-2">
          <input
            type="number"
            className="form-control"
            value={price !== undefined && price !== null ? price : ''}
            onChange={(e) => priceChanged(e.target.value as any)}
          />
        </div>
        <div className="col-2">
          {productOption && (
            <Button
              size="small"
              clickHandler={() => resetShippingOption()}
              icon={<Remove />}
              variant="noborder"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mt-1 text-muted">
            {product?.packageRequirements?.restrictions &&
              getRestrictionText(
                product?.packageRequirements?.restrictions[0] as Restriction,
                product?.packageRequirements as IPackageRequirements,
                t
              )}{' '}
            {product?.deliveryInformation?.insurance &&
              getInsuranceTexts(
                product?.deliveryInformation?.insurance as Insurance,
                t
              )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShippingOption
