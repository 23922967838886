import { connect } from 'react-redux'

import { getShippingOptions } from '../../../UI/UI.actions'
import Shipping from './Shipping'

const connectedComponent = connect(
  (state: any) => ({
    shippingOptions: state.ui.shippingOptions,
  }),
  {
    getShippingOptions,
  }
)(Shipping)

export default connectedComponent
