import React from 'react'
import type { IList } from '../../List/IList'
import { ReactComponent as Remove } from '@tradera/blueprint/theme/icons2/trashcan.svg'
import { useTranslation } from 'react-i18next'

interface DeleteItemsProps {
  closeModal: () => void
  list: IList
  selectedItems: string[]
  deleteItemFromList: (itemIds: string[], listId: string) => Promise<any>
}

const DeleteItems: React.FunctionComponent<DeleteItemsProps> = ({
  closeModal,
  list,
  selectedItems,
  deleteItemFromList,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <Remove className="icon icon-lg" />
        </div>
        <p className="text-left">
          {selectedItems.length === 1 ? (
            <span>{t('modal_deleteItems_question')}?</span>
          ) : (
            <span>
              {t('modal_deleteItems_description')} {selectedItems.length}{' '}
              {t('modal_deleteItems_descriptionAppend')}?
            </span>
          )}
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_deleteItems_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-danger btn-fluid"
              onClick={() => deleteItemFromList(selectedItems, list.id)}
            >
              {t('modal_deleteItems_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteItems
