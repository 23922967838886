import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Label from '../../../Components/Label'
import Select from 'react-select'
import {
  getProviderName,
  getByProductIdAndShippingType,
  getRestrictionText,
  getInsuranceTexts,
  SHIPPING_TAKEAWAY,
  getProductIdFromProductKey,
  getProviderIdFromProductKey,
} from '../../../Infrastructure/Helpers/shippingHelper'
import Button from '../../../Components/Button'
import { ReactComponent as Remove } from '../../../Icons/remove.svg'
import type {
  IOption,
  IPackageRequirements,
  IShippingAlternative,
  IShippingOptions,
  IShippingProduct,
  IStringOption,
  Insurance,
  Restriction,
} from './IShipping'
import { useDispatch } from 'react-redux'
import { openAlertContainer } from '../../../UI/UI.actions'

interface ShippingOptionProps {
  weightOptions: IOption[]
  label: string
  existingWeight?: number
  existingProductId?: number
  existingProviderId?: number
  existingPrice?: number
  removeShippingOption: (oldShippingAlternativeKey: string) => void
  shippingOptions: IShippingOptions[]
  shippingAlternative: IShippingAlternative[]
  shippingChanged: (
    oldShippingAlternativeKey: string,
    alternative: IShippingAlternative
  ) => void
  testId?: string
}
const ShippingOption: React.FunctionComponent<ShippingOptionProps> = ({
  weightOptions,
  label,
  existingWeight,
  existingProductId,
  existingProviderId,
  existingPrice,
  removeShippingOption,
  shippingOptions,
  shippingAlternative,
  shippingChanged,
  testId,
}) => {
  const { t } = useTranslation()
  const [weight, setWeight] = useState<number | undefined>()
  const [price, setPrice] = useState<number | null>()
  const [productOptions, setProductOptions] = useState<IStringOption[] | null>(
    []
  )
  const [productOption, setProductOption] = useState<IOption | null>()
  const [product, setProduct] = useState<IShippingProduct>()
  const [takenProductKey, setTakenProductKey] = useState<string | undefined>()

  const dispatch = useDispatch()

  const getProductOptionsByWeight = useCallback(
    (weight: number): IStringOption[] => {
      const chosenShipping = shippingOptions?.find(
        (item) => item.weight === weight
      )
      if (chosenShipping?.products) {
        const productOptions = chosenShipping?.products.map((item, index) => {
          return {
            value: item.id + '-' + item.shippingProviderId,
            label:
              getProviderName(t, item.shippingProvider) +
              ' - ' +
              getByProductIdAndShippingType(t, item.shippingProvider, item.id) +
              ' (' +
              item.price +
              ' kr)',
          }
        })
        return productOptions
      } else {
        return []
      }
    },
    [shippingOptions, t]
  )

  //If product is already chosen in the other shipping alternative, make sure that it is not picked again
  useEffect(() => {
    const takenProductKey = shippingAlternative
      .filter(
        (x) =>
          x.id !== SHIPPING_TAKEAWAY &&
          x.shippingProductId !== product?.shippingProviderId &&
          x.id !== product?.shippingProviderId
      )
      ?.map((item) => item.shippingProductId + '-' + item.id)[0]
    setTakenProductKey(takenProductKey)
  }, [shippingAlternative])

  //Set existing values if they exist
  useEffect(() => {
    if (existingWeight) {
      setWeight(existingWeight)
      const productOptions = getProductOptionsByWeight(existingWeight)
      setProductOptions(productOptions)
      if (existingProductId && existingProviderId) {
        const existingKey = existingProductId + '-' + existingProviderId
        setProductOption(
          productOptions.find((x) => x.value === existingKey) as any
        )
        const existingProduct = shippingOptions
          ?.find((x) => x.weight === weight)
          ?.products?.find(
            (x) =>
              x.id === existingProductId &&
              x.shippingProviderId === existingProviderId
          )
        if (!existingProduct) return
        existingProduct.price =
          existingPrice !== undefined ? existingPrice : existingProduct?.price
        setProduct(existingProduct)
        setPrice(
          existingPrice !== undefined ? existingPrice : existingProduct?.price
        )
      }
    }
  }, [
    existingWeight,
    shippingOptions,
    existingProductId,
    existingPrice,
    getProductOptionsByWeight,
  ])

  const productOptionChanged = (productKey: string) => {
    if (productKey !== takenProductKey) {
      const oldShippingProductKey =
        product?.id + '-' + product?.shippingProviderId
      const newProductOption = productOptions?.find(
        (x) => x.value === productKey
      )
      if (newProductOption) {
        setProductOption(newProductOption as any)
        const newProduct = shippingOptions
          ?.find((x) => x.weight === weight)
          ?.products?.find(
            (x) =>
              x.id === getProductIdFromProductKey(productKey) &&
              x.shippingProviderId === getProviderIdFromProductKey(productKey)
          )
        setProduct(newProduct)
        const price = newProduct?.price as any
        setPrice(price)
        shippingChanged(oldShippingProductKey, {
          id: getProviderIdFromProductKey(productKey),
          weight: weight as number,
          shippingProductId: getProductIdFromProductKey(productKey),
          price: price,
        })
      }
    } else {
      dispatch(openAlertContainer(t('alert_shippingTaken'), 'error'))
      setProductOption(null)
      setProduct(undefined)
    }
  }

  const priceChanged = (newPrice: number) => {
    if (!product?.shippingProviderId || !weight || !product?.id) return
    const oldShippingProductKey =
      product?.id + '-' + product?.shippingProviderId
    setPrice(newPrice)
    shippingChanged(oldShippingProductKey, {
      id: product?.shippingProviderId,
      weight: weight as number,
      shippingProductId: product?.id,
      price: newPrice,
    })
  }

  const weightChanged = (weight: number) => {
    if (product) {
      const oldShippingProductKey =
        product?.id + '-' + product?.shippingProviderId
      removeShippingOption(oldShippingProductKey)
    }
    setProductOption(null)
    setProduct(undefined)
    setPrice(null)
    setWeight(weight)
    const productOptions = getProductOptionsByWeight(weight)
    setProductOptions(productOptions)
    setPrice(0)
  }

  const resetShippingOption = () => {
    const oldShippingProductKey =
      product?.id + '-' + product?.shippingProviderId
    removeShippingOption(oldShippingProductKey)
    setWeight(undefined)
    setProductOption(null)
    setProduct(undefined)
    setPrice(null)
  }

  return (
    <div>
      <Label data-testid={testId + 'label'} label={label} />
      <div className="row gap-y-1">
        <div className="col-md-3">
          <Select
            data-testid={testId + 'weight'}
            placeholder={t('itemsForm_shipping_chooseWeight')}
            value={weightOptions.find((x) => x.value === weight)}
            onChange={(e) => weightChanged(e.value)}
            options={weightOptions}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({ ...base, borderRadius: 0 }),
            }}
          />
        </div>
        <div className="col-md-6">
          <Select
            data-testid={testId + 'product'}
            placeholder={t('itemsForm_shipping_chooseShipping')}
            value={productOption}
            onChange={(e) => productOptionChanged(e.value)}
            options={productOptions}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({ ...base, borderRadius: 0 }),
            }}
          />
        </div>
        <div className="col-md-2">
          <input
            data-testid={testId + 'price'}
            type="number"
            className="form-control"
            value={price !== undefined && price !== null ? price : ''}
            onChange={(e) => priceChanged(e.target.value as any)}
          />
        </div>
        <div className="col-md-1">
          {productOption && (
            <Button
              testId={testId + 'delete'}
              size="small"
              clickHandler={() => resetShippingOption()}
              icon={<Remove />}
              variant="noborder"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mt-1 text-muted">
            {product?.packageRequirements?.restrictions &&
              getRestrictionText(
                product?.packageRequirements?.restrictions[0] as Restriction,
                product?.packageRequirements as IPackageRequirements,
                t
              )}{' '}
            {product?.deliveryInformation?.insurance &&
              getInsuranceTexts(
                product?.deliveryInformation?.insurance as Insurance,
                t
              )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShippingOption
