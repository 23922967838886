import store from '../../Store/store'
import i18next from './i18n'

store.subscribe(() => {
  const userLanguage = store.getState().user.language || 'sv'
  if (i18next.language !== userLanguage) {
    i18next
      .changeLanguage(userLanguage)
      .catch((error) => console.error('Language change error:', error))
  }
})
