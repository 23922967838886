import { connect } from 'react-redux'
import {
  closeModal,
  openPublishModal,
  openPublishSelectedModal,
} from '../../UI/UI.actions'

import PublishSelected from './PublishSelected'

const connectedComponent = connect(
  (state: any) => {
    return {
      list: state.lists.byId[state.ui.id],
      items: state.lists.byId[state.ui.id].items.map(
        (item) => state.items.byId[item]
      ),
    }
  },
  {
    closeModal,
    openPublishModal,
    openPublishSelectedModal,
  }
)(PublishSelected)

export default connectedComponent
