import React from 'react'
import { useDispatch } from 'react-redux'
import CheckBox from '../../Components/CheckBox'
import { updateItem } from '../../Items/Items.action'
import type { IItem } from '../../Items/IItem'
import { SHIPPING_TAKEAWAY } from '../../Infrastructure/Helpers/shippingHelper'

interface TakeawayShippingRendererProps {
  data: IItem
}

const TakeawayShippingRenderer: React.FunctionComponent<
  TakeawayShippingRendererProps
> = ({ data }) => {
  const dispatch = useDispatch()

  const handleChange = () => {
    if (
      data?.shippingAlternative?.filter((x) => x.id === SHIPPING_TAKEAWAY)
        .length > 0
    ) {
      const newShipping = data.shippingAlternative.filter(
        (x) => x.id !== SHIPPING_TAKEAWAY
      )
      const itemToUpdate = {
        ...data,
        shippingAlternative: newShipping,
      }
      dispatch(updateItem(itemToUpdate, false))
    } else {
      const newShipping = [
        ...data.shippingAlternative,
        {
          id: SHIPPING_TAKEAWAY,
          name: 'Avhämtning',
          price: 0,
        },
      ]
      const itemToUpdate = {
        ...data,
        shippingAlternative: newShipping,
      }
      dispatch(updateItem(itemToUpdate, false))
    }
  }

  return (
    <CheckBox
      paddingless
      checked={
        data?.shippingAlternative?.filter((x) => x.id === SHIPPING_TAKEAWAY)
          .length > 0
      }
      label=""
      handleInputChange={() => handleChange()}
      name="takeawayShipping"
      styleGrid={true}
    />
  )
}
export default TakeawayShippingRenderer
