import { connect } from 'react-redux'
import {
  beginBankIdOnFileVerification,
  cancelBankIdVerification,
} from '../../User/BankId.actions'
import BankIdVerifyModal from './BankIdVerifyModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      bankIdOrderRef: state.ui.bankIdOrderRef,
      qrData: state.ui.qrData,
      autoStartToken: state.ui.autoStartToken,
      desktopAutoStartToken: state.ui.desktopAutoStartToken,
    }
  },
  {
    beginBankIdOnFileVerification,
    cancelBankIdVerification,
  }
)(BankIdVerifyModal)

export default connectedComponent
