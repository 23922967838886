import {
  returnedFromLoginPage,
  completeLoginFlowWithRefreshToken,
  refreshLoginCredentials,
  logout,
  goToLoginPage,
} from './Authentication.action'
import type { ILoginFlowWithRefreshTokenResult } from './Authentication.action'

import configService from '../Infrastructure/ConfigService/configService'

async function postData(url = '', data = {}, authToken = null) {
  let headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
  let options: any = {
    method: 'POST',
    headers,
    body: typeof data === 'string' ? data : JSON.stringify(data),
  }
  if (authToken) {
    options = {
      ...options,
      headers: {
        ...headers,
        Authorization: authToken,
      },
    }
  }

  const response = await fetch(url, options)
  return response.json()
}

async function get(url = '', authToken: string) {
  let options: any = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  }
  const response = await fetch(url, options)
  return response.json()
}

const authenticationMiddleware = () => (store) => (next) => async (action) => {
  if (
    action.type === returnedFromLoginPage.toString() ||
    action.type === refreshLoginCredentials.toString()
  ) {
    const clientTokenResponse = await postData(
      `${configService.config.REACT_APP_IDENTITY_BASE_URL}/client/token`,
      {
        clientId: 'ProLister',
        clientSecret:
          configService.config.REACT_APP_APP_KEY_FOR_TRADERA_WEB_API,
        clientVersion: '1.0',
      }
    )

    interface IRenewTokenResponse {
      accessToken: string
      expiresIn: number
      facebookId?: string
      memberId: number
      memberAlias: string
      memberFirstName: string
      memberLastNamn: string
      memberLastName: string
      memberState: string
      refreshToken: string
    }

    const renewTokenResponse: IRenewTokenResponse = await postData(
      `${configService.config.REACT_APP_IDENTITY_BASE_URL}/member/token/renew`,
      {
        refreshToken: action.payload.refreshToken,
      },
      clientTokenResponse.accessToken
    )

    const classicApiTokenResponse = await get(
      `${configService.config.REACT_APP_IDENTITY_BASE_URL}/member/classic-token`,
      renewTokenResponse.accessToken
    )

    const loginFlowResponse: ILoginFlowWithRefreshTokenResult = {
      accessToken: renewTokenResponse.accessToken,
      accessTokenExpiresIn: renewTokenResponse.expiresIn,
      memberAlias: renewTokenResponse.memberAlias,
      memberId: renewTokenResponse.memberId,
      memberFirstName: renewTokenResponse.memberFirstName,
      memberLastName: renewTokenResponse.memberLastName,
      refreshToken: renewTokenResponse.refreshToken,
      classicApiToken: classicApiTokenResponse.key,
      classicApiTokenExpiryTime: classicApiTokenResponse.expiration,
    }

    store.dispatch(completeLoginFlowWithRefreshToken(loginFlowResponse))
  }

  if (action.type === logout.toString()) {
    store.dispatch(goToLoginPage())
  }

  return next(action)
}

export default authenticationMiddleware
