import React, { useEffect, useState } from 'react'
import type { IList } from './IList'
import ProListerGrid from '../ProListerGrid'
import { getTabContent, selectListById } from './List.selector'
import { useSelector, useDispatch } from 'react-redux'
import type { ITab } from '../Content/IContent'
import type { TabTypes } from '../Content/IContent'
import { getAllItemColumns } from '../ProListerGrid/GridMetaData'
import type { IItem, IItemClickOrder } from '../Items/IItem'
import type { ITemplate } from '../Template/ITemplate'
import { ReactComponent as FailedIcon } from '../Icons/fail.svg'
import type { IUser } from '../User/IUser'
import type {
  ColumnMovedEvent,
  ValueSetterParams,
  SelectionChangedEvent,
  RowDragEndEvent,
} from 'ag-grid-community'
import {
  selectItemInList,
  updateItem,
  saveClickOrderOnItemInList,
  resetClickOrderOnItemInList,
  validateItemFromList,
} from '../Items/Items.action'
import { openAlertContainer } from '../UI/UI.actions'
import { SHIPPING_TAKEAWAY } from '../Infrastructure/Helpers/shippingHelper'
import Toolbar from './Toolbar/Toolbar'
import type { ItemListMenuItemProps } from '../Components/ListMenuItem/ListMenuItem'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'

interface ListProps {
  user: IUser
  list: (type: TabTypes, id: string) => IList | ITemplate
  id: string
  saving: boolean
  getContent: (type: TabTypes, id: string) => void
  selectedRawData: (type: TabTypes, id: string) => IItem[]
  selectedItems: (id: string) => string[]
  getToolbarItems: (id: string, t: TFunction) => ItemListMenuItemProps[]
  cellDoubleClick: (type: TabTypes, item: IItem) => void
  getContextMenu: (
    type: TabTypes,
    t: TFunction
  ) => {
    name: string
    link?: string
    icon: React.ReactNode
    action?: (selectedItem: IItem) => void
  }[]
  deleteItemFromList: (itemIds: string[], listId: string) => any
  reorderList: (listId: string, itemIds: string[]) => any
  updateColumnOrder: (columns: string[]) => any
  onGridApi: (id: string, api: any) => void
}

const List: React.FunctionComponent<ListProps> = ({
  user,
  id,
  list,
  saving,
  getContent,
  selectedRawData,
  selectedItems,
  getContextMenu,
  getToolbarItems,
  cellDoubleClick,
  reorderList,
  updateColumnOrder,
  onGridApi,
}) => {
  const { t } = useTranslation()
  const listfromState: IList = useSelector((state) => selectListById(state, id))
  const selectedContent: ITab = useSelector((state) => getTabContent(state, id))
  const rawData = selectedRawData(selectedContent.type, id)
  const contextMenu = getContextMenu(selectedContent.type, t)
  const toolbarItems = getToolbarItems(id, t)
  const [selectedItem, setSelectedItem] = useState<IItem>()
  const currentList = list(selectedContent.type, id)

  const onCellContextMenu = (item: IItem | IList) => {
    setSelectedItem(item as IItem)
  }

  const dispatch = useDispatch()

  const parseNumber = (num: string) => {
    return parseInt(num, 10)
  }

  const onCellEditingStopped = (e: ValueSetterParams) => {
    const changedColumn = e.colDef.field
    switch (changedColumn) {
      case 'startingBid':
      case 'buyNowPrice':
      case 'quantity':
      case 'reservationPrice': {
        if (!isNaN(parseNumber(e.newValue))) {
          const itemToUpdate = { ...e.data }
          itemToUpdate[changedColumn] = parseInt(e.newValue, 10)
          dispatch(updateItem(itemToUpdate, false))
        }
        break
      }
      case 'shippingCostFirst':
      case 'shippingCostSecond': {
        if (isNaN(parseNumber(e.newValue))) {
          break
        }
        //Check if shipping includes takeaway
        const takeawayExists =
          e.data.shippingAlternative.filter((x) => x.id === SHIPPING_TAKEAWAY)
            .length > 0
        //If it does, remove it first so its easier to see whats first and second shipping alternative
        const newShipping = e.data.shippingAlternative.filter(
          (x) => x.id !== SHIPPING_TAKEAWAY
        )
        if (e.colDef.field === 'shippingCostFirst' && newShipping.length > 0) {
          newShipping[0] = {
            ...newShipping[0],
            price: parseInt(e.newValue, 10),
          }
        } else if (
          e.colDef.field === 'shippingCostSecond' &&
          newShipping.length > 1
        ) {
          newShipping[1] = {
            ...newShipping[1],
            price: parseInt(e.newValue, 10),
          }
        } else {
          dispatch(openAlertContainer('Lägg till fraktmetod', 'error'))
          return
        }
        //If come and get exists, add it back
        if (takeawayExists) {
          newShipping.push({
            id: SHIPPING_TAKEAWAY,
            price: 0,
          })
        }
        const itemToUpdate = {
          ...e.data,
          shippingAlternative: [...newShipping],
        }
        dispatch(updateItem(itemToUpdate, false))
        break
      }
      case 'header':
      case 'reference':
      case 'acceptedBidders':
      case 'itemType':
      case 'condition':
      case 'advertisementLengthInDays':
      case 'shippingCondition': {
        const itemToUpdate = { ...e.data }
        itemToUpdate[changedColumn] = e.newValue
        dispatch(updateItem(itemToUpdate, false))
        break
      }
    }
  }

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    if (event.api !== null) {
      var selectedRows = event.api !== null ? event.api.getSelectedRows() : ''
      var selection = selectedRows as Array<IItem>

      //Cells should not be editable if user selects more than one row
      if (selectedRows?.length > 1) {
        event.api?.stopEditing()
      }

      dispatch(resetClickOrderOnItemInList())
      event.api.forEachNode((node) => {
        var item = node.data as IItem

        const selectedItemIndex = selection.findIndex((x) => x.id === item.id)
        const isChecked = selectedItemIndex > -1 ? true : false
        if ((!item.isChecked && isChecked) || (item.isChecked && !isChecked)) {
          dispatch(selectItemInList(item.id, isChecked))
        }

        if (selectedItemIndex > -1) {
          var clickItem: IItemClickOrder = {
            itemId: item.id,
            selectTime: new Date(),
            listId: item.listId,
          }
          dispatch(saveClickOrderOnItemInList(clickItem))
        }
      })
    }
  }

  function checkIfImagesExists(imageList, callback) {
    var itemsProcessed = 0
    var result = true
    imageList.forEach((url) => {
      const img = new Image()
      img.src = url

      if (img.complete) {
        itemsProcessed++
        if (itemsProcessed >= imageList.length) {
          callback(result)
        }
      } else {
        img.onload = () => {
          itemsProcessed++
          if (itemsProcessed >= imageList.length) {
            callback(result)
          }
        }
        img.onerror = () => {
          itemsProcessed++
          result = false
          if (itemsProcessed >= imageList.length) {
            callback(result)
          }
        }
      }
    })
  }

  useEffect(() => {
    rawData.forEach((item) => {
      checkIfImagesExists(item.imageUrls, (exists) => {
        if (!exists) {
          const validationResultUpdate = {
            ...item?.validationResult,
            isValid: false,
            properties: {
              ...item?.validationResult?.properties,
              imageUrls: false,
            },
          }
          dispatch(validateItemFromList(item.id, validationResultUpdate))
        }
      })
    })
  }, [])

  useEffect(() => {
    if (!listfromState) {
      getContent(selectedContent.type, id)
    }
  }, [getContent, id, selectedContent])

  const shouldAddPublishedColumn =
    currentList &&
    currentList.publicationResult != null &&
    currentList.publicationResult.length > 0

  let itemColumns = getAllItemColumns(
    shouldAddPublishedColumn,
    user ? user.columnOrders : [],
    currentList?.items?.length
  )

  const hasFailed =
    shouldAddPublishedColumn &&
    currentList.publicationResult?.toLowerCase() === 'failed'

  const numberOfFailedItems = rawData
    ?.map((i: any) => i?.publishResult?.toLowerCase() !== 'ok')
    .filter((x) => x).length

  const onColumnMoved = (event: ColumnMovedEvent) => {
    const newColumnOrders = event.columnApi
      .getColumnState()!
      .map((c) => c.colId + ':' + c.width)

    updateColumnOrder(newColumnOrders)
  }

  const onRowDragEnd = (event: RowDragEndEvent) => {
    if (event.api !== null) {
      const itemIds: string[] = []
      event.api.forEachNode((node) => itemIds.push(node.data.id))
      reorderList(currentList.id, itemIds)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Toolbar
        actions={toolbarItems}
        list={currentList}
        selectedContent={selectedContent}
        selectedItems={selectedItems(currentList.id)}
        saving={saving}
      />

      <div
        className="card"
        style={{ flex: 1, flexDirection: 'column', height: '100%' }}
      >
        {hasFailed && (
          <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
            <FailedIcon style={{ margin: '0 12px 0 24px' }} />
            <p
              style={{ margin: 'auto 0', color: '#da3530', fontWeight: 'bold' }}
            >
              {numberOfFailedItems}{' '}
              {numberOfFailedItems > 1 ? 'annonser' : 'annons'}{' '}
              {t('list_upload_failed')}
            </p>
          </div>
        )}
        <ProListerGrid
          user={user}
          paddingBottom={153}
          columns={itemColumns}
          onColumnMoved={(e) => onColumnMoved(e)}
          onRowDragEnd={(e) => onRowDragEnd(e)}
          rawData={rawData && rawData.length > 0 ? rawData : []}
          onCellContextMenu={(item) => onCellContextMenu(item as any)}
          onCellDoubleClicked={(item) =>
            cellDoubleClick(selectedContent.type, item as IItem)
          }
          selectedContentType={`items-${selectedContent.type}`}
          selectedContent={selectedItem!}
          menuItems={contextMenu as any}
          onCellEditingStopped={(e) =>
            onCellEditingStopped(e as ValueSetterParams)
          }
          onSelectionChanged={(event) =>
            onSelectionChanged(event as SelectionChangedEvent)
          }
          updateColumnOrder={updateColumnOrder}
          onGridApi={(api) => onGridApi(id, api)}
        />
      </div>
    </div>
  )
}

export default List
