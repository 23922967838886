import { ReactComponent as FailedIcon } from '@tradera/blueprint/theme/icons2/close-fill.svg'
import { useTranslation } from 'react-i18next'

interface SellItemsErrorProps {
  closeModal: () => void
  errorItems: number[]
  message: string
}

const SellItemsError: React.FunctionComponent<SellItemsErrorProps> = ({
  closeModal,
  errorItems,
  message,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <FailedIcon className="icon icon-lg" />
        </div>
        <p className="text-left">{message}</p>
        {errorItems.length > 0 &&
          errorItems.map((item) => (
            <p className="text-left mt-1 font-weight-bold">{item}</p>
          ))}
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_sellItemsError_button_close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellItemsError
