import { useState } from 'react'

type CopiedValue = string[] | null
type CopyFn = (text: string[]) => Promise<boolean>

function useCopyItemsToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = async (text: string[]) => {
    //TODO! Add support for copying clipboard in later task PN-498
    // if (!navigator?.clipboard) {
    //   console.warn('Clipboard not supported')
    // }
    try {
      //await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}

export default useCopyItemsToClipboard
