import React from 'react'
import './UI.scss'
import Authentication from '../Authentication'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Content from '../Content'
import LoginCallback from '../Authentication/LoginCallback'
import Login from '../Authentication/Login'
import { PageTracking } from '../Infrastructure/Tracking/PageTracking'
import Header from '../Components/Header/Header.container'

function UI() {
  return (
    <div className="UI">
      <Router>
        <Switch>
          <Route exact path="/login-callback">
            <LoginCallback />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/">
            <Authentication>
              <>
                <Header isLoggedIn={true} />
                <Content />
              </>
            </Authentication>
          </Route>
        </Switch>
        <PageTracking />
      </Router>
    </div>
  )
}

export default UI
