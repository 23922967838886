import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import type { ISellItem } from './ISellView'
import { deselectAll, selectItemInList } from './SellView.actions'

const initialState: any = null

const sellItemReducer: Reducer<ISellItem, IAction<any>> = handleActions(
  {
    [selectItemInList.toString()]: (
      state: ISellItem,
      action: IAction<any>
    ) => ({
      ...state,
      isChecked: action.payload.isChecked,
    }),
  },
  initialState
)

export default sellItemReducer
