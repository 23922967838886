import React from 'react'
import { useDispatch } from 'react-redux'
import { updateFieldCondition } from '../../Items/Items.action'
import type { IItem } from '../../Items/IItem'
import Select from '../../Components/Select'
import { useTranslation } from 'react-i18next'

interface ConditionElementRendererProps {
  data: IItem
}

const ConditionElementRenderer: React.FunctionComponent<
  ConditionElementRendererProps
> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSelectedRows = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const itemToUpdate = {
      ...data,
      condition: value,
    }
    dispatch(
      updateFieldCondition(data.id ? data.id : '', itemToUpdate.condition)
    )
  }

  return (
    /*  <div className={`${styles.selecter}`}>
             <select
                 className={`${styles.selecter}`}
                 name="condition"
                 value={data.condition}
                 onChange={(e) => handleSelectedRows(e)}
             >
                 <option value="Used">Begagnat</option>
                 <option value="Mint">Oanvänt</option>
 
             </select>
         </div>
        */
    <div style={{ width: '100%', padding: '0px', boxShadow: 'none' }}>
      <Select
        onChange={(e) => handleSelectedRows(e)}
        styleGrid={true}
        value={data.condition}
      >
        <option value="Used">
          {t('prolisterGrid_renderers_condition_used')}
        </option>
        <option value="Mint">
          {t('prolisterGrid_renderers_condition_mint')}
        </option>
      </Select>
    </div>
  )
}

export default ConditionElementRenderer
