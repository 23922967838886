import React from 'react'

interface ErrorLabelProps {
  label: string
  description: string
}

const ErrorLabel: React.FunctionComponent<ErrorLabelProps> = ({
  label,
  description,
}) => (
  <div className="d-flex align-items-center  mb-1 mt-2">
    <span className="label-small">{label}</span>
    {description && (
      <span className="text-small text-danger ml-1">{description}</span>
    )}
  </div>
)

export default ErrorLabel
