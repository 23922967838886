import type { Dispatch } from '@reduxjs/toolkit'
import { createActions } from 'redux-actions'
import { get, post } from '../Infrastructure/Network'
import {
  bankIdIsRequired,
  bankIdVerificationEnd,
  closeModal,
  openAlertContainer,
  openSellItemsErrorModal,
  openLoadingModal,
  openPublishErrorMessageModal,
  openPublishModal,
  openPublishProgressModal,
  setLoadingLists,
  setSaving,
  setUploadDateTime,
  setUploadInterval,
  setUploadSellItems,
} from '../UI/UI.actions'
import {
  SellListType,
  type ICancelItemsParams,
  type IOrder,
  type ISellItem,
  type ISellList,
  type ISellViewResult,
} from './ISellView'
import { closeTab, openContent } from '../Content/Content.actions'
import { TabTypes } from '../Content/IContent'
import type { IPublishException, IPublishViewModel } from '../List/IList'
import { PublishExceptionCode } from '../List/IList'
import type { IFileUploadImage } from '../Items/IItem'

export const { selectItemInList } = createActions({
  selectItemInList: (id: string, isChecked) => ({
    id,
    isChecked,
  }),
})

export const { deselectAll } = createActions({
  deselectAll: () => ({}),
})

export const { saveSellItem } = createActions({
  saveSellItem: (item: ISellItem) => item,
})

export const { getTraderaListsSucceeded } = createActions({
  getTraderaListsSucceeded: (lists: ISellList[]) => lists,
})

export const onGetTraderaListsInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(true))
  }

export const onGetTraderaListsSucceeded =
  (lists: ISellList[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
    dispatch(getTraderaListsSucceeded(lists))
  }

export const onGetTraderaListsRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
  }

export const getTraderaLists = () =>
  get({
    url: `/sellview/getlists`,
    initiated: () => onGetTraderaListsInitiated(),
    fulfilled: (lists: ISellList[]) => onGetTraderaListsSucceeded(lists),
    rejected: (error: any) => onGetTraderaListsRejected(error),
  })

export const { getOrdersSucceeded } = createActions({
  getOrdersSucceeded: (lists: IOrder[]) => lists,
})

export const onGetOrdersInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(true))
  }

export const onGetOrdersSucceeded =
  (lists: IOrder[]) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
    dispatch(getOrdersSucceeded(lists))
  }

export const onGetOrdersRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setLoadingLists(false))
  }

export const getOrders = () =>
  get({
    url: `/sellview/getorders`,
    initiated: () => onGetOrdersInitiated(),
    fulfilled: (lists: IOrder[]) => onGetOrdersSucceeded(lists),
    rejected: (error: any) => onGetOrdersRejected(error),
  })

export const {
  cancelItemsRequested,
  cancelItemsSucceeded,
  cancelItemsRejected,
} = createActions({
  cancelItemsRequested: () => ({}),
  cancelItemsSucceeded: (list) => list,
  cancelItemsRejected: () => ({}),
})

export const onCancelItemsSucceeded =
  (result: ISellViewResult) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(cancelItemsSucceeded(result.lists))
    dispatch(closeModal())
    dispatch(closeTab('sellview'))
    if (result.errorItems != null && result.errorItems.length > 0) {
      dispatch(
        openSellItemsErrorModal(
          result.errorItems,
          'Följande annonser kunde ej avbrytas. Försök igen eller kontakta kundtjänst.'
        )
      )
    } else {
      dispatch(
        openContent(
          {
            type: TabTypes.SellView,
            id: 'sellview',
            selected: true,
            name: 'Säljöversikt',
            sellViewType: SellListType.Active,
          },
          'Säljöversikt'
        )
      )
      dispatch(
        openAlertContainer(
          'Annonserna har avbrytits på Tradera och ligger under Ej sålda.',
          'success'
        )
      )
    }
  }

export const onCancelItemsRejected =
  (error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(openAlertContainer('Annonserna kunde inte avbrytas', 'error'))
  }

export const onCancelItemsInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(openLoadingModal('Avbryter..'))
    dispatch(cancelItemsRequested)
  }

export const cancelItems = (params: ICancelItemsParams) =>
  post({
    url: `/sellview/cancelitems`,
    body: params,
    initiated: onCancelItemsInitiated,
    fulfilled: (result: ISellViewResult) => onCancelItemsSucceeded(result),
    rejected: onCancelItemsRejected,
  })

export const {
  updateSellItemRequested,
  updateSellItemSucceeded,
  updateSellItemRejected,
} = createActions({
  updateSellItemRequested: () => ({}),
  updateSellItemSucceeded: (item: ISellItem) => item,
  updateSellItemRejected: (error: any) => ({}),
})

export const onUpdateSellItemInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setSaving(true))
  }

export const onUpdateSellItemSucceeded =
  (item: ISellItem) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(updateSellItemSucceeded(item))
    dispatch(setSaving(false))
    dispatch(closeTab('sellview'))
    dispatch(
      openContent(
        {
          type: TabTypes.SellView,
          id: 'sellview',
          selected: true,
          name: 'Säljöversikt',
          sellViewType: SellListType.Active,
        },
        'Säljöversikt'
      )
    )
    dispatch(openAlertContainer('Annonsen uppdaterades.', 'success'))
  }

export const onUpdateSellItemRejected =
  (errorCode: any, error: any) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setSaving(false))
    dispatch(
      openAlertContainer(
        error?.data ?? 'Annonsen kunde inte uppdateras.',
        'error'
      )
    )
  }

export const updateSellItem = (body: FormData) =>
  post({
    url: `/sellview/updatesellitem`,
    initiated: () => onUpdateSellItemInitiated(),
    fulfilled: (item: ISellItem) => onUpdateSellItemSucceeded(item),
    rejected: (error: number, errorMessage: any) =>
      onUpdateSellItemRejected(error, errorMessage),

    body: body,
    multipart: true,
  })

export const {
  republishItemsRequested,
  republishItemsSucceeded,
  republishItemsRejected,
} = createActions({
  republishItemsRequested: () => ({ pending: true }),
  republishItemsSucceeded: (list) => list,
  republishItemsRejected: (error, listId, bankId) => ({
    error,
    listId,
    bankId,
  }),
})

export const onRepublishItemsSucceeded =
  (result: ISellViewResult) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(republishItemsSucceeded(result.lists))
    dispatch(closeModal())
    dispatch(
      bankIdVerificationEnd({ bankIdSuccess: null, bankIdRequired: null })
    )
    dispatch(setUploadInterval(undefined))
    dispatch(setUploadDateTime(undefined))
    dispatch(setUploadSellItems([]))
    if (result.errorItems != null && result.errorItems.length > 0) {
      dispatch(
        openSellItemsErrorModal(
          result.errorItems,
          'Följande annonser kunde ej återpubliceras. Försök igen eller kontakta kundtjänst.'
        )
      )
    } else {
      dispatch(closeTab('sellview'))
      dispatch(
        openContent(
          {
            type: TabTypes.SellView,
            id: 'sellview',
            selected: true,
            name: 'Säljöversikt',
            sellViewType: SellListType.Unsold,
          },
          'Säljöversikt'
        )
      )
      dispatch(openAlertContainer('Återpubliceringen lyckades.', 'success'))
    }
  }

export const onRepublishItemsRejected =
  (error: any, publishException: IPublishException) =>
  (dispatch: Dispatch<any>): void => {
    if (
      publishException.errorCode ===
      PublishExceptionCode.BankIdVerificationNeeded
    ) {
      dispatch(
        bankIdIsRequired(
          true,
          publishException.listId,
          publishException.personalNumber
        )
      )
      dispatch(closeModal())
      dispatch(setUploadInterval(publishException.uploadInterval))
      dispatch(setUploadDateTime(publishException.uploadDateTime))
      dispatch(setUploadSellItems(publishException.sellItems))
      dispatch(openPublishModal(publishException.listId, []))
    } else {
      dispatch(republishItemsRejected(error, publishException))
      dispatch(closeModal())
      dispatch(setUploadSellItems([]))
      dispatch(openPublishErrorMessageModal(publishException.message))
    }
  }

export const onRepublishItemsInitiated =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(closeModal())
    dispatch(openPublishProgressModal())
    dispatch(republishItemsRequested)
  }

export const republishItems = (
  viewModel: IPublishViewModel,
  imageList: IFileUploadImage[]
) => {
  let body
  let multipart = false
  let url = `/sellview/republishitems`

  if (
    imageList &&
    imageList.length > 0 &&
    imageList.filter((x) => x.url.startsWith('blob')).length > 0
  ) {
    body = new FormData()
    imageList.forEach((image) => {
      if (image.file) {
        body.append('files', image.file)
      }
    })

    for (let key in viewModel) {
      if (viewModel[key] === null) continue
      if (key === 'sellItems') {
        body.append(key, JSON.stringify(viewModel[key]))
      } else {
        body.append(key, viewModel[key])
      }
    }
    multipart = true
    url = `/sellview/republishitemwithimages`
  } else {
    body = viewModel
  }

  return post({
    url: url,
    body: body,
    multipart: multipart,
    initiated: () => onRepublishItemsInitiated(),
    fulfilled: (result: ISellViewResult) => onRepublishItemsSucceeded(result),
    rejected: (error, errorMessage) =>
      onRepublishItemsRejected(error, errorMessage),
  })
}

export const {
  setOrderAsPaidRequested,
  setOrderAsPaidSucceeded,
  setOrderAsPaidRejected,
} = createActions({
  setOrderAsPaidRequested: () => ({}),
  setOrderAsPaidSucceeded: (orderId: string) => orderId,
  setOrderAsPaidRejected: () => ({}),
})

export const setOrderAsPaid = (orderId: string) =>
  post({
    url: `/sellview/setorderaspaid`,
    initiated: () => setOrderAsPaidRequested(),
    fulfilled: (orderId: string) => setOrderAsPaidSucceeded(orderId),
    rejected: setOrderAsPaidRejected,
    body: orderId,
  })

export const {
  setOrderAsShippedRequested,
  setOrderAsShippedSucceeded,
  setOrderAsShippedRejected,
} = createActions({
  setOrderAsShippedRequested: () => ({}),
  setOrderAsShippedSucceeded: (orderId: string) => orderId,
  setOrderAsShippedRejected: () => ({}),
})

export const setOrderAsShipped = (orderId: string) =>
  post({
    url: `/sellview/setorderasshipped`,
    initiated: () => setOrderAsShippedRequested(),
    fulfilled: (orderId: string) => setOrderAsShippedSucceeded(orderId),
    rejected: setOrderAsShippedRejected,
    body: orderId,
  })
