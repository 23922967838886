import { connect } from 'react-redux'

import Item from './Item'
import {
  createItem,
  getItem,
  updateItem,
  addImages,
  deleteImage,
} from '../Items.action'
import { closeTab } from '../../Content/Content.actions'

const connectedComponent = connect(
  (state: any) => ({
    user: state.user,
  }),
  {
    createItem,
    closeTab,
    getItem,
    updateItem,
    addImages,
    deleteImage,
  }
)(Item)

export default connectedComponent
