import React, { useRef } from 'react'
import { ReactComponent as Camera } from '../../Icons/camera.svg'
import ImagePreview from '../../Components/ImagePreview'

interface ImageCellRendererProps {
  value: string[]
}

const ImageCellRenderer: React.FunctionComponent<ImageCellRendererProps> = ({
  value,
}) => {
  const imageUrls = value ? value : []
  const iconRef = useRef(null)
  const [isVisible, setVisibility] = React.useState(false)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Camera
        style={{ marginRight: 10 }}
        ref={iconRef}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      />
      {imageUrls.length > 0 && (
        <ImagePreview
          isVisible={isVisible}
          targetRef={iconRef}
          imageUrls={imageUrls}
          height={236}
          width={236}
        />
      )}
      <span>{`${imageUrls.length} st`}</span>
    </div>
  )
}

export default ImageCellRenderer
