import { combineReducers } from '@reduxjs/toolkit'
import uiReducer from '../UI/UI.reducer'
import listsReducer from '../List/Lists.reducer'
import contentReducer from '../Content/Contents.reducer'
import authenticationReducer from '../Authentication/Authentication.reducer'
import { logout } from '../Authentication/Authentication.action'
import templateReducer from '../Template/Templates.reducer'
import ItemReducer from '../Items/Items.reducer'
import ItemWipReducer from '../Items/ItemsWip.reducer'
import templateItemReducer from '../TemplateItem/TemplateItems.reducer'
import userReducer from '../User/User.reducer'
import ItemChangesWipReducer from '../ItemChanges/ItemChangesWip.reducer'
import bankIdReducer from '../User/BankId.reducer'
import SellViewReducer from '../SellView/SellView.reducer'
import SellItemsReducer from '../SellView/SellItems.reducer'
import SellItemReducer from '../SellView/SellItem.reducer'
import OrdersReducer from '../SellView/Orders.reducer'

const appReducer = combineReducers({
  ui: uiReducer,
  templateItem: templateItemReducer,
  items: ItemReducer,
  itemsWip: ItemWipReducer,
  itemChangesWip: ItemChangesWipReducer,
  lists: listsReducer,
  templates: templateReducer,
  content: contentReducer,
  authentication: authenticationReducer,
  user: userReducer,
  bankId: bankIdReducer,
  sellItems: SellItemsReducer,
  sellItem: SellItemReducer,
  sellView: SellViewReducer,
  orders: OrdersReducer,
})

const rootReducer = (state, action) => {
  if (action.type === logout.toString()) {
    state = undefined
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
