import React from 'react'
import type { IList } from '../../List/IList'
import type { IItem } from '../../Items/IItem'
import { ReactComponent as WarningIcon } from '@tradera/blueprint/theme/icons2/alert-fill.svg'
import { useTranslation } from 'react-i18next'

interface PublishSelectedProps {
  closeModal: () => void
  openPublishModal: (listId: string, selectedItems: IItem[]) => void
  list: IList
  items: IItem[]
}

const getSelectedItems = (items: IItem[]) => {
  if (items === undefined || !items || items.length === 0) {
    return []
  }
  if (items.length > 0 && items[0] === undefined) {
    return []
  }
  return items.filter((i) => i.isChecked) ?? []
}

const PublishSelected: React.FunctionComponent<PublishSelectedProps> = ({
  closeModal,
  openPublishModal,
  list,
  items,
}) => {
  const { t } = useTranslation()
  const selectedItems = getSelectedItems(items)
  const validItems = selectedItems?.filter((x) => x.validationResult?.isValid)
  const unvalidSelectedItems = selectedItems?.filter(
    (x) => !x.validationResult?.isValid
  )
  const unvalidItems = items?.filter((x) => !x.validationResult?.isValid)
  return (
    <div className="modal-width-small">
      <div className="p-3">
        {unvalidItems.length > 0 && (
          <div className="text-center mb-2">
            <WarningIcon className="icon icon-lg" />
          </div>
        )}
        <p>
          {t('modal_publishSelected_info')} {selectedItems.length}{' '}
          {selectedItems.length === 1
            ? t('modal_publishSelected_ad') + '. '
            : t('modal_publishSelected_ads') + '. '}
          {unvalidItems.length > 0 && (
            <>
              {t('modal_publishSelected_list')} {unvalidItems.length}{' '}
              {unvalidItems.length === 1
                ? t('modal_publishSelected_listUnvalidSingular') +
                  ' ' +
                  t('modal_publishSelected_ad')
                : t('modal_publishSelected_listUnvalidPlural') +
                  ' ' +
                  t('modal_publishSelected_ads')}
              {' (' + t('modal_publishSelected_listAppend')}{' '}
              {unvalidSelectedItems.length}{' '}
              {unvalidSelectedItems.length === 1
                ? t('modal_publishSelected_selectedSingular') + '). '
                : t('modal_publishSelected_selectedPlural') + '). '}
              {t('modal_publishSelected_valid') + '. '}
            </>
          )}
          {t('modal_publishSelected_choice')}.
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            {unvalidItems.length > 0 ? (
              <button
                className="btn btn-outline-primary btn-fluid"
                onClick={() => openPublishModal(list.id, validItems)}
              >
                {t('modal_publishSelected_button_publishSelected')}
              </button>
            ) : (
              <button
                className="btn btn-outline-primary btn-fluid"
                onClick={() => closeModal()}
              >
                {t('modal_publishSelected_button_cancel')}
              </button>
            )}
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => openPublishModal(list.id, [])}
            >
              {t('modal_publishSelected_button_publishAll')}
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {unvalidItems.length > 0 ? (
              <button
                className="btn btn-primary btn-fluid"
                onClick={() => closeModal()}
              >
                {t('modal_publishSelected_button_cancel')}
              </button>
            ) : (
              <button
                className="btn btn-primary btn-fluid"
                onClick={() => openPublishModal(list.id, validItems)}
              >
                {t('modal_publishSelected_button_confirm')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishSelected
