import CheckBoxRenderer from './Renderers/CheckBoxRenderer'
import CheckBoxRankUpRenderer from './Renderers/CheckBoxRankUpRenderer'
import CheckBoxSelectRowRenderer from './Renderers/CheckBoxSelectRowRenderer'
import { format } from 'date-fns'
import svLocale from 'date-fns/locale/sv'
import DescriptionRenderer from './Renderers/DescriptionRenderer'
import ImageCellRenderer from './Renderers/ImageCellRenderer'
import PublicationResultRenderer from './Renderers/PublicationResultRenderer'
import DragCellRenderer from './Renderers/DragCellRenderer'
import OpenListCellRenderer from './Renderers/OpenListCellRenderer'
import SelectElementRenderer from './Renderers/AcceptedBiddersElementRenderer'
import ItemTypeElementRenderer from './Renderers/ItemTypeElementRenderer'
import ConditionElementRenderer from './Renderers/ConditionElementRenderer'
import AdLengthElementRenderer from './Renderers/AdLengthElementRenderer'
import CategoryRenderer from './Renderers/CategoryRenderer'
import AttributesRenderer from './Renderers/AttributesRenderer'
import EndTimeRenderer from './Renderers/EndTimeRenderer'
import TakeawayShippingRenderer from './Renderers/TakeawayShippingRenderer'
import { getCategoryName } from '../Infrastructure/Helpers/categoryHelper'
import {
  SHIPPING_TAKEAWAY,
  shippingMappingTable,
} from '../Infrastructure/Helpers/shippingHelper'
import { ItemType, type ICategory } from '../Items/IItem'
import { compareAsc } from 'date-fns'
import ShippingCostRenderer from './Renderers/ShippingCostRenderer'
import VatRenderer from './Renderers/VatRenderer'
import OpenMenuCellRenderer from './Renderers/OpenMenuCellRenderer'
import UnsoldStatusRenderer from './Renderers/UnsoldStatusRenderer'
import ActiveStatusRenderer from './Renderers/ActiveStatusRenderer'
import i18next from 'i18next'

const textFormatter = (params: any) =>
  `${params && params.value && params.value !== 'null' ? params.value : ''}`
const currencyFormatter = (params: any) =>
  `${params && params.value && params.value !== 'null' ? params.value : 0} kr`
const bidFormatter = (params: any) => {
  if (!params || !params.value || params.value === 'null') {
    return ''
  }
  return `${params.value} kr`
}
const vatFormatter = (params: any) => {
  if (!params || !params.value || params.value === 'null') {
    return ''
  }
  return `${params.value} %`
}
const amountFormatter = (params: any) =>
  `${params.value ? params.value.length : 0} st`
const dateFormatter = (params: any) => {
  if (!params.value) return ''
  const date = new Date(params.value)
  if (date.getFullYear() < 2000) {
    return i18next.t('prolisterGrid_metadata_dateFormatter')
  }
  return format(date, 'dd MMMM yyyy HH:mm', {
    locale: svLocale,
  })
}

const quantityFormatter = (params: any) => `${params.value ?? 0} st`

const itemsFormatter = (params: any) =>
  `${params.value ? params.value.length : 0}`

const categoryFormatter = (params: any) => {
  if (!params || !params.value) {
    return ''
  }

  return getCategoryName(params.value)
}

const advertisementTypeFormatter = (params: any) => {
  if (!params || !params.value) {
    return ''
  }
  const value = params.value.toLowerCase()
  if (value === ItemType.Auction.toLowerCase())
    return i18next.t('prolisterGrid_metadata_itemTypeFormatter_auction')
  if (value === ItemType.FixedPrice.toLowerCase())
    return i18next.t('prolisterGrid_metadata_itemTypeFormatter_fixedPrice')
  if (value === ItemType.ShopItem.toLowerCase())
    return i18next.t('prolisterGrid_metadata_itemTypeFormatter_shopItem')
  return params.value
}

const acceptedBiddersTypeFormatter = (params: any) => {
  if (!params || !params.value || params.value.length === 0) {
    return ''
  }
  const value = params.value
  if (value === 1) {
    return i18next.t('prolisterGrid_metadata_acceptedBiddersFormatter_sweden')
  } else if (value === 2) {
    return i18next.t('prolisterGrid_metadata_acceptedBiddersFormatter_world')
  } else if (value === 3) {
    return i18next.t('prolisterGrid_metadata_acceptedBiddersFormatter_eu')
  }
}

const conditionFormatter = (params: any) => {
  if (!params || !params.value) {
    return ''
  }

  var value = params.value.toLowerCase()

  if (value === 'used') {
    return i18next.t('prolisterGrid_metadata_conditionFormatter_used')
  }
  return i18next.t('prolisterGrid_metadata_conditionFormatter_mint')
}

const exposureFormatter = (params: any) => {
  if (!params || !params.value || params.value === 'null') {
    return ''
  }

  return params.value
}

const shippingFormatter = (params: any) => {
  if (!params || !params.value || params.value.length === 0) {
    return ''
  }

  return params.value
    .filter((x) => x.id !== SHIPPING_TAKEAWAY)
    .map((s) => shippingMappingTable[s.id])
    .join(', ')
}

const shippingComparator = (
  shippingAlternatives1: any,
  shippingAlternatives2: any
) => {
  if (shippingAlternatives1.length === 0 && shippingAlternatives2.length > 0) {
    return -1
  } else if (
    shippingAlternatives1.length > 0 &&
    shippingAlternatives2.length === 0
  ) {
    return 1
  }
  if (shippingAlternatives1.length > 0 && shippingAlternatives2.length > 0) {
    const shippingName1 = shippingMappingTable[shippingAlternatives1[0].id]
    const shippingName2 = shippingMappingTable[shippingAlternatives2[0].id]

    if (shippingName1 === shippingName2) return 0
    return shippingName1.localeCompare(shippingName2)
  }
}

const endTimeComparator = (
  endTime1: any,
  endTime2: any,
  nodeA: any,
  nodeB: any
) => {
  if (nodeA.data) {
    if (nodeA.data.hasChosenEndTime && !nodeB.data.hasChosenEndTime) {
      return 1
    } else if (!nodeA.data.hasChosenEndTime && nodeB.data.hasChosenEndTime) {
      return -1
    } else if (nodeA.data.hasChosenEndTime && nodeB.data.hasChosenEndTime) {
      const timeA = new Date(
        0,
        0,
        0,
        nodeA.data.chosenEndTimeHour,
        nodeA.data.chosenEndTimeMinute
      )
      const timeB = new Date(
        0,
        0,
        0,
        nodeB.data.chosenEndTimeHour,
        nodeB.data.chosenEndTimeMinute
      )
      return compareAsc(timeA, timeB)
    } else return 0
  }
}

const imageComparator = (imageList1: string[], imageList2: string[]) => {
  if (imageList1.length > imageList2.length) {
    return 1
  } else if (imageList1.length < imageList2.length) {
    return -1
  } else {
    return 0
  }
}

const categoryComparator = (category1: ICategory, category2: ICategory) => {
  if (category1 === null && category2 !== null) {
    return -1
  } else if (category1 !== null && category2 === null) {
    return 1
  }
  if (category1 !== null && category2 !== null) {
    const categoryName1 = getCategoryName(category1)
    const categoryName2 = getCategoryName(category2)
    if (categoryName1 && categoryName2) {
      return categoryName1.localeCompare(categoryName2)
    }
  }
  return 0
}

const regionOrder = {
  1: 0, // Sverige
  4: 1, // EU
  3: 2, // Hela världen
}

const acceptedBiddersComparator = (
  locationCodeA: number,
  locationCodeB: number
) => {
  const locationOrderA = regionOrder[locationCodeA]
  const locationOrderB = regionOrder[locationCodeB]
  return locationOrderA - locationOrderB
}

const allItemColumns = [
  {
    width: 55,
    name: 'uploadOrder',
    title: '',
    doubleClick: false,
    lockPosition: true,
    resizable: false,
    selection: true,
    cellStyle: function (params) {
      if (!params.data?.validationResult?.isValid) {
        return { backgroundColor: '#f3b1ae' }
      } else {
        return { backgroundColor: '#fff' }
      }
    },
  },
  {
    name: 'drag',
    title: '',
    width: 70,
    cellStyle: { paddingLeft: 15, paddingRight: 0, margin: 0 },
    rowDrag: true,
    doubleClick: false,
    sortable: false,
    resizable: false,
    lockPosition: true,
    cellRenderer: DragCellRenderer,
  },
  {
    name: 'imageUrls',
    doubleClick: true,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: 'prolisterGrid_metadata_allItemColumns_imageUrls',
    resizable: false,
    width: 80,
    cellRenderer: ImageCellRenderer,
    valueFormatter: amountFormatter,
    comparator: imageComparator,
  },
  {
    name: 'header',
    title: 'prolisterGrid_metadata_allItemColumns_header',
    editable: true,
    valueFormatter: textFormatter,
  },
  {
    name: 'description',
    title: 'prolisterGrid_metadata_allItemColumns_description',
    cellRenderer: DescriptionRenderer,
  },
  {
    name: 'category',
    cellStyle: { paddingLeft: 0, paddingRight: 0, margin: 0 },
    title: 'prolisterGrid_metadata_allItemColumns_category',
    valueFormatter: categoryFormatter,
    cellRenderer: CategoryRenderer,
    comparator: categoryComparator,
  },
  {
    name: 'attributes',
    title: 'prolisterGrid_metadata_allItemColumns_attributes',
    cellRenderer: AttributesRenderer,
  },
  {
    name: 'startingBid',
    title: 'prolisterGrid_metadata_allItemColumns_startingBid',
    valueFormatter: currencyFormatter,
    editable: (params) =>
      params.data?.itemType === ItemType.Auction ? true : false,
    cellStyle: function (params) {
      if (params.data?.itemType !== ItemType.Auction) {
        return { color: '#b3b3b3' }
      } else {
        return { color: '#000' }
      }
    },
  },
  {
    name: 'buyNowPrice',
    title: 'prolisterGrid_metadata_allItemColumns_buyNowPrice',
    valueFormatter: currencyFormatter,
    editable: true,
  },
  {
    name: 'itemType',
    title: 'prolisterGrid_metadata_allItemColumns_itemType',
    valueFormatter: advertisementTypeFormatter,
    cellRenderer: ItemTypeElementRenderer,
  },
  {
    name: 'condition',
    title: 'prolisterGrid_metadata_allItemColumns_condition',
    valueFormatter: conditionFormatter,
    cellRenderer: ConditionElementRenderer,
  },
  {
    name: 'isHighlighted',
    title: 'prolisterGrid_metadata_allItemColumns_isHighlighted',
    width: 103,
    resizable: false,
    cellRenderer: CheckBoxRenderer,
  },
  {
    name: 'acceptedBidders',
    title: 'prolisterGrid_metadata_allItemColumns_acceptedBidders',
    cellRenderer: SelectElementRenderer,
    valueFormatter: acceptedBiddersTypeFormatter,
    comparator: acceptedBiddersComparator,
  },
  {
    name: 'exposure',
    title: 'prolisterGrid_metadata_allItemColumns_exposure',
    doubleClick: true,
    valueFormatter: exposureFormatter,
  },
  {
    name: 'shippingAlternative',
    title: 'prolisterGrid_metadata_allItemColumns_shippingAlternative',
    doubleClick: true,
    sortable: true,
    valueFormatter: shippingFormatter,
    comparator: shippingComparator,
  },
  {
    name: 'shippingCostFirst',
    title: 'prolisterGrid_metadata_allItemColumns_shippingCostFirst',
    cellRenderer: ShippingCostRenderer,
    editable: true,
  },
  {
    name: 'shippingCostSecond',
    title: 'prolisterGrid_metadata_allItemColumns_shippingCostSecond',
    cellRenderer: ShippingCostRenderer,
    editable: true,
  },
  {
    name: 'takeawayShipping',
    title: 'prolisterGrid_metadata_allItemColumns_takeawayShipping',
    cellRenderer: TakeawayShippingRenderer,
    editable: false,
  },
  {
    name: 'restarts',
    title: 'prolisterGrid_metadata_allItemColumns_restarts',
    doubleClick: true,
  },
  {
    name: 'reference',
    title: 'prolisterGrid_metadata_allItemColumns_reference',
    editable: true,
    valueFormatter: textFormatter,
  },
  {
    name: 'latestUploadDate',
    title: 'prolisterGrid_metadata_allItemColumns_latestUploadDate',
    doubleClick: true,
    valueFormatter: dateFormatter,
  },
  {
    name: 'advertisementLengthInDays',
    title: 'prolisterGrid_metadata_allItemColumns_advertisementLengthInDays',
    cellRenderer: AdLengthElementRenderer,
  },
  {
    name: 'hasChosenEndTime',
    title: 'prolisterGrid_metadata_allItemColumns_hasChosenEndTime',
    width: 140,
    cellStyle: { paddingLeft: 0, paddingRight: 0, margin: 0 },
    cellRenderer: EndTimeRenderer,
    comparator: endTimeComparator,
  },
  {
    name: 'rankUp',
    title: 'prolisterGrid_metadata_allItemColumns_rankUp',
    width: 103,
    resizable: false,
    cellRenderer: CheckBoxRankUpRenderer,
  },
  {
    name: 'reservationPrice',
    title: 'prolisterGrid_metadata_allItemColumns_reservationPrice',
    valueFormatter: currencyFormatter,
    editable: true,
  },
  {
    name: 'latestObjectId',
    title: 'prolisterGrid_metadata_allItemColumns_latestObjectId',
  },
  {
    name: 'shippingCondition',
    title: 'prolisterGrid_metadata_allItemColumns_shippingCondition',
    editable: true,
    valueFormatter: textFormatter,
  },
  {
    name: 'publishResult',
    title: 'prolisterGrid_metadata_allItemColumns_publishResult',
    cellRenderer: PublicationResultRenderer,
  },
  {
    name: 'quantity',
    title: 'prolisterGrid_metadata_allItemColumns_quantity',
    cellStyle: (params) =>
      params.data?.itemType !== ItemType.ShopItem
        ? { color: '#b3b3b3' }
        : { color: '#000' },
    editable: (params) =>
      params.data?.itemType === ItemType.ShopItem ? true : false,
    valueFormatter: quantityFormatter,
  },
  {
    name: 'vat',
    title: 'prolisterGrid_metadata_allItemColumns_vat',
    cellRenderer: VatRenderer,
  },
]

const getAllItemColumns = (
  hasPublishColumn: boolean,
  columnOrders: string[] = [],
  numberOfItems: number = 0
) => {
  let columns = [...allItemColumns]
  if (columnOrders.length > 0) {
    let newColumns: any[] = []

    columnOrders.forEach((n) => {
      let columnOrder = n.split(':')
      const column = columns.find((c) => c.name === columnOrder[0])
      if (
        column &&
        column.name !== 'drag' &&
        column.name !== 'uploadOrder' &&
        column.name !== 'publishResult'
      ) {
        if (columnOrder[1]) {
          column.width = parseInt(columnOrder[1])
        }
        newColumns.push(column)
      }
    })
    columns = [...newColumns]
  }
  let dragColumn = allItemColumns.find((c) => c.name === 'drag')
  if (dragColumn && !columns.find((c) => c.name === 'drag')) {
    columns.splice(0, 0, dragColumn)
  }
  let orderColumn = allItemColumns.find((c) => c.name === 'uploadOrder')
  if (orderColumn && !columns.find((c) => c.name === 'uploadOrder')) {
    if (numberOfItems > 99) {
      orderColumn.width = 63
    } else if (numberOfItems > 999) {
      orderColumn.width = 73
    }

    columns.splice(0, 0, orderColumn)
  }
  let publishColumn = allItemColumns.find((c) => c.name === 'publishResult')
  if (
    publishColumn &&
    hasPublishColumn &&
    !columns.find((c) => c.name === 'publishResult')
  ) {
    columns.splice(1, 0, publishColumn)
  }

  return columns
}

const allListsColumns = [
  {
    name: 'openList',
    title: '',
    width: 50,
    doubleClick: true,
    sortable: false,
    resizable: false,
    lockPosition: true,
    cellRenderer: OpenListCellRenderer,
    selection: true,
  },
  {
    name: 'name',
    title: 'prolisterGrid_metadata_allListsColumns_name',
    doubleClick: true,
    editable: true,
  },
  {
    name: 'items',
    title: 'prolisterGrid_metadata_allListsColumns_items',
    doubleClick: true,
    valueFormatter: itemsFormatter,
  },
  {
    name: 'lastChanged',
    title: 'prolisterGrid_metadata_allListsColumns_lastChanged',
    doubleClick: true,
    valueFormatter: dateFormatter,
  },
  {
    name: 'createdDate',
    title: 'prolisterGrid_metadata_allListsColumns_createdDate',
    doubleClick: true,
    valueFormatter: dateFormatter,
  },
  {
    name: 'publicationDate',
    title: 'prolisterGrid_metadata_allListsColumns_publicationDate',
    doubleClick: true,
    valueFormatter: dateFormatter,
  },
  {
    name: 'publicationResult',
    title: 'prolisterGrid_metadata_allListsColumns_publicationResult',
    cellRenderer: PublicationResultRenderer,
  },
  {
    name: 'startDate',
    title: 'prolisterGrid_metadata_allListsColumns_startDate',
    doubleClick: true,
    valueFormatter: dateFormatter,
  },
]

const activeColumns = [
  {
    name: 'menu',
    title: '',
    width: 50,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sortable: false,
    resizable: false,
    lockPosition: true,
    cellRenderer: OpenMenuCellRenderer,
    selection: true,
  },
  {
    name: 'imageUrls',
    doubleClick: true,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: 'prolisterGrid_metadata_allActiveColumns_imageUrls',
    resizable: false,
    width: 80,
    cellRenderer: ImageCellRenderer,
    valueFormatter: amountFormatter,
    comparator: imageComparator,
  },
  {
    name: 'status',
    title: 'prolisterGrid_metadata_allActiveColumns_status',
    cellRenderer: ActiveStatusRenderer,
    width: 120,
  },
  {
    name: 'id',
    title: 'prolisterGrid_metadata_allActiveColumns_id',
    width: 120,
  },
  {
    name: 'header',
    title: 'prolisterGrid_metadata_allActiveColumns_header',
    valueFormatter: textFormatter,
  },
  {
    name: 'itemType',
    title: 'prolisterGrid_metadata_allActiveColumns_itemType',
    width: 150,
    valueFormatter: advertisementTypeFormatter,
  },
  {
    name: 'startingBid',
    title: 'prolisterGrid_metadata_allActiveColumns_startingBid',
    width: 120,
    valueFormatter: currencyFormatter,
    cellStyle: function (params) {
      if (params.data?.itemType !== ItemType.Auction) {
        return { color: 'transparent' }
      } else {
        return { color: '#000' }
      }
    },
  },
  {
    name: 'maxBid',
    title: 'prolisterGrid_metadata_allActiveColumns_maxBid',
    width: 120,
    valueFormatter: bidFormatter,
  },
  {
    name: 'buyNowPrice',
    title: 'prolisterGrid_metadata_allActiveColumns_buyNowPrice',
    width: 120,
    valueFormatter: currencyFormatter,
  },
  {
    name: 'quantity',
    title: 'prolisterGrid_metadata_allActiveColumns_quantity',
    width: 120,
    cellStyle: (params) =>
      params.data?.itemType !== ItemType.ShopItem
        ? { color: '#b3b3b3' }
        : { color: '#000' },
    valueFormatter: quantityFormatter,
  },
  {
    name: 'vat',
    title: 'prolisterGrid_metadata_allActiveColumns_vat',
    width: 120,
    valueFormatter: vatFormatter,
  },
  {
    name: 'publishDate',
    title: 'prolisterGrid_metadata_allActiveColumns_publishDate',
    valueFormatter: dateFormatter,
  },
  {
    name: 'endDate',
    title: 'prolisterGrid_metadata_allActiveColumns_endDate',
    valueFormatter: dateFormatter,
  },
]

const unsoldColumns = [
  {
    name: 'menu',
    title: 'prolisterGrid_metadata_unsoldColumns_menu',
    width: 50,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sortable: false,
    resizable: false,
    lockPosition: true,
    cellRenderer: OpenMenuCellRenderer,
    selection: true,
  },
  {
    name: 'imageUrls',
    doubleClick: true,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: 'prolisterGrid_metadata_unsoldColumns_imageUrls',
    resizable: false,
    width: 80,
    cellRenderer: ImageCellRenderer,
    valueFormatter: amountFormatter,
    comparator: imageComparator,
  },
  {
    name: 'status',
    title: 'prolisterGrid_metadata_unsoldColumns_status',
    cellRenderer: UnsoldStatusRenderer,
    width: 120,
  },
  {
    name: 'id',
    title: 'prolisterGrid_metadata_unsoldColumns_id',
    width: 150,
  },
  {
    name: 'header',
    title: 'prolisterGrid_metadata_unsoldColumns_header',
    valueFormatter: textFormatter,
  },
  {
    name: 'buyNowPrice',
    title: 'prolisterGrid_metadata_unsoldColumns_buyNowPrice',
    width: 100,
    valueFormatter: currencyFormatter,
  },
  {
    name: 'startingBid',
    title: 'prolisterGrid_metadata_unsoldColumns_startingBid',
    width: 150,
    valueFormatter: currencyFormatter,
    cellStyle: function (params) {
      if (params.data?.itemType !== ItemType.Auction) {
        return { color: '#b3b3b3' }
      } else {
        return { color: '#000' }
      }
    },
  },
  {
    name: 'quantity',
    title: 'prolisterGrid_metadata_unsoldColumns_quantity',
    width: 120,
    cellStyle: (params) =>
      params.data?.itemType !== ItemType.ShopItem
        ? { color: '#b3b3b3' }
        : { color: '#000' },
    valueFormatter: quantityFormatter,
  },
  {
    name: 'vat',
    title: 'prolisterGrid_metadata_unsoldColumns_vat',
    width: 120,
    cellRenderer: vatFormatter,
  },
  {
    name: 'itemType',
    title: 'prolisterGrid_metadata_unsoldColumns_itemType',
    width: 150,
    valueFormatter: 'advertisementTypeFormatter',
  },
  {
    name: 'reference',
    title: 'prolisterGrid_metadata_unsoldColumns_reference',
    valueFormatter: textFormatter,
  },
  {
    name: 'endDate',
    title: 'prolisterGrid_metadata_unsoldColumns_endDate',
    valueFormatter: dateFormatter,
  },
]

const allTemplateColumns = [
  {
    name: 'isChecked',
    title: '',
    cellRenderer: CheckBoxSelectRowRenderer,
    suppressSizeToFit: true,
    width: 80,
    resizable: false,
    lockPosition: true,
  },
  {
    name: 'name',
    title: 'Namn',
    editable: true,
  },
  {
    name: 'numberOfitems',
    title: 'Antal annonser',
    doubleClick: true,
  },
  {
    name: 'lastChanged',
    title: 'Ändringsdatum',
    valueFormatter: dateFormatter,
  },
  {
    name: 'createdDate',
    title: 'Tilläggsdatum',
    valueFormatter: dateFormatter,
  },
]

export {
  allItemColumns,
  allListsColumns,
  activeColumns,
  unsoldColumns,
  allTemplateColumns,
  getAllItemColumns,
}
