import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { ISellItem, ISellList } from './ISellView'
import {
  deselectAll,
  getTraderaListsSucceeded,
  saveSellItem,
  selectItemInList,
} from './SellView.actions'
import SellItemReducer from './SellItem.reducer'

export interface ISellItemsState {
  [key: string]: ISellItem
}

export const initialState: ISellItemsState = {}

const normalizeItems = (items: ISellItem[]) =>
  items.reduce((_ads, _ad) => {
    _ads[_ad.id!] = _ad
    return _ads
  }, {})

const deselectItems = (state: ISellItemsState): ISellItemsState => {
  const newState = { ...state }
  Object.keys(newState).forEach((key) => {
    if (!newState[key].isChecked) return
    newState[key] = { ...newState[key], isChecked: false }
  })
  return newState
}

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

export const makeItemsFromLists = (lists: ISellList[]) => {
  const listItems = lists.map((list) => list.items)
  const items: ISellItem[] = [].concat(...(listItems as any))
  return items
}

const byId: Reducer<ISellItemsState, IAction<any>> = handleActions(
  {
    [getTraderaListsSucceeded.toString()]: (
      state: ISellItemsState,
      action: IAction<any>
    ) => ({
      ...state,
      ...normalizeItems(makeItemsFromLists(action.payload)),
    }),
    [selectItemInList.toString()]: (
      state: ISellItemsState,
      action: IAction<{ id: string; isChecked: boolean }>
    ) => ({
      ...state,
      [action.payload.id]: SellItemReducer(state[action.payload.id], action),
    }),
    [deselectAll.toString()]: (state: ISellItemsState) => deselectItems(state),
  },
  initialState
)

const allIds = handleActions(
  {
    [getTraderaListsSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => [
      ...state.concat(
        makeItemsFromLists(action.payload).map((item: any) => item.id)
      ),
    ],
    [saveSellItem.toString()]: (state: string[] = [], action: IAction<any>) => {
      if (action.payload.id && !state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return state
    },
  },
  []
)

const SellItemsReducer = combineReducers({
  byId,
  allIds,
})

export default SellItemsReducer
