import { ReactComponent as BankIdIcon } from '@tradera/blueprint/theme/icons-colored/bankid.svg'
import { ReactComponent as BankIdButtonIcon } from '@tradera/blueprint/theme/icons2/bankid.svg'
import { useTranslation } from 'react-i18next'

interface BankIdModalProps {
  closeModal: () => void
  beginBankIdVerification: () => Promise<any>
  personalNumber: any
}

const BankIdModal: React.FunctionComponent<BankIdModalProps> = ({
  closeModal,
  beginBankIdVerification,
  personalNumber,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="p-3">
        <p className="text-left">{t('modal_bankId_text')}</p>
        <p className="text-left">{t('modal_bankId_description')}</p>
        <div className="text-center">
          <BankIdIcon className="icon icon-lg mr-1" />
          <span>{personalNumber}</span>
          <p className="mt-3">
            {t('modal_bankId_help')}{' '}
            <a
              href="https://info.tradera.com/sakerhetscenter/fragor-och-svar-bank-id/"
              target="_blank"
              rel="noreferrer"
            >
              {t('modal_bankId_click')}
            </a>
          </p>
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_bankId_button_cancel')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => beginBankIdVerification()}
            >
              <BankIdButtonIcon className="icon mr-1" />
              {t('modal_bankId_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankIdModal
