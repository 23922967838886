import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import trackingService from './trackingService'

export const PageTracking = () => {
  const history = useHistory()
  useLayoutEffect(
    () =>
      history.listen((location) =>
        trackingService.trackPageView(
          document.title,
          window.location.href,
          location.pathname
        )
      ),
    [history]
  )
  return null
}
