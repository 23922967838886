interface VATOption {
  value: number
  label: string
}

const VAT_OPTIONS: VATOption[] = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 25,
    label: '25%',
  },
]

export default VAT_OPTIONS
