import React, { useState } from 'react'
import { format, addDays } from 'date-fns'
import svLocale from 'date-fns/locale/sv'
import Select from '../../../Components/Select'
import CheckBox from '../../../Components/CheckBox'
import { ItemType, type IItemChanges } from '../../IItem'
import styles from '../EditItemsForm.module.scss'
import Label from '../../../Components/Label'
import CheckBoxExpander from '../../../Components/CheckBoxExpander'
import { useTranslation } from 'react-i18next'

interface ItemLengthProp {
  updateStateByItemChanges: (property: string, value: any) => void
  itemChanges: IItemChanges
}

const ItemLength: React.FunctionComponent<ItemLengthProp> = ({
  updateStateByItemChanges,
  itemChanges,
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  let dates: {
    date: Date
    dateNumber: number
  }[] = []
  let hours: string[] = []
  let minutes: string[] = []

  for (let i = 3; i <= 14; i++) {
    const date = addDays(new Date(), i)
    dates = [...dates, { date, dateNumber: i }]
  }
  for (let i = 0; i < 24; i++) {
    hours = [...hours, i < 10 ? `0${i}` : `${i}`]
  }
  for (let i = 0; i < 60; i++) {
    minutes = [...minutes, i < 10 ? `0${i}` : `${i}`]
  }

  const changeEndDate = (a, b) => {
    // Triggar när välj sluttid checkas i eller ur
    updateStateByItemChanges(a, b)
  }
  const changeEndTimeHours = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const hour = parseInt(e.currentTarget.value)
    updateStateByItemChanges('chosenEndTimeHour', hour as any)
  }
  const changeEndTimeMinutes = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const minute = parseInt(e.currentTarget.value)
    updateStateByItemChanges('chosenEndTimeMinute', minute)
  }
  const changeItemLength = (e) => {
    // Denna triggas när man ändrar i dropdown för att välja annonslängd
    const number = parseInt(e.target.value)
    updateStateByItemChanges('advertisementLengthInDays', number)
  }

  return (
    <>
      {itemChanges.itemType === ItemType.FixedPrice && (
        <div className={styles.expandContainer}>
          <div className="row align-items-center">
            <div className="col-md-4">
              <Label label="Annonslängd" />
            </div>
            <div className="col-md-8">
              <span>{t('editItemsForm_itemLength_info')}</span>
            </div>
          </div>
        </div>
      )}
      {itemChanges.itemType === ItemType.Auction && (
        <>
          <div>
            <CheckBoxExpander
              checked={itemChanges.changeAdvertismentLength}
              label={t('editItemsForm_itemLength_label')}
              name="changeAdvertismentLength"
              updateStateByItemChanges={updateStateByItemChanges}
              expandedCallback={setExpanded}
            />
            {expanded && (
              <div className={styles.expandContainer}>
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      onChange={(e) => changeItemLength(e)}
                      defaultValue={
                        itemChanges.advertisementLengthInDays
                          ? itemChanges.advertisementLengthInDays.toString()
                          : '7'
                      }
                      disable={!itemChanges.changeAdvertismentLength}
                    >
                      {dates.map((item, key) => (
                        <option
                          key={`${item.dateNumber}${key}`}
                          value={item.dateNumber}
                        >
                          {`${item.dateNumber} ${t(
                            'editItemsForm_itemLength_days'
                          )} ${format(item.date, 'iii dd MMM', {
                            locale: svLocale,
                          })}`}
                          )
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="d-flex gap-x-2">
                      <CheckBox
                        name="hasChosenEndTime"
                        label={t('editItemsForm_itemLength_endTime')}
                        checked={itemChanges.hasChosenEndTime}
                        handleInputChange={(a, b) => changeEndDate(a, b)}
                        disable={!itemChanges.changeAdvertismentLength}
                      />
                      {itemChanges.hasChosenEndTime! && (
                        <>
                          <Select
                            onChange={(e) => changeEndTimeHours(e)}
                            defaultValue={
                              itemChanges.chosenEndTimeHour !== 0 &&
                              itemChanges.chosenEndTimeHour < 10
                                ? '0' + itemChanges.chosenEndTimeHour.toString()
                                : itemChanges.chosenEndTimeHour !== 0 &&
                                  itemChanges.chosenEndTimeHour >= 10
                                ? itemChanges.chosenEndTimeHour.toString()
                                : '00'
                            }
                            disable={!itemChanges.hasChosenEndTime}
                          >
                            {hours.map((item, key) => (
                              <option key={`${item}${key}`} value={item}>
                                {`${item}`}
                              </option>
                            ))}
                          </Select>
                          <label> : </label>
                          <Select
                            onChange={(e) => changeEndTimeMinutes(e)}
                            defaultValue={
                              itemChanges.chosenEndTimeMinute !== 0 &&
                              itemChanges.chosenEndTimeMinute < 10
                                ? '0' +
                                  itemChanges.chosenEndTimeMinute.toString()
                                : itemChanges.chosenEndTimeMinute !== 0 &&
                                  itemChanges.chosenEndTimeMinute >= 10
                                ? itemChanges.chosenEndTimeMinute.toString()
                                : '00'
                            }
                            disable={!itemChanges.hasChosenEndTime}
                          >
                            {minutes.map((item, key) => (
                              <option key={`${item}${key}`} value={item}>
                                {`${item}`}
                              </option>
                            ))}
                          </Select>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ItemLength
