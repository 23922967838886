import { connect } from 'react-redux'
import { getCampaignCodeValid } from '../../../UI/UI.actions'
import CampaignCode from './CampaignCode'

const connectedComponent = connect(
  (state: any) => ({
    campaignCodeValid: state.ui.campaignCodeValid,
  }),
  {
    getCampaignCodeValid,
  }
)(CampaignCode)

export default connectedComponent
