import { Portal } from 'react-portal'
import Modal from 'react-modal'
import { ReactComponent as Close } from '@tradera/blueprint/theme/icons2/close.svg'
import { ReactComponent as Open } from '@tradera/blueprint/theme/icons2/open.svg'
import Button from '../Components/Button'
import styles from './ResponsiveModal.module.scss'
import { useMediaQuery } from 'react-responsive'

import ImportListModal from './ImportList/'
import ImportMappingModal from './ImportMapping'
import PublishList from './PublishList'
import BankIdModal from './BankIdModal'
import BankIdVerifyModal from './BankIdVerifyModal'
import PublicationResult from './PublicationResult/'
import PublishErrorMessage from './PublishErrorMessage'
import SellItemsError from './SellItemsError'
import ErrorMessage from './ErrorMessage'
import ImportingListModal from './ImportingList'
import ImportingPlzipListModal from './ImportingPlzipList'
import EditManyItemsAtOnce from './EditManyItemsAtOnce'
import EditShopItem from './EditShopItem'
import EditItem from './EditItem'
import CreateItemFromImage from './CreateItemFromImage'
import EditCategoryModal from './EditCategoryModal'
import PublishProgress from './PublishProgress'
import LoadingModal from './LoadingModal'
import PublishSelected from './PublishSelected'
import PublishValid from './PublishValid'
import DeleteItems from './DeleteItems'
import DeleteLists from './DeleteLists'
import CancelSellItems from './CancelSellItems'
import SelectColumnsModal from './SelectColumnsModal'
import SettingsModal from './SettingsModal'
import { useEffect, useRef, useState } from 'react'
import useImageList from '../Hooks/useImageList'
import { useSelector } from 'react-redux'
import type { RootState } from '../Store/rootReducer'
import type { IUIState } from '../UI/UI.reducer'
import { useTranslation } from 'react-i18next'

const ResponsiveModal = ({
  isModalOpen,
  modalType,
  closeModal,
  title,
  isLarge,
  linkUrl,
  linkText,
}) => {
  const { t } = useTranslation()
  const [closeClicked, setCloseClicked] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  const { imageList, clearImages, setImageListDirectly } = useImageList()
  const uploadSellItems = useSelector(
    (state: RootState) => (state.ui as IUIState).uploadSellItems || []
  )

  const prevUploadSellItemsRef = useRef<typeof uploadSellItems>([])

  useEffect(() => {
    if (
      prevUploadSellItemsRef.current.length > 0 &&
      uploadSellItems.length === 0
    ) {
      clearImages()
    }

    prevUploadSellItemsRef.current = uploadSellItems
  }, [uploadSellItems, clearImages])

  useEffect(() => {
    if (!isModalOpen) {
      setCloseClicked(false)
    }
  }, [isModalOpen])

  return (
    <Portal>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
          },
          content: {
            overflowX: 'hidden',
            padding: 0,
            margin: 0,
            backgroundColor: 'white',
            borderRadius: 0,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: isLarge ? 875 : 485,
            maxHeight: isLarge ? 'min(700px,100%)' : 'min(530px,100%)',
            minHeight: 180,
            minWidth: isLarge && !isSmallScreen ? 750 : 375,
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-header-sticky">
              <div className="row align-items-center text-center">
                <div className="col-1">
                  {linkUrl && linkUrl.length > 0 && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={linkUrl}
                      className="d-inline-flex align-items-center align-middle"
                    >
                      <Open className="icon icon-xs icon-dark" />
                      <span className="ml-1">{linkText}</span>
                    </a>
                  )}
                </div>
                <div className="col-10">
                  <span className={styles.title}>{t(title)}</span>
                </div>
                <div className="col-1">
                  <Button
                    clickHandler={() => {
                      setCloseClicked(true)
                      closeModal()
                    }}
                    icon={<Close className="icon" />}
                    variant="noborder"
                  />
                </div>
              </div>
            </div>
            <div className="modal-body">
              {modalType === 'import' && <ImportListModal />}
              {modalType === 'importing' && <ImportingListModal />}
              {modalType === 'importingPlzip' && <ImportingPlzipListModal />}
              {modalType === 'importMapping' && <ImportMappingModal />}
              {modalType === 'publishList' && (
                <PublishList
                  imageList={imageList}
                  closeClicked={closeClicked}
                  clearImages={clearImages}
                />
              )}
              {modalType === 'bankIdInit' && <BankIdModal />}
              {modalType === 'bankIdVerify' && <BankIdVerifyModal />}
              {modalType === 'publishProgress' && <PublishProgress />}
              {modalType === 'loadingModal' && <LoadingModal />}
              {modalType === 'publishSelected' && <PublishSelected />}
              {modalType === 'publishValid' && <PublishValid />}
              {modalType === 'deleteItems' && <DeleteItems />}
              {modalType === 'deleteLists' && <DeleteLists />}
              {modalType === 'cancelSellItems' && <CancelSellItems />}
              {modalType === 'publishResult' && <PublicationResult />}
              {modalType === 'publishErrorMessage' && <PublishErrorMessage />}
              {modalType === 'sellItemsError' && <SellItemsError />}
              {modalType === 'errorMessage' && <ErrorMessage />}
              {modalType === 'editManyItemsAtOnce' && <EditManyItemsAtOnce />}
              {modalType === 'editShopItem' && <EditShopItem />}
              {modalType === 'editItem' && (
                <EditItem
                  imageList={imageList}
                  setImageListDirectly={setImageListDirectly}
                  closeClicked={closeClicked}
                  clearImages={clearImages}
                />
              )}
              {modalType === 'createItemFromImage' && <CreateItemFromImage />}
              {modalType === 'editCategoryModal' && <EditCategoryModal />}
              {modalType === 'selectColumnsModal' && <SelectColumnsModal />}
              {modalType === 'settingsModal' && <SettingsModal />}
            </div>
          </div>
        </div>
      </Modal>
    </Portal>
  )
}
export default ResponsiveModal
