import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import type { ITemplate } from './ITemplate'
import {
  createTemplateSucceeded,
  duplicateTemplateSucceeded,
  getTemplatesSucceeded,
  updateTemplate,
  updateTemplateNameSucceeded,
} from './Template.actions'

const initialState: any = null

const templateReducer: Reducer<ITemplate, IAction<any>> = handleActions(
  {
    [getTemplatesSucceeded.toString()]: (
      _state: ITemplate,
      action: IAction<ITemplate>
    ) => action.payload,
    [duplicateTemplateSucceeded.toString()]: (
      _state: ITemplate,
      action: IAction<ITemplate>
    ) => action.payload,
    [createTemplateSucceeded.toString()]: (
      _state: ITemplate,
      action: IAction<ITemplate>
    ) => action.payload,
    [updateTemplate.toString()]: (
      _state: ITemplate,
      action: IAction<ITemplate>
    ) => action.payload,
    [updateTemplateNameSucceeded.toString()]: (
      state: ITemplate,
      action: IAction<ITemplate>
    ) => ({
      ...state,
      name: action.payload.name,
    }),
  },
  initialState
)

export default templateReducer
