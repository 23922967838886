import { createActions } from 'redux-actions'
import { deleteRequest, get, post, put } from '../Infrastructure/Network'
import type { ITemplate } from './ITemplate'

export const {
  getTemplatesRequested,
  getTemplatesSucceeded,
  getTemplatesRejected,
} = createActions({
  getTemplatesRequested: () => ({}),
  getTemplatesSucceeded: (templates) => templates,
  getTemplateRejected: () => ({}),
})

export const getTemplates = () =>
  get({
    url: `/template/templates`,
    initiated: getTemplatesRequested,
    fulfilled: (template) => getTemplatesSucceeded(template),
    rejected: getTemplatesRejected,
  })

export const {
  getTemplateByIdRequested,
  getTemplateByIdSucceeded,
  getTemplateByIdRejected,
} = createActions({
  getTemplateByIdRequested: () => ({}),
  getTemplateByIdSucceeded: (template: ITemplate) => template,
  getTemplateByIdRejected: () => ({}),
})
export const getTemplateById = (id: string) =>
  get({
    url: `/template/${id}`,
    initiated: getTemplateByIdRequested,
    fulfilled: getTemplateByIdSucceeded,
    rejected: getTemplateByIdRejected,
  })

export const {
  createTemplateRequested,
  createTemplateSucceeded,
  createTemplateRejected,
} = createActions({
  createTemplateRequested: () => ({}),
  createTemplateSucceeded: (template: ITemplate) => template,
  createTemplateRejected: () => ({}),
})

export const createTemplate = (listId: string) =>
  post({
    url: `/template/list/${listId}`,
    initiated: createTemplateRequested,
    fulfilled: (template: ITemplate) => createTemplateSucceeded(template),
    rejected: createTemplateRejected,
  })

export const {
  duplicateTemplateRequested,
  duplicateTemplateSucceeded,
  duplicateTemplateRejected,
} = createActions({
  duplicateTemplateRequested: () => ({}),
  duplicateTemplateSucceeded: (Template: ITemplate) => Template,
  duplicateTemplateRejected: () => ({}),
})

export const duplicateTemplate = (id: string) =>
  post({
    url: `/Template/duplicate/${id}`,
    initiated: duplicateTemplateRequested,
    fulfilled: (Template: ITemplate) => duplicateTemplateSucceeded(Template),
    rejected: duplicateTemplateRejected,
  })

export const {
  deleteTemplateRequested,
  deleteTemplateSucceeded,
  deleteTemplateRejected,
} = createActions({
  deleteTemplateRequested: () => ({}),
  deleteTemplateSucceeded: (templateIds: string[]) => templateIds,
})
export const deleteTemplate = (ids: string[]) =>
  deleteRequest({
    url: `/template?${ids.map((id) => `templateids=${id}`).join('&')}`,
    body: ids,
    initiated: deleteTemplateRequested,
    fulfilled: () => deleteTemplateSucceeded(ids),
    rejected: duplicateTemplateRejected,
  })

export const { updateTemplate } = createActions({
  updateTemplate: (template: ITemplate) => template,
})

export const {
  updateTemplateNameRequested,
  updateTemplateNameSucceeded,
  updateTemplateNameRejected,
} = createActions({
  updateTemplateNameRequested: () => ({}),
  updateTemplateNameSucceeded: (id, name) => ({ id, name }),
  updateTemplateNameRejected: () => ({}),
})

export const updateTemplateName = (templateId: string, name: string) => {
  return put({
    url: `/template/${templateId}`,
    initiated: updateTemplateNameRequested,
    fulfilled: () => updateTemplateNameSucceeded(templateId, name),
    rejected: updateTemplateNameRejected,
    body: name,
  })
}
