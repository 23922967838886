import React from 'react'
import trackingService from '../../Infrastructure/Tracking/trackingService'
import styles from './Button.module.scss'

interface ButtonProps {
  text?: string
  variant:
    | 'primary'
    | 'outlined'
    | 'dark'
    | 'danger'
    | 'disable'
    | 'outlined-danger'
    | 'outlined-primary'
    | 'text-danger'
    | 'noborder'
  size?: 'large' | 'medium' | 'small' | 'xsmall' | 'normal' | 'switch'
  testId?: string
  icon?: React.ReactNode
  clickHandler?: (e?) => void
  style?: any
  disable?: boolean
  trackingData?: TrackingData
  content?: React.ReactNode
}
const Button: React.FunctionComponent<ButtonProps> = ({
  text,
  variant,
  size,
  clickHandler,
  testId,
  icon,
  style = {},
  disable,
  trackingData,
  content,
}) => (
  <button
    data-testid={testId ? testId : ''}
    onClick={() => {
      trackingData && trackingService.trackEvent(trackingData)
      clickHandler && clickHandler()
    }}
    className={`${styles.button} ${styles[variant]} ${
      size ? styles[size] : ''
    }`}
    style={{ ...style }}
    disabled={disable}
  >
    {text}
    {icon}
    {content}
  </button>
)
export default Button
