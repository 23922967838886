import React from 'react'
import ListMenuItem from '../../Components/ListMenuItem'
import styles from './Toolbar.module.scss'
import type { ItemListMenuItemProps } from '../../Components/ListMenuItem/ListMenuItem'

interface ToolbarProps {
  menuItems: ItemListMenuItemProps[]
}

const Toolbar: React.FunctionComponent<ToolbarProps> = ({ menuItems }) => {
  return (
    <>
      <div className={`container-fluid ${styles.listmenu}`}>
        <div className="d-flex align-items-center pb-1">
          {menuItems.map((item, key) => (
            <ListMenuItem
              action={item.action}
              key={key}
              name={item.name}
              icon={item.icon}
              isDisabled={item.isDisabled}
              isHidden={item.isHidden}
              tooltip={item.tooltip}
              variant={item.variant}
              className={
                key < 1
                  ? `${styles.buttonAction} primary ${styles.primaryAction}`
                  : styles.buttonAction
              }
            />
          ))}
        </div>
      </div>
    </>
  )
}
export default Toolbar
