import { connect } from 'react-redux'
import { closeModal, openErrorMessageModal } from '../../UI/UI.actions'

import PublishErrorMessage from './PublishErrorMessage'

const connectedComponent = connect(
  (state: any) => {
    return {
      modalType: state.ui.modalType,
      errorMessage: state.ui.modalData,
    }
  },
  {
    closeModal,
    openErrorMessageModal,
  }
)(PublishErrorMessage)

export default connectedComponent