import React from 'react'
import CheckBox from '../../Components/CheckBox'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

const allPossibleColumns = [
  {
    id: 'acceptedBidders',
    label: 'modal_selectColumns_acceptedBidders',
    type: 'item',
  },
  {
    id: 'advertisementLengthInDays',
    label: 'modal_selectColumns_advertisementLengthInDays',
    type: 'item',
  },
  { id: 'itemType', label: 'modal_selectColumns_itemType', type: 'item' },
  { id: 'description', label: 'modal_selectColumns_description', type: 'item' },
  { id: 'imageUrls', label: 'modal_selectColumns_imageUrls', type: 'item' },
  { id: 'exposure', label: 'modal_selectColumns_exposure', type: 'item' },
  {
    id: 'shippingAlternative',
    label: 'modal_selectColumns_shippingAlternative',
    type: 'item',
  },
  {
    id: 'isHighlighted',
    label: 'modal_selectColumns_isHighlighted',
    type: 'item',
  },
  {
    id: 'shippingCostFirst',
    label: 'modal_selectColumns_shippingCostFirst',
    type: 'item',
  },
  { id: 'category', label: 'modal_selectColumns_category', type: 'item' },
  {
    id: 'shippingCostSecond',
    label: 'modal_selectColumns_shippingCostSecond',
    type: 'item',
  },
  { id: 'attributes', label: 'modal_selectColumns_attributes', type: 'item' },
  {
    id: 'takeawayShipping',
    label: 'modal_selectColumns_takeawayShipping',
    type: 'item',
  },
  { id: 'buyNowPrice', label: 'modal_selectColumns_buyNowPrice', type: 'item' },
  { id: 'restarts', label: 'modal_selectColumns_restarts', type: 'item' },
  { id: 'rankUp', label: 'modal_selectColumns_rankUp', type: 'item' },
  { id: 'reference', label: 'modal_selectColumns_reference', type: 'item' },
  {
    id: 'reservationPrice',
    label: 'modal_selectColumns_reservationPrice',
    type: 'item',
  },
  { id: 'header', label: 'modal_selectColumns_header', type: 'item' },
  {
    id: 'latestObjectId',
    label: 'modal_selectColumns_latestObjectId',
    type: 'item',
  },
  {
    id: 'latestUploadDate',
    label: 'modal_selectColumns_latestUploadDate',
    type: 'item',
  },
  { id: 'condition', label: 'modal_selectColumns_condition', type: 'item' },
  { id: 'startingBid', label: 'modal_selectColumns_startingBid', type: 'item' },
  {
    id: 'hasChosenEndTime',
    label: 'modal_selectColumns_hasChosenEndTime',
    type: 'item',
  },
  {
    id: 'shippingCondition',
    label: 'modal_selectColumns_shippingCondition',
    type: 'item',
  },
  { id: 'vat', label: 'modal_selectColumns_vat', type: 'shopItem' },
  { id: 'quantity', label: 'modal_selectColumns_quantity', type: 'shopItem' },
]

interface SelectColumnsModalProps {
  closeModal: () => void
  columnOrders: string[]
  updateColumnOrder: (columnOrder: any) => Promise<any>
  user: IUser
}

const SelectColumnsModal: React.FunctionComponent<SelectColumnsModalProps> = ({
  closeModal,
  columnOrders,
  updateColumnOrder,
  user,
}) => {
  const { t } = useTranslation()
  const onColumnCheckboxClicked = (name: string, isChecked: boolean) => {
    const newColumnOrders = isChecked
      ? [...columnOrders].concat([name])
      : columnOrders.filter((c) => !c.includes(name))

    updateColumnOrder(newColumnOrders)
  }

  const isColumnChecked = (id: string) => {
    let isChecked = false
    columnOrders?.forEach(function (column) {
      if (column.includes(id)) {
        isChecked = true
      }
    })
    return isChecked
  }
  return (
    <div>
      <div className="p-3">
        <div>
          <div className="row">
            {allPossibleColumns
              .filter(
                (x) =>
                  x.type === 'item' || (x.type === 'shopItem' && user.hasShop)
              )
              .map((pc, i) => (
                <div key={i} className="col-md-6">
                  <CheckBox
                    checked={isColumnChecked(pc.id)}
                    handleInputChange={(name, isChecked) =>
                      onColumnCheckboxClicked(name, isChecked as boolean)
                    }
                    label={t(pc.label)}
                    name={pc.id}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_selectColumns_button_confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectColumnsModal
