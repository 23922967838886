import { connect } from 'react-redux'
import { closeModal, minimize, setImportFileSize } from '../../UI/UI.actions'
import ImportingPlzipListModal from './ImportingPlzipListModal'

const connectedComponent = connect(
  (state: any) => {
    return {
      loading: state.ui.loading,
    }
  },
  {
    closeModal,
    minimize,
  }
)(ImportingPlzipListModal)

export default connectedComponent
