import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import styles from './RadioButton.module.scss'

interface RadioButtonProps {
  name: string
  value: string
  label: string
  checked: boolean
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  name,
  value,
  label,
  checked,
  handleInputChange,
}) => {
  const [id] = useState(_uniqueId('radio-'))
  return (
    <>
      <input
        style={{ visibility: 'hidden', width: '0px' }}
        type="radio"
        className="btn-check"
        name={name}
        value={value}
        id={id}
        onChange={(e) => handleInputChange(e)}
        checked={checked}
      />
      <label
        className={`${styles.outlined} ${checked ? styles.checked : ''}`}
        htmlFor={id}
      >
        {label}
      </label>
    </>
  )
}

export default RadioButton
