import React from 'react'
import type { ISellItem } from '../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface IActiveStatusRendererProps {
  data: ISellItem
}

const ActiveStatusRenderer: React.FunctionComponent<
  IActiveStatusRendererProps
> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div>
      {data.publishDate && new Date(data.publishDate) > new Date() ? (
        <span
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          className="gray-background status-label text-white"
        >
          {t('prolisterGrid_renderers_activeStatus_scheduled')}
        </span>
      ) : data.totalBids > 0 ? (
        <span
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          className="green-background status-label text-white"
        >
          {t('prolisterGrid_renderers_activeStatus_hasBids')}
        </span>
      ) : (
        <span
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
          }}
          className="yellow-background status-label"
        >
          {t('prolisterGrid_renderers_activeStatus_active')}
        </span>
      )}
    </div>
  )
}

export default ActiveStatusRenderer
