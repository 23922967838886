import { connect } from 'react-redux'
import { openAlertContainer } from '../../UI/UI.actions'
import { resetAlertContainer } from '../../UI/UI.actions'
import AlertContainer from './AlertContainer'

const connectedComponent = connect(
  (state: any) => {
    const message = state.ui.message
    const alertType = state.ui.alertType
    return {
      message,
      alertType,
    }
  },
  {
    openAlertContainer,
    resetAlertContainer,
  }
)(AlertContainer)

export default connectedComponent
