import type { Reducer} from 'redux';
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { ITemplate } from './ITemplate'
import {
  createTemplateSucceeded,
  deleteTemplateSucceeded,
  duplicateTemplateSucceeded,
  getTemplatesSucceeded,
  updateTemplate,
  updateTemplateNameSucceeded,
} from './Template.actions'

import templateReducer from './Template.reducer'

export interface ITemplatesState {
  [key: string]: ITemplate
}

export const initialState: ITemplatesState = {}

const normalizeTemplate = (templates: ITemplate[]) =>
  templates.reduce((_templates, _template) => {
    _templates[_template.id] = _template
    _templates[_template.id].items = _templates[_template.id].items.map(
      (item) => item.id
    )
    return _templates
  }, {})

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

export const removeProperties = (obj: any, properties: string[]) => {
  const newState = { ...obj }
  properties.map((property) => delete newState[property])
  return newState
}

const byId: Reducer<ITemplatesState, IAction<any>> = handleActions(
  {
    [getTemplatesSucceeded.toString()]: (
      state: ITemplatesState,
      action: IAction<ITemplate[]>
    ) => normalizeTemplate(action.payload),
    [duplicateTemplateSucceeded.toString()]: (
      state: ITemplatesState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: templateReducer(state[action.payload.id], action),
    }),
    [deleteTemplateSucceeded.toString()]: (
      state: ITemplatesState,
      action: IAction<any>
    ) => removeProperties(state, action.payload),
    [createTemplateSucceeded.toString()]: (
      state: ITemplatesState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: templateReducer(state[action.payload.id], action),
    }),
    [updateTemplate.toString()]: (
      state: ITemplatesState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: templateReducer(state[action.payload.id], action),
    }),
    [updateTemplateNameSucceeded.toString()]: (
      state: ITemplatesState,
      action: IAction<any>
    ) => ({
      ...state,
      [action.payload.id]: templateReducer(state[action.payload.id], action),
    }),
  },
  initialState
)

const allIds = handleActions(
  {
    [getTemplatesSucceeded.toString()]: (
      _state: string[] = [],
      action: IAction<ITemplate[]>
    ) => [...action.payload.map((l: ITemplate) => l.id)],
    [duplicateTemplateSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => [...state, action.payload.id],
    [deleteTemplateSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => state.filter((s) => !action.payload.includes(s)),
  },
  []
)

const templatesReducer = combineReducers({
  byId,
  allIds,
})

export default templatesReducer
