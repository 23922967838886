import { connect } from 'react-redux'
import { closeModal } from '../../UI/UI.actions'
import { deleteItemFromList } from '../../List/List.actions'

import DeleteItems from './DeleteItems'

const connectedComponent = connect(
  (state: any) => {
    return {
      list: state.lists.byId[state.ui.id],
      selectedItems: state.lists.byId[state.ui.id]?.items.filter(
        (item) => state.items.byId[item]?.isChecked
      ),
    }
  },
  {
    closeModal,
    deleteItemFromList,
  }
)(DeleteItems)

export default connectedComponent
