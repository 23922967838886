import React from 'react'
import { useDispatch } from 'react-redux'
import CheckBox from '../../Components/CheckBox'
import type { TabTypes } from '../../Content/IContent'
import { updateFieldIsHighlighted } from '../../Items/Items.action'
import type { IItem } from '../../Items/IItem'

interface CheckBoxRendererProps {
  value: boolean
  setValue: (value: boolean) => void
  rowIndex: number
  data: IItem
  test: any
  selectedContentType?: TabTypes | string
}

const CheckBoxRenderer: React.FunctionComponent<CheckBoxRendererProps> = ({
  value,
  setValue,
  rowIndex,
  data,
  test,
  selectedContentType,
}) => {
  const dispatch = useDispatch()
  const handleSelectedRows = () => {
    const itemToUpdate = {
      ...data,
      isHighlighted: !data.isHighlighted,
    }
    dispatch(
      updateFieldIsHighlighted(
        data.id ? data.id : '',
        itemToUpdate.isHighlighted
      )
    )
  }
  return (
    <CheckBox
      paddingless
      checked={data.isHighlighted}
      label=""
      value={data.id}
      handleInputChange={() => handleSelectedRows()}
      name="isHighlighted"
      styleGrid={true}
    />
  )
}
export default CheckBoxRenderer
