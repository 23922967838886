import React from 'react'
import { ReactComponent as Remove } from '@tradera/blueprint/theme/icons2/trashcan.svg'
import type { ICancelItemsParams, ISellItem } from '../../SellView/ISellView'
import { SellListType } from '../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface CancelSellItemsProps {
  closeModal: () => void
  selectedItems: ISellItem[]
  cancelItems: (params: ICancelItemsParams) => void
}

const CancelSellItems: React.FunctionComponent<CancelSellItemsProps> = ({
  closeModal,
  selectedItems,
  cancelItems,
}) => {
  const { t } = useTranslation()
  const selectedItemsCount = selectedItems.length
  const selectedItemsWithoutBid = selectedItems.filter(
    (item) => item.totalBids === 0
  ).length
  const selectedItemsWithBids = selectedItems.filter(
    (item) => item.totalBids > 0
  ).length
  const params: ICancelItemsParams = {
    listId: SellListType.Active,
    items: selectedItems.filter((item) => item.totalBids === 0),
  }
  return (
    <div>
      <div className="p-3">
        <div className="text-center mb-2">
          <Remove className="icon icon-lg" />
        </div>
        <p className="text-left">
          {selectedItemsCount === 1 && selectedItemsWithoutBid === 1 && (
            <span>{t('modal_cancelSellItems_question')}</span>
          )}
          {selectedItemsCount <= selectedItemsWithBids && (
            <span>{t('modal_cancelSellItems_description')}</span>
          )}
          {selectedItemsCount > 1 && selectedItemsWithBids === 0 && (
            <span>
              {t('modal_cancelSellItems_confirmationQuestion')}{' '}
              {selectedItemsCount}{' '}
              {t('modal_cancelSellItems_confirmationDescription')}
            </span>
          )}
          {selectedItemsCount > selectedItemsWithBids &&
            selectedItemsWithBids > 0 && (
              <span>
                {t('modal_cancelSellItems_confirmationWithBids')}{' '}
                {selectedItemsCount}{' '}
                {t('modal_cancelSellItems_confirmationWithBidsAppend')}.{' '}
                {selectedItemsWithBids}{' '}
                {t('modal_cancelSellItems_confirmationWithBidsDescription')}{' '}
                {selectedItemsWithoutBid}{' '}
                {t('modal_cancelSellItems_confirmationWithBidsAppend')}?
              </span>
            )}
        </p>
      </div>
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary btn-fluid"
              onClick={() => cancelItems(params)}
              disabled={selectedItemsCount <= selectedItemsWithBids}
            >
              {t('modal_cancelSellItems_button_confirm')}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary btn-fluid"
              onClick={() => closeModal()}
            >
              {t('modal_cancelSellItems_button_cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelSellItems
