class TrackingService {
  /**
   * Used for tracking Universial Events.
   * @param trackingData universal event to be tracked
   */
  trackEvent([category, action, label] : TrackingData) {
    window.gaTracking.trackEvent(action, {
      'event_category': category,
      'event_label': label || ''
    })
  }

  trackPageView(title: string, location: string, path: string) {
    window.gaTracking.configure({
      'page_title': title,
      'page_location': location,
      'page_path': path
    })
  }
}

export default new TrackingService();
