import React from 'react'
import { useDispatch } from 'react-redux'
import { addDays, format } from 'date-fns'
import svLocale from 'date-fns/locale/sv'
import { updateFieldAdvertisementLengthInDays } from '../../Items/Items.action'
import { ItemType, type IItem } from '../../Items/IItem'
import Select from '../../Components/Select'
import { useTranslation } from 'react-i18next'

interface AdLengthElementRendererProps {
  data: IItem
}

const AdLengthElementRenderer: React.FunctionComponent<
  AdLengthElementRendererProps
> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let dates: {
    date: Date
    dateNumber: number
  }[] = []
  for (let i = 3; i <= 14; i++) {
    const date = addDays(new Date(), i)
    dates = [...dates, { date, dateNumber: i }]
  }
  const handleSelectedRows = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value)
    const itemToUpdate = {
      ...data,
      advertisementLengthInDays: value,
    }
    dispatch(
      updateFieldAdvertisementLengthInDays(
        data.id ? data.id : '',
        itemToUpdate.advertisementLengthInDays
      )
    )
  }

  return (
    <div>
      {data.itemType === ItemType.Auction ? (
        <Select
          onChange={(e) => handleSelectedRows(e)}
          value={
            data.advertisementLengthInDays
              ? data.advertisementLengthInDays.toString()
              : '7'
          }
          styleGrid={true}
        >
          {dates.map((data, key) => (
            <option key={`${data.dateNumber}${key}`} value={data.dateNumber}>
              {`${data.dateNumber} ` +
                t('prolisterGrid_renderers_adLength_days') +
                ` ${format(data.date, 'iii dd MMM', {
                  locale: svLocale,
                })})`}
            </option>
          ))}
        </Select>
      ) : (
        <p className="mt-1">
          30 {t('prolisterGrid_renderers_adLength_days')}{' '}
          {format(addDays(new Date(), 30), 'iii dd MMM', {
            locale: svLocale,
          })}
        </p>
      )}
    </div>
  )
}

export default AdLengthElementRenderer
