import { connect } from 'react-redux'
import FileUpload from './FileUpload'

const connectedComponent = connect((state: any) => {
  return {
    loading: state.ui.loading,
  }
}, {})(FileUpload)

export default connectedComponent
