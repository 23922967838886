import type { Reducer } from 'redux'
import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import type { IAction } from '../Store/interfaces'
import type { IItem } from './IItem'
import {
  saveItemWip,
  deleteItemSucceeded,
  addImagesSucceeded,
} from './Items.action'
import { closeContent } from '../Content/Content.actions'

export interface IItemsState {
  [key: string]: IItem
}

export const initialState: IItemsState = {}

export const removeProperty = (obj: any, propertyName: string) => {
  const newState = { ...obj }
  delete newState[propertyName]
  return newState
}

const byId: Reducer<IItemsState, IAction<any>> = handleActions(
  {
    [saveItemWip.toString()]: (state: IItemsState, action: IAction<any>) => {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    },
    [deleteItemSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      let newState = { ...state }
      for (var id in action.payload.itemIds) {
        newState = removeProperty(state, id)
      }

      return newState
    },
    [addImagesSucceeded.toString()]: (
      state: IItemsState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          imageUrls: [
            ...state[action.payload.id].imageUrls,
            ...action.payload.images,
          ],
        },
      }
    },
    [closeContent.toString()]: (state: IItemsState, action: IAction<any>) =>
      removeProperty(state, action.payload.tabToCloseId),
  },
  initialState
)

const allIds = handleActions(
  {
    [saveItemWip.toString()]: (state: string[] = [], action: IAction<any>) => {
      if (action.payload.id && !state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return [...state]
    },
    [deleteItemSucceeded.toString()]: (
      state: string[],
      action: IAction<any>
    ) => {
      return [...state.filter((s) => !action.payload.itemIds.includes(s))]
    },
    [closeContent.toString()]: (state: string[], action: IAction<any>) =>
      state.filter((x) => x !== action.payload.tabToCloseId),
  },
  []
)

const ItemsWipReducer = combineReducers({
  byId,
  allIds,
})

export default ItemsWipReducer
