import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import EditItemsForm from '../../Items/EditItemsForm'
import Button from '../../Components/Button'
import useItemChanges from '../../ItemChanges/ItemChanges.hook'
import type { IItem, IItemChanges } from '../../Items/IItem'
import type { IList } from '../../List/IList'
import type { IUser } from '../../User/IUser'
import { useTranslation } from 'react-i18next'

interface EditManyItemsAtOnceProps {
  closeModal: () => void
  list: IList
  items: IItem[]
  user: IUser
  updateManyItemsAtOnce: (id: string, updateValues: IItemChanges) => void
}

const getSelectedItems = (items: IItem[]) => {
  if (items === undefined || !items || items.length === 0) {
    return []
  }
  if (items.length > 0 && items[0] === undefined) {
    return []
  }
  return items.filter((i) => i.isChecked) ?? []
}

const getSelectedItemsIds = (items: IItem[]) => {
  if (items === undefined || !items || items.length === 0) {
    return []
  }
  if (items.length > 0 && items[0] === undefined) {
    return []
  }
  const newItemIds = items.map((i: any) => i.id)
  return newItemIds
}

const EditManyItemsAtOnce: React.FunctionComponent<
  EditManyItemsAtOnceProps
> = ({ closeModal, list, items, user, updateManyItemsAtOnce }) => {
  const { t } = useTranslation()
  const selectedItems = getSelectedItems(items)

  const [isDisabledSave, setSaveDisabled] = useState(false)

  const updateItems = (itemChanges: IItemChanges, listId: string) => {
    updateManyItemsAtOnce(list.id, itemChanges)
  }

  const {
    itemChanges,
    handleInputChange,
    handleSelectChange,
    updateStateByItemChanges,
    setItemChanges,
  } = useItemChanges(getSelectedItemsIds(selectedItems), list.id)

  const dispatch = useDispatch()

  useEffect(() => {}, [setItemChanges])

  return (
    <div>
      <EditItemsForm
        itemChanges={itemChanges}
        user={user}
        handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e, dispatch)
        }
        handleSelectChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          handleSelectChange(e, dispatch)
        }
        updateStateByItemChanges={(e, v) =>
          updateStateByItemChanges(e, v, dispatch)
        }
      />
      <div className="modal-footer modal-footer-sticky border-top">
        <div className="row">
          <div className="col-6">
            <Button
              clickHandler={() => closeModal()}
              variant="outlined"
              size="normal"
              text={t('modal_editManyItemsAtOnce_button_cancel')}
            />
          </div>
          <div className="col-6">
            <Button
              testId="create-itemchanges"
              clickHandler={() => {
                updateItems(itemChanges, list.id)
                setSaveDisabled(true)
              }}
              variant={isDisabledSave ? 'disable' : 'primary'}
              size="normal"
              text={t('modal_editManyItemsAtOnce_button_confirm')}
              disable={isDisabledSave}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditManyItemsAtOnce
