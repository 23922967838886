import { connect } from 'react-redux'
import Content from './Content'
import { createList, importListFromPlZip } from '../List/List.actions'
import {
  addContent,
  closeTab,
  changeTab,
  openContent,
} from '../Content/Content.actions'
import { duplicateItems, moveItems } from '../Items/Items.action'
import {
  openImportMappingModal,
  setImportFileSize,
  setImportData,
} from '../UI/UI.actions'

const connectedComponent = connect(
  (state: any) => ({
    content: state.content.allIds.map((id) => state.content.byId[id]),
    lists: state.lists.allIds.map((id) => state.lists.byId[id]),
    clickedItems: state.ui.clickedItems,
    numberOfLists: state.user.numberOfLists,
  }),
  {
    addContent,
    closeTab,
    changeTab,
    duplicateItems,
    openContent,
    createList,
    openImportMappingModal,
    importListFromPlZip,
    setImportFileSize,
    setImportData,
    moveItems,
  }
)(Content)

export default connectedComponent
