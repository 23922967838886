import { connect } from 'react-redux'
import { closeModal, openErrorMessageModal } from '../../UI/UI.actions'

import ErrorMessage from './ErrorMessage'

const connectedComponent = connect(
  (state: any) => {
    return {
      errorCode: state.ui.modalData
    }
  },
  {
    closeModal,
    openErrorMessageModal,
  }
)(ErrorMessage)

export default connectedComponent