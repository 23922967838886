import React from 'react'
import RadioButton from '../../../Components/RadioButton'
import type { IItem } from '../../IItem'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ConditionProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  item: IItem | ISellItem
}

const Condition: React.FunctionComponent<ConditionProps> = ({
  handleInputChange,
  item,
}) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex">
      <div>
        <RadioButton
          name="condition"
          value="Mint"
          checked={item.condition === 'Mint' ? true : false}
          label={t('itemsForm_condition_mint')}
          handleInputChange={(e) => handleInputChange(e)}
        />
      </div>
      <div className="ml-2">
        <RadioButton
          name="condition"
          value="Used"
          checked={item.condition === 'Used' ? true : false}
          label={t('itemsForm_condition_used')}
          handleInputChange={(e) => handleInputChange(e)}
        />
      </div>
    </div>
  )
}

export default Condition
