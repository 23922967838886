import React, { useEffect, useState, useRef } from 'react'
import type { ITab } from './IContent'
import { TabTypes } from './IContent'
import TabItem from './TabItem/index'
import type { IList } from '../List/IList'
import MyList from '../MyLists'
import List from '../List'
import Item from '../Items/Item'
import ResponsiveModal from '../Modal'
import AlertContainer from '../Alert/AlertContainer'
import useCopyItemsToClipboard from '../Hooks/useCopyItemsToClipboard'
import { useDispatch } from 'react-redux'
import type { IItemClickOrder, IMoveParams } from '../Items/IItem'
import { ReactComponent as MyLists } from '../Icons/tab-my-lists.svg'
import { ReactComponent as NewList } from '../Icons/new-list.svg'
import { ReactComponent as ImportLists } from '../Icons/import-lists.svg'
import Footer from '../Components/Footer'
import styles from './Content.module.scss'
import { openAlertContainer } from '../UI/UI.actions'
import Papa from 'papaparse'
import SellView from '../SellView'
import { useTranslation } from 'react-i18next'

interface TabsProps {
  content: ITab[]
  lists: IList[]
  closeTab: (id: string) => void
  changeTab: (id: string) => void
  duplicateItems: (listId: string, itemIds: string[]) => Promise<any>
  clickedItems: IItemClickOrder[]
  openContent: (content: ITab, name: string) => void
  createList: () => void
  openImportMappingModal: () => void
  importListFromPlZip: (plZipFile: any) => void
  setImportFileSize: (importFileSize: number) => void
  setImportData: (importData: any) => void
  numberOfLists: number
  moveItems: (params: IMoveParams) => Promise<any>
}

interface ListApiProps {
  listId: string
  api: any
}

const Content: React.FunctionComponent<TabsProps> = ({
  content,
  lists,
  closeTab,
  changeTab,
  duplicateItems,
  clickedItems,
  openContent,
  createList,
  openImportMappingModal,
  importListFromPlZip,
  setImportFileSize,
  setImportData,
  numberOfLists,
  moveItems,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState<ITab>()
  const [copiedItems, copyItems] = useCopyItemsToClipboard()
  const [controlActive, setControlActive] = useState(false)
  const [cutFromList, setCutfromList] = useState<string | undefined>()
  const hiddenFileInput = useRef(null)
  const [gridApiList, setGridApiList] = useState<ListApiProps[]>([])

  const onGridApi = (id: string, api: any) => {
    const existingApiIndex = gridApiList.findIndex((item) => item.listId === id)
    if (existingApiIndex === -1) {
      const newApi: ListApiProps = {
        listId: id,
        api: api,
      }
      const updatedApiList = [...gridApiList, newApi]
      setGridApiList(updatedApiList)
    }
  }

  useEffect(() => {
    const currentTabExists = content.filter((x) => x.id === currentTab?.id)
    if (currentTabExists.length === 0) {
      //If tab was closed remove grid api reference
      const updatedApiList = [
        ...gridApiList.filter((x) => x.listId !== currentTab?.id),
      ]
      setGridApiList(updatedApiList)
    }
    const selectedTab = content.filter((x) => x.selected === true)
    if (selectedTab.length === 1 && currentTab?.id !== selectedTab[0].id) {
      //Deselect all possibly selected items in last current tab
      if (
        currentTab &&
        (currentTab.type === TabTypes.List ||
          currentTab.type === TabTypes.MyList ||
          currentTab.type === TabTypes.SellView)
      ) {
        const selectedGridApi = gridApiList.find(
          (x) => x.listId === currentTab?.id
        )
        if (selectedGridApi) {
          selectedGridApi.api.deselectAll()
        }
      }
      //If last tab was ad and new tab is list with ad in it, auto select that ad
      if (
        currentTab?.type === TabTypes.Item &&
        selectedTab[0].type === TabTypes.List
      ) {
        const selectedGridApi = gridApiList.find(
          (x) => x.listId === selectedTab[0].id
        )
        if (selectedGridApi) {
          selectedGridApi.api.forEachNode((node) => {
            if (node.data.id === currentTab.id) {
              node.setSelected(true)
            }
          })
        }
      }
      setCurrentTab(selectedTab[0])
    }
  }, [content])

  useEffect(() => {
    if (numberOfLists > 0) {
      openContent(
        {
          type: TabTypes.MyList,
          id: 'mylists',
          selected: true,
          name: t('header_myLists'),
        },
        t('header_myLists')
      )
    }
  }, [openContent, numberOfLists])

  useEffect(() => {
    function handlePressStart({ code, key }: KeyboardEvent): void {
      if (key === 'Control' || key === 'Meta') {
        setControlActive(true)
      }
      if (controlActive === true && code === 'KeyC') {
        if (currentTab!.type === TabTypes.List) {
          const selectedIds = clickedItems.map((x) => x.itemId) as string[]
          copyItems(selectedIds)
          setCutfromList(undefined)
        }
      }
      if (controlActive === true && code === 'KeyX') {
        if (currentTab!.type === TabTypes.List) {
          const selectedIds = clickedItems.map((x) => x.itemId) as string[]
          copyItems(selectedIds)
          setCutfromList(currentTab!.id)
        }
      }
      if (controlActive === true && code === 'KeyV') {
        if (currentTab!.type === TabTypes.List && copiedItems && !cutFromList) {
          duplicateItems(currentTab!.id, copiedItems as string[])
        } else if (
          currentTab!.type === TabTypes.List &&
          copiedItems &&
          cutFromList !== undefined
        ) {
          const moveParams: IMoveParams = {
            fromListId: cutFromList,
            toListId: currentTab!.id,
            itemIds: copiedItems,
          }
          moveItems(moveParams)
        }
        setCutfromList(undefined)
      }
    }

    function handlePressFinish({ key }: KeyboardEvent): void {
      if (key === 'Control' || key === 'Meta') {
        setControlActive(false)
      }
    }

    document.addEventListener('keydown', handlePressStart)
    document.addEventListener('keyup', handlePressFinish)

    return () => {
      document.removeEventListener('keydown', handlePressStart)
      document.removeEventListener('keyup', handlePressFinish)
    }
  }, [controlActive, copiedItems, copyItems, content])

  const handleFileSelected = (event) => {
    const fileToImport = event.target.files[0]
    if (fileToImport !== undefined) {
      const fileSize = fileToImport.size
      setImportFileSize(fileSize)
      if (fileToImport.name.slice(-3) === 'csv') {
        Papa.parse(fileToImport, {
          skipEmptyLines: true,
          delimitersToGuess: [
            ';',
            ',',
            '\t',
            '|',
            ';',
            Papa.RECORD_SEP,
            Papa.UNIT_SEP,
          ],
          complete: function (results) {
            setImportData(results)
          },
        })
        openImportMappingModal()
      } else if (fileToImport.name.slice(-5) === 'plzip') {
        importListFromPlZip(fileToImport)
        event.target.value = null
      } else {
        dispatch(openAlertContainer(t('alert_importFormat'), 'error'))
      }
    }
  }

  const onClickedImport = () => {
    ;(hiddenFileInput as any).current.click()
  }

  return (
    <div className={styles.content}>
      <ResponsiveModal />
      <AlertContainer />
      {content.length === 0 && numberOfLists !== undefined && (
        <div className={styles.emptyState}>
          <p className={styles.headerStyle}>{t('content_welcome')}!</p>
          <div className={styles.buttonContainer}>
            <div
              className={styles.button}
              role="button"
              onClick={() => createList()}
            >
              <div className={styles.icon}>
                <NewList width={50} height={50} />
              </div>
              <p className={styles.textStyle}>{t('content_createNewList')}</p>
            </div>
            <div
              className={styles.button}
              role="button"
              onClick={() => onClickedImport()}
            >
              <div className={styles.icon}>
                <ImportLists width={50} height={50} />
              </div>
              <p className={styles.textStyle}>{t('content_importList')}</p>
            </div>
            <div
              className={styles.button}
              role="button"
              onClick={() =>
                openContent(
                  {
                    type: TabTypes.MyList,
                    id: 'mylists',
                    selected: true,
                    name: t('header_myLists'),
                  },
                  t('header_myLists')
                )
              }
            >
              <div className={styles.icon}>
                <MyLists width={50} height={50} />
              </div>
              <p className={styles.textStyle}>{t('content_toYourLists')}</p>
            </div>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileSelected}
            style={{ display: 'none' }}
            accept=".plzip, .csv"
          />
          <Footer position="fixed" />
        </div>
      )}
      <ul className="nav nav-tabs">
        {content.map((item, key) => (
          <TabItem
            currentItem={key}
            changeTab={() => changeTab(item.id)}
            closeTab={() => closeTab(item.id)}
            id={item.id ? item.id : undefined}
            key={item.id}
            name={item.name}
            active={item.selected}
            tabType={item.type}
          />
        ))}
      </ul>
      {content.map((item, _key) => {
        switch (item.type) {
          case TabTypes.List:
          case TabTypes.Template:
            return (
              <div
                key={item.id}
                style={{
                  display: item.selected ? 'flex' : 'none',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <List
                  onGridApi={(id, api) => onGridApi(id, api)}
                  id={item.id}
                />
              </div>
            )
          case TabTypes.MyList:
            return (
              <div
                key={item.id}
                style={{
                  display: item.selected ? 'flex' : 'none',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <MyList onGridApi={(id, api) => onGridApi(id, api)} />
              </div>
            )
          case TabTypes.SellView:
            return (
              <div
                key={item.id}
                style={{
                  display: item.selected ? 'flex' : 'none',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <SellView onGridApi={(id, api) => onGridApi(id, api)} />
              </div>
            )
          case TabTypes.Item:
          case TabTypes.TemplateItem:
            return (
              <div
                key={item.id}
                style={{ display: item.selected ? 'flex' : 'none' }}
              >
                <Item id={item.id} />
              </div>
            )
          default:
            return null
        }
      })}
    </div>
  )
}
export default Content
