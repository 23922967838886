import React from 'react'
import { useDispatch } from 'react-redux'
import { updateItem } from '../../Items/Items.action'
import { ItemType, type IItem } from '../../Items/IItem'
import Select from '../../Components/Select'
import VAT_OPTIONS from '../../Infrastructure/Helpers/optionsHelper'
import type { IUser } from '../../User/IUser'

interface VatRendererProps {
  data: IItem
  user: IUser
}

const VatRenderer: React.FunctionComponent<VatRendererProps> = ({
  data,
  user,
}) => {
  const dispatch = useDispatch()
  const changeVat = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.currentTarget.value)
    const itemToUpdate = {
      ...data,
      vat: value,
    }
    dispatch(dispatch(updateItem(itemToUpdate, false)))
  }

  return (
    <div>
      {data.itemType === ItemType.ShopItem || user.isCompany ? (
        <Select
          onChange={(e) => changeVat(e)}
          value={data.vat ? data.vat.toString() : ''}
          styleGrid={true}
        >
          {VAT_OPTIONS.map((item, key) => (
            <option key={`${item.value}${key}`} value={item.value}>
              {`${item.label}`}
            </option>
          ))}
        </Select>
      ) : (
        <>{data.vat && <p className="mt-1 text-function">{data.vat}%</p>}</>
      )}
    </div>
  )
}

export default VatRenderer
