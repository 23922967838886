import type { Dispatch, SetStateAction } from 'react'
import type React from 'react'
import { useState } from 'react'
import { validateItem } from '../../Infrastructure/Helpers/validation'
import { ItemType, type IItem } from '../IItem'
import { saveItemWip } from '../Items.action'

interface UseItemProps {
  item: IItem
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    dispatch: any
  ) => void
  handleEditorChange: (editorState: string, dispatch: any) => void
  updateStateByItem: (
    property: string,
    value: string | number | boolean | File[] | string[],
    dispatch: any
  ) => void
  setItems: Dispatch<SetStateAction<IItem>>
}

function createDefaultItem(): IItem {
  const now = new Date()
  return {
    listId: '',
    campaignCode: '',
    campaignCodeValid: false,
    title: 'test',
    isChecked: false,
    imageUrls: [],
    imageRotations: [],
    header: '',
    description: '',
    startingBid: 0,
    buyNowPrice: 0,
    reservationPrice: 0,
    isHighlighted: false,
    rankUp: false,
    reference: '',
    condition: '',
    uploadOrder: 0,
    publishResult: '',
    latestObjectId: '',
    restarts: 0,
    acceptedBidders: 4,
    advertisementLengthInDays: 7,
    exposure: null,
    shippingAlternative: [],
    itemType: ItemType.Auction,
    chosenEndTimeHour: 0,
    chosenEndTimeMinute: now.getMinutes(),
    hasChosenEndTime: false,
    shippingCondition: '',
    quantity: 1,
  }
}

const useItem = (specificItem: IItem) => {
  const [item, setItems] = useState<IItem>(
    specificItem ? specificItem : createDefaultItem()
  )

  const updateItemState = (key: any, value: any, dispatch: any) => {
    setItems((item) => {
      const newItem: IItem = {
        ...item,
        [key]: value,
      }
      const resultOfValidation = validateItem(newItem)
      newItem.validationResult = resultOfValidation

      dispatch(saveItemWip(newItem))
      return newItem
    })
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dispatch: any
  ) => {
    event.persist()
    updateItemState(event.target.name, event.target.value, dispatch)
  }

  const handleEditorChange = (editorState: string, dispatch: any) => {
    updateItemState('description', editorState, dispatch)
  }

  const updateStateByItem = (
    property: string,
    value: string | number | boolean | File[] | string[],
    dispatch: any
  ) => {
    updateItemState(property, value, dispatch)
  }

  return {
    item,
    handleInputChange,
    handleEditorChange,
    updateStateByItem,
    setItems,
  }
}

export default useItem
