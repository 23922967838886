interface OptionProps {
  id: string
  title: string
  checked: boolean
  disabled: boolean
  onClick: () => void
}

const Option = ({ id, checked, disabled, onClick, title }: OptionProps) => {
  const noOp = () => {}
  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        id={id}
        checked={!!checked}
        onChange={noOp}
        disabled={disabled}
      />
      <label
        className="form-check-label size-london"
        htmlFor={id}
        onClick={disabled ? noOp : onClick}
      >
        {title}
      </label>
    </div>
  )
}

export default Option
