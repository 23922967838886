import React from 'react'
import styles from './ListMenuItem.module.scss'

export interface ItemListMenuItemProps {
  name: string
  icon?: React.ReactNode
  testId?: string
  isDisabled?: boolean
  isHidden?: boolean
  variant?: string
  tooltip?: string
  action?: () => void
  inVisibleMeny?: boolean
  className?: string
}

const ItemListMenuItem: React.FunctionComponent<ItemListMenuItemProps> = ({
  name,
  icon,
  testId,
  isDisabled = false,
  isHidden = false,
  variant,
  tooltip,
  action,
  className,
}) => (
  <>
    {!isHidden && (
      <div
        className={`${
          variant === 'outline'
            ? styles.menuItemOutline
            : variant === 'filled'
            ? styles.menuItemFilled
            : variant === 'primary'
            ? styles.menuItemPrimary
            : styles.menuItem
        } 
        ${className ? className : ''}
        ${tooltip ? 'tooltip_container' : ''}
        ${isDisabled ? styles.disable : isHidden ? styles.hide : ''}`}
      >
        <button
          data-testid={testId ? testId : ''}
          disabled={isDisabled}
          onClick={() => action && action()}
        >
          {icon && icon} <span>{name}</span>
        </button>
        {tooltip && <div className="tooltip">{tooltip}</div>}
      </div>
    )}
  </>
)
export default ItemListMenuItem
