import type { ICategory, IItem } from '../../IItem'
import ProListerSelect from '../../../Components/ProListerSelect'
import type { ISellItem } from '../../../SellView/ISellView'
import { useTranslation } from 'react-i18next'

interface ICategoryPickerProps {
  item: IItem | ISellItem
  categories: ICategory[]
  number: number
  defaultValueId?: string
  isValidCategoryLevel: boolean
  setSubCategories: (categories: ICategory[]) => void
  updateItemCategory: (c: ICategory) => void
  clearOtherCategoryOptions?: () => void
  clearAttributes: () => void
  setAttributeCategoryId: (id: string) => void
  getAttributes: (id: string) => void
}

const getNewCategoryObject = (
  item: IItem | ISellItem,
  chosenCategory: ICategory,
  number: number
) => {
  const cleanedCategory = { ...chosenCategory }
  delete cleanedCategory['category']
  if (number === 1) {
    return cleanedCategory
  } else if (number === 2) {
    return {
      ...item.category!,
      category: [cleanedCategory],
    }
  } else if (number === 3) {
    ;(item.category?.category as any)[0].category = [cleanedCategory]
    return item.category
  } else {
    ;(item.category?.category as any)[0].category[0].category = [
      cleanedCategory,
    ]
    return item.category
  }
}

const CategoryPicker = ({
  item,
  categories,
  number,
  defaultValueId,
  isValidCategoryLevel,
  setSubCategories,
  updateItemCategory,
  clearOtherCategoryOptions,
  clearAttributes,
  setAttributeCategoryId,
  getAttributes,
}: ICategoryPickerProps) => {
  const { t } = useTranslation()
  const hasCategories = categories && categories.length > 0
  const newItem = item.id && !item.id.startsWith('newitem') ? false : true

  if (!hasCategories) {
    return (
      <ProListerSelect
        options={[]}
        onChange={() => {}}
        placeholder={t('itemsForm_categoryPicker_placeholder')}
        disabledPlaceholder={
          t('itemsForm_categoryPicker_placeholderDisabled') + ` ${number}`
        }
      />
    )
  }

  return (
    <ProListerSelect
      options={categories.map((c) => {
        return { value: c.id, label: t('categories:category' + c.id + '_name') }
      })}
      defaultValueId={defaultValueId}
      onChange={(o) => {
        const chosenCategory = categories.find((c) => c.id === o.value)!
        const subCategories = chosenCategory.category
        const newCategory = getNewCategoryObject(item, chosenCategory, number)
        updateItemCategory(newCategory!)
        if (subCategories && subCategories.length > 0) {
          setSubCategories(subCategories)
          if (clearOtherCategoryOptions) {
            clearOtherCategoryOptions()
          }
          clearAttributes()
        } else {
          setAttributeCategoryId(o.value)
          if (clearOtherCategoryOptions) {
            clearOtherCategoryOptions()
          }
          getAttributes(o.value)
          setSubCategories([])
        }
      }}
      placeholder={t('itemsForm_categoryPicker_placeholder')}
      errorStyling={!isValidCategoryLevel && !newItem}
    />
  )
}

export default CategoryPicker
