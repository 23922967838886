import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import type { IItem } from './IItem'
import {
  addImagesSucceeded,
  createItemSucceeded,
  deleteImageSucceeded,
  selectItemInList,
  checkHighlightOnItemInList,
  checkRankUpOnItemInList,
  updateItemSucceeded,
  validateItemFromList,
} from './Items.action'
import { validateItem } from '../Infrastructure/Helpers/validation'

const initialState: any = null

const validate = (item: IItem) => {
  const resultOfValidation = validateItem(item)
  return {
    ...item,
    validationResult: resultOfValidation,
  }
}

const normalizeItem = (item: IItem, oldState: IItem) => {
  item.isChecked = oldState.isChecked
  item = validate(item)
  return item
}

const itemReducer: Reducer<IItem, IAction<any>> = handleActions(
  {
    [createItemSucceeded.toString()]: (_state: IItem, action: IAction<IItem>) =>
      validate(action.payload),
    [updateItemSucceeded.toString()]: (_state: IItem, action: IAction<IItem>) =>
      normalizeItem(action.payload, _state),
    [selectItemInList.toString()]: (state: IItem, action: IAction<any>) => ({
      ...state,
      isChecked: action.payload.isChecked,
    }),
    [validateItemFromList.toString()]: (state: IItem, action: IAction<any>) => {
      return {
        ...state,
        validationResult: action.payload.validationResult,
      }
    },
    [checkHighlightOnItemInList.toString()]: (
      state: IItem,
      action: IAction<any>
    ) =>
      validate({
        ...state,
        isHighlighted: action.payload.isHighligted,
      }),
    [checkRankUpOnItemInList.toString()]: (
      state: IItem,
      action: IAction<any>
    ) =>
      validate({
        ...state,
        rankUp: action.payload.rankUp,
      }),
    [addImagesSucceeded.toString()]: (state: IItem, action: IAction<any>) =>
      validate({
        ...state,
        imageUrls: [...state.imageUrls, ...action.payload.images],
      }),
    [deleteImageSucceeded.toString()]: (state: IItem, action: IAction<any>) =>
      validate({
        ...state,
        imageUrls: state.imageUrls?.filter(
          (image) => image !== action.payload.imageUrl
        ),
      }),
  },
  initialState
)

export default itemReducer
