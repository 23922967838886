import type { IAction } from '../Store/interfaces'
import type { Reducer } from 'react'
import { handleActions } from 'redux-actions'
import type { ITab } from './IContent'
import { addContent, changeTab } from './Content.actions'

const initialState: any = null

const ContentReducer: Reducer<ITab, IAction<any>> = handleActions(
  {
    [addContent.toString()]: (_state: ITab, action: IAction<ITab>) =>
      action.payload,
    [changeTab.toString()]: (state: ITab, _action: IAction<ITab>) => {
      return { ...state, selected: true }
    },
  },
  initialState
)

export default ContentReducer
